// import { startLoading, finishLoading } from '../loading/action.loadings';
import { GET, POST,PUT, POST_FORM, POST_FILE } from "./Action";
import {saveToken, setSignOut} from "../authentication/actions";
import cookie from "js-cookie";
import ROUTES from '../api-routes';
import authService from '../api-authorization/AuthorizeService';
export const API = 'API';


function checkStatus(response) {
    if (!response.ok) {
        throw {response, statusCode: response.status}
    }
    return response.json()
}

const refresh = (refreshToken, baseRoute) => {
    let params = new URLSearchParams();
    params.append('grant_type', 'refresh_token');
    params.append('refresh_token', refreshToken);
    params.append('client_id', 'android');
    // return fetch(baseRoute + 'Users/TokenInv', {
    //     method: 'POST',
    //     body: params
    // })
    // .then(res => {
    //     console.log('response in refreshing')
    //     if (!res.ok) {
    //         console.log(res)
    //         throw new Error(res.text());
    //     }
    //     return res.json()
    // })
    // .then(data => {
    //     console.log('json response in refresh')
    //     if (!data.access_token) {
    //         return null;
    //     }
    //     return data
    // })
    return authService.getAccessToken() .then(res => {
        //     console.log('response in refreshing');
            if (!res.ok) {
                throw new Error(res.text());
            }
            return res.json()
        })
        .then(token => {
            if (!token) {
                return null;
            }
            return token
        })
};


const api = ({ dispatch, getState }) => next => action => {

    if (action.type !== API) {
        return next(action);
    }

    let baseRoute = ROUTES.api;

    const { url, success, failed, method, data, loadingActionName } = action.payload;

    const _url = baseRoute + url;

    // if (loadingActionName) {
    //     dispatch(startLoading(loadingActionName));
    // }

    let request;
    let token = {};
 
    authService.getAccessToken().then(tokenInfo => {
        token = tokenInfo
        let authHeaders = {};
        if (token) {
            authHeaders = {
            'Authorization' : `Bearer ${token}`
            }
        }

        if(method == GET){
            request = fetch(_url, {
            headers: authHeaders
            })
        } else if(method == POST){
            request = fetch(_url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                ...authHeaders
            },
            body: JSON.stringify(data)
            })
        } else if(method == PUT){
            request = fetch(_url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeaders
                },
                body: JSON.stringify(data)
            })
        }else if(method ==POST_FILE){
            const formData = new FormData() ;
            console.log('api file', data.file)
            formData.append('file', data.file, data.file.name);
            request = fetch(_url, {
                method: 'POST',
                headers: {
                    // 'Content-Type': 'multipart/form-data',
                    ...authHeaders
                },
                body: formData
            })

        } else {
            request = fetch(_url, {
            method: 'POST',
            body: data,
            headers: authHeaders
            })
        }
        return request
        .then(checkStatus)
        .then(data => {
        /*if (schema) {
            data = normalize(data, schema);
        }*/
            if(success) {
                next(success(data));
            }
        // if (loadingActionName) {
        //     dispatch(finishLoading(loadingActionName, true));
        // }
        })
        .catch(error => {
            const statusCode = error.statusCode;
            if (statusCode == 401) {
                console.log('catch 401');
                cookie.set('tokenInfo', null);
                dispatch(saveToken(null, true));
                if (!token) {
                    return next(setSignOut(true))
                }
                return refresh(token, baseRoute)
                    .then(tokenInfo => {
                        if (tokenInfo) {
                            dispatch(saveToken(tokenInfo, true));
                            dispatch(action)
                        }
                    })
                    .catch((error) => {
                        console.error('There has been a problem with your fetch operation:', error);
                        return next(setSignOut(true))
                    });
            }
            else{
                console.log('in catching error in response', error);
                if (!error.response) {
                    if(failed) {
                        return next(failed({error: error.FetchError}));
                    }
                }
                else{
                    console.log(error.response);
                    error.response.text().then(error => {
                        if(failed) {
                            return next(dispatch(failed({statusCode, error , data})));
                        }
                        // if (loadingActionName) {
                        //     dispatch(finishLoading(loadingActionName, false));
                        // }
                    })
                }
            }
        })
    })

};

export default api;
