import {GET , POST , PUT , POST_FORM} from "../common/Action";
import Action from "../common/Action";


//actions
export const getDashboardContentAction  = new Action('GET_DASHBOARD_CONTENT_ACTION' , POST , 'api/Dashboard/GetDashboard') ;

//action creators
export const getDashboardContent = (data) => {
    return getDashboardContentAction.callApi(data);
};
