import {ApprovedAsyncVendorsAction , DisApprovedAsyncVendorsAction} from "./actions";

export default (state=[] , action) => {
  switch (action.type) {
      case ApprovedAsyncVendorsAction.resultAction :
            return {...action.payload.data , action : 'Approved'} ;
      case DisApprovedAsyncVendorsAction.resultAction :
            return {...action.payload.data , action : 'DisApproved'} ;
      default :
            return state ;
  }
};