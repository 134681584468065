import {AddTermsAndConditionsAction} from "./actions";
import _ from 'lodash'

export default (state={} , action) => {
    switch (action.type) {
        case AddTermsAndConditionsAction.resultAction:
            return {succeeded:action.payload.data.succeeded , key:action.payload.data.id , result: action.payload.data}
        default:
            return state;
    }
}