import Action, { POST ,GET } from "../common/Action"


export const AddLegalDocAction = new Action ('[legal docs] ADD_LEGAL_DOC_ACTION' , POST , 'api/LegalDocument/CreateLegalDocument')
export const getLegalDocsAction = new Action('[legal docs] GET_LEGAL_DOCS_ACTION' , POST , 'api/LegalDocument/GetAllLegalDocument')
export const updateLegalDocAction = new Action('[legal docs] UPDATE_LEGAL_DOC_ACTION', POST , 'api/LegalDocument/UpdateLegalDocument')
export const getLegalDocByIdAction = new Action('[legal docs] GET_LEGAL_DOC_BY_ID_ACTION', GET , (params)=>`api/LegalDocument/GetLegalDocument?id=${params.id}`)
export const getTermsAndConditionHistoryAction = new Action('[legal docs] GET_TERMS_AND_CONDITIONS_VERSION_HISTORY_ACTION' , POST , 'api/legalDocument/GetTermsAndConditionsVersionHistory')
export const getTermsAndConditionsAction = new Action('[terms and conditions] GET_TERMS_AND_CONDITIONS_ACTION' , POST , 'api/termsAndConditions/GetTermsAndConditions')


export const getTermsAndConditions = (data) =>{
    return getTermsAndConditionsAction.callApi(data)
}

export const AddLegalDoc = (data) =>{
    return AddLegalDocAction.callApi(data)
}

export const getLegalDocs =(data) =>{
    return getLegalDocsAction.callApi(data)
}

export const updateLegalDoc = (data) =>{
    return updateLegalDocAction.callApi(data)
}

export const getLegalDocById = (id) =>{
    return getLegalDocByIdAction.callApi(null , {id})
}
  
export const getTermsAndConditionsHistory = (data) =>{
    return getTermsAndConditionHistoryAction.callApi(data)
}