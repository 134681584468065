// import reducerGetAllUsers from "./reducer.getAllUsers";
// import reducerGetAllAcceptances from './reducer.getAllAcceptance'
// import reducerGetAllTermsVersions from './reducer.allVersions'
// import reducerGetDatasetAllAcceptance from './reducer.getAllDatasetAcceptance'
import reducerGetTermsAndConditions from './reducer.getTermsAndConditions'
import reducerGetResults from './reducer.getResults'
import reducerGetTermsAndConditionsDatasets from './reducer.getTermsAndConditionsDatasetList'
import reducerAddNewTermsGetResults from './reducer.addNewTerm'
import reducerAllTemplates from './reducer.AllTemplates';
export default {
//    TermsAndConditionsGetAllDatasetUsers:reducerGetAllUsers,
   TermsAndConditionsGetAllTermsAndConditions:reducerGetTermsAndConditionsDatasets,
//    TermsAndConditionsGetAllAcceptances: reducerGetAllAcceptances,
//    TermsAndConditionsGetAllTermsVersions:reducerGetAllTermsVersions,
//    TermsAndConditionsGetDatasetAllAcceptances:reducerGetDatasetAllAcceptance,
   TermsAndConditionsGetTermsAndConditions:reducerGetTermsAndConditions,
   TermsAndConditionsGetResults:reducerGetResults,
   TermsAndConditionsAddTermsGetResults:reducerAddNewTermsGetResults,
   TermsAndConditionsAllTemplates:reducerAllTemplates
};