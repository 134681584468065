import React , {useState , useEffect} from 'react' ;
import {connect} from 'react-redux' ;
import classNames from "classnames";
import Select from "react-select";
import {dropDownStyles} from "./formFields";

const SelectBox = props => {

    const {onChange , value , options , styles , label} = props ;

    return (
        <div className={classNames(`inputBox input-width_170`)}>
            {label ? <label>{label}</label> : null}
            <Select
                value={value}
                onChange={onChange}
                options={options}
                styles={styles}
            />
        </div>
    );
};

const mapStateToProps = (state , ownProps) => {
  return {

  };
};

export default connect(mapStateToProps , {})(SelectBox) ;