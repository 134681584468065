import Action, {GET , POST , PUT , POST_FORM} from "../common/Action"

//actions
export const getLanguagesAction = new Action('GET_LANGUAGES_ACTION' , GET , 'api/Languages/GetAll') ;
export const newLanguageAction = new Action('NEW_LANGUAGE_ACTION' , POST , 'api/Languages/Add');
export const editLanuageAction = new Action('UPDATE_LANGUAGE_ACTION' , POST , 'api/Languages/update');

//action creators

export const getLanguages = () => {
    return getLanguagesAction.callApi() ;
};

export const addNewLanguage = (data) =>{
    return newLanguageAction.callApi(data);
}

export const updateLanguage = (data) =>{
    return editLanuageAction.callApi(data)
}