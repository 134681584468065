import React , {useState , useEffect} from 'react' ;
import {connect} from 'react-redux' ;
import LeftNav from '../components/left-menu' ;
import TableComponent from '../components/TableComponent';
import {GetAllApprovalRule , AddApprovalRule ,DeleteApprovalRule , getAllDataprovider , getAllEntityTypes} from "./actions";
import _ from 'lodash'
import { Fragment } from 'react';
import cssClasses from '../ApprovedVendors/ApprovedVendorListStyles.module.css'
import classNames from 'classnames';
import SimpleLoading from '../components/simpleLoading';
import NewApprovalRuleModal from './NewApprovalRuleModal'
import DeleteModal from '../components/cmp.modal' ;

const MainPage = props => {

    const {allApprovalRule , approvalRuleResult , allDataprovider , allEntityTypes} = props
    const [rows , setRows] = useState(null)
    const [total , setTotal] = useState(0)
    const [dtParameter , setDtParameter] = useState({current:1 , pageSize:10 , filter:null})
    const [loading , setLoading] = useState(false)
    const [columns , setColumns] = useState(null)
    const [showModal , setShowModal] = useState(false)
    const [selectedApprovalRule , setSelectedApprovalRule] = useState(null)
    const [showDeleteModal , setShowDeleteModal] = useState(false)

    useEffect(() => {
        setLoading(true)
        props.GetAllApprovalRule({dtParameter:dtParameter}) ;
    } , [dtParameter]) ;

    useEffect(()=>{
        props.getAllDataprovider()
        props.getAllEntityTypes()
    },[])


    useEffect(()=>{
        if(!approvalRuleResult)
            return
        setShowModal(false)
        setShowDeleteModal(false)
        if(approvalRuleResult.succeeded){
            props.GetAllApprovalRule({dtParameter:{...dtParameter , dir:"desc",orderBy:"lastChangeStatusDate"}})
        }else{
            console.log('Error happend try again later')
        }
    },[approvalRuleResult])

    useEffect(()=>{
        if(!allDataprovider)
            return
    },[allDataprovider])


    useEffect(()=>{
        if(!allApprovalRule||_.isEmpty(allApprovalRule))
            return
        if(!columns){
            setColumns(allApprovalRule.columns)
        }
        setLoading(false)
        setRows(allApprovalRule.data)
        setTotal(allApprovalRule.total)
    },[allApprovalRule])

    
    const AddNewApprovalRule = (values) =>{
        props.AddApprovalRule(values)
    }

    const onConfirmDeleteClick = () =>{
        props.DeleteApprovalRule(selectedApprovalRule)
        setSelectedApprovalRule(null)
    }

    return (
        <>
            <div className="mainHeader organizer font-size-24 mediumWeight">
                <div className="titleItems">
                    <span className="mainTitle middleContext">Approval Rules</span>
                </div>
                <div className="filterItems">
                    <div className="filters inlineView">
                        <div className="changeDate inlineView"></div>
                        <div className="accessLevel inlineView"></div>
                    </div>
                    <Fragment>
                        <span style={{marginRight:'10px'}} onClick={()=>setShowModal(true)} className={classNames(cssClasses.cursorPointer , "createNewPlan font-size-18")}>
                            Add New Approval Rule
                        </span>
                        {selectedApprovalRule?
                        <span style={{marginRight:'10px'}} onClick={()=>setShowDeleteModal(true)} className={classNames(cssClasses.cursorPointer , "createNewPlan font-size-18")}>
                            Delete Approval Rule
                        </span>
                        :null}
                    </Fragment>
                </div>
            </div>
        {rows&&columns?
            <div className="adminBoilerplate organizer">
                <TableComponent
                    pageSizes={[5,10,15]} 
                    totalCount={total} 
                    setDtParameter={setDtParameter} 
                    loading = {loading}
                    dtParameter = {dtParameter}
                    rows={rows} 
                    columns={columns}
                    api="api/approvalRule/GetAll"
                    selectedList={selectedApprovalRule? [selectedApprovalRule.id]:[]}
                    setSelectedList = {(list)=> setSelectedApprovalRule(rows.filter(r => r.id == list[0])[0])}
                    selectable={true}
                    />
            </div>
        :<SimpleLoading/>}
        {showModal?
            <NewApprovalRuleModal entityTypes={allEntityTypes} allDataprovider={allDataprovider} title={"Add new Approval Rule"} closeModal={()=>setShowModal(false)} onSubmit={AddNewApprovalRule} /> 
        :null}
        {showDeleteModal && selectedApprovalRule?
            <DeleteModal closeModal={() => setShowDeleteModal(false)} 
            acceptAction={onConfirmDeleteClick} data={null} 
            title="Delete ApprovalRule"
            modalMessage={`Are you sure you want to remove auto ${selectedApprovalRule.entityTypeString}  approval from ${selectedApprovalRule.dataProviderUserName}?`} 
            // resultMessage={resultMessage}
        /> 
        :null}
        </>
  ) ;
};

const mapStateToProps = (state , ownProps) => {
    return {
        allApprovalRule : state.ApprovedAsyncRules,
        approvalRuleResult : state.ApprovedRulesResults,
        allDataprovider : state.ApprovedRulesAllDataprovider,
        allEntityTypes :state.ApprovedRulesAllEntityTypes
    } ;
};

export default connect(mapStateToProps , {GetAllApprovalRule , AddApprovalRule , DeleteApprovalRule , getAllDataprovider , getAllEntityTypes})(MainPage) ;


