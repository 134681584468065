import React , {useState , useEffect} from 'react' ;
import {connect} from 'react-redux' ;
import LeftNav from '../components/left-menu' ;
import TableComponent from '../components/TableComponent';
import {GetApprovableAsyncPerson , ApprovedAsyncPersons , DisApprovedAsyncPersons, setPriority} from "./actions";
import _ from 'lodash'
import { Fragment } from 'react';
import cssClasses from '../ApprovedVendors/ApprovedVendorListStyles.module.css'
import classNames from 'classnames';
import { Link, withRouter } from 'react-router-dom';
import SimpleLoading from '../components/simpleLoading';
const MainPage = props => {
    const {personList , ApprovedActionResult} = props
    const [selectedPersons , setSelectedPersons] = useState([])
    const [rows , setRows] = useState(null)
    const [total , setTotal] = useState(0)
    const [dtParameter , setDtParameter] = useState({current:1 , pageSize:10 , filter:null})
    const [loading , setLoading] = useState(false)
    const [columns , setColumns] = useState(null
        // {field:'email' , headerName:'email' , flex:1}
    //   {field:'uniqueUrl' , headerName:t('Unique URL'),flex:1},
    //   {field:'description' , headerName:t('Description'),flex:1},
    //   {field:'id',headerName:t('َActions') , flex:0.5 , renderCell: (params) => (
    //     <Fragment>
    //     <div className="cardListDetailToolsItem blueBackground clickShape whiteColor" 
    //       onClick={()=>history.push(`/datasetRegistration/${params.value}`)}>
                  
    //                   <span className="icon icon-edit whiteColor"></span>
    //             </div>  
    //             <div
    //             onClick={() =>
    //               deleteDataEntry(params.value)
    //             }
    //             className="cardListDetailToolsItem redBackground clickShape whiteColor"
    //           >
                
    //             <span className="icon icon-close whiteColor"></span>
    //         </div> 
    //         </Fragment>
    //   )}
    )

    

    useEffect(() => {
        setLoading(true)
        props.GetApprovableAsyncPerson({dtParameter:dtParameter}) ;
    } , [dtParameter]) ;


    useEffect(()=>{
        if(!ApprovedActionResult)
            return
        setSelectedPersons([])
        if(ApprovedActionResult.succeeded){
            props.GetApprovableAsyncPerson({dtParameter:{...dtParameter ,current:1 ,dir:"desc",orderBy:"lastChangeStatusDate"}})
            setDtParameter({...dtParameter , current:1 ,dir:"desc",orderBy:"lastChangeStatusDate"})
        }else{
            console.log('Error happend try again later')
        }
    },[ApprovedActionResult])


    useEffect(()=>{
        if(!personList||_.isEmpty(personList))
            return
        var cols  = personList.columns.map(col=>{
            if(col.field && col.field=='priority'){
                return ({
                    ...col ,  editable:true
                })
            }
            if(!col.hasRenderCell)
                return col
            if(col.field&&col.field.includes('image')||col.field=='banner'){
                return ({...col , renderCell:(param)=>(<img src={param.value}/>)})
            }
            if(col.field&&col.type=='list'){
                return ({...col , type:null , renderCell:(params)=>(
                    _.map(
                          params.value,
                          (key , index) => {
                                return (
                                  <span
                                  key={index}
                                  className={cssClasses.keyItem}
                                  >
                                    {key}
                                  </span>
                                );
                              }
                              )
                            
                    
                  )})
            }})
        cols.push({
            field:'id', flex:1 , minWidth:200 , headerName:'Action' , renderCell:(params)=>(
                <Link to={{pathname:`${process.env.REACT_APP_DATAPROVIDER_API}/person/${params.value}`}}  target='_blank' style={{textDecoration : 'none' , color:'black'}}><span className="giveAccess blueBackground clickShape">
                    <span className="icon icon-right-sign-arrow whiteColor"></span>
                    </span>
                </Link>
            )
        })
        setColumns(cols)
        
        setLoading(false)
        setRows(personList.data)
        setTotal(personList.total)
    },[personList])


    const approvePersons = ()=>{
        if(_.isEmpty(selectedPersons))
            return
        const approvalDto = {ids:selectedPersons , approvedStatus:2 , adminMessage:''}
        props.ApprovedAsyncPersons(approvalDto)
    }
    const NotApprovePersons = ()=>{
        if(_.isEmpty(selectedPersons))
            return
        const approvalDto = {ids:selectedPersons , approvedStatus:3 , adminMessage:''}
        props.DisApprovedAsyncPersons(approvalDto)
    }

    const onSortValueChange = (id, value) =>{
        props.setPriority({
            Ids:[id],
            Priority:value
        })
    }
    

    return (
        <>
            <div className="mainHeader organizer font-size-24 mediumWeight">
                <div className="titleItems">
                    <span className="mainTitle middleContext">Approved Persons</span>
                </div>
                <div className="filterItems">
                    <div className="filters inlineView">
                        <div className="changeDate inlineView"></div>
                        <div className="accessLevel inlineView"></div>
                    </div>
                    {!_.isEmpty(selectedPersons)?
                    <Fragment>
                        <span style={{marginRight:'10px'}} onClick={approvePersons} className={classNames(cssClasses.cursorPointer , "createNewPlan font-size-18")}>
                            Approve
                        </span>
                        <span onClick={NotApprovePersons} className={classNames(cssClasses.cursorPointer,"font-size-18 createNewPlan")}>
                            DisApprove
                        </span>
                    </Fragment>
                    :null}
                </div>
            </div>
            {rows&&columns?
              <div className="adminBoilerplate organizer">
                    <TableComponent
                        pageSizes={[5,10,15]} 
                        checkboxSelection={true}
                        totalCount={total} 
                        selectedList = {selectedPersons}
                        setSelectedList={setSelectedPersons} 
                        setDtParameter={setDtParameter} 
                        loading = {loading}
                        dtParameter = {dtParameter}
                        rows={rows} 
                        columns={columns}
                        getRowClassName={(params) => 
                            cssClasses[`row--${params.row.approvedStatus.split(' ').join('')}`]
                        }
                        api = 'api/person/GetApprovable'
                        hasSort={true}
                        changeSortColumnValue={onSortValueChange}
                        />
              </div>
            :<SimpleLoading/>}
        </>
  ) ;
};

const mapStateToProps = (state , ownProps) => {
    return {
        personList : state.ApprovedAsyncPersons,
        ApprovedActionResult : state.ApprovedPersonsResults,
    } ;
};

export default connect(mapStateToProps , {GetApprovableAsyncPerson , ApprovedAsyncPersons , DisApprovedAsyncPersons,setPriority})(withRouter(MainPage)) ;


