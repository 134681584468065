import {getExtensionsAction } from "./actions";

export default (state={} , action) => {
    switch (action.type) {
        case getExtensionsAction.resultAction :
            return {...state ,
                totalCount:action.payload.data.total, 
                extensions:{...state.extensions , [action.payload.postedData.PageNumber] : action.payload.data.data}
            };
        default :
            return state ;
    }
};