import React , {useState , useEffect} from 'react'
import { change, Field, reduxForm } from 'redux-form'
import {connect} from "react-redux";
import {renderSimpleInputField , renderTextAreaField , renderDropdown , SelectTelCodeInput , telephoneInput} from "../components/formFields";
import _ from 'lodash' ;
import classNames from 'classnames' ;
import { getAllAccessLevel , getUserDropDownsData } from './actions';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

export const salutationOptions = [
    {label : 'Mr.' , value : 1} ,
    {label: 'Ms.' , value: 2} ,
    {label : 'Dr.' , values : 3}
];


const validate = (values) => {
    let errors = {};

    if (!values.email) {
        errors.email = 'Email is Required' ;
    }
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Email is not Valid' ;
    }
    // if (!values.password) {
    //     errors.password = 'Password is Required' ;
    // }else if(values.password.length<6){
    //     errors.password ="Passwords must be at least 6 characters."
    // }
    // if (!values.salutation || _.isEmpty(values.salutation)) {
    //     errors.salutation = 'Salutation is Required' ;
    // }
    // if (!values.firstName) {
    //     errors.firstName = 'First Name is Required' ;
    // }
    // if (!values.lastName) {
    //     errors.lastName = 'Last Name is Required' ;
    // }
    if (!values.accessLevel || _.isEmpty(values.accessLevel)) {
        errors.accessLevel = 'Access Level is Required' ;
    }
    // if (!values.country || _.isEmpty(values.country)) {
    //     errors.country = 'Country is Required' ;
    // }
    // if (!values.phone) {
    //     errors.phone = 'Phone Number is Required' ;
    // }
    // else if (!/^\d+$/.test(values.phone)) {
    //     errors.phone = 'Phone Number is not Valid' ;
    // }
    // if (!values.position || _.isEmpty(values.position)) {
    //     errors.position = 'Position is Required' ;
    // }
    // if (!values.institution) {
    //     errors.institution = 'Institution is Required' ;
    // }
    // if (!values.typeOfIndustry || _.isEmpty(values.typeOfIndustry)) {
    //     errors.typeOfIndustry = 'Type of Industry is Required' ;
    // }
    // if (!values.addressLine1) {
    //     errors.addressLine1 = 'Address Line 1 is Required' ;
    // }
    // if (!values.addressLine2) {
    //     errors.addressLine2 = 'Address Line 2 is Required' ;
    // }
    // if (!values.city) {
    //     errors.city = 'City is Required' ;
    // }
    // if (!values.state) {
    //     errors.state = 'State is Required' ;
    // }
    // if (!values.postalCode) {
    //     errors.postalCode = 'Postal Code is Required' ;
    // }
    // else if (!/^\d+$/.test(values.postalCode)) {
    //     errors.postalCode = 'Postal Code is not Valid' ;
    // }
    return errors ;
};

let UserForm = props => {
    const { handleSubmit , change , edit , closeModal  , userDropDownsData , formResultMessage , homeData , getAllAccessLevel} = props;
    const user = props.initialValues
    const [accessLevels , setAccessLevels] = useState([])

    useEffect(()=>{
        props.getAllAccessLevel()
        props.getUserDropDownsData()
    },[])

    
    useEffect(()=>{
        if(!homeData && !user)
            return 
        if(user.salutation)
            change('userForm' , 'salutation' , salutationOptions.filter(s=> s.label == user.salutation)[0])
        if(homeData.type=='GET_ALL_ACCESS_LEVEL'){
            setAccessLevels(homeData.data.map(acc=>({value:acc.id , label:acc.tireName})))
            if(user && user.accessLevelId){
                change('userForm','accessLevel' , homeData.data.filter(acc => acc.id == user.accessLevelId).map(acc=>({value:acc.id , label:acc.tireName}))[0])
            }
        }else{
            if(user.positionId){
                change('userForm' , 'position' , homeData.positions.filter(p=> p.id == user.positionId).map(p=>({value: p.id , label:p.label}))[0])
            }
            if(user.industryId){
                change('userForm' , 'typeOfIndustry' , homeData.industries.filter(p=> p.id == user.industryId).map(industry=>({label : industry.title , value : industry.id}))[0])
            }
            if(user.countryId || user.phoneCountryId){
                change('userForm' , 'country' , homeData.countries.filter(p=> p.id == user.countryId).map(country=>({label : country.name , value : country.id}))[0])
                change('userForm' , 'phoneCountryId' , homeData.countries.filter(p=> p.id == user.countryId)[0])
            }
        }
    },[homeData , user])


    return (
        <div className="popup profiles" style={{overflow:'hidden'}}>
            <div className="popupClose"></div>
            <div className="popUpBox columnContent">
                <div className="popupHeader spaceBetween organizer">
                    <div className="popUpTitle font-size-24 mediumWeight">
                        {edit ? "Edit User" : "Add New User"}
                    </div>
                    <div className="popupCross clickShape" onClick={closeModal}>
                        <span className="icon icon-cross font-size-18 lightGrayColor"></span>
                    </div>
                </div>
                <div className="popUpContent organizer" style={{overflowY:'scroll' , maxHeight:'85%'}}>
                    <Field name='email' disabled={edit?true:false} widthClass={2} cssClasses="leftNormalMargin" component={renderSimpleInputField} label='Email'/>
                    {/* <Field name='password' widthClass={2} cssClasses="leftNormalMargin" component={renderSimpleInputField} label='Password' type="password"/> */}
                    <Field name='position' widthClass={2} cssClasses="leftNormalMargin" component={renderDropdown} options={_.map(userDropDownsData.positions , position => {
                        return {label : position.label , value : position.id};
                    })} label='Position'/>
                    <Field name='accessLevel' widthClass={1} cssClasses="leftNormalMargin" component={renderDropdown} options={accessLevels} label='Access Level'/>
                    <Field name='salutation' widthClass={1} cssClasses="leftNormalMargin" component={renderDropdown} options={salutationOptions} label='Salutation'/>
                    <Field name='firstName' widthClass={1} cssClasses="leftNormalMargin" component={renderSimpleInputField} label='First Name'/>
                    <Field name='lastName' widthClass={1} cssClasses="leftNormalMargin" component={renderSimpleInputField} label='Last Name'/>
                    <Field name='country' widthClass={2} cssClasses="leftNormalMargin" component={renderDropdown} options={_.map(userDropDownsData.countries , country => {
                        return {label : country.name , value : country.id};
                    })} label='Country'/>
                    {/* <div className="row topUnitMargin col-lg-6 col-md-6 col-sm-12"> */}
                    <div className={classNames("row_instance leftNormalMargin topUnitMargin input-width_300")}>
                        <div className="setPrice inputBox">
                            <label>Telephone</label>
                            <div className="input-group organizer ">
                                <Field name='phoneCountryId' component={ SelectTelCodeInput} 
                                    options={userDropDownsData.countries}
                                />
                                <Field name='phoneNumber' type='tel' component={telephoneInput} />
                            </div>
                        </div>
                    </div>


                    {/* <Field name='phoneNumber' widthClass={1} cssClasses="leftNormalMargin" component={renderSimpleInputField} label='Phone'/> */}
                    <Field name='institution' widthClass={2} cssClasses="leftNormalMargin" component={renderSimpleInputField} label='Institution'/>
                    <Field name='typeOfIndustry' widthClass={1} cssClasses="leftNormalMargin" component={renderDropdown} options={_.map(userDropDownsData.industries , industry => {
                        return {label : industry.title , value : industry.id};
                    })} label='Type of Industry'/>
                    <Field name='city' widthClass={1} cssClasses="leftNormalMargin" component={renderSimpleInputField} label='City'/>
                    <Field name='state' widthClass={1} cssClasses="leftNormalMargin" component={renderSimpleInputField} label='State'/>
                    <Field name='postalCode' widthClass={1} cssClasses="leftNormalMargin" component={renderSimpleInputField} label='Postal Code'/>
                    <Field name='addressLine1' cssClasses="leftNormalMargin" component={renderTextAreaField} label='Address Line 1'/>
                    <Field name='addressLine2' cssClasses="leftNormalMargin" component={renderTextAreaField} label='Address Line 2'/>


                    <div className="organizer aggregateAction inlineView clickShape leftNormalMargin topUnitMargin" onClick={handleSubmit}>
                        <div className="normalButton inlineView blueBackground whiteColor">
                            {edit ? "Update User" : "Add User"}
                        </div>
                    </div>
                    {formResultMessage ? <div className={classNames("topNormalMargin" , {'redColor' : !formResultMessage.success , 'greenColor' : formResultMessage.success})}>{formResultMessage.message}</div> : null}
                </div>
            </div>
        </div>
    ) ;
};

const mapStateToProps = state => {
    return {
        userDropDownsData : state.userDropDownsData,
        homeData : state.userHomeData
    };
};

const form = connect(mapStateToProps , {change, getAllAccessLevel , getUserDropDownsData})(UserForm);

const UserInfoForm = reduxForm({
    // a unique name for the form
    form: 'userForm',
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    enableReinitialize : true ,
    validate
})(form);

export default withRouter(withTranslation()(UserInfoForm));