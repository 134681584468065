import {getUsageHistory} from "./actions";
import React , {useState , useEffect} from 'react' ;
import {connect} from 'react-redux' ;
import TableComponent from '../components/TableComponent';
import _ from 'lodash'
import { Fragment } from 'react';
import cssClasses from '../ApprovedVendors/ApprovedVendorListStyles.module.css'
import classNames from 'classnames';
import SimpleLoading from "../components/simpleLoading";
import { Link, withRouter } from "react-router-dom";

const MainPage = props => {

    const {usageHistories} = props ;

    const [rows , setRows] = useState(null)
    const [total , setTotal] = useState(0)
    const [dtParameter , setDtParameter] = useState({current: 1, pageSize: 10, filter: null, dir: null, orderBy: null})
    const [loading , setLoading] = useState(false)
    const [columns , setColumns] = useState(null)
    
    useEffect(() => {
        if(!dtParameter)
            return
        setLoading(true)
        props.getUsageHistory({dtParameter:dtParameter}) ;
    } , [dtParameter]) ;

    useEffect(()=>{
        if(!usageHistories||_.isEmpty(usageHistories))
            return
        const cols = usageHistories.columns.map(col=>{
            if(!col.hasRenderCell){
                return col
            }else if(col.field == 'endTime' || col.field == 'dateTime'){
                return ({...col , renderCell:(param)=>(
                    <div className="displayFlex mediumWeight">
                        {_.join(_.split(_.split(param.value , 'T')[0] , '-') , '/')}
                        <p className="font-size-12 lightWeight">{_.split(param.value , 'T')[1].substring(0 , _.indexOf(_.split(param.value , 'T')[1] , '.'))}</p>
                    </div>
                )})
            }
        })
        cols.push({
            field:'id', flex:1 , minWidth:200 , headerName:'Action' , renderCell:(params)=>(
                <Link to={`/usageHistoryInner/${params.value}`} style={{textDecoration : 'none' , color:'black'}}><span className="giveAccess blueBackground clickShape">
                    <span className="icon icon-right-sign-arrow whiteColor"></span>
                    </span>
                </Link>
            )
        })
        setColumns(cols)
        setLoading(false)
        setRows(usageHistories.data)
        setTotal(usageHistories.total)
    },[usageHistories])


    return (
        <Fragment>
            {!rows||!columns?
                <SimpleLoading/>
            :
            <Fragment>
                <div className="mainHeader organizer font-size-24 mediumWeight">
                    <div className="titleItems">
                        <span className="mainTitle middleContext">Usage History</span>
                    </div>
                </div>
                <div className="adminBoilerplate organizer">
                    <TableComponent
                        checkboxSelection={false}
                        selectedList = {[]}
                        setSelectedList = {(e)=> console.log(e)}
                        pageSizes={[5,10,15]} 
                        totalCount={total} 
                        setDtParameter={setDtParameter} 
                        loading = {loading}
                        dtParameter = {dtParameter}
                        rows={rows} 
                        columns={columns}
                        api = "api/history/GetUsageHistory"
                    />
                </div>
            </Fragment>
            }
        </Fragment>
  ) ;
};

const mapStateToProps = (state , ownProps) => {
    return {
        usageHistories : state.usageHistories ,
    } ;
};

export default connect(mapStateToProps , {getUsageHistory})(withRouter(MainPage)) ;