import {GET , POST , PUT , POST_FORM} from "../common/Action";
import Action from '../common/Action' ;


//actions
export const getDetailedUsageHistoryAction = new Action('GET_DETAILED_USAGE_HISTORY' , GET , (param)=>`api/history/GetDetailUsageHistory?id=${param.id}`) ;


//action creators
export const getDetailUsageHistory = (id) => {
    return getDetailedUsageHistoryAction.callApi(null , {id}) ;
};