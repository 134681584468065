import React , {useState , useEffect, Fragment} from 'react' ;
import {connect} from 'react-redux' ;
import LeftNav from '../components/left-menu' ;
import Pagination from "../components/pagination";
import {getAllPolls , deletePoll , getPoll , updatePoll} from "./actions";
import _ from 'lodash' ;

const MainPage = props => {

    const {commissions , commissionsTotalCount} = props ;

    const [pageNumber , setPageNumber] = useState(1) ;
    const [viewByPage , setViewByPage] = useState(8) ;

    useEffect(() =>{
        const data = {
            PageNumber : pageNumber ,
            PageSize : viewByPage
        };
        props.getAllPolls(data) ;
    } , [pageNumber , viewByPage]) ;

    const updateList = (selectedPageNumber  , selectedViewByPage) => {
        setPageNumber(selectedPageNumber) ;
        setViewByPage(selectedViewByPage) ;
    };
  return (
      <Fragment>
              <div className="mainHeader organizer font-size-24 mediumWeight">
                  <div className="titleItems" style={{width : 'unset'}}>
                      <span className="mainTitle middleContext">Commissions</span>
                      
                  </div>
                  
              </div>
              <div className="adminBoilerplate organizer">
                  <table className="tableStructure organizer">
                      <thead className="tableHeader">
                      <th>Full Name</th>
                      <th>Email</th>
                      <th>Date</th>
                      <th>Type</th>
                      <th>Status</th>
                      </thead>
                      {commissions[pageNumber] ? _.map(commissions[pageNumber] , commission => {
                        return (
                            <tr>
                                <td>{`${commission.user.salutation}${commission.user.firstName} ${commission.user.lastName}`}</td>
                                <td>{commission.email}</td>
                                <td>{_.split(commission.registerDate , 'T')[0]}</td>
                                <td>Lattary</td>
                                <td className="spaceBetween fullWidth greenColor">Assigned To Question
                                    <span className="giveAccess blueBackground">
                            <span className="icon icon-right-sign-arrow whiteColor"></span>
                        </span></td>
                            </tr>
                        );
                      })  : null}
                  </table>
                  <Pagination pageNumber={pageNumber} viewByPage={viewByPage} changeData={updateList} totalPages={Math.ceil(commissionsTotalCount/viewByPage)}/>
              </div>
        </Fragment>
  ) ;
};

const mapStateToProps = (state , ownProps) => {
    return {
        commissions : state.commissions ,
        commissionsTotalCount : state.commissionsTotalCount
    } ;
};

export default connect(mapStateToProps , {getAllPolls , getPoll , deletePoll , updatePoll})(MainPage) ;