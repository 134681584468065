import Action, { POST ,GET } from "../common/Action"

export const GetAllPagesAction = new Action('GET_ALL_PAGES_ACTION' , POST, 'api/MetaTag/getAllPages');
export const GetPageMetaTagsAction  = new Action("GET_PAGES_META_TAGS_ACTION" , POST , 'api/MetaTag/getPageMetaTags');
export const GetCustomPageMetaTagsAction = new Action("GET_CUSTOM_PAGE_META_TAGS_ACTION" , POST , 'api/MetaTag/getCustomPageMetaTags');
export const UpdateMetaTagAction = new Action('UPDATE_META_TAG_ACTION' , POST , 'api/MetaTag/Update')


export const getAllPages = (data) =>{
    return GetAllPagesAction.callApi(data)
}

export const getPageMetaTags = (data) =>{
    return GetPageMetaTagsAction.callApi(data)
}

export const getCustomPageMetaTags = (data) =>{
    return GetCustomPageMetaTagsAction.callApi(data)
}

export const updateMetaTag = (data) =>{
    return UpdateMetaTagAction.callApi(data)
}
