import _ from 'lodash'
import React, { useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Field, reduxForm, change, reset } from 'redux-form'
import { renderDropdown , renderSimpleInputField } from '../components/formFields'

const NewLanguageForm = props => {
    const {change , reset , handleSubmit , isNew} = props;
    
    useEffect(()=>{
        if(_.isEmpty(props.initialValues)){
            change('reduxNewLanguageForm', "name","")
            change('reduxNewLanguageForm', "code","")
            change('reduxNewLanguageForm', "direction",null)
        }else{
            change('reduxNewLanguageForm', "name",props.initialValues.name)
            change('reduxNewLanguageForm', "code",props.initialValues.code)
            change('reduxNewLanguageForm', "direction",props.initialValues.direction)
        }
    },[props.initialValues])

    return(
        <div>
            <div className="organizer">
                <div className="inputBox input-width_170">
                    <Field component={renderDropdown} label='Direction' name='direction' options={[{value:'RTL' , label:'RTL'},{value:'LTR' , label:'LTR'}]}  /> 
                </div>
            </div>
            <div className="organizer topUnitMargin">
                <div className="inputBox input-width_300">
                    <Field component={renderSimpleInputField} label='Language Name' name='name' type="text"/>
                </div>
            </div>
            <div className="organizer topUnitMargin">
                <div className="inputBox input-width_300">
                    <Field component={renderSimpleInputField} label="Language Code" name='code' type="text"/>
                </div>
            </div>
            <div className="organizer topNormalMargin">
                <div onClick={handleSubmit} className="normalButton clickShape inlineView blueBackground whiteColor">
                    {isNew? "Add":"Save Changes"}
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = (state, ownProps) => {
    return {
    };
};

const form = connect(mapStateToProps, { change , reset
})(withRouter(withTranslation()(NewLanguageForm)));
  

const ReduxLanguageForm = reduxForm({
  // a unique name for the form
  form: 'reduxNewLanguageForm'
})(form)

export default withRouter(withTranslation()(ReduxLanguageForm));
