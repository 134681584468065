import React , {useState , useEffect ,Fragment} from 'react' ;
import {connect} from 'react-redux' ;
import LeftNav from '../components/left-menu' ;
import {Link, useParams , withRouter} from 'react-router-dom' ;
import {getDetailUsageHistory} from './actions.js'
import _ from 'lodash';
import SimpleLoading from '../components/simpleLoading';

const MainPage = props => {
    const {detailUsageHistory} = props
    const params = useParams()
    const [isLoading , setIsLoading] = useState(false)

  useEffect(() => {
      if(params.id){
          setIsLoading(true)
          props.getDetailUsageHistory(params.id)
      }
  } ,[]);

  useEffect(()=>{
    if(!detailUsageHistory && _.isEmpty(detailUsageHistory)){
        return
    }
    setIsLoading(false)
  },[detailUsageHistory])
  
  return (
      isLoading?
        <SimpleLoading/>:
        detailUsageHistory?
      <Fragment>
              <div className="mainHeader organizer font-size-24 mediumWeight">
                  <div className="titleItems">
                <span className="mainTitle middleContext">
                    Usage History
                    <span className="icon icon-right-direction font-size-14"></span>
                    <span className="font-size-18 mediumWeight">{detailUsageHistory.firstName} {detailUsageHistory.lastName}</span>
                </span> 
                    {detailUsageHistory.userId?
                    <Link to={`/users/${detailUsageHistory.userId}`}>
                      <div className="visitUser blueColor font-size-14 inlineView"><span
                          className="icon icon-visit"></span> Visit
                          User
                      </div>
                    </Link>
                    :null}
                  </div>
                  <div className="filterItems">
                      <div className="filters inlineView">
                          <div className="changeDate inlineView"></div>
                          <div className="accessLevel inlineView"></div>
                      </div>
                      <span className="icon icon-zoom font-size-18"></span>
                  </div>
              </div>
              <div className="adminBoilerplate organizer">
                  <div className="userInnerBox organizer">
                      <div className="innerTopShelf organizer">
                          <div className="innerUserId">
                              <div className="innerTitle font-size-18 mediumWeight">User ID</div>
                              <div className="innerContext font-size-14 topUnitMargin">{detailUsageHistory.userId?detailUsageHistory.userId:'-'}</div>
                          </div>
                          <div className="innerEmail">
                              <div className="innerTitle font-size-18 mediumWeight">Email</div>
                              <div className="innerContext font-size-14 topUnitMargin">{detailUsageHistory.email?detailUsageHistory.email:'-'}
                              </div>
                          </div>
                          <div className="innerIp">
                              <div className="innerTitle font-size-18 mediumWeight">IP Address</div>
                              <div className="innerContext font-size-14 topUnitMargin">{detailUsageHistory.ipAddress?detailUsageHistory.ipAddress:'-'}</div>
                          </div>
                          <div className="innerAccessLevel">
                              <div className="innerTitle font-size-18 mediumWeight">Access Level</div>
                              <div className="innerContext font-size-14 topUnitMargin mediumWeight"
                                   style={{color: "#FAA652"}}>
                                  {detailUsageHistory.accessLevelName?detailUsageHistory.accessLevelName:'-'}
                              </div>
                          </div>
                          <div className="innerSession">
                              <div className="innerTitle font-size-18 mediumWeight spaceBetween">

                                  <div className="startSession">
                                      Start Session
                                  </div>
                                  <span
                                      className="icon icon-right-sign-arrow leftNormalMargin font-size-14 whiteColor"></span>
                                  <div className="endSession leftNormalMargin">
                                      End Session
                                  </div>
                              </div>
                              <div className="innerContext font-size-14 topUnitMargin spaceBetween">
                                  <div className="startSession mediumWeight">
                                      {detailUsageHistory.dateTime?detailUsageHistory.dateTime.split('T')[0]:"-"}
                                      <p className="font-size-12 lightWeight">{detailUsageHistory.dateTime?detailUsageHistory.dateTime.split('T')[1]:""}</p>
                                  </div>
                                  <span className="icon icon-right-sign-arrow leftNormalMargin"></span>
                                  <div className="endSession mediumWeight leftNormalMargin">
                                      {detailUsageHistory.endData?detailUsageHistory.endData.split('T')[0]:"-"}
                                      <p className="font-size-12 lightWeight">{detailUsageHistory.endData?detailUsageHistory.split('T')[1]:""}</p>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="innerBottomShelf organizer">
                          <div className="innerQuestionId">
                              <div className="innerTitle font-size-18 mediumWeight">Question ID</div>
                              <div className="innerContext font-size-14 topUnitMargin">{detailUsageHistory.questionId?detailUsageHistory.questionId:'-'}</div>
                          </div>
                          <div className="innerQuestionTitle">
                              <div className="innerTitle font-size-18 mediumWeight">Question Title</div>
                              <div className="innerContext font-size-14 topUnitMargin">{detailUsageHistory.questionTitle?detailUsageHistory.questionTitle:'-'}
                              </div>
                              {detailUsageHistory.questionId?
                              <Link to={{ pathname: `http://157.56.30.157:8094/question/${detailUsageHistory.questionId}`}} target='_blank'>
                                <div className="visitQuestion blueColor font-size-14 topUnitMargin inlineView">
                                    <span className="icon icon-visit"></span> Visit Question
                                </div>
                              </Link>
                              :null}
                          </div>
                      </div>
                  </div>
                  <div className="visitInfo organizer topNormalMargin">
                      <div className="halfColumn">
                          <div className="innerTitle font-size-18 mediumWeight">Last Visited URL</div>
                          <div className="innerContext font-size-14 topUnitMargin">
                              {detailUsageHistory.lastVisitedUrl?detailUsageHistory.lastVisitedUrl:'-'}
                          </div>
                          {detailUsageHistory.lastVisitedUrl?
                        <Link to={{ pathname: detailUsageHistory.LastVisitedUrl}} target="_blank">
                          <div className="visitQuestion blueColor font-size-14 topUnitMargin inlineView">
                              <span className="icon icon-visit"></span> Visit URL
                          </div>
                        </Link>
                        :null}
                      </div>
                      <div className="halfColumn">

                          <div className="innerTitle font-size-18 mediumWeight">Last Search Query</div>
                          <div className="innerContext font-size-14 topUnitMargin">
                              {detailUsageHistory.LastSearchQuery?detailUsageHistory.LastSearchQuery:'-'}
                          </div>
                          {detailUsageHistory.LastSearchQuery?
                            <Link to={{ pathname: detailUsageHistory.LastSearchQuery}} target="_blank">
                            <div className="visitQuestion blueColor font-size-14 topUnitMargin inlineView">
                                <span className="icon icon-visit"></span> Visit Query
                            </div>
                            </Link>
                        :null}
                      </div>
                  </div>
              </div>
          </Fragment>
          :null
  ) ;
};

const mapStateToProps = (state , ownProps) => {
    return {
        detailUsageHistory:state.detailedUsageHistory
    } ;
};
export default connect(mapStateToProps , {getDetailUsageHistory})(withRouter(MainPage)) ;