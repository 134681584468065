import {GET , POST , PUT , POST_FORM} from "../common/Action";
import Action from "../common/Action";



export const getMapProfilesAction = new Action('GET_MAP_PROFILES_ACTION' , GET , params =>`api/maps/GetMapProfiles?mapId=${params.mapId}`) ;
export const getMapProfileAction = new Action('GET_MAP_PROFILE_ACTION' , GET , 'api/maps/GetMapProfile') ;
export const getRegionsAction = new Action('GET_REGIONS_ACTION' , GET , params =>`api/maps/GetRegions?mapId=${params.mapId}`) ;
export const aggregateRegionsAction = new Action('AGGREGATE_REGION_ACTION' , POST , 'api/maps/AggregateRegions') ;
export const editAggregationAction = new Action('EDIT_AGGREGATION_ACTION' , POST , 'api/maps/EditAggregation') ;
export const deleteProfileAction = new Action('DELETE_PROFILE_ACTION' , GET , params =>`api/maps/deleteProfile?profileId=${params.profileId}`) ;


export const getMapProfiles = (mapId , cancellationToken) => {
    return getMapProfilesAction.callApi(null , {mapId}) ;
};
export const getMapProfile = (profileId , cancellationToken) => {
    return getMapProfileAction.callApi(null , {profileId}) ;
};
export const getRegions = (mapId) => {
    return getRegionsAction.callApi(null , {mapId}) ;
};
export const aggregateRegions = (aggregateMapProfileModel , cancellationToken) => {
    return aggregateRegionsAction.callApi(aggregateMapProfileModel) ;
};
export const editAggregation = (aggregateMapProfileModel , cancellationToken) => {
    return editAggregationAction.callApi(aggregateMapProfileModel) ;
};
export const deleteProfile =(profileId)=>{
    return deleteProfileAction.callApi(null,{profileId});
}
