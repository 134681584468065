import React , {useState , useEffect , Fragment} from 'react' ;
import { withTranslation } from 'react-i18next';
import {connect} from 'react-redux' ;
import { withRouter } from 'react-router-dom';
import SimpleLoading from '../components/simpleLoading';
import {getLanguages , addNewLanguage , updateLanguage} from "../languages/actions";
import NewLanguageForm from './newLanguageForm';

const MainPage = props => {
    const {languages , result } = props;
    const [newLang , setNewLang] = useState(false)
    const [selectedLanguage , setSelectedLanguage] = useState(null)
    const [message , setMessage] = useState("")

    useEffect(() => {
        props.getLanguages() ;
    } , []) ;

    useEffect(()=>{
        if(!languages)
            return 
    },[languages])

    useEffect(()=>{
        if(!newLang)
            return 
        setSelectedLanguage(null)
    } , [newLang])

    useEffect(()=>{
        if(!selectedLanguage)
            return
        setNewLang(false)
        
    },[selectedLanguage])

    useEffect(()=>{
        if(!result)
            return 
        if(result.action == 'NEW'){
            if(result.succeeded){
                props.getLanguages()
                setSelectedLanguage(result.id)
            }else{
                setMessage("Something Unexpectedly Happened ! Please Try Again Later .")
            }
        }else if(result.action == 'UPDATE'){
            if(result.succeeded){
                props.getLanguages()
            }else{
                setMessage("Something Unexpectedly Happened ! Please Try Again Later .")
            }
        }
    },[result])

    const addNewLanguage = (values)=>{
        if(!values || !values.label || !values.code || !values.direction){
            setMessage("All fields are required")
            return
        }
        setMessage(null)
        const data = {
            cultureName: values.code,
            isRtl:values.direction.value =="LTR"? false : true,
            isActive: true,
            name: values.name
        }
        if(newLang){
            props.addNewLanguage(data)
        }else{
            props.updateLanguage(data)
        }
        // setNewLang(false)
    }

    
    return (
        <Fragment>
            <div className="mainHeader organizer font-size-24 mediumWeight">
                <div className="titleItems">
                    <span className="mainTitle middleContext">Languages</span>
                </div>
                <div className="filterItems">
                    <div onClick={()=>setNewLang(true)} className="createNewPlan">
                        <span className="icon icon-add"></span> Add New Language
                    </div>
                </div>
            </div>
            {languages?
            <div className="adminBoilerplate organizer">
                <div className="twoSidePanel organizer">
                    <div className="columnSide threeForthColumn noHeader websiteContent">
                        <table className="tableStructure organizer">
                            <tr className="tableHeader">
                                <th>Label</th>
                                <th>Name</th>
                                <th>Direction</th>

                            </tr>
                            {languages.map(lang =>(
                                <tr className={selectedLanguage && selectedLanguage.id == lang.id?'active':''} key={lang.id} onClick={()=>setSelectedLanguage(lang)}>
                                    <td>{lang.label}</td>
                                    <td>{lang.name}</td>
                                    <td>{lang.direction.label}</td>
                                </tr>
                            ))}
                        </table>
                    </div>
                    <div className="columnSide oneForthColumn normalPadding">
                        {selectedLanguage?
                            <NewLanguageForm isNew={newLang} initialValues={selectedLanguage} onSubmit={addNewLanguage} />
                        :newLang?
                            <NewLanguageForm isNew={newLang} initialValues={{}} onSubmit={addNewLanguage} />
                        :null}
                    </div>
                </div>
            </div>
            :<SimpleLoading/>}
        </Fragment>
  ) ;
};

const mapStateToProps = (state , ownProps) => {
    return {
        languages : state.languageTable,
        result: state.languageResult 
    } ;
};

export default connect(mapStateToProps , {getLanguages , addNewLanguage , updateLanguage})(withRouter(withTranslation()(MainPage))) ;