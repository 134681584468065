import {editLanuageAction, newLanguageAction} from "./actions";
import _ from 'lodash' ;

export default (state={} , action) => {
    switch (action.type) {
        case newLanguageAction.resultAction :
            return {id:action.payload.data ,succeeded:action.payload.data.succeeded , action : 'NEW'} ;
        case editLanuageAction.resultAction :
            return {id:action.payload.data ,succeeded:action.payload.data.succeeded , action : 'UPDATE'} ;
        default :
            return state ;
    }
};

