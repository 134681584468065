import {addNewResourceAction , changeResourceValueAction , deleteResourceValueAction} from "./actions";

export default (state={} , action) => {
  switch (action.type) {
      case addNewResourceAction.resultAction :
          return {id:action.payload.data , action : 'NEW'} ;
      case changeResourceValueAction.resultAction :
          return {...action.payload.data , action : 'EDIT'} ;
      case deleteResourceValueAction.resultAction :
          return {...action.payload.data , action : 'DELETE'}  ;
      default :
          return state ;
  }
};