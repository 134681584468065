import {ApprovedAsyncContractsAction , DisApprovedAsyncContractsAction} from "./actions";

export default (state=[] , action) => {
  switch (action.type) {
      case ApprovedAsyncContractsAction.resultAction :
            return {...action.payload.data , action : 'Approved'} ;
      case DisApprovedAsyncContractsAction.resultAction :
            return {...action.payload.data , action : 'DisApproved'} ;
      default :
            return state ;
  }
};