import {GET , POST , PUT , POST_FORM} from "../common/Action";
import Action from "../common/Action";

//actions
export const GetApprovableAsyncDatasetAction = new Action('GET_APPROVABLE_DATASET_ACTION' , POST , 'api/datasetBundle/GetApprovable') ;
export const ApprovedAsyncDatasetsAction = new Action('APPROVED_ASYNC_DATASET_ACTION' , POST , 'api/datasetBundle/Approve');
export const DisApprovedAsyncDatasetsAction = new Action('DISAPPROVED_ASYNC_DATASET_ACTION' , POST , 'api/datasetBundle/DisApprove');
export const setProrityAction = new Action('APPROVED_DATASET_SET_PRORITY_ACTION' , POST , 'api/datasetBundle/setPriority');

//action creators
export const GetApprovableAsyncDataset = (data) => {
  return GetApprovableAsyncDatasetAction.callApi(data) ;
};

export const ApprovedAsyncDatasets = (data) =>{
  return ApprovedAsyncDatasetsAction.callApi(data);
}

export const DisApprovedAsyncDatasets = (data) =>{
  return DisApprovedAsyncDatasetsAction.callApi(data);
}

export const setPriority = (data) =>{
  return setProrityAction.callApi(data);
}