import {GET , POST , PUT , POST_FORM} from "../common/Action";
import Action from '../common/Action' ;

//actions
export const getFolderHistoryAction = new Action('GET_FOLDER_HISTORY' , POST , 'api/history/GetFolderHistory') ;



//action creators
export const getFolderHistory = (data) => {
    return getFolderHistoryAction.callApi(data) ;
};