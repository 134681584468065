import {GET , POST , PUT , POST_FORM} from "../common/Action";
import Action from "../common/Action";

//actions
export const getMapsAction = new Action('GET_MAPS_ACTION' , GET , 'api/maps/GetAll') ;
export const getMapAction = new Action('GET_MAP_ACTION' , GET ,params => `api/maps/Get?mapId=${params.mapId}`) ;
export const addMapAction = new Action('ADD_MAP_ACTION' , POST , 'api/maps/Add') ;
export const downloadMapAction = new Action('DOWNLOAD_MAP_ACTION' , GET , 'api/maps/Download') ;
export const updateMapAction = new Action('UPDATE_MAP_ACTION' , POST , 'api/maps/Update') ;


//action creators
export const getMaps = () => {
    return getMapsAction.callApi() ;
};
export const getMap = (mapId) => {
    return getMapAction.callApi(null , {mapId}) ;
};
export const addMap = (map) => {
    return addMapAction.callApi(map) ;
};
export const downloadMap = (mapId) => {
    return downloadMapAction.callApi(null , {mapId}) ;
};
export const updateMap = (map) => {
    return updateMapAction.callApi(map) ;
};
