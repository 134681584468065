import React, {useState, useEffect, Fragment} from 'react' ;
import {connect} from 'react-redux' ;
import _, { get } from 'lodash' ;
import classNames from 'classnames' ;
import RichTextEditor from '../components/RichTextEditor'
import SimpleLoading from "../components/simpleLoading";
import SelectBox from '../components/selectBox' ;
import { UpdateContract , GetStaticVoxdashContracts } from './actions'


const Contracting = props => {
    const {AllStaticContracts , getResult} = props;
    const [selectedContentPageId , setSelectedContentPageId] = useState(null) ;
    const [selectedLang, setSelectedLang] = useState({}) ;
    const [contents , setContents] = useState({}) ;
    const [languageOptions , setLanguageOptions] = useState([]) ;
    const [resultMessage , setResultMessage] = useState(null) ;
    const [pointerHoverId , setPointerHoverId ] = useState(null) ;
    const [activeContract , setActiveContract] = useState(null);
    const [list , setList] = useState([])
    const [description , setDescription] = useState("")
    const [contentChange , setContentChange] = useState(false)


    useEffect(()=>{
        props.GetStaticVoxdashContracts()
    },[])


    useEffect(()=>{
        if(!AllStaticContracts)
            return
        setList(AllStaticContracts)
        setActiveContract(AllStaticContracts[0])
    },[AllStaticContracts])

    useEffect(()=>{
        if(!getResult)
            return
        if(getResult.action == 'UPDATE'){
            setResultMessage({success : getResult.succeeded , message : getResult.succeeded ? 'Edited !' : 'Something Unexpectedly Happened ! Please Try Again Later .'}) ;
        }
    },[getResult])

    useEffect(()=>{
        setResultMessage(null)
        if(!activeContract)
            return
        setContentChange(true)
        setDescription(activeContract.description?activeContract.description:"<p></p>")
        
    },[activeContract])


    const onSaveClick = ()=>{
        setResultMessage(null)
        const data = {...activeContract , description:description}
        setList([...list.filter(l=>l.id!=data.id) , data])
        props.UpdateContract(data)
    }
    

    return (
        <Fragment>
            <div className="mainHeader organizer font-size-24 mediumWeight">
                <div className="titleItems">
                    <span className="mainTitle middleContext">Contracting</span>
                </div>
            </div>
            {!_.isEmpty(list)?
            <div className="adminBoilerplate organizer">
                <div className="twoSidePanel organizer">
                    <div className="columnSide quarterColumn noHeader websiteContent">
                        <table className="tableStructure organizer">
                            <thead className="tableHeader">
                                <tr>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {list.map(contract=>(
                                    <tr key={contract.id} className={classNames("clickShape" , {'active':activeContract.id===contract.id})} onClick={() => setActiveContract(contract)}>
                                        <td>{contract.title}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="columnSide threeQuarterColumn normalPadding">
                        {description && activeContract ?
                        <Fragment>
                            <div className="organizer topNormalMargin">
                                <div className="inputBox">
                                    {/* <label>{activeContract.title}</label> */}
                                    
                                    <RichTextEditor
                                        setContentChange = {setContentChange}
                                        contentChange={contentChange}
                                        onChangeEditor={(evt) => {
                                            setDescription(evt)
                                        }}
                                        valueObj={description}
                                    />    
                                </div>
                            </div>
                            <div className="organizer topUnitMargin">

                                <div className="normalButton inlineView blueBackground whiteColor clickShape" onClick={onSaveClick}>
                                    Save Changes
                                </div>
                            </div>
                            {resultMessage ? 
                                <div className={classNames("organizer textInCenter",  
                                    {"redColor" : !resultMessage.success , "greenColor" : resultMessage.success})}>
                                        {resultMessage.message}
                                </div> : null
                            }
                        </Fragment>
                        :null}
                    </div>
                </div>
            </div>
            :null}
        </Fragment>
    );
};

const mapStateToProps = (state , ownProps) => {
    return {
        AllStaticContracts:state.ContractingGetAllStaticContracts,
        getResult : state.ContractGetResult
    } ;
};

export default connect(mapStateToProps , {GetStaticVoxdashContracts , UpdateContract})(Contracting) ;