import {getLanguagesAction} from "./actions";
import _ from 'lodash' ;

export default (state=[] , action) => {
    switch (action.type) {
        case getLanguagesAction.resultAction :
            return  action.payload.data.map(lang=>({id:lang.id , direction:lang.isRtl?{value:'RTL',label:'RTL'}:{value:'LTR' , label:'LTR'} ,name:lang.name ,  label:lang.cultureName , code:lang.id})) ;
        default :
            return state ;
    }
};

