import {aggregateRegionsAction , editAggregationAction , deleteProfileAction} from "./actions";

export default (state={} , action) => {
  switch (action.type) {
      case aggregateRegionsAction.resultAction :
          return {data:action.payload.data , action : 'NEW'} ;
      case editAggregationAction.resultAction :
          return {data:action.payload.data , action : 'EDIT'} ;
      case deleteProfileAction.resultAction :
          return {data:action.payload.data , action : 'DELETE'} ;
      default :
          return state ;
  }
};