import {addMapAction , updateMapAction} from "./actions";

export default (state={} , action) => {
  switch (action.type) {
      case addMapAction.resultAction :
          return {id:action.payload.data , ...action.payload.data , action : 'NEW'} ;
      case updateMapAction.resultAction :
          return {...action.payload.data , action : 'EDIT'} ;
      default :
          return state ;
  }
};