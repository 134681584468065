import React , {useState , useEffect, Fragment} from 'react' ;
import {connect} from 'react-redux' ;
import LeftNav from '../components/left-menu' ;

const MainPage = props => {
  return (
      <Fragment>
        <div className="mainHeader organizer font-size-24 mediumWeight">
            <div className="titleItems">
                <span className="mainTitle middleContext">Access Levels</span>
            </div>
            <div className="filterItems">
                <div className="createNewPlan">
                    <span className="icon icon-add clickShape"></span> New Access Level
                </div>
            </div>
        </div>
            <div className="adminBoilerplate organizer">

                <div className="twoSidePanel organizer">
                    <div className="columnSide noHeader normalPadding">
                        <div className="organizer spaceBetween">
                            <div className="inputBox input-width_300">
                                <label>Plan Name</label>
                                <input type="text"/>
                            </div>
                            <div className="planAccColor">
                                <div className="accColorTitle mediumWeight">
                                    <span className="icon icon-upgrade"></span>
                                    Acc Lvl Color
                                </div>
                                <div className="accColorSelectBox"></div>
                            </div>
                        </div>

                        <div className="organizer topUnitMargin">

                            <div className="inputBox topUnitMargin">
                                <label>Meta Description</label>
                                <textarea></textarea>
                            </div>
                        </div>

                        <div className="planFeatures organizer">
                            <div className="organizer">
                                <div className="tagItem">
                                    <div className="planFeatureTitle mediumWeight organizer">Features</div>
                                    <ul className="topUnitMargin organizer">
                                        <li>
                                            Surveys Below 2010
                                        </li>
                                        <li>
                                            My Folder
                                        </li>
                                        <li>
                                            Questions Without Files
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="columnSide">
                        <div className="columnHeader spaceBetween organizer">
                            <div className="addItem font-size-18 mediumWeight">
                                All Features
                            </div>
                            <div className="defaultFilter">
                                Only Defaults
                            </div>
                        </div>
                        <div className="columnContent organizer features">
                            <ul className="mainFeatures">
                                <li>
                                    Crosstab
                                    <span className="acceptFeature icon icon-chrck"></span>
                                </li>
                                <li>
                                    Filter Respondent
                                    <span className="acceptFeature icon icon-chrck"></span>
                                </li>
                                <li>
                                    Codeout & Combined
                                    <span className="acceptFeature icon icon-chrck"></span>
                                </li>
                                <li>
                                    Maps Region Aggregation
                                    <span className="acceptFeature icon icon-chrck"></span>
                                </li>
                            </ul>
                            <ul className="addedFeatures">
                                <li>
                                    Crosstab
                                    <div className="featuresAction">
                                        <span className="acceptFeature icon icon-chrck inlineView"></span>
                                        <span className="editFeature icon icon-edit font-size-12 inlineView"></span>
                                        <span className="deleteRow inlineView">Delete</span>
                                    </div>
                                </li>
                                <li>
                                    2019 Surveys
                                    <div className="featuresAction">
                                        <span className="rejectFeature icon icon-negative inlineView"></span>
                                        <span className="editFeature icon icon-edit font-size-12 inlineView"></span>
                                        <span className="deleteRow inlineView">Delete</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <form className="featureForm organizer">

                            <div className="aggregateForm spaceBetween organizer">
                                <div className="aggregateName inlineView">

                                    <div className="inputBox fullWidth">
                                        <label>Feature Label</label>
                                        <input type="text" value="Surveys After 2017"/>
                                    </div>
                                </div>
                                <div className="aggregateAction inlineView">
                                    <div className="normalButton inlineView blueBackground whiteColor">
                                        Save Changes
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="viewNavigations organizer topNormalMargin">
                    <div className="viewPerPage mediumWeight">
            <span className="counterTitle">
                Views / Page
            </span>
                        <span className="counterItem active">8</span>
                        <span className="counterItem">12</span>
                        <span className="counterItem">16</span>
                        <span className="counterItem">32</span>
                    </div>
                    <div className="pagination mediumWeight">
                        <span
                            className="rightNormalMargin icon icon-left-sign-arrow font-size-14 lightGrayColor middleContext"></span>
                        <span className="counterItem">1</span>
                        <span className="counterItem activeSide">2</span>
                        <span className="counterItem active">3</span>
                        <span className="counterItem activeSide">4</span>
                        <span className="counterItem">5</span>
                        <span className="lightGrayColor">...</span>
                        <span className="counterItem">17</span>
                        <span className="counterItem">18</span>
                        <span
                            className="leftNormalMargin icon icon-right-sign-arrow font-size-14 lightGrayColor middleContext"></span>
                    </div>
                </div>
            </div>
    </Fragment>
  ) ;
};

const mapStateToProps = (state , ownProps) => {
    return {

    } ;
};

export default connect(mapStateToProps , {})(MainPage) ;