import React, {useState, useEffect, Fragment} from 'react' ;
import {connect} from 'react-redux' ;
import {getAllAutocompletes , getAutocompletes ,addNewAutocomplete , editAutocomplete , deleteAutocomplete} from "./actions";
import {getLanguages} from '../languages/actions';
import _, { filter } from 'lodash' ;
import classNames from 'classnames' ;
import {dropDownStyles} from "../components/formFields";
import SimpleLoading from "../components/simpleLoading";
import SelectBox from '../components/selectBox' ;
import FormModal from './FormModal';
import DeleteModal from '../components/cmp.modal' ;
import {useTranslation} from 'react-i18next';
import i18n from '../i18n';

const Autocomplete = props => {
    
    const {t} = useTranslation();
    const [isLoadingFirstList , setIsLoadingFirstList] = useState(false);
    const [isLoadingSecondList , setIsLoadingSecondList] = useState(false)
    const {autocompletes  , languages , autocompleteSelectedList , autocompleteResult  } = props ;
    const [filteredAutocompleteSelectedList , setFilteredAutocompleteSelectedList] = useState({});
    const [selectedautocompleteId , setSelectedAutocompleteId] = useState(null) ;
    const [selectedLang, setSelectedLang] = useState({}) ;
    const [resultMessage , setResultMessage] = useState(null) ;
    const [languageOptions , setLanguageOptions] = useState([]) ;
    const [showModal , setShowModal] = useState(false) ;
    const [isEdit , setIsEdit] = useState(false) ;
    const [showDeleteModal , setShowDeleteModal] = useState(false) ;
    const [selectedAutocomplete , setSelectedAutocomplete] = useState(null) ;
    
    
    useEffect(() => {
        if(!languages){
            return;
        }
        let langs =_.map(languages , lang => {
            return {label : lang.cultureName , value : lang.id , isRtl : lang.isRtl}
        });

        setLanguageOptions(langs) ;
        setSelectedLang(langs[0]) ;

    } , [languages])


    useEffect(()=>{
        if(selectedLang){
            i18n.changeLanguage(selectedLang.value)
        }
    } , [selectedLang])

    useEffect(() => {
        setIsLoadingFirstList(false)
        if(_.isEmpty(autocompletes)){
            setSelectedAutocompleteId(null)
        }else{
            props.getAutocompletes(autocompletes[0].id);
            setSelectedAutocompleteId(0)
        }
    } , [autocompletes])
    
    useEffect(()=>{
        setIsLoadingSecondList(false)
        if(_.isEmpty(autocompleteSelectedList))
            return
        setFilteredAutocompleteSelectedList(
            {...filteredAutocompleteSelectedList , ...autocompleteSelectedList})                     
    },[autocompleteSelectedList])
    

    useEffect(() => {
       
        if (!_.isEmpty(autocompleteResult)) {
            if (autocompleteResult.action === 'FAILURE') {
                setResultMessage({success : false , message : 'Something Unexpectedly Happened ! Please Try Again Later .'}) ;
            }else if (autocompleteResult.action === 'EDIT_AUTO') {
                setResultMessage({success : autocompleteResult.succeeded , message : autocompleteResult.succeeded ? 'Edited !' : autocompleteResult.errors.join(', ')}) ;
                setIsLoadingSecondList(true)
                i18n.reloadResources().then(() => setIsLoadingSecondList(false));
                props.getAutocompletes(autocompletes[selectedautocompleteId].id);
            }
            else if (autocompleteResult.action === 'NEW_AUTO') {
                setResultMessage({success : autocompleteResult.succeeded , message : autocompleteResult.succeeded ? 'Added !' : autocompleteResult.errors.join(', ')}) ;
                if (autocompleteResult.success) {
                    //setPageNumber(1) ;
                    //setIsEdit(true) ;
                }
                setIsLoadingSecondList(true)
                i18n.reloadResources().then(() => setIsLoadingSecondList(false));
                props.getAutocompletes(autocompletes[selectedautocompleteId].id);
            }
            else if (autocompleteResult.action === 'DELETE_AUTO') {
                setIsLoadingSecondList(true)
                setResultMessage({success : autocompleteResult.succeeded , message : autocompleteResult.succeeded ? 'Deleted !' : autocompleteResult.errors.join(', ')}) ;
                props.getAutocompletes(autocompletes[selectedautocompleteId].id);
            }
        }
    } , [autocompleteResult]) ;

    
    

    useEffect(() => {
        setIsLoadingFirstList(true)
        props.getAllAutocompletes() ;
        props.getLanguages() ;
    } , []) ;



    useEffect(() => {
        if (selectedautocompleteId == null ||filteredAutocompleteSelectedList[autocompletes[selectedautocompleteId].id]) {
            return;
        }
        setResultMessage(null) ;
        setIsLoadingSecondList(true);
        props.getAutocompletes(autocompletes[selectedautocompleteId].id);

    } , [selectedautocompleteId]) ;

    

    const onSubmit = (values) => {
        setShowModal(false);
        setResultMessage(null) ;
        const data = {...values , languageId:selectedLang.value }
        if (isEdit) {
            props.editAutocomplete(data) ;
        }
        else {
            props.addNewAutocomplete(data) ;
        }
    };

    function camelize(str) {
        return str.trim()
        .split(' ')
        .map(a => a.trim())
        .map(a => a[0].toUpperCase() + a.substring(1))
        .join("")
     }

    const onEditClick = (autocomplete) => {
        setSelectedAutocomplete(autocomplete);  
        setIsEdit(true) ;
        setShowModal(true) ;
        setResultMessage(null) ;
    };

    const onNewClick = () => {
          setShowModal(true) ;
          setIsEdit(false) ;
          setResultMessage(null) ;
    };

    const onDeleteClick=(autocomplete)=>{
        setResultMessage(null) ;
        setSelectedAutocomplete(autocomplete) ;
        setShowDeleteModal(true) ;
    }

    const onConfirmDeleteClick = () => {
        setResultMessage(null) ;
        setShowDeleteModal(false);
        props.deleteAutocomplete(selectedAutocomplete.id) ;
    };

    return (
        <Fragment>
            <div className="mainHeader organizer font-size-24 mediumWeight">
                <div className="titleItems">
                    <span className="mainTitle middleContext">{t('Autocomplete')}</span>
                </div>
            {resultMessage ? 
                <div className={classNames("topNormalMargin" , 
                {'redColor' : !resultMessage.success , 'greenColor' : resultMessage.success})}>
                    {resultMessage.message}
                </div>
            : null}
            </div>
            <div className="adminBoilerplate organizer">
                {isLoadingFirstList?
                <SimpleLoading/>
                :
                <Fragment>
                    <div className="twoSidePanel organizer">
                        <div className="columnSide quarterColumn noHeader websiteContent" style={{height:'1000px'}}>
                            <table className="tableStructure organizer">
                                <thead className="tableHeader">
                                    <tr>
                                        <th>{t('Autocomplete List')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {_.map(autocompletes, (con  , index)=> (
                                    <tr key={con.id} className={classNames("clickShape" , {"active" : selectedautocompleteId == index})} 
                                        onClick={() => setSelectedAutocompleteId(index)}>
                                        <td>{t(con.label)}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="columnSide threeQuarterColumn normalPadding">
                            {_.isEmpty(filteredAutocompleteSelectedList)||!autocompletes ||isLoadingSecondList?
                                <SimpleLoading/>
                                :
                                <Fragment>
                                    <div className="organizer mainHeader">
                                        <SelectBox
                                            label="Languages"
                                            value={selectedLang}
                                            options={languageOptions}
                                            styles={dropDownStyles}
                                            onChange={lang => setSelectedLang(lang)}
                                        />
                                        <div className="filterItems">
                                            <div className="createNewPlan" style={{cursor:'pointer'}} onClick={onNewClick}>
                                                <span className="icon icon-add">{t('New Autocomplete')}</span>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="adminBoilerplate organizer">
                                        <table className="tableStructure organizer">
                                            <thead className="tableHeader">
                                                <tr>
                                                    <th>{t('Name')}</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            {autocompletes && autocompletes[selectedautocompleteId] && !_.isEmpty(filteredAutocompleteSelectedList[autocompletes[selectedautocompleteId].id])?
                                            _.map(filteredAutocompleteSelectedList[autocompletes[selectedautocompleteId].id] , (content) => (
                                            <tbody key={content.id} >
                                                <tr>
                                                    <td>
                                                        <span className="boldWeight font-size-16"></span>
                                                        {t(content.label)}
                                                    </td>
                                                    <td>
                                                        <span className="editRow leftNormalMargin redBackground clickShape" onClick={()=>onDeleteClick(content)}>
                                                            <span className="icon icon-delete whiteColor"></span>
                                                        </span>
                                                        <span className="editRow leftNormalMargin greenBackground clickShape">
                                                            <span className="icon icon-edit whiteColor"onClick={()=>onEditClick(content)}></span>
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            )):null}

                                        </table>
                                    </div>
                                </Fragment>
                            }
                        </div>
                    </div>
                </Fragment>}
            </div>

            {showModal && autocompletes && selectedautocompleteId != null ?
                <FormModal 
                    title={isEdit?"Edit autocomplete":"New autocomplete"}

                    message = {isEdit?
                        "choose a new name for "+ t(selectedAutocomplete.label):
                        "choose a name for new "+t(autocompletes[selectedautocompleteId].label)}

                    initialValues={isEdit ? 
                        {...selectedAutocomplete , value:t(selectedAutocomplete.label)} : 
                        {
                            languageId:selectedLang.value,
                            parentId:autocompletes[selectedautocompleteId].id, 
                            value:'',
                            label:'autocomplete.'+autocompletes[selectedautocompleteId].label+'.value'
                        }
                    } 

                    resultMessage={resultMessage} 
                    onSubmit={(values)=>onSubmit(values)} 
                    edit={isEdit} 
                    closeModal={() => setShowModal(false)}
                /> 
            : null}

            {showDeleteModal && selectedAutocomplete !== null ? 
                <DeleteModal closeModal={() => setShowDeleteModal(false)} 
                    acceptAction={onConfirmDeleteClick} data={null} 
                    title="Delete autocomplete"
                    modalMessage={`Are you sure you want to delete ${t(selectedAutocomplete.label)} from ${t(autocompletes[selectedautocompleteId].label)}`} 
                    resultMessage={resultMessage}
                /> 
            : null}
        </Fragment>
    )
}


const mapStateToProps = (state , ownProps) => {
    return {
        autocompletes : state.autocompletes ,
        languages : state.languages ,
        autocompleteSelectedList: state.selectedAutocompletes,
        autocompleteResult : state.autocompleteResult
    } ;
};

export default connect(mapStateToProps , {getAllAutocompletes, getLanguages , getAutocompletes ,addNewAutocomplete , editAutocomplete , deleteAutocomplete})(Autocomplete) ;

