import React , {Fragment , useState , useEffect} from 'react' ;
import {connect} from 'react-redux' ;
import _ from 'lodash' ;
import {months} from "./mainPage";
import classNames from 'classnames' ;
import {sendMessage , getUserMessages , getAllUsersMessages} from "./actions";
import { Link } from 'react-router-dom';


const ChatBox = (props) => {

    const {userId , chatBoxData , chatName , sendMessageResult} = props ;
    const [messageText , setMessageText] = useState('') ;
    let lastDate = null ;

    useEffect(() => {
        if (!_.isEmpty(sendMessageResult)) {
            if (sendMessageResult.success) {
                props.getAllUsersMessages() ;
                props.getUserMessages(userId) ;
            }
        }
    } , [sendMessageResult]) ;

    const renderMessages = (message) => {
        let dateLineRender = null ;
        const date = _.split(message.dateTime , 'T')[0] ;
        const isUser = !message.isRecieved ;
        if (date !== lastDate) {
            lastDate = date ;
            dateLineRender =
                <div className="conversationDate organizer">
                    <span className="dashedLine"></span>
                    <span className="dateInner blueColor font-size-10 mediumWeight">{`${_.split(date , '-')[2]} ${months[parseInt(_.split(date , '-')[1]) - 1]}, ${_.split(date , '-')[0]}`}</span>
                </div> ;
        }
        return (
            <Fragment>
                {dateLineRender}
                {/* this part is not complete  .you have to fix this tomorrow */}
                <div className="organizer">
                    <div className={classNames("" , {"userMessage leftFloat" : isUser , "adminMessage rightFloat" : !isUser})}>
                        <span className={classNames("" , {'userImage' : isUser , 'adminImage' : !isUser} )} style={{backgroundColor: isUser ? "#FF6767" : "#5F65FF"}}>{isUser  ? message.user.firstName && message.user.lastName ? `${message.user.firstName[0].toUpperCase()}${message.user.lastName[0].toUpperCase()}` : 'User' : 'Admin'}</span>
                        <div className="messageBox">
                            <div className="messageContainer inlineView">
                                <img className={classNames("userPointer" , {'userPointer' : isUser , 'adminPointer' : !isUser})} src={require(`../../img/${isUser ? 'userPointer' : 'adminPointer'}.svg`)}/>
                                <div className="messageContent font-size-14">
                                    {message.text}
                                </div>
                                <span
                                    className="messageTime font-size-10 lightGrayColor">{_.split(message.dateTime , 'T')[1]}</span>
                            </div>
                        </div>
                        <div className="messageActions">
                            <span className="icon icon-options tempDisable"></span>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    };
    const sendMessage = () => {
        const data = {
            Text : messageText ,
            UserId : userId
        };
        props.sendMessage(data) ;
        setMessageText('') ;
    };

    return (
        <div className="">
            <div className="messageHeader mediumWeight spaceBetween organizer">
                <div className="userName font-size-24">{chatName}</div>
                <Link to={`users/${userId}`}>
                    <div className="visitUser blueColor font-size-14 clickShape">
                        <span className="icon icon-visit"></span>
                        Visit User
                    </div>
                </Link> 
            </div>
            <div className="messageMainContent organizer">
                {chatBoxData && !_.isEmpty(chatBoxData) ? _.map(chatBoxData , (message , index) => {
                    return renderMessages(message) ;
                } ) : chatBoxData ? <div className="textInCenter">No Messages Yet ...</div> : <div className="textInCenter">Loading ...</div>}
            </div>
            <div className="messageComposeField">
                <div style={{maxHeight:'150px'}} className="messageTextarea">
                    <textarea value={messageText} onChange={e => setMessageText(e.target.value)} className="writeMessage" placeholder="Write Your Message Here ..."></textarea>
                </div>
                <div className={classNames("messageSendButton whiteColor" , {'blueBackground clickShape' : messageText , 'grayBackground' : !messageText})} onClick={() => messageText?sendMessage() : null}>
                    <span className="icon icon-send"></span>
                </div>
            </div>
        </div>
    ) ;
};

const mapStateToProps = (state , ownProps) => {
    return {
        chatBoxData : state.usersMessages[ownProps.userId] ,
        sendMessageResult : state.sendMessageResult
    } ;
};

export default connect(mapStateToProps , {getUserMessages , sendMessage , getAllUsersMessages})(ChatBox) ;