import React, { useEffect } from 'react'
import ReduxNewLegalDocumentForm from './NewLegalDocumentForm'

// close modal

const NewLegalDocForm = (props)=>{
    
    return (
        <form>
            <div className="popup profiles">
                <div className="popupClose" onClick={props.closeModal}></div>
                <div className="popUpBox" style={{width:'90%'}}>
                    <div className="popupHeader spaceBetween organizer">
                        <div className="popUpTitle font-size-24 mediumWeight">
                            Add New Legal document
                        </div>
                        <div className="popupCross clickShape" onClick={props.closeModal}>
                            <span className="icon icon-cross font-size-18 lightGrayColor"></span>
                        </div>
                    </div>
                    <div className="popUpContent organizer" style={{maxHeight:'550px' , overflowY:'scroll'}}>
                        <ReduxNewLegalDocumentForm
                            fromEdit={false}
                            closeModal={props.closeModal} 
                            onSubmit={props.onSubmit}
                            loading = {props.loading}
                            setLegalText={props.setLegalText}
                        />
                    </div>
                </div>
            </div>
        </form>
    )
}

export default NewLegalDocForm;