import {getAllUsersMessagesAction} from "./actions";
import _ from 'lodash' ;

export default (state={}, action ) => {
    switch (action.type) {
        case getAllUsersMessagesAction.resultAction :
            return _.mapKeys(action.payload.data , 'userId') ;
        default :
            return state ;
    }
};