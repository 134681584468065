import React , {useState , useEffect , Fragment} from 'react' ;
import FileUploader from '../components/LogoUploader';
import ROUTES from '../api-routes'

const MainPage = props => {
    const lightLogo = "logos/logo-light.svg"
    const dartLogo = "logos/logo-dark.svg"
    const favIcon = "logos/favIcon.ico"
    const baseURL = ROUTES.api
    const [message , setMessage] = useState("")

    return (
        <Fragment>
            <div className="mainHeader organizer font-size-24 mediumWeight">
                <div className="titleItems">
                    Logos
                </div>
            </div>
            <div className="adminBoilerplate organizer">
                {message}
                <div className="organizer font-size-20 mediumWeight">Light Logo</div>
                <img src={(baseURL+lightLogo)}/>
                <div className="coverActions organizer topUnitMargin ">
                    <FileUploader setMessage ={setMessage}insideBtn={'Change logo'} type={0}/> 
                </div>
            </div>
            <div className="adminBoilerplate organizer">
                <div className="organizer font-size-20 mediumWeight">Dark Logo</div>
                <img src={(baseURL+dartLogo)}/>
                <div className="coverActions organizer topUnitMargin ">
                    <FileUploader setMessage ={setMessage} insideBtn={'Change logo'} type={1}/> 
                </div>
            </div>
            <div className="adminBoilerplate organizer">
                <div className="organizer font-size-20 mediumWeight">Fav Icon</div>
                <img src={(baseURL+favIcon)}/>
                <div className="coverActions organizer topUnitMargin ">
                    <FileUploader setMessage ={setMessage} insideBtn={'Change Icon'} type={2}/> 
                </div>
            </div>
        </Fragment>
    );
};

export default MainPage;