import React , {useState , useEffect , Fragment} from 'react' ;
import {connect} from 'react-redux' ;
import LeftNav from '../components/left-menu' ;

const MainPage = props => {
  return (
      <Fragment>
              <div className="mainHeader organizer font-size-24 mediumWeight">
                  <div className="titleItems">
                      <span className="mainTitle middleContext">Topics</span>
                      
                  </div>
                  
              </div>
              <div className="adminBoilerplate organizer">

                  <div className="twoSidePanel organizer">
                      <div className="columnSide">
                          <div className="columnHeader organizer spaceBetween">
                              <div className="inner">
                                  <div className="backToTopic lightGrayColor middleContext inlineView">
                                      <span className="icon icon-left-sign-arrow"></span>
                                      Back
                                  </div>
                                  <span className="verticalSmallLine inlineView"></span>
                                  <span className="topicReference inlineView middleContext font-size-18 mediumWeight">Topic: Countries</span>
                              </div>
                          </div>
                          <div className="emptyTopic textInCenter">
                              <span className="icon icon-subTopic font-size-100"></span>
                              <p className="font-size-24 lightWeight topUnitMargin lightGrayColor">
                                  No Sub Topic Assigned to This Topic
                              </p>
                              <span className="horizontalLine"></span>
                              <div className="addNew blueColor font-size-24 mediumWeight"><span
                                  className="icon icon-add font-size-20"></span> Add New Sub Topic
                              </div>
                          </div>
                          <table className="tableStructure organizer tempDisable">
                              <thead className="tableHeader">
                              <th>ID</th>
                              <th>Title</th>
                              </thead>
                              <tbody>
                              <tr>
                                  <td>00001</td>
                                  <td className="spaceBetween fullWidth">
                                            <span className="leftFloat">
                                                Iran Elections
                                            </span>
                                      <span className="tableAction">
                                                <span className="buttonInTable">
                                                    <span className="pinkColor mediumWeight">12</span>
                                                    Follower
                                                </span>
                                                <span className="buttonInTable">
                                                    <span className="blueColor mediumWeight">12</span>
                                                    Sub Topics
                                                    <span
                                                        className="inlineView icon icon-edit blueColor font-size-12 leftNormalMargin"></span>
                                                </span>
                                            </span>
                                  </td>
                              </tr>
                              <tr>
                                  <td>00001</td>
                                  <td className="spaceBetween fullWidth">
                                            <span className="leftFloat">
                                                Iran Elections
                                            </span>
                                      <span className="tableAction">
                                                <span className="buttonInTable">
                                                    <span className="pinkColor mediumWeight">12</span>
                                                    Follower
                                                </span>
                                                <span className="buttonInTable">
                                                    <span className="blueColor mediumWeight">12</span>
                                                    Sub Topics
                                                    <span
                                                        className="inlineView icon icon-edit blueColor font-size-12 leftNormalMargin"></span>
                                                </span>
                                            </span>
                                  </td>
                              </tr>
                              <tr>
                                  <td>00001</td>
                                  <td className="spaceBetween fullWidth">
                                            <span className="leftFloat">
                                                Iran Elections
                                            </span>
                                      <span className="tableAction">
                                                <span className="buttonInTable">
                                                    <span className="pinkColor mediumWeight">12</span>
                                                    Follower
                                                </span>
                                                <span className="buttonInTable">
                                                    <span className="blueColor mediumWeight">12</span>
                                                    Sub Topics
                                                    <span
                                                        className="inlineView icon icon-edit blueColor font-size-12 leftNormalMargin"></span>
                                                </span>
                                            </span>
                                  </td>
                              </tr>
                              </tbody>
                          </table>
                      </div>
                      <div className="columnSide">
                          <div className="columnHeader spaceBetween organizer">
                              <div className="addItem blueColor font-size-18 mediumWeight">
                                  <span className="icon icon-add "></span>
                                  New Sub Topic
                              </div>
                          </div>
                          <div className="columnContent organizer">
                              <div className="emptyTopic textInCenter">
                                  <span className="icon icon-subTopic font-size-100"></span>
                                  <p className="font-size-24 lightWeight topUnitMargin lightGrayColor">
                                      No Subtopic Selected, Please Select a Subtopic or Create a New One
                                  </p>
                              </div>
                              <div className="addTopicContainer organizer tempDisable">
                                  <div className="coverOrganizer">
                                      <div className="tempDisable" src="../../img/cover.jpg">
                                        <span>
                                            <span className="icon icon-photo"></span>
                                            Add Cover
                                        </span>
                                      </div>

                                      <div className="coverActions topUnitMargin">
                                          <span className="changeCover blueColor"><span
                                              className="icon icon-photo"></span> Change Cover </span>
                                          <span className="deleteCover redColor leftNormalMargin"><span
                                              className="icon icon-bin"></span> Delete Cover </span>
                                      </div>
                                  </div>
                                  <form className="mainInfo organizer">
                                      <div className="formTopic font-size-24 mediumWeight">Main Info</div>
                                      <div className="inputBox active input-width_170 topUnitMargin">
                                          <label>ID</label>
                                          <input type="text" value="00003"/>
                                      </div>
                                      <div className="inputBox input-width_170 leftNormalMargin">
                                          <label>Language</label>
                                          <input type="text"/>
                                      </div>
                                      <div className="organizer topUnitMargin">
                                          <div className="inputBox input-width_300">
                                              <label>Label</label>
                                              <input type="text"/>
                                          </div>
                                      </div>
                                      <div className="organizer topUnitMargin">
                                          <div className="inputBox">
                                              <label>Description</label>
                                              <textarea placeholder="Enter description for selected topic …"></textarea>
                                          </div>
                                      </div>
                                  </form>
                                  <form className="SeoFields organizer topNormalMargin">
                                      <div className="formTopic font-size-24 mediumWeight">SEO Fields</div>
                                      <div className="organizer topUnitMargin">
                                          <div className="inputBox">
                                              <label>metaTitle</label>
                                              <input type="text" value="00003"/>
                                          </div>
                                          <div className="inputBox topUnitMargin">
                                              <label>Meta Description</label>
                                              <textarea></textarea>
                                          </div>
                                      </div>
                                  </form>
                                  <div className="organizer topUnitMargin">

                                      <div className="normalButton inlineView blueBackground whiteColor">
                                          Save Changes
                                      </div>

                                      <div className="normalButton inlineView grayOutline redColor leftNormalMargin">
                                          Delete Topic
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>

                  <div className="viewNavigations organizer topNormalMargin">
                      <div className="viewPerPage mediumWeight">
                <span className="counterTitle">
                    Views / Page
                </span>
                          <span className="counterItem active">8</span>
                          <span className="counterItem">12</span>
                          <span className="counterItem">16</span>
                          <span className="counterItem">32</span>
                      </div>
                      <div className="pagination mediumWeight">
                          <span
                              className="rightNormalMargin icon icon-left-sign-arrow font-size-14 lightGrayColor middleContext"></span>
                          <span className="counterItem">1</span>
                          <span className="counterItem activeSide">2</span>
                          <span className="counterItem active">3</span>
                          <span className="counterItem activeSide">4</span>
                          <span className="counterItem">5</span>
                          <span className="lightGrayColor">...</span>
                          <span className="counterItem">17</span>
                          <span className="counterItem">18</span>
                          <span
                              className="leftNormalMargin icon icon-right-sign-arrow font-size-14 lightGrayColor middleContext"></span>
                      </div>
                  </div>
              </div>
          </Fragment>
  ) ;
};

const mapStateToProps = (state , ownProps) => {
    return {

    } ;
};

export default connect(mapStateToProps , {})(MainPage) ;