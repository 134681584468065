import React , {Fragment} from 'react' ;
import classNames from 'classnames' ;
import Select , {components} from 'react-select' ;
import RichTextEditor from '../components/RichTextEditor';
import { scroller } from 'react-scroll';
import makeAnimated from "react-select/animated";
import { withTranslation } from 'react-i18next';


export function scrollToFirstError(errors) {
  const errorFields = getErrorFieldNames(errors);
  if(!errorFields)
    return;
  // Using breakable for loop
  for (let i = 0; i < errorFields.length; i++) {

    const fieldName = errorFields[i];
    // Checking if the marker exists in DOM
    if (document.querySelectorAll(`[name="${fieldName}"]`).length) {
      scroller.scrollTo(fieldName, { offset: -200 });
      break;
    }
  }
}



function getErrorFieldNames(obj, name = '') {
  if(!obj){
    return;
  }
  const errorArr = [];
  errorArr.push(Object.keys(obj).map((key) => {
    const next = obj[key];
    if (next) {
      if (typeof next === 'string') {
        return name + key;
      }
      // Keep looking
      if (next.map) {
        errorArr.push(next.map((item, index) => getErrorFieldNames(item, `${name}${key}[${index}].`)).filter(o => o));
      }
    }
    return null;
  }).filter(o => o));
  return flatten(errorArr);
}

function flatten(arr) {
  return arr.reduce((flat, toFlatten) => flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten), []);
}

const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      width: "100%",
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      display: "none",
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: "none",
    }),
    control: (provided, state) => ({
      ...provided,
      borderRadius: "4px",
    }),
};

const CustomeSingleValue = (props) => {
    const { data, innerRef, innerProps } = props;
    return (
      <div ref={innerRef} {...innerProps} style={{ padding: "0 0.5rem" }}>
        <span className={`flag-icon flag-icon-${data.icon.toLowerCase()}`}></span>
        {/* <span style={{ fontSize: "12px"}}> {data.name}</span> */}
        <span style={{ fontSize: "14px" }}> +{data.code}</span>
      </div>
    );
};

const CustomOption = (props) => {
    const { data, innerRef, innerProps } = props;
    return (
      <div ref={innerRef} {...innerProps} style={{ padding: "0 0.5rem" }}>
        {/* <img
          src={data.Icon}
          style={{ paddingRight: "5px", paddingLeft: "10px" }}
        /> */}
        {data.icon?
        <span className={`flag-icon flag-icon-${data.icon.toLowerCase()}`}></span> 
        :null}
        <span style={{ fontSize: "12px"}}> {data.name}</span>
        <span style={{ fontSize: "14px"}}> +{data.code}</span>
      </div>
    );
};


export const dropDownStyles = {
    input : (provided , state) => {
        return {
            ...provided ,
            padding : "0.6rem 1rem" ,
            // border: "solid #efefef 1px"
        };
    } ,
    indicatorSeparator: (styles) => ({display:'none'}) ,
    container : (provided , state) => {
        return {
            ...provided ,
            border: "solid #efefef 1px"
        };
    },
    control : (provided , state) => {
        return {
            ...provided ,
            height : '45px'
        };
    } ,
    valueContainer : (provided , state) => {
        return {
            ...provided ,
            marginTop : '-3px'
        };
    }
};

export const checkBoxInput = ({
  input,
  type,
  label,
  isChecked,
  cssClasses, 
  meta: { touched, error, warning, active },
}) => {
  return(
  <div>
    <div
      style={{ marginLeft: "5px", marginRight: "5px" }}
      className={classNames(cssClasses)}
    >
      <label htmlFor="exampleFormControlInput1" className="customizeLable">
        <input checked={isChecked} type={type} {...input} placeholder=""/>
        <span className='ml-2'>{label}</span>
      </label>
      {touched && error ? (
        <div className="redColor font-size-10">{error}</div>
      ) : (
        <div className="whiteColor font-size-10"></div>
      )}
    </div>
  </div>
)};


export const SimpleInput = ({
  input,
  initialValue,
  min,
  max,
  type,
  label,
  cssClasses,
  disabled,
  tooltip,
  meta: { touched, error, warning, active },
}) =>(cssClasses && cssClasses === "simple" ? (
    <input value={input.value} initialValue={initialValue} {...input} min={min} max={max} disabled={disabled} type={type} />
  ) : (
    <>
      {/* <ReactTooltip
        id="main"
        place={"top"}
        type={"dark"}
        effect="solid"
        multiline={true}
      /> */}
      <div className="organizer topUnitMargin">
        <div
          className={classNames("inputBox")}
        >
          <label htmlFor="exampleFormControlInput1" className="customizeLable">
            {label}
            {tooltip ? (
              <span
                data-for="main"
                data-tip={tooltip}
                data-iscapture="true"
                className="icon-question ml5"
              ></span>
            ) : null}
          </label>
          <input
            min={min} 
            max={max}
            //value={input.value}
            disabled={disabled}
            type={type}
            {...input}
            className={`${cssClasses} form-control customizeInput`}
            placeholder={input.value?input.value:""}
          />
          {touched && error ? (
            <div className="redColor font-size-10">{error}</div>
          ) : (
            <div className="whiteColor font-size-10"></div>
          )}
        </div>
      </div>
    </>
  ));


const animatedComponents = makeAnimated();

export const SelectInput = withTranslation()(
  ({
    t,
    input,
    value,
    name,
    onChange,
    label,
    isMulti,
    disabled,
    cssClasses,
    tooltip,
    defaultValue,
    options,
    getOptionValue,
    getOptionLabel,
    meta,
    error
  }) => (
    <div className=" topUnitMargin">
      <div className={classNames("inputBox")}>
        {label ? (
          <>
            {/* <ReactTooltip
              id="main"
              place={"top"}
              type={"dark"}
              effect="solid"
              multiline={true}
            /> */}
            <label
              htmlFor="exampleFormControlInput1"
              className="customizeLable "
            >
              {label}
              {tooltip ? (
                <span
                  data-for="main"
                  data-tip={tooltip}
                  data-iscapture="true"
                  className="icon-question ml5"
                ></span>
              ) : null}
            </label>
          </>
        ) : null}

        <Select
          name = {input? input.name:name}
          id={input? input.name:name}
          className={classNames(cssClasses)}
          getOptionLabel={getOptionLabel}
          getOptionValue={
            getOptionValue ? getOptionValue : (option) => option.id
          }
          // styles={{
            // control: styles => ({ ...styles,overflow: 'visible' , whiteSpace: 'nowrap' , width: 'fit-content'}),
            // valueContainer:styles=>({...styles , color:'red'}),
            // multiValue: (provided, state) => ({
            //   ...provided,
            //   color: 'red',
            //   backgroundColor:'green',
            //   overflow: 'visible' , whiteSpace: 'nowrap' , width: 'fit-content',
            // }),
          // }}

          components={animatedComponents}
          value={input ? input.value : value || (defaultValue||"")}
          isMulti={isMulti}
          isDisabled={disabled}
          isSearchable={true}
          isClearable={true}
          options={options}
          onChange={input? input.onChange:onChange}
        />

        {input? input.touched && input.error : error ? (
          <div className="redColor font-size-10">{error}</div>
        ) : (
          <div className="whiteColor font-size-10"></div>
        )}
      </div>
    </div>
  )
);

export const RadioInputComponent = ({
  input,
  type,
  label,
  cssClasses , 
  meta: { touched, error, warning, active },
}) => (
  <div className={classNames("inline-block" , cssClasses)}>
    
    <label htmlFor="exampleFormControlInput1" className={classNames(cssClasses , "customizeLable")}>
      <input
        style={{ marginRight: "5px" }}
        type={type}
        {...input}
      />
      {label}
    </label>
    
    {touched && error ? (
      <div className="redColor font-size-10">{error}</div>
    ) : (
      <div className="whiteColor font-size-10"></div>
    )}
  </div>
);

export const renderSimpleInputField = ({
                                input,
                                label,
                                required = true ,
                                type,
                                placeholder,
                                disabled,
                                widthClass ,
                                cssClasses ,
                                meta: {touched, error, warning , active}
                            }) => (
    <div className={classNames(`inputBox${cssClasses ? ` ${cssClasses}` : ''}` , {"input-width_170" : widthClass === 1 , "input-width_300" : widthClass === 2 , "active" : active || input.value } , "topUnitMargin")}>
        <label className='customizeLabel'>{label}</label>
        <input disabled={disabled} type={type} {...input} className="" placeholder={placeholder}/>
        {touched && error ? <div className="redColor font-size-10">{error}</div> : null}
    </div>
);

export const renderTextAreaField = ({
                                           input,
                                           label,
                                           required = true ,
                                           placeholder,
                                           disabled,
                                           cssClasses ,
                                           meta: {touched, error, warning , active}
                                       }) => (
    <div className={classNames(cssClasses , "organizer topUnitMargin")}>
        <div className={classNames("inputBox" , {"active" : active || input.value})}>
            <label className='customizeLabel'>{label}</label>
            <textarea disabled={disabled} {...input} placeholder={placeholder}></textarea>
        </div>
        {touched && error ? <div className="redColor font-size-12">{error}</div> : <div className="whiteColor font-size-10">voxdash</div>}
    </div>
);

export const renderDropdown = ({input: {value, onChange}, label ,cssClasses , widthClass, options, meta: {touched , active, error, warning}}) => {
    return (
        <div className={classNames(`inputBox${cssClasses ? ` ${cssClasses}` : ''}` , {"input-width_170" : widthClass === 1 , "input-width_300" : widthClass === 2 , "active" : active || value } , "topUnitMargin")}>
            <label className='customizeLabel'>{label}</label>
             <Select
                    value={value}
                    onChange={onChange}
                    options={options}
                    styles={dropDownStyles}
                />
            {touched && error ? <div className="redColor font-size-10">{error}</div> : <div className="whiteColor font-size-10">voxdash</div>}
        </div>
    )
};

export const SelectComponent = (value, onChange, label ,cssClasses , widthClass, options) => {
    return (
        <div className={classNames(`inputBox${cssClasses ? ` ${cssClasses}` : ''}` , {"input-width_170" : widthClass === 1 , "input-width_300" : widthClass === 2 , "active" : value })}>
            <label>{label}</label>
             <Select
                value={value}
                onChange={onChange}
                options={options}
                styles={dropDownStyles}
            />
        </div>
    )
};

export const renderTextEditor = ({input: {value, onChange}, label , meta: {touched , active, error, warning} , reset , setReset}) => {
    return (
        <div className={classNames("inputBox" , {"active" : active || value})}>
            <label className='customizeLabel'>{label}</label>
            <RichTextEditor
              reset={reset}
              setReset={setReset}
              onChangeEditor={onChange}
              valueObj={value}
            />    
            {touched && error ? <div className="redColor font-size-10">{error}</div> : <div className="whiteColor font-size-10">voxdash</div>}
        </div>
    )
};

// export const renderFileUploader = ({input: {value, onChange}, label, options, placeholder, meta: {touched, error, warning}}) => {
//     return (
//         <Fragment>
//             {label ? <h4 className="" style={{width : '100%'}}>{label} {error ?
//                 <span className="importantField"></span> : null}</h4> : null}
//             <FileUploader value={value} onChange={onChange}/>
//             {error && touched ? <span className="organizer errorBox nonImportantFontSize redImportant">{error}</span> : null}
//         </Fragment>
//     )
// };

export const SelectTelCodeInput = (FieldProps) => (
    <div className="input-group-append">
      <Select
        styles={{ ...customStyles , control: (provided, state) => ({
          ...provided ,
          height: 44,
          minWidth:'120px',
          overflow:'visible',
          fontSize:'14px'
        }), menuList: (provided, state) => ({
          ...provided ,
          width: 250
        }), menu: (provided, state) => ({
          ...provided ,
          width: 250,
          textAlign: 'left',
          display: 'flex',
          gap: 10,
          cursor: 'pointer'
        }), valueContainer: (provided, state) => ({
          ...provided ,
          display: 'flex',
          flexWrap: 'nowrap'
        })
      
      }}
        className={FieldProps.cssClasses}
        value = {FieldProps.input.value}
        onChange={FieldProps.input.onChange}
        getOptionLabel={(opt) => opt.code}
        getOptionValue={(option) => option.name}
        components={{ Option: CustomOption, SingleValue: CustomeSingleValue }}
        isMulti={false}
        isDisabled={FieldProps.disabled}
        isSearchable={true}
        options={FieldProps.options}
        // onInputChange={(e) => console.log("---", e)}
        // filterOption = { e => console.log("---",e)}
      />
    </div>
  );
  
  export const telephoneInput =({
      input,
      type,
      label,
      tooltip,
      meta: { touched, error, warning, active },
    }) => (
      <>
        <input
          type={type}
          {...input}
          style={{height:'inherit' , fontSize:'14px' , marginLeft:'5px' , borderRadius:'5px' , position: 'relative' , 
          flex: '1 1 auto', width: '1%', minWidth: '0', marginBottom: '0' , padding:'0 10px 0 10px'}}
          className="form-control customizeInput"
          aria-label="Text input with dropdown button"
        />
        {touched && error ? (
          <div className="redColor font-size-10">{error}</div>
        ) : (
          <div className="whiteColor font-size-10"></div>
        )}
      </>
    );
  
