import {GET , POST} from "../common/Action";
import Action from "../common/Action";



//actions
export const getGeographicRegionsAction = new Action('GET_GEOGRAPHICREGIONS_ACTION' , POST , 'api/GeographicRegion/GetAll') ;
export const newGeographicRegionAction = new Action('ADD_GEOGRAPHICREGIONS_ACTION' , POST , 'api/GeographicRegion/Add') ;
export const editGeographicRegionAction = new Action('EDIT_GEOGRAPHICREGIONS_ACTION' , POST , 'api/GeographicRegion/update') ;
export const deleteGeographicRegionAction = new Action('DELETE_GEOGRAPHICREGIONS_ACTION' , GET , params=>`api/GeographicRegion/delete?id=${params.id}`);

//action creators
export const getGeographicRegions = (data) => {
  return getGeographicRegionsAction.callApi(data) ;
};


export const newgeographicRegion = (geographicRegion)=>{
  return newGeographicRegionAction.callApi(geographicRegion);
};
export const editgeographicRegion= (geographicRegion) => {
  return editGeographicRegionAction.callApi(geographicRegion) ;
};

export const deletegeographicRegion=(id) => {
  return deleteGeographicRegionAction.callApi(null , {id}) ;
};