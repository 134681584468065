import {addTopicAction , editTopicAction , deleteTopicAction , addSubTopicAction , editSubTopicAction , deleteSubTopicAction} from "./actions";

export default (state={} , action) => {
    switch (action.type) {
        case addTopicAction.resultAction :
        case addSubTopicAction.resultAction :
            return {...action.payload.data , action : 'ADD_TOPIC'} ;
        case editTopicAction.resultAction :
        case editSubTopicAction.resultAction :
            return {...action.payload.data , action : 'EDIT_TOPIC'} ;
        case deleteTopicAction.resultAction :
        case deleteSubTopicAction.resultAction :
            return {...action.payload.data , action : 'DELETE_TOPIC'} ;
        default :
            return state ;
    }
};