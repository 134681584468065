import React from 'react';
import {Link } from 'react-router-dom' ;
import classNames from 'classnames' ;
import _ from 'lodash' ;

const icon = (props)=>{
    let alartText = "";
    if(props.name==="Messages"){
        if(props.alart){
            alartText = <span className="activeBullet"></span>;
        }
        return(
            <Link to="/messages" style={{textDecoration : "none" , color:'black'}}>
                
                    <li className={classNames({"alert":props.alart}  ,'blackColor' , {"active" : _.includes(props.location.pathname , '/messages')})}>
                        <span className="icon icon-message"></span>
                        <span className="listText">Messages</span>
                        {alartText}
                    </li>
                    </Link>
        )
    }
    else if(props.path ==="" ){
        return(
            <li className="clickShape" >
                <div onClick={()=>props.onclick(props.active_id)}>
                    <span className={"icon "+props.class}></span>
                    <span className="listText" >{props.name} </span>
                    <span className={classNames("pointArrow icon font-size-14 rightFloat" , {"icon-up-direction" : props.active , "icon-bottom-direction" : !props.active})}></span>
                </div>

                {props.active ?
                    <ul className="innerList">
                    {props.iconList.map((icon , index)=>{
                        return(
                            <li key={index} style={{padding:'1rem'}} className={classNames("" , {"active" : _.includes(props.location.pathname , '/'+icon.path)})}>
                                <Link to={'/'+icon.path} style={{textDecoration : "none" , color:'block'}}>
                                    <span className={"blackColor icon "+ icon.class}></span>
                                    <span className="blackColor listText">{icon.name}</span>
                                </Link>
                            </li>
                        )
                    })}
                </ul> : null}
            </li>
        )
        
    }
    else{
        return (
            <li className={classNames(props.name , {"active" : _.includes(props.location.pathname , '/'+props.path)})}>
                <Link to={'/'+props.path} style={{textDecoration : "none" , color:'block'}}>
                    <span  onMouseOver={props.onMouseOver} className={"blackColor icon "+ props.class}></span>
                    <span className="blackColor listText">{props.name}</span>
                </Link>
            </li>
            
        );
    }
}


export default icon;


