import * as React from 'react';
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton
} from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import _ from 'lodash';
import { createSvgIcon } from '@mui/material';
import ROUTES from '../api-routes';
import authService from '../api-authorization/AuthorizeService';
import UseToken from '../api-authorization/UseToken';

export default function TableComponent(props) {

  const Operators = {
    contains : 1,
    lt : 2,
    lte : 3,
    gt : 4,
    gte : 5,
    startsWith : 6,
    doesnotstartwith : 7,
    endsWith : 8,
    doesnotendwith : 9,
    doesnotcontain : 10,
    equals : 11,
    neq : 12,
    isEmpty : 13,
    isNotEmpty : 14,
    between : 15,
    is:16,
    isAnyOf:17
}

const covertToPascalCase = (w) =>{
  return w[0].toUpperCase() + w.slice(1);
}

  const baseRoute = ROUTES.api;
  
  const ExportIcon = createSvgIcon(
    <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
    'SaveAlt',
  );

  const onExportBtnClick = ()=>{
    const token = UseToken()
    const request = fetch(baseRoute + props.api, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },
      body: JSON.stringify({dtParameter:{...props.dtParameter , export:true}})
    })
    request.then(r => r.json()).then(data => {
        if(data && !_.isEmpty(data) && data.path){
          let a = document.createElement('a');
          a.href = baseRoute+data.path;
          a.download = 'export.xlsx';
          a.click();
        }
    });
  }


  const CustomToolbar = () => {
    
    // const handleExport = (options) => apiRef.current.exportDataAsCsv(options);

    const buttonBaseProps = {
      color: 'primary',
      size: 'small',
      startIcon: <ExportIcon />,
    };

    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <Button
          {...buttonBaseProps}
          onClick={() => onExportBtnClick()}
            // handleExport({ getRowsToExport: getRowsFromCurrentPage })}
        >
          Export
        </Button>
        
      </GridToolbarContainer>
    );
  };

  

  const onPageSizeChange = (pageSize)=>{
    if(props.dtParameter.current*pageSize>props.totalCount)
      props.setDtParameter({...props.dtParameter , current:1})
    props.setDtParameter({...props.dtParameter , pageSize:pageSize})
  }


  const onFilterChange = (model)=>{
    console.log('filter' , model , model.items.map(i => Operators[[i.operator]]))
    const cleanModel = {...model , items:model.items.filter(it=>it.operator=='isEmpty'||it.operator=='isNotEmpty'||it.value)}
    if(cleanModel.items.length==0)
      props.setDtParameter({...props.dtParameter ,filter:null})
    else
      props.setDtParameter({...props.dtParameter ,filter:
        {
          filters:cleanModel.items.map(i => (
            {
              field:covertToPascalCase(i.field),
              value1: Array.isArray(i.value)?i.value.join(','):i.value,
              operator:i.operator?Operators[i.operator]||1:null
            }
          )),
          logic:cleanModel.logicOperator

        } 
      })
  }

  const onSortChange = (sortModel) =>{
    if(sortModel==null||_.isEmpty(sortModel))
      props.setDtParameter({...props.dtParameter , dir:null , orderBy:null})
    else if(sortModel[0].field != props.dtParameter.orderBy || sortModel[0].sort != props.dtParameter.dir)
      props.setDtParameter({...props.dtParameter , dir:sortModel[0].sort , orderBy:sortModel[0].field})
  }

  const handleRowEditCommit = React.useCallback(
    (params) => {
      const id = params.id;
      const key = params.field;
      const value = params.value; 
      props.changeSortColumnValue(id , value)
    }
    , []
  );

  const processRowUpdate = React.useCallback(
    (newRow, oldRow) =>{
      props.changeSortColumnValue(newRow.id , newRow.priority)
      return newRow
    },
      // new Promise((resolve, reject) => {
      //   const mutation = computeMutation(newRow, oldRow);
      //   if (mutation) {
      //     // Save the arguments to resolve or reject the promise later
      //     setPromiseArguments({ resolve, reject, newRow, oldRow });
      //   } else {
      //     resolve(oldRow); // Nothing was changed
      //   }
      // }),
    [],
  );

  const onPaginationChange = (pagination) =>{
    if (props.dtParameter.current * pagination.pageSize > props.totalCount)
      props.setDtParameter({
        ...props.dtParameter,
        pageSize:pagination.pageSize,
        current: 1,
      });
    else
    props.setDtParameter({
      ...props.dtParameter,
      pageSize: pagination.pageSize,
      current:pagination.page+1
    });
  }


  return (
    <div className='tableComponent' style={{ height: 250 + (50 *props.dtParameter.pageSize)}}>
      <DataGrid
        components={{ Toolbar: CustomToolbar }}
        className = {props.className}
        getRowClassName = {props.getRowClassName}
        rows={props.rows}
        columns={props.columns}
        pagination
        onCellClick={(selected) => {
          if(selected.field == 'id')
            return 
          if(props.selectedList.filter(s => s == selected.id).length>0){
            props.setSelectedList(props.selectedList.filter(s => s!= selected.id))
          }else{
            props.setSelectedList([...props.selectedList , selected.id])
          }
        }}
        checkboxSelection={props.checkboxSelection}
        // onSelectionModelChange={(newSelection) => {
        //   props.setSelectedList(newSelection)
        // }}
        selectionModel={props.selectedList}
        paginationModel={{page:props.dtParameter.current-1, pageSize:props.dtParameter.pageSize}}
        onPaginationModelChange={onPaginationChange}
        pageSizeOptions={props.pageSizes}
        rowCount={props.totalCount}
        paginationMode={props.clientPaginationMode?"client":'server'}
        filterMode={props.clientFilterMode?"client":'server'}
        loading={props.loading}
        onFilterModelChange={onFilterChange}
        sortingMode={props.clientSortMode?"client":'server'}
        sortModel = {props.dtParameter.sort}
        onSortModelChange={onSortChange}
        experimentalFeatures= {props.hasSort?{ newEditingApi: true }:null}
        // onCellEditCommit={handleRowEditCommit}
        // onCellEditStop={handleRowEditCommit}
        processRowUpdate={processRowUpdate}
      />
    </div>
  );
}