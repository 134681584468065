import {CreateDefaultBannerAction , DeleteDefaultBannerAction} from "./actions";
import _ from 'lodash' ;

export default (state={} , action) => {
    switch (action.type) {
        case CreateDefaultBannerAction.resultAction :
            return {id:action.payload.data ,succeeded:action.payload.data.succeeded , action : 'NEW'} ;
        case DeleteDefaultBannerAction.resultAction :
            return {result:action.payload.data ,succeeded:action.payload.data.succeeded , action : 'DELETE'} ;
        default :
            return state ;
    }
};

