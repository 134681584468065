import React , {useState , useEffect} from 'react' ;
import {connect} from 'react-redux' ;
import LeftNav from '../components/left-menu' ;
import TableComponent from '../components/TableComponent';
import {GetApprovableAsyncDataset , ApprovedAsyncDatasets , DisApprovedAsyncDatasets, setPriority} from "./actions";
import _, { update } from 'lodash'
import { Fragment } from 'react';
import cssClasses from '../ApprovedVendors/ApprovedVendorListStyles.module.css'
import classNames from 'classnames';
import { Link, withRouter } from 'react-router-dom';
import SimpleLoading from '../components/simpleLoading';

const MainPage = props => {
    const {datasetList , ApprovedActionResult} = props
    const [selectedDatasets , setSelectedDatasets] = useState([])
    const isStandard = props.location.pathname.includes('Standards')
    const [rows , setRows] = useState(null)
    const [total , setTotal] = useState(0)
    const [dtParameter , setDtParameter] = useState({current:1 , pageSize:10 , filter:null})
    const [loading , setLoading] = useState(false)
    const [columns , setColumns] = useState(null)

    useEffect(()=>{
        setLoading(true)
        props.GetApprovableAsyncDataset({dtParameter:dtParameter, isStandard:isStandard}) ;
    },[isStandard])

    useEffect(() => {
        setLoading(true)
        props.GetApprovableAsyncDataset({dtParameter:dtParameter, isStandard:isStandard}) ;
    } , [dtParameter]) ;


    useEffect(()=>{
        if(!ApprovedActionResult)
            return
        setSelectedDatasets([])
        if(ApprovedActionResult.succeeded){
            props.GetApprovableAsyncDataset({dtParameter:{...dtParameter,current:1, dir:"desc",orderBy:"lastChangeStatusDate"},isStandard:isStandard})
            setDtParameter({...dtParameter , current:1 ,dir:"desc",orderBy:"lastChangeStatusDate" })
        }if(ApprovedActionResult.type=="Priority" ){
            console.log('the value is change' , ApprovedActionResult.id)
        }   
        else{
            console.log('Error happend try again later')
        }
    },[ApprovedActionResult])

    // React.useCallback(
    //     (newRow, oldRow) =>
    //       new Promise((resolve, reject) => {
    //         console.log('newRow', newRow, 'oldRow', oldRow)
    //         if (true) {
    //             console.log('ture')
    //           // Save the arguments to resolve or reject the promise later
    //         //   setPromiseArguments({ resolve, reject, newRow, oldRow });
    //         } else {
    //           resolve(oldRow); // Nothing was changed
    //         }
    //       }),
    //     [],
    // );

    

    useEffect(()=>{
        if(!datasetList||_.isEmpty(datasetList))
            return
        const cols = datasetList.columns.map(col=>{
            if(col.field && col.field=='priority'){
                return ({
                    ...col ,  editable:true
                })
            }
            if(!col.hasRenderCell)
                return col
            if(col.field&&col.field.includes('logo')||col.field=='banner'){
                return ({...col , renderCell:(param)=>(<img src={param.value}/>)})
            }
            if(col.field&&col.type=='list'){
                return ({...col , type:null , renderCell:(params)=>(
                    _.map(
                          params.value,
                          (key , index) => {
                                return (
                                  <span
                                  key={index}
                                  className={cssClasses.keyItem}
                                  >
                                    {key}
                                  </span>
                                );
                              }
                              )
                            
                    
                  )})
            }})
            cols.push({
                field:'id', flex:1 , minWidth:200 , headerName:'Action' , renderCell:(params)=>(
                    <Link  target='_blank' to={{pathname:`${process.env.REACT_APP_DATAPROVIDER_API}/dataset/${params.value}`}} style={{textDecoration : 'none' , color:'black'}}><span className="giveAccess blueBackground clickShape">
                        <span className="icon icon-right-sign-arrow whiteColor"></span>
                        </span>
                    </Link>
                )
            })
            
        setColumns(cols)
        setLoading(false)
        setRows(datasetList.data.map(d=>({...d , sort:1})))
        setTotal(datasetList.total)
    },[datasetList])


    const approveDatasets = ()=>{
        if(_.isEmpty(selectedDatasets))
            return
        const approvalDto = {ids:selectedDatasets , approvedStatus:2 , adminMessage:''}
        props.ApprovedAsyncDatasets(approvalDto)
    }
    const NotApproveDatasets = ()=>{
        if(_.isEmpty(selectedDatasets))
            return
        const approvalDto = {ids:selectedDatasets , approvedStatus:3 , adminMessage:''}
        props.DisApprovedAsyncDatasets(approvalDto)
    }

    const onSortValueChange = (id, value) =>{
        props.setPriority({
            Ids:[id],
            Priority:value
        })
    }

    return (
        <>
            <div className="mainHeader organizer font-size-24 mediumWeight">
                <div className="titleItems">
                    <span className="mainTitle middleContext">{isStandard?"Approved Standards":"Approved Datasets"}</span>
                </div>
                <div className="filterItems">
                    <div className="filters inlineView">
                        <div className="changeDate inlineView"></div>
                        <div className="accessLevel inlineView"></div>
                    </div>
                    {!_.isEmpty(selectedDatasets)?
                    <Fragment>
                        <span style={{marginRight:'10px'}} onClick={approveDatasets} className={classNames(cssClasses.cursorPointer , "createNewPlan font-size-18")}>
                            Approve
                        </span>
                        <span onClick={NotApproveDatasets} className={classNames(cssClasses.cursorPointer,"font-size-18 createNewPlan")}>
                            DisApprove
                        </span>
                    </Fragment>
                    :null}
                </div>
            </div>
        {rows&&columns?
             <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="adminBoilerplate organizer">
                        <TableComponent
                            checkboxSelection={true}
                            pageSizes={[5,10,15]} 
                            totalCount={total} 
                            selectedList = {selectedDatasets}
                            setSelectedList={setSelectedDatasets} 
                            setDtParameter={setDtParameter} 
                            loading = {loading}
                            dtParameter = {dtParameter}
                            rows={rows} 
                            columns={columns}
                            hasSort={true}
                            changeSortColumnValue={onSortValueChange}
                            getRowClassName={(params) => 
                                cssClasses[`row--${params.row.approvedStatus.split(' ').join('')}`]
                            }
                            api = 'api/datasetBundle/GetApprovable'
                        />
                    </div>
                </div>
            </div>
        :<SimpleLoading/>}
        </>
  ) ;
};

const mapStateToProps = (state , ownProps) => {
    return {
        datasetList : state.ApprovedAsyncDatasets,
        ApprovedActionResult : state.ApprovedDatasetsResults,
    } ;
};

export default connect(mapStateToProps , {GetApprovableAsyncDataset , ApprovedAsyncDatasets , DisApprovedAsyncDatasets, setPriority})(withRouter(MainPage)) ;


