import classNames from "classnames"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Field, reduxForm } from "redux-form"
import { SimpleInput } from "../components/formFields"

const validate = (values) =>{
    let errors  ={}
    if(!values.title){
        errors.title="required!"
    }
    return errors
}


const NewDataRoleModal = props =>{

    return(
        <div className="popup profiles">
            <div className="popupClose" onClick={props.closeModal}></div>
            <div className="popUpBox" style={{width:'90%'}}>
                <div className="popupHeader spaceBetween organizer">
                    <div className="popUpTitle font-size-24 mediumWeight">
                        New Data Role
                    </div>
                    <div className="popupCross clickShape" onClick={props.closeModal}>
                        <span className="icon icon-cross font-size-18 lightGrayColor"></span>
                    </div>
                </div>
                <div className="popUpContent organizer" style={{maxHeight:'550px' , overflowY:'scroll'}}>
                    <Field name='title' label="Data Role Title" component={SimpleInput}/>                    
                    <div className="aggregateAction inlineView rightFloat">
                        <div className="normalButton inlineView grayBackground whiteColor clickShape topNormalMargin" onClick={props.closeModal}>
                            Cancel
                        </div>
                        <div className={classNames("normalButton inlineView whiteColor topNormalMargin leftNormalMargin" , {"blueBackground clickShape" : !props.loading , "grayBackground" : props.loading})} onClick={props.handleSubmit}>
                            Save changes
                        </div>
                    </div> 
                </div>
            </div>
        </div>
        
    )
}


const mapStateToProps = (state, ownProps) => {
    return {
    };
};

const form = connect(mapStateToProps, {
})(withRouter((NewDataRoleModal)));
  

const ReduxNewDataRoleForm = reduxForm({
  // a unique name for the form
  form: 'reduxNewDataRoleForm',
  validate
})(form)

export default withRouter(withTranslation()(ReduxNewDataRoleForm));