import {AddNewDataRoleAction, ApprovedAsyncDataRolesAction , DisApprovedAsyncDataRolesAction} from "./actions";

export default (state=[] , action) => {
  switch (action.type) {
      case ApprovedAsyncDataRolesAction.resultAction :
            return {...action.payload.data , action : 'Approved'} ;
      case DisApprovedAsyncDataRolesAction.resultAction :
            return {...action.payload.data , action : 'DisApproved'} ;
      case AddNewDataRoleAction.resultAction:
            return {...action.payload.data , action :'New'}
      default :
            return state ;
  }
};