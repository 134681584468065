//@ts-nocheck
import React, { useState, Fragment, useEffect } from "react";
import classNames from "classnames";
import { BlockManager, BasicType, AdvancedType } from "easy-email-core";
import { EmailEditor, EmailEditorProvider } from "easy-email-editor";
import { ExtensionProps, StandardLayout } from "easy-email-extensions";
import { useWindowSize } from "react-use";
import "easy-email-editor/lib/style.css";
import "easy-email-extensions/lib/style.css";
import "@arco-themes/react-easy-email-theme/css/arco.css";
import { Dialog, Transition } from "@headlessui/react";
import _ from "lodash";
import Modal from "../components/Modal.tsx";
import {
  Button,
  ConfigProvider,
  Dropdown,
  Form,
  Input,
  Menu,
  Message,
  PageHeader,
  Select,
  Space,
  Spin,
} from "@arco-design/web-react";

interface propsType {
  show: boolean;
  closeModal: () => void;
  children?: React.ReactNode;
  footer?: React.ReactNode;
  title?: string;
  className?: string;
  width?: string;
}

const defaultProps = {};

const initialValues = {
  subject: "",
  subTitle: "",
  content: BlockManager.getBlockByType(BasicType.PAGE)!.create({}),
};

const EasyEmailEditor = (props) => {
  const {
    loading,
    acceptAction,
    data,
    resultMessage,
    modalMessage,
    show,
    closeModal,
    children,
    footer,
    title,
    className,
  } = props;

  const { width } = useWindowSize();
  const [template, setTemplate] = useState({
    subject: "",
    subTitle: "",
    content: BlockManager.getBlockByType(BasicType.PAGE)!.create({}),
  });
  useEffect(() => {
    // axios.get("/api/template").then(({ data }) => {
    //   setTemplate(data);
    // });
  }, []);

  const onSubmit = (values) => {
    console.log("values of easy email", values.content );
    // setTemplate(values)
    // const data = {
    //   Title: contentPagesDetails[selectedContentPageId].title,
    //   Id: contentPagesDetails[selectedContentPageId].id,
    //   Contents: contents[selectedContentPageId],
    // };
    props.updateContentPage({
      id: props.id,
      Title: props.title,
      Contents: { Content: "", EmailTemplateJson: values.content, ContentType: 1 },
    });
  };

  const smallScene = width < 1400;

  return (
    <>
      <Modal
        className="w-[100vw] h-[100vh]"
        show={true}
        title={<></>}
        closeModal={closeModal}
      >
        <div className="w-full overflow-y-auto">
          <EmailEditorProvider
            data={template}
            onSubmit={onSubmit}
            height={"calc(100vh - 72px)"}
            autoComplete
            dashed={false}
          >
            {({ values }, { submit, restart }) => {
              return (
                <StandardLayout compact={!smallScene} showSourceCode={true}>
                  <div className="w-full p-4">
                    <div
                      onClick={submit}
                      className="w-fit h-fit px-6 py-2 flex flex-row justify-center items-center rounded-md cursor-pointer bg-blue-700 text-blue-50"
                    >
                      save
                    </div>
                  </div>
                  <EmailEditor />
                </StandardLayout>
              );
            }}
          </EmailEditorProvider>
        </div>
      </Modal>
    </>
  );
};

export default EasyEmailEditor;
