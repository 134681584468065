import {getAutocompletesAction} from "./actions";
import _ from 'lodash';

export default (state = {} , action) => {
    switch (action.type) {
        case getAutocompletesAction.resultAction :
            return {...state ,[action.payload.postedData.fieldValueType]: action.payload.data.result}
        default :
            return state ;

    }
}