import {getDatasetHasTermsAndConditionApprovalListAction } from "./actions";
import _ from 'lodash'

export default (state={} , action) => {
    switch (action.type) {
        case getDatasetHasTermsAndConditionApprovalListAction.resultAction :
            return {...action.payload.data};
        default:
            return state;
    }
}

