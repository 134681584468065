import { SettingsSuggestRounded } from '@mui/icons-material'
import React from 'react'
import { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { ApplicationPaths, QueryParameterNames } from './ApiAuthorizationConstants'
import authService from './AuthorizeService'
import { setUser } from './action'
import { connect } from 'react-redux'

class AuthorizeRoute extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ready: false,
            authenticated: false
        };
    }

    componentDidMount() {
        console.log("DidMount")
        this._subscription = authService.subscribe(() => this.authenticationChanged());
        this.populateAuthenticationState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    render() {
        const { ready, authenticated } = this.state;
        var link = document.createElement("a");
        link.href = this.props.path;
        // console.log("log_undefined", link.search=='', link.hash=='', link.pathname)
        const returnUrl = `${link.protocol}//${link.host}${link.pathname=='/undefined'? '': link.pathname}${link.search}${link.hash}`;
        const redirectUrl = `${ApplicationPaths.Login }?${QueryParameterNames.ReturnUrl}=${encodeURIComponent(returnUrl)}`
        console.log("props", this.props);

        console.log('render authorized', ready, authenticated, returnUrl, redirectUrl, );
        if (!ready) {
            return <div>You are not authorized</div>;
        } else {  
            console.log('render authorized', ready, authenticated, returnUrl, redirectUrl, );      
            
            const { component: Component, ...rest } = this.props;
            console.log("rest", rest)

          
            // return <h5>Fuck you either way</h5>


            if(authenticated){
                return <Route {...rest} render={(props) => {
                    console.log("Authenticated**********")
                    authService.getUser().then(user => this.props.setUser(user))
                        return <Component {...props} />
                }}/> 
            }
            console.log("Not Authenticated*************88");
            return <Redirect to={redirectUrl} replace/>
            // return <Route {...rest}
            //     render={(props) => {
            //         if (authenticated) {
            //             authService.getUser().then(user => this.props.setUser(user))
            //             return <Component {...props} />
            //         } else {
            //             return <Redirect to={redirectUrl} replace/>
            //         }
            //     }} />
        }
    }

    async populateAuthenticationState() {
        const authenticated = await authService.isAuthenticated();
        this.setState({ ready: true, authenticated });
    }

    async authenticationChanged() {
        this.setState({ ready: false, authenticated: false });
        await this.populateAuthenticationState();
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
      user: state.user
    };
  };
  


export default connect(mapStateToProps, {setUser})(AuthorizeRoute)