import getAllAprovalreducer from './reducer.getAllApproval'
import getApprovalResults from './reducer.getResult'
import getAllDataproviderReducer from './reducer.getAllDataprovider'
import getAllEntityTypeReducer from './reducer.getAllEntityType'

export default {
    ApprovedAsyncRules : getAllAprovalreducer,
    ApprovedRulesResults:getApprovalResults,
    ApprovedRulesAllDataprovider: getAllDataproviderReducer,
    ApprovedRulesAllEntityTypes: getAllEntityTypeReducer
};