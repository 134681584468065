import React , {useState , useEffect , Fragment} from 'react' ;
import {connect} from 'react-redux' ;
import classNames from "classnames";
import useDebounce from '../components/use-debounce' ;
import _ from 'lodash' ;
import {getUserSurveyPurchases , changeUserSurveyPurchases , getAllUsers } from "./actions";

const CheckBoxes = (props) => {

    const {closeModal , userSurveyPurchases  ,userId , userFormResult} = props ;
    const [checkList , setCheckList] = useState(null);
    const [checkedIsInitializedFlag , setCheckedIsInitializedFlag] = useState(false) ;
    const [checked , setChecked] = useState(null) ;
    const [allSurveys , setAllSurveys] = useState(null) ;
    const [changed , setChanged] = useState(false) ;
    const [text , setText] = useState(null) ;
    const [resultMessage , setResultMessage] = useState(null) ;
    const query = useDebounce(text , 500) ;


    useEffect(() => {
        if (userSurveyPurchases) {
            if (!checkedIsInitializedFlag) {
                setAllSurveys(_.map(userSurveyPurchases , survey => {
                    return {survey : survey , defaultValue : survey.surveyAccessId ? true : false } ;
                })) ;
                // setChecked(_.map(userSurveyPurchases , survey => {
                //     return {id : survey.surveyId , checked : survey.surveyAccessId ? true : false} ;})) ;
                let checkedObj = {} ;
                for (let i = 0; i < _.size(userSurveyPurchases) ; i++) {
                    checkedObj = {...checkedObj , [userSurveyPurchases[i].surveyId] : userSurveyPurchases[i].surveyAccessId ? true : false} ;
                }
                setChecked(checkedObj) ;
                setCheckedIsInitializedFlag(true) ;
                setChanged(false) ;
            }
            setCheckList(_.map(userSurveyPurchases , survey => {
                return {survey : survey , defaultValue : survey.surveyAccessId ? true : false } ;
            })) ;
        }

    } , [userSurveyPurchases]) ;

    useEffect(() => {
        if (query !== null) {
            props.getUserSurveyPurchases(userId , query) ;
        }
    } , [query]) ;

    useEffect(() => {
        props.getUserSurveyPurchases(userId , query) ;
        setResultMessage(null) ;
    } , []) ;

    useEffect(() => {
        if (!_.isEmpty(userFormResult)) {
            if (userFormResult.action === 'CHANGE_USER_SURVEY_PURCHASES') {
                if (userFormResult.IsSuccess) {
                    setResultMessage({success : true , message : 'Successfully Updated !'}) ;
                    setText(null) ;
                    props.getUserSurveyPurchases(userId , '') ;
                }
                else {
                    setResultMessage({success : false , message : 'Something Unexpectedly Happened ! Please Try Again Later .'}) ;
                }
            }
        }
    } , [userFormResult]) ;

    const onSurveyPurchasesUpdateClick = (checkList) => {
        // console.log(checkList) ;
        let changedList = [] ;
        for (let i = 0; i <_.size(allSurveys) ; i++) {
            if (checked[allSurveys[i].survey.surveyId] && !allSurveys[i].survey.surveyAccessId) {
                changedList.push({
                    State : 2 ,
                    SurveyId : allSurveys[i].survey.surveyId
                });
            }
            else if (!checked[allSurveys[i].survey.surveyId] && allSurveys[i].survey.surveyAccessId) {
                // console.log('make off' , checkList[i]) ;
                changedList.push({
                    State : 0 ,
                    SurveyAccessId : allSurveys[i].survey.surveyAccessId
                });
            }

        }
        const data = {
            UserId : userId ,
            ChangeSurveyAccesses :changedList
        };
        setResultMessage(null) ;
        setCheckedIsInitializedFlag(false) ;
        props.changeUserSurveyPurchases(data) ;

    };

    const updateCheckList = (surveyId) => {
        const checkedObj = {...checked , [surveyId] : !checked[surveyId]} ;
        let isChanged = false ;
        for (let i = 0; i <_.size(allSurveys) ; i++) {
            if (checkedObj[allSurveys[i].survey.surveyId] !== allSurveys[i].defaultValue) {
                isChanged = true ;
                break ;
            }
        }
        setChanged(isChanged) ;
        setChecked(checkedObj) ;
        if (!changed) setChanged(true) ;
    };

    return (<div className="popup profiles">
            <div className="popupClose" onClick={closeModal}></div>
            <div className="popUpBox">
                <div className="popupHeader spaceBetween organizer">
                    <div className="popUpTitle font-size-24 mediumWeight">
                        Survey Accesses
                    </div>
                    <div className="inputBox rightFloat input-width_300">
                        <input disabled={false} value={text} onChange={(e) => setText(e.target.value)} type="text" className="" placeholder="Search Survey ..."/>
                    </div>
                    <div className="popupCross clickShape" onClick={closeModal}>
                        <span className="icon icon-cross font-size-18 lightGrayColor"></span>
                    </div>
                </div>
                <div className="popUpContent organizer">
                        <Fragment>
                            <div className="organizer">
                                {_.map(checkList , (item , index) => {
                                    return (
                                        <div className="organizer">
                                            <div className={classNames("checkbox clickShape" , {"isSelected" : checked[item.survey.surveyId]})} onClick={(e) => updateCheckList(item.survey.surveyId)}>
                                                <input type="checkbox" className="clickShape" checked={checked[item.survey.surveyId]}/>
                                                {item.survey.datasetName}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="aggregateAction inlineView rightFloat">
                                <div className="normalButton inlineView redBackground whiteColor clickShape topNormalMargin" onClick={closeModal}>
                                    Cancel
                                </div>
                                <div className={classNames("normalButton inlineView whiteColor topNormalMargin leftNormalMargin" , {"grayBackground" : !changed , "clickShape blueBackground" : changed})} onClick={() => {if(changed)onSurveyPurchasesUpdateClick(_.map(checkList , (item , index) => {
                                    return {...item , checked : checked[index]}
                                }))}}>
                                    Update
                                </div>
                            </div>
                            {resultMessage ? <div className={classNames("leftFloat organizer topNormalMargin" , {'redColor' : !resultMessage.success , 'greenColor' : resultMessage.success})}>{resultMessage.message}</div> : null}

                        </Fragment>

                </div>
            </div>
        </div>) ;
};

const mapStateToProps = (state , ownProps) => {
    return {
        userSurveyPurchases : state.userSurveyPurchases[ownProps.userId] ,
        userFormResult : state.userFormResult
    };
};

export default connect(mapStateToProps , {getUserSurveyPurchases  , changeUserSurveyPurchases , getAllUsers})(CheckBoxes) ;