import React , {useState , useEffect , Fragment} from 'react' ;
import {connect} from 'react-redux' ;
import {addFaq , deleteFaq , getAllFaqs , getFaq , updateFaq} from "./actions";
import {getLanguages} from "../languages/actions"
import SelectBox from '../components/selectBox' ;
import {dropDownStyles} from "../components/formFields";
import _ from 'lodash' ;
import SimpleLoading from '../components/simpleLoading';
import DeleteModal from '../components/cmp.modal' ;
import FaqReduxForm from './FaqForm.js'

const MainPage = props => {
    const EDITFAQ = 1 , NEWFAQ =2;
    let {faqs , languages , selectedFaq , faqResult} = props;
    const [selectedFaqId ,setSelectedFaqId ] = useState(null);
    const [selectedLang, setSelectedLang] = useState({}) ;
    const [languageOptions , setLanguageOptions] = useState([]) ;
    const [isNew , setIsNew] = useState(false);
    const [showModal , setShowModal] = useState(false);
    const [faqFormResult , setFaqFormResult] = useState({});
    
    useEffect(() => {
        const param = {
            language:1,
            categoryId:1
        };
        props.getAllFaqs(param) ;
        props.getLanguages();
    } , []) ;

    useEffect(() => {
        if(!languages){
            return;
        }
        let langs =_.map(languages , lang => {
            return {label : lang.cultureName , value : lang.id , isRtl : lang.isRtl}
        });

        setLanguageOptions(langs) ;
        setSelectedLang(langs[0]) ;

    } , [languages])

    useEffect(() => {
        if(!selectedLang)
            return ;
        const param = {
            language:selectedLang.value,
            categoryId:1
        };
        faqs = {};
        props.getAllFaqs(param) ;
    } , [selectedLang]) ;

  useEffect(()=>{
      if(_.isEmpty(faqs)||selectedFaqId){
          return 
      }

      setSelectedFaqId(_.map(faqs , contentPage => {
        return contentPage ;
    })[0].id) ;  
    
  },[faqs]);


useEffect(() => {
    if (!selectedFaqId) {
        return;
    }
    setIsNew(false);
    if(!selectedFaq||!selectedFaq[selectedFaqId]){
        setFaqFormResult(null) ; 
        props.getFaq(selectedFaqId);
    }else{
        setFaqFormResult(null) ; 
    }
} , [selectedFaqId]) ;

useEffect(() => {
    if (!_.isEmpty(faqResult)) {
        if (faqResult.action === 'EDIT_FAQ') {
            setFaqFormResult({success : faqResult.success , message : faqResult.success ? 'Edited !' : 'Something Unexpectedly Happened ! Please Try Again Later .'}) ;
            if (faqResult.success) {
                props.getFaq(selectedFaqId);
            }
        }
        else if (faqResult.action === 'DELETE_FAQ') {
            setFaqFormResult({success : faqResult.success , message : faqResult.success ? 'Deleted !' : 'Something Unexpectedly Happened ! Please Try Again Later .'}) ;
            
        }
        else if (faqResult.action === 'NEW_FAQ') {
            setSelectedFaqId(faqResult.id)
        }
        if (faqResult.success ||faqResult.action === 'NEW_FAQ' ) {
            faqs = {};
            props.getAllFaqs();
        }
    }
} , [faqResult]) ;

  const onAddButtonClick = ()=>{
      setIsNew(true);
  }

  const onConfirm = (values)=>{
    setFaqFormResult(null);
    if (values.type ===EDITFAQ){
        const data = {...selectedFaq[selectedFaqId] , answer:values.answer , question:values.question}
        props.updateFaq(data);
    }else if(values.type === NEWFAQ){
        const data = {...values ,language:null, languageId :values.language.value , priority:selectedFaq[selectedFaqId].priority}
        props.addFaq(data);
    }
  }
  const onDeleteConfirm=()=>{
    setShowModal(false);
    props.deleteFaq(selectedFaqId)
  }
  const findId=(id)=>{
      if(!id){
          return null;
      }
    return(
        _.filter(languageOptions,(lang)=>{
            return lang.value ===id
        }).map((item)=>{
            return ({label:item.label ,value:item.value})
        }))
  }
  const selectId=(id)=>{
      setSelectedFaqId(id);
      setIsNew(false);
  }
  return(
      <Fragment>
          <div className="mainHeader organizer  mediumWeight">
              <div className="titleItems">
                  <span className="mainTitle font-size-24 middleContext">FAQ Contents</span>
                    <SelectBox
                       // label="Languages"
                        value={selectedLang}
                        options={languageOptions}
                        styles={dropDownStyles}
                        onChange={lang => setSelectedLang(lang)}
                    />
                </div>
            </div>
              <div className="adminBoilerplate organizer">

                  <div className="twoSidePanel organizer">
                      <div className="columnSide twentyColumn noHeader websiteContent">
                          <table className="tableStructure organizer">
                              <thead className="tableHeader">
                                  <tr>
                                    <th className="spaceBetween fullWidth">
                                        Category
                                    </th>
                                  </tr>
                              </thead>
                              <tbody>
                                <tr className="active">
                                    <td>First category</td>
                                </tr>
                              </tbody>
                          </table>
                      </div>
                      <div className="columnSide leftBorder websiteContent" style={{width:'30%'}}>

                          <table className="tableStructure organizer">
                              <thead className="tableHeader">
                                  <tr>
                                    <th>Question
                                        {/* <span style={{float:'right'}} className="createNewFaq inlineView clickShape" onClick={onAddButtonClick}>
                                            <span className="icon icon-add"></span> add
                                        </span> */}
                                        {/* <div className="filterItems"> */}
                                            <div style={{float:'right'}} className="createNewPlan clickShape" onClick={onAddButtonClick}>
                                                <span className="icon icon-add">New Question</span>
                                            </div>
                                        {/* </div> */}
                                    </th>
                                  </tr>
                              </thead>
                              <tbody>
                              {!_.isEmpty(faqs)?
                              _.map(faqs,(faq,id)=>{
                                  return (
                                    <tr key={id} onClick={()=>{selectId(id)}} className={faq.id==selectedFaqId?"active":''}>
                                        <td>
                                            {faq.question}
                                        </td>
                                    </tr>
                                  )
                              })
                              :<SimpleLoading/>}
                             
                              </tbody>
                          </table>
                      </div>
                    {(selectedFaq&&selectedFaq[selectedFaqId]&&!_.isEmpty(selectedFaq[selectedFaqId]))?
                        <FaqReduxForm 
                            initialValues={isNew ?{type:NEWFAQ,language:findId(selectedLang.value)[0]}: {...selectedFaq[selectedFaqId],type:EDITFAQ,language:findId(selectedFaq[selectedFaqId].languageId)[0]}}
                            faqFormResult={faqFormResult}
                            isNew={isNew}
                            languageOptions={languageOptions}
                            dropDownStyles={dropDownStyles}
                            onSubmit={onConfirm}
                            setShowModal={setShowModal}/>
                    :<SimpleLoading/> }
                  </div>
              </div>
              {showModal ? <DeleteModal closeModal={() => setShowModal(false)} acceptAction={onDeleteConfirm} data={null} title="Delete FAQ" modalMessage={'Are you sure you want to delete?'} resultMessage={faqFormResult}/> : null}
          </Fragment>
  ) ;
};

const mapStateToProps = (state , ownProps) => {
    return {
        faqs :state.faqs,
        languages : state.languages ,
        selectedFaq :state.selectedFaq,
        faqResult :state.faqResult
    } ;
};


export default connect(mapStateToProps , {addFaq , updateFaq , deleteFaq , getFaq , getAllFaqs,getLanguages})(MainPage) ;