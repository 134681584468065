import {getTopicsAction , getSubTopicsAction} from "./actions";

export default (state=0 , action) => {
    switch (action.type) {
        case getTopicsAction.resultAction :
        case getSubTopicsAction.resultAction :
            return action.payload.data.totalCount ;
        default :
            return state ;
    }
};