import {GET , POST , PUT , POST_FORM} from "../common/Action";
import Action from "../common/Action";

//actions
export const GetApprovableAsyncVendorAction = new Action('GET_APPROVABLE_VENDORS' , POST , 'api/vendor/GetApprovable') ;
export const ApprovedAsyncVendorsAction = new Action('APPROVED_ASYNC_VENDOR_ACTION' , POST , 'api/vendor/Approve');
export const DisApprovedAsyncVendorsAction = new Action('DISAPPROVED_ASYNC_VENDOR_ACTION' , POST , 'api/vendor/DisApprove');
export const setProrityAction = new Action('APPROVED_VENDOR_SET_PRORITY_ACTION' , POST , 'api/vendor/setPriority');


//action creators
export const GetApprovableAsyncVendor = (data) => {
  return GetApprovableAsyncVendorAction.callApi(data) ;
};

export const ApprovedAsyncVendors = (data) =>{
  return ApprovedAsyncVendorsAction.callApi(data);
}

export const DisApprovedAsyncVendors = (data) =>{
  return DisApprovedAsyncVendorsAction.callApi(data);
}

export const setPriority = (data) =>{
  return setProrityAction.callApi(data);
}