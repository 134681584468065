import React , {useState , useEffect, Fragment} from 'react' ;
import {connect} from 'react-redux' ;
import LeftNav from '../components/left-menu' ;
import {addPage , editPage , getPage , getAllPages , deletePage} from "./actions";
import _ from "lodash";
import classNames from "classnames";
import CustomPageForm from "./customPageForm";
import SimpleLoading from "../components/simpleLoading";
import DeleteModal from '../components/cmp.modal' ;
import { getLanguages } from '../languages/actions';

export const languagesOptions = (languages) => {
    return _.map(languages , lang => {
        return {label : lang.cultureName , value : lang.id , isRtl : lang.isRtl}}) ;
};


const MainPage = props => {

    const {customPagesDetails , allCustomPages , languages , customPagesFormResult} = props ;

    const [selectedCustomPageId , setSelectedCustomPageId] = useState(null) ;
    const [showDeletePageModalId , setShowDeletePageModalId] = useState(null) ;
    const [formInitialValues , setFormInitialValues] = useState({}) ;
    const [formResultMessage , setFormResultMessage] = useState(null) ;
    const [selectedCustomPageIndex , setSelectedCustomPageIndex] = useState(0) ;
    const [formLoading , setFormLoading] = useState(false) ;
    const [pointerHoverId , setPointerHoverId] = useState(null) ;
    const [selectedMetaTags , setSelectedMetaTags] = useState(null)
    const [isMetaTagUpdated , setIsMetaTagUpdated] = useState(false)

    useEffect(() => {
        props.getLanguages();
        props.getAllPages() ;
    } , []) ;

    useEffect(() => {
        if (!_.isEmpty(allCustomPages)) {
            setSelectedCustomPageId(allCustomPages[selectedCustomPageIndex].id) ;
        }
    } , [allCustomPages]) ;

    useEffect(() => {
        if (!showDeletePageModalId) {
            setFormResultMessage(null) ;
        }

        if (selectedCustomPageId) {
            setFormInitialValues({}) ;
            props.getPage(selectedCustomPageId) ;
        }
        else {
            setFormInitialValues({}) ;
        }
    } , [selectedCustomPageId]) ;

    useEffect(() => {
        if (!_.isEmpty(customPagesDetails)) {
            if (customPagesDetails[selectedCustomPageId]) {
                    const initialValues = {
                        id: customPagesDetails[selectedCustomPageId].id,
                        language : _.filter(languagesOptions(languages) , language => language.value === customPagesDetails[selectedCustomPageId].languageId)[0] ,
                        pageTitle : customPagesDetails[selectedCustomPageId].title ,
                        pageDescription : customPagesDetails[selectedCustomPageId].description ,
                        content : customPagesDetails[selectedCustomPageId].content ,
                        metaTags : customPagesDetails[selectedCustomPageId].metaTags ,
                        metaDescription : '',
                        uniqueName : customPagesDetails[selectedCustomPageId].uniqueName
                    } ;
                    setFormInitialValues(initialValues) ;
            }
            else {
                setFormInitialValues({}) ;
            }
        }
    } , [customPagesDetails]) ;

    useEffect(() => {
        if (!_.isEmpty(customPagesFormResult)) {
            setFormLoading(false) ;
            if (customPagesFormResult.succeeded) {
                if (customPagesFormResult.action === 'ADD') {
                    setFormResultMessage({success : true , message : 'SuccessFully Added to Your Custom Pages.'}) ;
                    setSelectedCustomPageIndex(_.size(allCustomPages)) ;
                    props.getAllPages() ;
                    // setSelectedCustomPageId(null) ;
                }
                else if (customPagesFormResult.action === 'EDIT') {
                    setFormResultMessage({success : true , message : "Page Successfully Updated."}) ;
                    props.getAllPages() ;
                }
                else if (customPagesFormResult.action === 'DELETE') {
                    setFormResultMessage({success : true , message : "Page Successfully Deleted."}) ;
                    setSelectedCustomPageIndex(0) ;
                    props.getAllPages() ;

                }
            }
            else {
                setFormResultMessage({success : false , message : 'Something Unexpectedly Happened. Please Try Again Later.'})
            }
        }
    } , [customPagesFormResult]) ;

    const updateMetaTagsFunction = (values) =>{
        setIsMetaTagUpdated(true)
        setSelectedMetaTags(values)
    }
    const onCustomPageFormSubmit = (values) => {
        setFormResultMessage(null) ;
        let data = {
            Title : values.pageTitle ,
            Description : values.pageDescription ,
            MetaTags : isMetaTagUpdated? selectedMetaTags :values.metaTags ,
            MetaDescription : values.metaDescription ,
            languageId : values.language.value ,
            uniqueName : values.uniqueName
        };
        setFormLoading(true) ;
        if (!selectedCustomPageId ) {
            if(values.content){
                data = {...data , Content : values.content} ;
                props.addPage(data) ;
            }else{
                setFormResultMessage('Content is required!')
            }
        }
        else {
            data = {...data , Id : customPagesDetails[selectedCustomPageId].id , Content : values.content , CreationDate : customPagesDetails[selectedCustomPageId].creationDate} ;
            props.editPage(data) ;
        }
    };
    const onDeleteCustomPageClick = () => {
            setFormResultMessage(null) ;
            setShowDeletePageModalId(selectedCustomPageId) ;
    };
    const onDeleteCustomPageModalSubmit = () => {
        setFormResultMessage(null) ;
        setFormLoading(true) ;
        props.deletePage(showDeletePageModalId) ;
    };

    return (
        <Fragment>
            <div className="mainHeader organizer font-size-24 mediumWeight">
                <div className="titleItems">
                    <span className="mainTitle middleContext">Custom Pages</span>
                </div>
                <div className="createNewPlan inlineView clickShape" onClick={() => setSelectedCustomPageId(null)}>
                    <span className="icon icon-add"></span> New Custom Page
                </div>
            </div>
            <div className="adminBoilerplate organizer">
                {_.isEmpty(allCustomPages) || _.isEmpty(languages)? <SimpleLoading/> :
                    <div className="twoSidePanel organizer">
                        <div className="columnSide quarterColumn noHeader websiteContent">
                            <table className="tableStructure organizer">
                                <thead className="tableHeader">
                                <th>Page Title</th>
                                </thead>
                                <tbody>
                                {!_.isEmpty(allCustomPages) ? _.map(allCustomPages , (customPage , index) => {
                                    return (
                                        <tr className={classNames("clickShape" , {"active" : selectedCustomPageId === customPage.id || pointerHoverId === customPage.id})} onClick={() => {setSelectedCustomPageId(customPage.id); setSelectedCustomPageIndex(index)}} onMouseEnter={() => setPointerHoverId(customPage.id)} onMouseLeave={() => setPointerHoverId(null)}>
                                            <td>{customPage.title}</td>
                                        </tr>
                                    );
                                }) 
                                : null}
                                </tbody>
                            </table>
                        </div>
                        {!selectedCustomPageId ? 
                        <CustomPageForm updateMetaTagsFunction={updateMetaTagsFunction} loading={formLoading} resultMessage={formResultMessage} initialValues={{}} edit={selectedCustomPageId !== null} onSubmit={onCustomPageFormSubmit} deleteCustomPage={onDeleteCustomPageClick}/> :
                        !_.isEmpty(formInitialValues) ? 
                        <CustomPageForm loading={formLoading} updateMetaTagsFunction={updateMetaTagsFunction} resultMessage={formResultMessage} initialValues={formInitialValues} edit={selectedCustomPageId !== null} onSubmit={onCustomPageFormSubmit} deleteCustomPage={onDeleteCustomPageClick}/> :
                            <div className="columnSide threeQuarterColumn normalPadding">
                            <SimpleLoading/>
                            </div>
                        }
                    </div>
                }
            </div>
            {showDeletePageModalId ?
                <DeleteModal
                    loading={formLoading}
                    closeModal={() => {setShowDeletePageModalId(null) ; if (formResultMessage)setFormResultMessage(null) ;}}
                    acceptAction={onDeleteCustomPageModalSubmit}
                    title="Delete Page"
                    modalMessage={`Are you sure that you want to delete page "${customPagesDetails[showDeletePageModalId].title}" ? `}
                    data={null}
                    resultMessage={formResultMessage}
                />
            : null}
      </Fragment>
  ) ;

};

const mapStateToProps = (state , ownProps) => {
    return {
        allCustomPages : state.allCustomPages ,
        customPagesDetails : state.customPagesDetails ,
        customPagesFormResult : state.customPagesFormResult ,
        languages : state.languages
    } ;
};

export default connect(mapStateToProps , {getAllPages , getPage , addPage , getLanguages , editPage , deletePage})(MainPage) ;