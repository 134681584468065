import {GET , POST , PUT , POST_FORM} from "../common/Action";
import Action from "../common/Action";

//actions
export const GetApprovableAsyncContractAction = new Action('GET_APPROVABLE_CONTRACT_ACTION' , POST , 'api/contract/GetApprovable') ;
export const ApprovedAsyncContractsAction = new Action('APPROVED_ASYNC_CONTRACT_ACTION' , POST , 'api/contract/Approve');
export const DisApprovedAsyncContractsAction = new Action('DISAPPROVED_ASYNC_CONTRACT_ACTION' , POST , 'api/contract/DisApprove');


//action creators
export const GetApprovableAsyncContract = (data) => {
  return GetApprovableAsyncContractAction.callApi(data) ;
};

export const ApprovedAsyncContracts = (data) =>{
  return ApprovedAsyncContractsAction.callApi(data);
}

export const DisApprovedAsyncContracts = (data) =>{
  return DisApprovedAsyncContractsAction.callApi(data);
}