import React, {useEffect, useState} from 'react';
import 'babel-polyfill';
import './App.css';
import {connect} from 'react-redux' ;
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter
} from "react-router-dom";
import AddPlan from './features/addPlan/mainPage.js';
import Commissions from './features/commissions/mainPage.js';
import CustomPages from './features/customPages/mainPage.js' ;
import Dashboard from './features/dashboard/mainPage.js' ;
import Faq from './features/faq/mainPage.js';
import FolderHistory from './features/folderHistory/mainPage.js' ;
import Labels from './features/labels/mainPage.js' ;
import Languages from './features/languages/mainPage.js' ;
import Logoes from './features/logoes/mainPage.js' ;
import Maps from './features/maps/mainPage.js' ;
import Messages from './features/messages/mainPage.js' ;
import PartnerRequest from './features/partnerRequests/mainPage.js' ;
import Plans from './features/plans/mainPage.js' ;
import SearchHistory from './features/searchHistory/mainPage.js' ;
import SearchHistoryInner from './features/searchHistoryInner/mainPage.js' ;
import SubTopics from './features/subTopics/mainPage.js' ;
import Topics from './features/topics/mainPage.js' ;
import UsageHistory from './features/usageHistory/mainPage.js' ;
import UsageHistoryInner from './features/usageHistoryInner/mainPage.js' ;
import Users from './features/users/mainPage.js' ;
import WebsiteContent from './features/websiteContent/mainPage.tsx';
import Autocomplete from './features/autocomplete/Autocomplete.js';
import GeographicRegion from './features/geographicRegion/GeographicRegion.js';
import UploadableExtension from './features/uploadableExtension/extension.js';
import Contracting from './features/contracting/Contracting.js'
import Profile from './features/Profile/profile.js'
import ApprovedVendors from './features/ApprovedVendors/mainPage.js'
import ApprovedPersons from './features/ApprovedPersons/mainPage.js'
import ApprovedDatasets from './features/ApprovedDatasets/mainPage.js'
import ApprovedTopics from './features/ApprovedTopics/mainPage.js'
import ApprovedFieldValues from './features/ApprovedFieldValues/mainPage.js'
import ApprovedContracts from './features/ApprovedContracts/mainPage.js'
import Resource from './features/resources/main.js'
import Templates from './features/templates/mainPage.js'
import LegalDocs from './features/legalDocuments/mainPage.js'
import ApprovedDataRoles from './features/ApprovedDataRoles/mainPage.js'
import DefaultBanner from './features/defaultBanner/mainPage.js'
import ApprovalRules from './features/ApprovalRules/mainPage.js'
import MetaTags from './features/MetaTags/mainPage.js'
import LegalDocHistory from './features/legalDocuments/legalDocHistory.js'

import _ from 'lodash' ;
// import 'react-dates/initialize';
// import 'react-dates/lib/css/_datepicker.css';
// import './features/components/react-dates-styles.css' ;
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import LeftNav from './features/components/left-menu.js';
import classNames from 'classnames' ;
import { ContainerService } from "./features/common/notification.js";
//identity
import { ApplicationPaths } from "./features/api-authorization/ApiAuthorizationConstants.js";
import ApiAuthorizationRoutes from "./features/api-authorization/ApiAuthorizationRoutes.js";
// import authService from './features/api-authorization/AuthorizeService'
import AuthorizeRoute from "./features/api-authorization/AuthorizeRoute.js";


import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'

const App = (props) =>  {
    const [smallNav , setSmallNav] = useState(false) ;
    useEffect(() => {
        if (props.location.pathname === '/') {
            props.history.push('/dashboard') ;
        }
    } ,[]) ;

  return (
    
    <ContainerService.Provider>
    <div className="container">
        <LeftNav smallNav = {smallNav} setSmallNav = {setSmallNav}/>
        <div className={classNames("mainContent" , {'smallNav' : smallNav})}>
      
        {props.user ? <h1>Not Authorized</h1> : 
                <Switch>
                    <Route
          path={ApplicationPaths.ApiAuthorizationPrefix}
          component={ApiAuthorizationRoutes}
        /> 
                  <AuthorizeRoute path="/addPlan">
                      <AddPlan/>
                  </AuthorizeRoute>
                  <AuthorizeRoute path="/commissions">
                      <Commissions/>
                  </AuthorizeRoute>
                  <AuthorizeRoute path="/customPages">
                      <CustomPages/>
                  </AuthorizeRoute>
                  <AuthorizeRoute path="/dashboard">
                      <Dashboard/>
                  </AuthorizeRoute>
                  <AuthorizeRoute path="/faq">
                      <Faq/>
                  </AuthorizeRoute>
                  <AuthorizeRoute path="/folderHistory">
                      <FolderHistory/>
                  </AuthorizeRoute>
                  <AuthorizeRoute path="/labels">
                      <Labels/>
                  </AuthorizeRoute>
                  <AuthorizeRoute path="/languages">
                      <Languages/>
                  </AuthorizeRoute>
                  <AuthorizeRoute path="/logos">
                      <Logoes/>
                  </AuthorizeRoute>
                  <AuthorizeRoute path="/maps" exact>
                      <Maps/>
                  </AuthorizeRoute>
                  <AuthorizeRoute path="/maps/:id">
                      <Profile/>
                  </AuthorizeRoute>
                  <AuthorizeRoute path="/messages">
                      <Messages/>
                  </AuthorizeRoute>
                  <AuthorizeRoute path="/partnerRequest">
                      <PartnerRequest/>
                  </AuthorizeRoute>
                  <AuthorizeRoute path="/accessLevels">
                      <Plans/>
                  </AuthorizeRoute>
                  <AuthorizeRoute path="/searchHistory">
                      <SearchHistory/>
                  </AuthorizeRoute>
                  <AuthorizeRoute path="/searchHistoryInner/:id">
                      <SearchHistoryInner/>
                  </AuthorizeRoute>
                  <AuthorizeRoute path="/sobTopics">
                      <SubTopics/>
                  </AuthorizeRoute>
                  <AuthorizeRoute path="/topics">
                      <Topics/>
                  </AuthorizeRoute>
                  <AuthorizeRoute path="/usageHistory">
                      <UsageHistory/>
                  </AuthorizeRoute>
                  <AuthorizeRoute path="/usageHistoryInner/:id">
                      <UsageHistoryInner/>
                  </AuthorizeRoute>
                  <AuthorizeRoute path="/users" exact>
                      <Users/>
                  </AuthorizeRoute>
                  <AuthorizeRoute path="/users/:id">
                      <Users/>
                  </AuthorizeRoute>
                  <AuthorizeRoute path="/websiteContent">
                      <WebsiteContent/>
                  </AuthorizeRoute>
                  <AuthorizeRoute path="/autocomplete">
                      <Autocomplete/>
                  </AuthorizeRoute>
                  <AuthorizeRoute path="/geographicRegions">
                      <GeographicRegion/>
                  </AuthorizeRoute>
                  <AuthorizeRoute path="/restrictedExtension">
                      <UploadableExtension/>
                  </AuthorizeRoute>
                  <AuthorizeRoute path="/contracting">
                      <Contracting/>
                  </AuthorizeRoute>
                  <AuthorizeRoute path="/approvedVendors">
                      <ApprovedVendors/>
                  </AuthorizeRoute>
                  <AuthorizeRoute path="/approvedAssociations">
                      <ApprovedVendors/>
                  </AuthorizeRoute>
                  <AuthorizeRoute path="/approvedPersons">
                      <ApprovedPersons/>
                  </AuthorizeRoute>
                  <AuthorizeRoute path="/approvedDatasets">
                      <ApprovedDatasets/>
                  </AuthorizeRoute>
                  <AuthorizeRoute path="/approvedStandards">
                      <ApprovedDatasets/>
                  </AuthorizeRoute>
                  <AuthorizeRoute path="/approvedContracts">
                      <ApprovedContracts/>
                  </AuthorizeRoute>
                  <AuthorizeRoute path="/approvedTopics">
                      <ApprovedTopics/>
                  </AuthorizeRoute>
                  <AuthorizeRoute path="/approvedFieldValues">
                      <ApprovedFieldValues/>
                  </AuthorizeRoute>
                  <AuthorizeRoute path="/resources">
                      <Resource/>
                  </AuthorizeRoute>
                  <AuthorizeRoute path="/approvedDataRoles">
                      <ApprovedDataRoles/>
                  </AuthorizeRoute>
                  <AuthorizeRoute path="/resources">
                      <Resource/>
                  </AuthorizeRoute>
                  <AuthorizeRoute path="/templates">
                    <Templates/>
                  </AuthorizeRoute>
                  <AuthorizeRoute path="/legalDocs">
                    <LegalDocs/>
                  </AuthorizeRoute>
                  <AuthorizeRoute path="/legalDocHistory/:id">
                    <LegalDocHistory/>
                  </AuthorizeRoute>
                  <AuthorizeRoute path="/defaultBanner">
                    <DefaultBanner/>
                  </AuthorizeRoute>
                  <AuthorizeRoute path="/ApprovalRules">
                    <ApprovalRules/>
                  </AuthorizeRoute>
                  <AuthorizeRoute path="/MetaTags">
                    <MetaTags/>
                  </AuthorizeRoute>
              </Switch>
          }
      </div>
    </div>
    </ContainerService.Provider>
  );
} ;

const mapStateToProps = (state , ownProps) => {
  return {
        user: state.user
  };
};

export default withRouter(connect(mapStateToProps , {})(App));
