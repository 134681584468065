import {AddApprovalRuleAction  , DeleteApprovalRuleAction} from "./actions";

export default (state=[] , action) => {
    switch (action.type) {
        case AddApprovalRuleAction.resultAction :
            return {...action.payload.data , action : 'Add'} ;
        case DeleteApprovalRuleAction.resultAction :
            return {...action.payload.data , action : 'Delete'} ;
        default :
            return state ;
  }
};