import React , {useState , useEffect, Fragment} from 'react' ;
import {connect} from 'react-redux' ;
import SimpleLoading from "../components/simpleLoading";
import {getFolderHistory} from "./actions";
import _ from 'lodash' ;
import { Link, withRouter } from 'react-router-dom';
import TableComponent from '../components/TableComponent';

const MainPage = props => {

    const {folderHistories} = props ;

    const [rows , setRows] = useState(null)
    const [total , setTotal] = useState(0)
    const [dtParameter , setDtParameter] = useState({current: 1, pageSize: 10, filter: null, dir: null, orderBy: null})
    const [loading , setLoading] = useState(false)
    const [columns , setColumns] = useState(null)
    
    useEffect(() => {
        if(!dtParameter)
            return
        setLoading(true)
        props.getFolderHistory({dtParameter:dtParameter}) ;
    } , [dtParameter]) ;

    useEffect(()=>{
        if(!folderHistories||_.isEmpty(folderHistories))
            return
        const cols = folderHistories.columns.map(col=>{
            if(!col.hasRenderCell && col.field!='dateTime'){
                return col
            }else if(col.field =='dateTime'){
                return ({...col , renderCell:(param)=>(
                    <div className="displayFlex mediumWeight">
                        {_.join(_.split(_.split(param.value , 'T')[0] , '-') , '/')}
                        <p className="font-size-12 lightWeight">{_.split(param.value , 'T')[1].substring(0 , _.indexOf(_.split(param.value , 'T')[1] , '.'))}</p>
                    </div>
                )})
            }else{
                return col
            }
            
        })
        cols.push({
            field:'id', flex:1 , minWidth:200 , headerName:'Action' , renderCell:(params)=>(
                <Link to={`/usageHistoryInner/${params.value}`} style={{textDecoration : 'none' , color:'black'}}>
                    <span className="giveAccess  blueBackground clickShape">
                    <span className="icon icon-right-sign-arrow whiteColor"></span>
                    </span>
                </Link>
            )
        })
        setColumns(cols)
        setLoading(false)
        setRows(folderHistories.data)
        setTotal(folderHistories.total)
    },[folderHistories])

    return (
        <Fragment>
            {!rows||!columns?
                <SimpleLoading />
            :
            <Fragment>
                <div className="mainHeader organizer font-size-24 mediumWeight">
                    <div className="titleItems">
                        <span className="mainTitle middleContext">Folder History</span>
                    </div>
                </div>
                <div className="adminBoilerplate organizer">
                    <TableComponent
                        selectedList = {[]}
                        setSelectedList = {(e)=> console.log(e)}
                        pageSizes={[5,10,15]} 
                        checkboxSelection={false}
                        totalCount={total} 
                        setDtParameter={setDtParameter} 
                        loading = {loading}
                        dtParameter = {dtParameter}
                        rows={rows} 
                        columns={columns}
                        api = 'api/history/GetFolderHistory'
                    />
                </div>
            </Fragment>
            }
        </Fragment>
  ) ;
};

const mapStateToProps = (state , ownProps) => {
    return {
        folderHistories : state.folderHistories
    } ;
};

export default connect(mapStateToProps , {getFolderHistory})(withRouter(MainPage)) ;