import {editPageAction , addPageAction , deletePageAction} from "./actions";

export default (state={} , action) => {
  switch (action.type) {
      case addPageAction.resultAction :
          return {...action.payload.data , action : "ADD"} ;
      case editPageAction.resultAction :
          return {...action.payload.data , action : "EDIT"} ;
      case deletePageAction.resultAction :
          return {...action.payload.data , action: "DELETE"} ;
      default :
          return state ;
  }
};