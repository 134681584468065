
import reducerAllLegalDocs from './reducer.getAll';
import reducerGetResults from './reducer.getResults';
import reducerLegalDocById from './reducer.getLegalDocById'
import reducerGetTermsAndConditions from './reducer.getTermsAndConditions';
import reducerGetLegalDocHistory from './reducer.getLegalDocHistory';



export default {
   LegalDocsGetAllLegalDocs:reducerAllLegalDocs,
   LegalDocsGetResults:reducerGetResults,
   LegalDocsGetLegalDocById: reducerLegalDocById,
   LegalDocsGetTermsAndConditions:reducerGetTermsAndConditions,
   LegalDocsGetLegalDocHistory: reducerGetLegalDocHistory
};