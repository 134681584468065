
import React , {useState , useEffect, Fragment, useDebugValue} from 'react' ;
import {connect} from 'react-redux' ;
import Pagination from "../components/pagination";
import _, { isEmpty } from 'lodash' ;
import DeleteModal from '../components/cmp.modal' ;
import classNames from 'classnames' ;
import { useParams } from 'react-router-dom';
import {getMapProfiles , getRegions ,aggregateRegions , editAggregation ,deleteProfile} from "./actions";
import {Link } from 'react-router-dom' ;
import SimpleLoading from '../components/simpleLoading';



const Profile = (props)=>{
    const {mapProfiles , mapRegions , resultMessage} = props ;
    const [selectedProfile , setSelectedProfile] = useState(null);
    const [selectedAggregate , setSelectedAggregate] = useState(null);
    const [newProfile , setNewProfile] = useState(false);
    const [deleteProfileModel , setDeleteProfileModel]=useState(false);
    const [newAggregate , setNewAggregate] = useState(false);
    const [profileContent , setProfileContent] = useState(null);
    const [AggregateContent , setAggregateContent] = useState(null);
    const [deleteAggregate , setDeleteAggregate] = useState(false);
    const [editAggregate , setEditAggregate] = useState(false);
    const [errorMessage , setErrorMessage] = useState("");
    const [message , setMessage]= useState(null);
    const params = useParams();
    const [loadingList , setLoadingList] = useState(false)
    const [loading , setLoading] = useState(false)

    
    useEffect(() =>{
        setLoadingList(true)
        props.getMapProfiles(params.id);
        props.getRegions(params.id);
    } , []) ;

    useEffect(() =>{
        if(_.isEmpty(mapProfiles)){
            return;
        }
        setLoadingList(false)
        setSelectedProfile(_.map(mapProfiles , profile=>{return profile})[0])
    } , [mapProfiles]) ;

    
    useEffect(()=>{
        if(_.isEmpty(selectedProfile)){
            return 
        }
        setNewProfile(false);
        setProfileContent(null);
        setAggregateContent(null);
        setNewAggregate(false);
        setSelectedAggregate( _.map(selectedProfile.aggregatedRegions,aggregate=>{
            return aggregate
        })[0])

    },[selectedProfile]);

    useEffect(() =>{
        if(newAggregate){
            setSelectedAggregate(null);
            setAggregateContent({});
            setEditAggregate(false);
        }
        setErrorMessage(null);
    } , [newAggregate]) ;

    useEffect(() =>{
        if(selectedAggregate){
            setNewAggregate(false);
            setAggregateContent(null);
            setDeleteAggregate(false);
            setEditAggregate(false);
        }
        
    } , [selectedAggregate]) ;


    useEffect(() => {
        if (_.isEmpty(resultMessage))return;
        setLoading(false)
        if (resultMessage.action === 'EDIT') {
                setLoadingList(true)
                props.getMapProfiles(params.id);   
        }
        else if (resultMessage.action === 'DELETE') {
            setLoadingList(true)
            props.getMapProfiles(params.id);
        }
        else if (resultMessage.action === 'NEW') {
            setLoadingList(true)
            props.getMapProfiles(params.id);
        }
        
    } , [resultMessage]) ;

    useEffect(() =>{
        setErrorMessage(null);
        if(editAggregate){
            setSelectedAggregate(null);
            const regions = _.map(selectedAggregate.aggregatedRegionDetails, reg=>{
                return {...reg , id : reg.geoId}
            })
            setAggregateContent({newName:selectedAggregate.newName ,
                newId:selectedAggregate.newId, 
                id : selectedAggregate.id ,
                aggregatedRegionDetails:regions});
        }
        
    } , [editAggregate]) ;

    useEffect(() =>{
        
        
    } , [profileContent]) ;

    useEffect(() =>{
        setErrorMessage(null);
        if(newProfile){
            setSelectedAggregate(null);
            setSelectedProfile(null);
            setProfileContent({mapId:params.id });
            
        }else{
            if(mapProfiles&&!_.isEmpty(mapProfiles)){
                setSelectedProfile(mapProfiles[0]);
            }
        }
        
    } , [newProfile]) ;

    const createNewId = (regions)=>{
        let newId=""
        _.map(regions,region=>{
            newId+=region.geoName
        })
        return newId;
        
    }
    const saveProfile=()=>{
        if(!profileContent.name){
            setErrorMessage("Enter a profile name!");
        }else if(!profileContent.aggregatedRegions||_.isEmpty(profileContent.aggregatedRegions)){
            setErrorMessage("create aggregate!");
        }else{
            setLoading(true)
            props.aggregateRegions(profileContent);
            setNewProfile(false);
            setProfileContent(null);
            setErrorMessage(null);
        }
    }
    const deleteFromList = (aggregate)=>{
        const arr = _.map(_.filter(profileContent.aggregatedRegions,agg=>{
            return agg.newId!==aggregate.newId
        }),aggregate=>{
            return {newName:aggregate.newName , newId:createNewId(aggregate.aggregatedRegionDetails) , aggregatedRegionDetails:_.map(aggregate.aggregatedRegionDetails,region=>{
                return {geoId:region.geoId , geoName:region.geoName}
            })};
        });
        
        setProfileContent({...profileContent , aggregatedRegions :arr});
        setSelectedAggregate(null);
    }

    const saveAggregate=()=>{
        if(!AggregateContent.newName){
            setErrorMessage("Enter a aggregate name!")
        }else if(!AggregateContent.aggregatedRegionDetails||_.isEmpty(AggregateContent.aggregatedRegionDetails)){
            setErrorMessage("Choose regions!")
        }else if(AggregateContent.aggregatedRegionDetails.length < 2){
            setErrorMessage("Select at least 2 regions!")
        }else{
            if(newProfile){
                if(editAggregate){
                    const arr = _.map(_.filter(profileContent.aggregatedRegions,agg=>{
                        return agg.newId!==AggregateContent.newId
                    }),aggregate=>{
                        return {newName:aggregate.newName , newId:createNewId(aggregate.aggregatedRegionDetails) , aggregatedRegionDetails:_.map(aggregate.aggregatedRegionDetails,region=>{
                            return {geoId:region.geoId , geoName:region.geoName}
                        })};
                    });
                    arr.push({...AggregateContent , newId:createNewId(AggregateContent.aggregatedRegionDetails), aggregatedRegionDetails:_.map(AggregateContent.aggregatedRegionDetails,region=>{
                        return {geoId:region.geoId , geoName:region.geoName}
                        })
                    })
                    setProfileContent({...profileContent , aggregatedRegions :arr});
                   
                }
                else if(profileContent.aggregatedRegions){
                    const arr = _.map(profileContent.aggregatedRegions, aggregate=>{
                            return {...aggregate , newId:createNewId(aggregate.aggregatedRegionDetails)}
                    });
                    arr.push({...AggregateContent , newId:createNewId(AggregateContent.aggregatedRegionDetails)})
                    setProfileContent({...profileContent , aggregatedRegions :arr});
                }else{
                    setProfileContent({...profileContent , aggregatedRegions :[{...AggregateContent , newId:createNewId(AggregateContent.aggregatedRegionDetails)}]});
                }
            }else{
                if(!selectedProfile){
                    setErrorMessage("create or choose the map profile!")
                    return 
                }
                const profile = {mapId:params.id, id:selectedProfile.id , name:selectedProfile.name , aggregatedRegions:_.map(AggregateContent.id? _.filter(selectedProfile.aggregatedRegions , agg =>{
                    return agg.id!==AggregateContent.id
                }):selectedProfile.aggregatedRegions,aggregate=>{
                    return {newName:aggregate.newName , newId:createNewId(aggregate.aggregatedRegionDetails) ,  aggregatedRegionDetails:_.map(aggregate.aggregatedRegionDetails,region=>{
                        return {geoId: region.geoId , geoName : region.geoName}
                    })}
                })}
                const arr = profile.aggregatedRegions;
                arr.push(AggregateContent.id?{newName:AggregateContent.newName , newId:createNewId(AggregateContent.aggregatedRegionDetails) , aggregatedRegionDetails : _.map(AggregateContent.aggregatedRegionDetails , region=>{return {geoId: region.geoId , geoName: region.geoName}})}:{...AggregateContent , newId:createNewId(AggregateContent.aggregatedRegionDetails)} )
                setProfileContent({...profile , aggregatedRegions :arr});
                setLoading(true)
                props.editAggregation(profile);
                setProfileContent(null);
            }
            setNewAggregate(false);
            setAggregateContent(null);
            setEditAggregate(false);
            setErrorMessage(null);
        }
    }

    const addRegionToAggregate=(region)=>{
        if(AggregateContent&&AggregateContent.aggregatedRegionDetails){
            if(_.isEmpty(_.filter(AggregateContent.aggregatedRegionDetails,reg=>{
                return region.id ===reg.geoId
            }))){
                const arr = AggregateContent.aggregatedRegionDetails;
                arr.push({geoId:region.id , geoName:region.mapCode});
                setAggregateContent({...AggregateContent , aggregatedRegionDetails:arr});
            }else{
                const arr = _.filter(AggregateContent.aggregatedRegionDetails,reg=>{
                    return region.id !==reg.geoId
                })
                setAggregateContent({...AggregateContent , aggregatedRegionDetails:arr});
            }
        }else{
            setAggregateContent({...AggregateContent , aggregatedRegionDetails:[{geoId:region.id , geoName:region.mapCode}]});
        }
    }

    const isRegionDisable = (id)=>{
        let aggregates = {};
        if(selectedProfile &&!_.isEmpty(selectedProfile)){
            if(!editAggregate){
                aggregates = selectedProfile.aggregatedRegions
            }else {
                aggregates = _.filter(selectedProfile.aggregatedRegions , aggregate=>{
                    return aggregate.id !==AggregateContent.id
                })
            }
            return !_.isEmpty(_.filter(aggregates , aggregate=>{
                return !_.isEmpty( _.filter(aggregate.aggregatedRegionDetails , region=>{
                    return region.geoId===id
                }))
            }))
        }
        else if(newProfile){
            if(!editAggregate){
                aggregates = profileContent? profileContent.aggregatedRegions:[]
            }else {
                aggregates = _.filter(profileContent.aggregatedRegions , aggregate=>{
                    return aggregate.newId !==AggregateContent.newId
                })
            }
            return !_.isEmpty(_.filter(aggregates , aggregate=>{
                return !_.isEmpty( _.filter(aggregate.aggregatedRegionDetails , region=>{
                    return region.geoId===id
                }))
            }))
        }
        return false;
    }
        
    const onDeleteAggregationConfirm=()=>{
        setDeleteAggregate(false);

        const profile = {mapId:params.id, id:selectedProfile.id, name:selectedProfile.name , aggregatedRegions:_.map(_.filter(selectedProfile.aggregatedRegions , agg =>{
            return agg.id!==selectedAggregate.id
        }),aggregate=>{
            return {newName:aggregate.newName , newId:createNewId(aggregate.aggregatedRegionDetails) ,  aggregatedRegionDetails:_.map(aggregate.aggregatedRegionDetails,region=>{
                return{geoId: region.geoId , geoName : region.geoName}
            })}
        })}
        setLoading(true)
        props.editAggregation({...profile})
    }

    const onDeleteProfileConfirm=()=>{
        setDeleteProfileModel(false);
        setLoading(true)
        props.deleteProfile(selectedProfile.id);
        if(mapProfiles && !_.isEmpty()){
            setSelectedProfile(mapProfiles[0])
        }else{
            setSelectedProfile(null)
        }
        // setSelectedProfile(mapProfiles && !_.isEmpty(mapProfiles) ? mapProfiles[0] : null)
        setSelectedAggregate(null)
    }

    return (
        <Fragment>
            <div className="mainHeader organizer font-size-24 mediumWeight">
                <div className="titleItemsWithoutWidth">
                    <Link to={'/maps'} style={{textDecoration : "none" , color:'black'}}>
                        <span className=" mainTitle middleContext">Maps</span>
                    </Link>
                    <span className={"icon icon-right-direction"}></span>
                    <span className="mainTitle middleContext">Profiles</span>
                </div>
                <div className="filterItems">
                    <div className="createNewPlan clickShape" onClick={()=>setNewProfile(true)}>
                        <span className="icon icon-add">New profile</span>
                    </div>
                </div>
            </div>
            <div className="adminBoilerplate organizer">
                <div className="twoSidePanel organizer noHeader">
                    {loadingList?
                    <SimpleLoading /> 
                    :
                    <div className="columnSide" style={{width:'40%'}}>
                        <table className="tableStructure organizer">
                            <thead className="tableHeader haveActive">
                                <tr>
                                    <th>Name</th>
                                </tr>
                            </thead>
                            <tbody>
                            {mapProfiles&&!_.isEmpty(mapProfiles) ? _.map(mapProfiles, (profile) => {
                                return (
                                    <tr key={profile.id} onClick={()=>{setSelectedProfile(profile)}} className={classNames("clickShape",{"active" : selectedProfile?selectedProfile.id== profile.id:false})}>
                                        <td style={{textAlign:'left'}}>{profile.name}</td>
                                    </tr>
                                );
                            }) : null}
                            </tbody>
                        </table>
                    </div>
                    }
                    {loading?
                    <SimpleLoading/>
                    :
                    <div className="columnSide" style={{width:'60%'}}>
                        <div className="columnContent organizer">
                            <div className="addTopicContainer organizer">
                                <ul>
                                    {newProfile?
                                    <li>
                                        <div className="inputBox">
                                            <label>Profile Name</label>
                                            <input type="text" value={profileContent&&profileContent.name?profileContent.name:""} 
                                                onChange={(e)=>setProfileContent({...profileContent , name:e.target.value})}/>
                                        </div>
                                    </li>
                                    :null}
                                    <li className="aggregateListnoBorder">
                                        <h4 style={{float:'left'}}>Aggregations</h4>
                                        <div style={{float:'right'}}>
                                        {newAggregate||editAggregate? 
                                            <span className="editRow leftNormalMargin greenBackground clickShape">
                                                <span className="whiteColor icon icon-chrck" onClick={()=>saveAggregate()}></span>
                                            </span>
                                        :
                                            <span className="editRow leftNormalMargin blueBackground clickShape">
                                                <span className="icon icon-add whiteColor" onClick={()=>setNewAggregate(true)}></span>
                                            </span>
                                        }
                                        {selectedAggregate?
                                            <span className="editRow leftNormalMargin redBackground clickShape" onClick={()=>selectedAggregate.id? setDeleteAggregate(true):deleteFromList(selectedAggregate)}>
                                                <span className="icon icon-delete whiteColor"></span>
                                            </span>
                                        :newAggregate||editAggregate?
                                            <span className="editRow leftNormalMargin redBackground clickShape">
                                                <span className="whiteColor icon icon-cross" onClick={()=>newAggregate?setNewAggregate(false):setEditAggregate(false)}>
                                                </span>
                                            </span>:null
                                        }
                                        {selectedAggregate?
                                        
                                        <span className="editRow leftNormalMargin greenBackground clickShape">
                                            <span className="icon icon-edit whiteColor"onClick={()=>setEditAggregate(true)}></span>
                                        </span>:null}
                                        </div>
                                    
                                    </li>
                                    {selectedProfile?
                                    <li className="aggregateList">
                                        <div style={{margin:'10px'}}>
                                        {_.map(selectedProfile.aggregatedRegions, (aggregate , id) => (
                                            <div key={id} className={classNames("profileItem clickShape ",{'active':selectedAggregate? aggregate.id === selectedAggregate.id:false})} onClick={()=>setSelectedAggregate(aggregate)} >
                                                <div className="itemTitle">{aggregate.newName}</div>
                                            </div>    
                                        ))}
                                        </div>
                                    </li>
                                    :
                                    newProfile?
                                        profileContent&&profileContent.aggregatedRegions?
                                        <li className="aggregateList">
                                            <div style={{margin:'10px'}}>
                                            {_.map(profileContent.aggregatedRegions, (aggregate , id) => {
                                                return (
                                                    <div key={id} className={classNames("profileItem clickShape ",{'active':selectedAggregate? aggregate.newId === selectedAggregate.newId:false})} onClick={()=>setSelectedAggregate(aggregate)} >
                                                        <div className="itemTitle">{aggregate.newName}</div>
                                                    </div>    
                                                );}
                                            )}
                                            </div>
                                        </li>
                                        :null
                                    :null
                                    }
                                </ul>
                                {newAggregate||editAggregate?

                                <div className="inputBox organizer topUnitMargin">
                                    <label>Aggregation Name</label>
                                    <input type="text" value={AggregateContent&&AggregateContent.newName?AggregateContent.newName:""} onChange={(e)=>setAggregateContent({...AggregateContent,newName:e.target.value})}/>
                                </div>
                                :null
                                }
                                {!selectedAggregate?
                                <Fragment>
                                    <h4 className='topUnitMargin'>Regions</h4>
                                    <div className="aggregateList topUnitMargin">    
                                        <div style={{padding:'10px'}}>
                                            {mapRegions?
                                            _.map(mapRegions,region=>{
                                                return(
                                                    <div key={region.id} className={classNames("profileItem clickShape ",
                                                    {'disable':newAggregate||editAggregate?isRegionDisable(region.id):false},
                                                    {'active':newAggregate||editAggregate?AggregateContent&&AggregateContent.aggregatedRegionDetails?
                                                        !_.isEmpty(_.filter(AggregateContent.aggregatedRegionDetails,reg=>{return region.id===reg.geoId})):false:false})} onClick={()=>addRegionToAggregate(region)} >
                                                        <div className="itemTitle">{region.name}</div>
                                                    </div>
                                                ); 
                                            })
                                            :null}
                                        </div>
                                    </div>
                                </Fragment>
                                :
                                <Fragment>
                                    <h4 className='topUnitMargin'>Regions</h4>
                                    <div className="aggregateList topUnitMargin">    
                                        <div style={{padding:'10px'}}>
                                        {mapRegions?
                                            _.map(mapRegions,region=>{
                                                return(
                                                    <div key={region.id} className={classNames("profileItem clickShape ",{'active':selectedAggregate?!_.isEmpty(_.filter(selectedAggregate.aggregatedRegionDetails,reg=>{return region.id===reg.geoId})):false})}  >
                                                        <div className="itemTitle">{region.name}</div>
                                                    </div>
                                                ); 
                                            })
                                            :null}
                                        
                                        </div>
                                    </div>
                                </Fragment>}
                                {newProfile||selectedProfile?
                                <div className="organizer topUnitMargin">
                                    {newProfile?
                                    <div className="normalButton inlineView blueBackground whiteColor clickShape" onClick={()=>saveProfile()}>
                                        Save Changes
                                    </div>:null}
                                    {newProfile?<div className="normalButton inlineView grayOutline redColor leftNormalMargin clickShape" onClick={()=>setNewProfile(false)}>
                                        Cancel
                                    </div>
                                    :selectedProfile?
                                    <div className="normalButton inlineView grayOutline redColor leftNormalMargin clickShape" onClick={()=>setDeleteProfileModel(true)}>
                                        Delete Profile
                                    </div>
                                    :null}
                                </div>:null}
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </div>
            {deleteAggregate&&selectedAggregate?
            <DeleteModal
                closeModal={() => setDeleteAggregate(false)}
                acceptAction={onDeleteAggregationConfirm}
                title="Delete aggregate" modalMessage={`Are you sure you want to delete ${selectedAggregate.newName} `}
            /> : null}
            {deleteProfileModel&&selectedProfile?
            <DeleteModal
                closeModal={() => setDeleteProfileModel(false)}
                acceptAction={onDeleteProfileConfirm}
                title="Delete profile" modalMessage={`Are you sure you want to delete ${selectedProfile.name} `}
            /> : null}
        </Fragment>
    );
}
const mapStateToProps = (state , ownProps) => {
    return {
        mapProfiles :state.mapProfiles,
        mapRegions : state.mapRegions,
        resultMessage: state.profileResultMessage
        
    } ;
};

export default connect(mapStateToProps , {getMapProfiles , getRegions , aggregateRegions , editAggregation , deleteProfile})(Profile) ;


/*
{
    "mapId": "1",
    "name": "5 geographical areas",
    "aggregatedRegions":[
        {"aggregatedMapProfileId": "1",
         "newName": "Tehran", 
         "aggregatedRegionDetails":[
             {"geoId": 10, "geoName": "IR.AL"},
             {"geoId": 18, "geoName": "IR.GO"},
             {"geoId": 29, "geoName": "IR.MN"},
             {"geoId": 31, "geoName": "IR.QZ"},
             {"geoId": 34, "geoName": "IR.SM"},
             {"geoId": 32, "geoName": "IR.QM"},
             {"geoId": 37, "geoName": "IR.TH"}
         ]
        },{
            "aggregatedMapProfileId": "1",
            "newName": "Esfahan", 
            "aggregatedRegionDetails":[
                {"geoId": 12, "geoName": "IR.BS"},
                {"geoId": 13, "geoName": "IR.CM"},
                {"geoId": 16, "geoName": "IR.FA"},
                {"geoId": 20, "geoName": "IR.HG"},
                {"geoId": 15, "geoName": "IR.ES"},
                {"geoId": 25, "geoName": "IR.KB"}
            ]
        },
        {
            "ggregatedMapProfileId": "1", 
            "newName": "Tabriz", 
            "aggregatedRegionDetails":[
                {"geoId": 11, "geoName": "IR.AR"},
                {"geoId": 14, "geoName": "IR.EA"},
                {"geoId": 17, "geoName": "IR.GI"},
                {"geoId": 26, "geoName": "IR.KD"},
                {"geoId": 38, "geoName": "IR.WA"},
                {"geoId": 40, "geoName": "IR.ZA"}
            ]
        },{
            "aggregatedMapProfileId": "1", 
            "newName": "Kermanshah", 
            "aggregatedRegionDetails":[
                {"geoId": 19, "geoName": "IR.HD"},
                {"geoId": 21, "geoName": "IR.IL"},
                {"geoId": 23, "geoName": "IR.BK"},
                {"geoId": 24, "geoName": "IR.KZ"},
                {"geoId": 27, "geoName": "IR.LO"},
                {"geoId": 28, "geoName": "IR.MK"}
            ]
        },
        {
            "aggregatedMapProfileId": "1", 
            "newName": "Mashhad", 
            "aggregatedRegionDetails":[
                {"geoId": 22, "geoName": "IR.KE"},
                {"geoId": 30, "geoName": "IR.KS"},
                {"geoId": 33, "geoName": "IR.KV"},
                {"geoId": 35, "geoName": "IR.SB"},
                {"geoId": 36, "geoName": "IR.KJ"},
                {"geoId": 39, "geoName": "IR.YA"}
            ]
        }
    ]

}
 */