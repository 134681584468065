import React, {useState, useEffect, Fragment} from 'react' ;
import {connect} from 'react-redux' ;
import {getGeographicRegions ,newgeographicRegion , editgeographicRegion , deletegeographicRegion} from "./actions";
import Pagination from "../components/pagination";
import classNames from 'classnames' ;
import _ from 'lodash' ;
import FormModal from './FormModal.js';
import DeleteModal from '../components/cmp.modal' ;
import {getLanguages} from "../languages/actions";
import SimpleLoading from '../components/simpleLoading';


const GeographicRegion=(props)=>{
    let {allRegions} = props;
    const { resultMessage} =props;
    const [isEdit , setIsEdit] = useState(false) ;
    const [showDeleteModal , setShowDeleteModal] = useState(false) ;
    const [isNew , setIsNew] = useState(false);
    const [selectedGeographic , setSelectedGeographic]= useState(null);
    const [pageNumber , setPageNumber] = useState(1) ;
    const [viewByPage , setViewByPage] = useState(8) ;
    const [savedFileId , setSavedFileId] = useState(null)
    const [message , setMessage] = useState("")

    const onConfirm = (values) => {
        if(savedFileId){
            values = {...values , logo:savedFileId}
        }else if(isEdit && selectedGeographic.logo){
            values = {...values , logo: selectedGeographic.logo}
        }
        if (isEdit) {
            props.editgeographicRegion(values) ;
            setIsEdit(false);
        }
        else {
            props.newgeographicRegion(values) ;
            setIsNew(false);
        }
    };

    const onFileUploaded= (list) =>{
        const savedFileId = list[0];
        setSavedFileId(savedFileId)
    }

    useEffect(()=>{
        const data = {
            PageNumber : pageNumber ,
            PageSize : viewByPage 
        };
        props.getGeographicRegions(data) ;
    },[])

    useEffect(()=>{
        if(isNew){
            setIsEdit(false);
        }
    },[isNew])

    useEffect(()=>{
        if(isEdit){
            setIsNew(false);
        }
    },[isEdit])

    useEffect(()=>{
        if (_.isEmpty(resultMessage)) {
            return;
        }
        if(resultMessage.succeeded){
            const data = {
                PageNumber : pageNumber ,
                PageSize : viewByPage 
            };
            props.getGeographicRegions(data);
            if (resultMessage.action === 'NEW') {
                setSelectedGeographic(_.filter(allRegions.regions[pageNumber] , regions=>{
                    return regions.id ===resultMessage.key
                }))
            }
        }else{
            setMessage({success :false , message :'Something Unexpectedly Happened ! Please Try Again Later.'}) ;  
        }
        
    },[resultMessage])
    
    useEffect(()=>{
        if(!allRegions||!allRegions.regions){
            return;
        }
        setSelectedGeographic(_.map(allRegions.regions[pageNumber],region=>{
            return region
        })[0])
    } , [allRegions]);

    useEffect(()=>{
        if(!allRegions ||!allRegions.regions){
            return
        }
        if(!allRegions.regions[pageNumber]){
            const data = {
                PageNumber : pageNumber ,
                PageSize : viewByPage 
            };
            props.getGeographicRegions(data)
        }
    },[pageNumber])

    useEffect(()=>{
        const data = {
            PageNumber : pageNumber ,
            PageSize : viewByPage 
        };
        allRegions = {};
        props.getGeographicRegions(data)
        
    },[viewByPage])
    const onEditClick=(geo)=>{
        setIsEdit(true);
        setSelectedGeographic(geo)
    }
    const onDeleteClick=(geo)=>{
        setShowDeleteModal(true);
        setSelectedGeographic(geo)
    }


    const updateList = (selectedPageNumber  , selectedViewByPage) => {
        setPageNumber(selectedPageNumber) ;
        setViewByPage(selectedViewByPage) ;
    };

    const onConfirmDeleteClick=()=>{
        setShowDeleteModal(false);
        props.deletegeographicRegion(selectedGeographic.id);
    }
    
    return (
        <Fragment>
            <div className="mainHeader organizer font-size-24 mediumWeight">
                <div className="titleItems">
                    <span className="mainTitle middleContext">Geographic Regions</span>
                </div>
                <div className="createNewPlan inlineView clickShape" onClick={()=>setIsNew(true)}>
                    <span className="icon icon-add"></span> New Region
                </div>
                <div className="filterItems tempDisable">
                    <div className="filters inlineView">
                        <div className="changeDate inlineView"></div>
                        <div className="accessLevel inlineView"></div>
                    </div>
                    <span className="icon icon-zoom font-size-18 clickShape tempDisable"></span>
                </div>
            </div>
            {message?
                <div className={classNames("leftFloat organizer topNormalMargin" , 
                    {'redColor' : !message.success , 'greenColor' : message.success})}>
                        {message.message}
                </div> 
            : null}

            {allRegions&&allRegions.regions?
            <div className="adminBoilerplate organizer">
                <table className="tableStructure organizer">
                    <thead className="tableHeader">
                        <tr>
                            <th>Name</th>
                            <th>Logo</th>
                            <th>Description</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                            {_.map(allRegions.regions[pageNumber],(region)=>{
                                return(
                                <tr key={region.id}>
                                    <td onClick={()=>setSelectedGeographic(region)}>{region.name}</td>
                                    <td onClick={()=>setSelectedGeographic(region)}>
                                        <img path={region.logo}/>
                                    </td>
                                    <td onClick={()=>setSelectedGeographic(region)}>{region.description}</td>
                                    <td>
                                        <span className="editRow leftNormalMargin redBackground clickShape" onClick={()=>onDeleteClick(region)}>
                                            <span className="icon icon-delete whiteColor"></span>
                                        </span>
                                        <span className="editRow leftNormalMargin greenBackground clickShape">
                                            <span className="icon icon-edit whiteColor"onClick={()=>onEditClick(region)}></span>
                                        </span>
                                    </td>
                                </tr>
                                )
                            })}
                    </tbody>
                </table>
                    <Pagination pageNumber={pageNumber} viewByPage={viewByPage} 
                        changeData={updateList} 
                        totalPages={Math.ceil(allRegions.totalCount/viewByPage)}/>
            </div>:<SimpleLoading/>
            }
        {isNew||isEdit ? <FormModal onFileUploaded={onFileUploaded} initialValues={isNew?{}:selectedGeographic} onSubmit={onConfirm}
                             title={isNew?"Add geographic":"Edit geographic"} closeModal={() => isNew?setIsNew(false):setIsEdit(false)}/> : null}
        {showDeleteModal  ? <DeleteModal closeModal={() => setShowDeleteModal(false)} acceptAction={onConfirmDeleteClick} data={null} title="Delete GeographicRegion" modalMessage={`Are you sure you want to delete ${selectedGeographic.name}`} /> : null}
        </Fragment>   
    ) ;
};

const mapStateToProps = (state , ownProps) => {
    return {
        allRegions:state.allRegions,
        resultMessage:state.geoResultMessage
    } ;
};

export default connect(mapStateToProps , {getGeographicRegions, getLanguages ,newgeographicRegion , editgeographicRegion , deletegeographicRegion})(GeographicRegion) ;
