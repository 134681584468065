import {GET , POST , PUT , POST_FORM} from "../common/Action";
import Action from "../common/Action";

//actions
export const getAllContentPagesAction = new Action('GET_ALL_CONTENT_PAGES_ACTION' , GET , 'api/content/GetAllContentPages') ;
export const updateContentPageAction = new Action('UPDATE_CONTENT_PAGE_ACTION' , POST , 'api/content/UpdateContentPage') ;
export const getContentPageAction = new Action('GET_CONTENT_PAGE_ACTION' , GET , (params) => `api/content/GetContentPage?id=${params.id}`) ;

//action creators
export const getAllContentPages = (data) => {
  return getAllContentPagesAction.callApi(data) ;
};
export const getContentPage = (id) => {
    return getContentPageAction.callApi(null , {id}) ;
};
export const updateContentPage =  (contentPageViewModel) => {
    return updateContentPageAction.callApi(contentPageViewModel ) ;
};
