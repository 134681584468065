import {GET , POST , PUT , POST_FORM} from "../common/Action";
import Action from "../common/Action";

//actions
export const GetApprovableAsyncDataRoleAction = new Action('GET_APPROVABLE_DATAROLE_ACTION' , POST , 'api/dataRole/GetApprovable') ;
export const ApprovedAsyncDataRolesAction = new Action('APPROVED_ASYNC_DATAROLE_ACTION' , POST , 'api/dataRole/Approve');
export const DisApprovedAsyncDataRolesAction = new Action('DISAPPROVED_ASYNC_DATAROLE_ACTION' , POST , 'api/dataRole/DisApprove');
export const AddNewDataRoleAction = new Action('ADD_NEW_DATAROLE_ACTION' , POST , 'api/dataRole/add')
export const UpdateDataRoleAction = new Action('UPDATE_DATAROLE_ACTION' , POST , 'api/dataRole/update')
export const DeleteDataRoleAction = new Action('DELETE_DATAROLE_ACTION' , GET , params=>`api/dataRole/delete?id=${params.id}`)

//action creators
export const GetApprovableAsyncDataRole = (data) => {
  return GetApprovableAsyncDataRoleAction.callApi(data) ;
};

export const ApprovedAsyncDataRoles = (data) =>{
  return ApprovedAsyncDataRolesAction.callApi(data);
}

export const DisApprovedAsyncDataRoles = (data) =>{
  return DisApprovedAsyncDataRolesAction.callApi(data);
}

export const AddNewDataRole = (data) =>{
  return AddNewDataRoleAction.callApi(data)
}

export const UpdateDataRole = (data) =>{
  return UpdateDataRoleAction.callApi(data)
}

export const DeleteDataRole =(id) =>{
  return DeleteDataRoleAction.callApi(null , {id})
}