import {GET , POST , PUT , POST_FORM} from "../common/Action";
import Action from "../common/Action";
//actions
export const getAllUsersAction = new Action('GET_ALL_USERS_ACTION', POST , 'api/users/getAllUsers') ;
export const getUserDropDownsDataAction = new Action('GET_HOME_DATA_ACTION' , GET , 'api/Content/GetHomeData') ;
export const getUserAction = new Action('GET_USER_ACTION' , POST , (param) => `api/users/GetUser?userId=${param.id}`) ;
export const addNewUserAction = new Action('ADD_NEW_USER_ACTION' , POST , 'api/users/AddUser') ;
export const editUserAction = new Action('EDIT_USER_ACTION' , POST , 'api/users/EditUser') ;
export const deleteUserAction = new Action('DELETE_USER_ACTION' , POST , 'api/users/DeleteUser');
export const getUserSurveyPurchasesAction = new Action('GET_USER_SURVEY_PURCHASES_ACTION' , POST , 'api/surveyaccess/GetSurveyAccesses') ;
export const changeUserSurveyPurchasesAction = new Action('CHANGE_USER_SURVEY_PURCHASES_ACTION' , POST , 'api/surveyaccess/ChangeSurveyAccesses') ;
export const clearFormResultAction = new Action('CLEAR_FORM_RESULT_ACTION') ;
export const editAccessIdAction = new Action('EDIT_ACCESS_ID_ACTION' , POST , 'api/users/EditAccessId') ;
export const getAllAccessLevelAction = new Action('GET_ALL_ACCESS_LEVEL_ACTIONS' , GET , 'api/accessLevel/getAllAccessLevels');
//action creators
export const clearFormResult = () => {
  return clearFormResultAction.setResult({}) ;
};
export const getAllUsers = (data) => {
  return getAllUsersAction.callApi(data) ;
};
export const getUserDropDownsData = () => {
  return getUserDropDownsDataAction.callApi() ;
};
export const getUser = (id) => {
  return getUserAction.callApi(null , {id}) ;
};
export const addNewUser = (user) => {
  return addNewUserAction.callApi(user) ;
};
export const editUser = (user) => {
  return editUserAction.callApi(user) ;
};
export const deleteUser = (userId) => {
  return deleteUserAction.callApi({userId}) ;
};
export const getUserSurveyPurchases = (UserId , Filter) => {
  return getUserSurveyPurchasesAction.callApi({UserId , Filter}) ;
};
export const changeUserSurveyPurchases = (data) => {
  return changeUserSurveyPurchasesAction.callApi(data) ;
};
export const editAccessId = (Id , AccessLevelId) => {
  return editAccessIdAction.callApi({Id , AccessLevelId}) ;
};

export const getAllAccessLevel = () =>{
  return getAllAccessLevelAction.callApi();
}

