//@ts-nocheck
import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import LeftNav from "../components/left-menu";
import {
  getContentPage,
  updateContentPage,
  getAllContentPages,
} from "./actions";
import { getLanguages } from "../languages/actions";
import _ from "lodash";
import classNames from "classnames";
import { dropDownStyles } from "../components/formFields";
import Select from "react-select";
import SimpleLoading from "../components/simpleLoading";
import SelectBox from "../components/selectBox";
import RichTextEditor from "../components/RichTextEditor";
import { BlockManager, BasicType, AdvancedType } from "easy-email-core";
import { EmailEditor, EmailEditorProvider } from "easy-email-editor";
import { ExtensionProps, StandardLayout } from "easy-email-extensions";
import { useWindowSize } from "react-use";
import "easy-email-editor/lib/style.css";
import "easy-email-extensions/lib/style.css";
import "@arco-themes/react-easy-email-theme/css/arco.css";
import EasyEmailEditor from "../components/easyEmailEditor.tsx";

const initialValues = {
  subject: "Welcome to Easy-email",
  subTitle: "Nice to meet you!",
  content: BlockManager.getBlockByType(BasicType.PAGE)!.create({}),
};

const MainPage = (props) => {
  const {
    websiteContentPages,
    contentPagesDetails,
    languages,
    updateContentPageResult,
  } = props;

  const [selectedContentPageId, setSelectedContentPageId] = useState(null);
  const [selectedLang, setSelectedLang] = useState({});
  const [contents, setContents] = useState({});
  const [contentsEasyEmail, setContentsEasyEmail] = useState({});
  const [languageOptions, setLanguageOptions] = useState([]);
  const [resultMessage, setResultMessage] = useState(null);
  const [pointerHoverId, setPointerHoverId] = useState(null);
  const [initial, setInitial] = useState(false);
  const [editorState, setEditorState] = useState("");
  const [easyEditorState, setEasyEditorState] = useState({});

  const [languageChange, setLanguageChange] = useState(false);
  const [reset, setReset] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const { width } = useWindowSize();

  const smallScene = width < 1400;

  useEffect(() => {
    if (initial) {
      setInitial(false);
      return;
    }
    setContents({
      ...contents,
      [selectedContentPageId]: {
        ...contents[selectedContentPageId],
        [selectedLang.value]: editorState,
      },
    });
  }, [editorState]);


  
  useEffect(() => {
    setContentsEasyEmail({
      ...contentsEasyEmail,
      [selectedContentPageId]: {
        ...contentsEasyEmail[selectedContentPageId],
        [selectedLang.value]: easyEditorState,
      },
    });
  }, [easyEditorState]);

  

  useEffect(() => {
    if (
      !contents ||
      !selectedContentPageId ||
      !selectedLang ||
      !contents[selectedContentPageId]
    )
      return;
    setEditorState(
      contents[selectedContentPageId][selectedLang.value]
        ? contents[selectedContentPageId][selectedLang.value]
        : ""
    );
    setInitial(true);
  }, [contents, selectedContentPageId, selectedLang]);


  console.log("selectedContentPageId",contents)

  useEffect(() => {
    if (!selectedLang) return;
    setLanguageChange(true);
  }, [selectedLang, selectedContentPageId]);

  const saveContentPage = () => {
    setResultMessage(null);
    const data = {
      Title: contentPagesDetails[selectedContentPageId].title,
      Id: contentPagesDetails[selectedContentPageId].id,
      Contents: contents[selectedContentPageId],
    };
    props.updateContentPage(data);
  };

  useEffect(() => {
    if (!_.isEmpty(websiteContentPages)) {
      if (_.size(websiteContentPages) > 0) {
        setSelectedContentPageId(
          _.map(websiteContentPages, (contentPage) => {
            return contentPage;
          })[0].id
        );
      }
    }
  }, [websiteContentPages]);

  useEffect(() => {
    props.getAllContentPages();
    props.getLanguages();
  }, []);

  useEffect(() => {
    if (selectedContentPageId) {
      setResultMessage(null);
      props.getContentPage(selectedContentPageId);
    }
  }, [selectedContentPageId]);

  useEffect(() => {
    if (!languages) {
      return;
    }
    let langs = _.map(languages, (lang) => {
      return { label: lang.cultureName, value: lang.id, isRtl: lang.isRtl };
    });

    setLanguageOptions(langs);
    setSelectedLang(langs[0]);
  }, [languages]);

  useEffect(() => {
    if (_.isEmpty(contentPagesDetails) || !selectedContentPageId) {
      return;
    }
    setContents({
      ...contents,
      [selectedContentPageId]:
        contentPagesDetails[selectedContentPageId].contents,
    });
  }, [contentPagesDetails]);
  useEffect(() => {
    if (!_.isEmpty(updateContentPageResult)) {
      if (updateContentPageResult.succeeded) {
        setResultMessage({ success: true, message: "Content Updated !" });
      } else {
        setResultMessage({
          success: false,
          message:
            "Something Unexpectedly Happened . Please Try Again Later . ",
        });
      }
    }
  }, [updateContentPageResult]);

  useEffect(() => {
    if (
      !contents ||
      !selectedContentPageId ||
      !selectedLang ||
      !contents[selectedContentPageId]
    )
      return;
  }, [selectedLang]);

  return (
    <Fragment>
      <div className="mainHeader organizer font-size-24 mediumWeight">
        <div className="titleItems">
          <span className="mainTitle middleContext">Website Content</span>
        </div>
      </div>
      <div className="adminBoilerplate organizer">
        {_.isEmpty(websiteContentPages) ? (
          <SimpleLoading />
        ) : (
          <div className="twoSidePanel organizer">
            <div className="columnSide quarterColumn noHeader websiteContent">
              <table className="tableStructure organizer">
                <thead className="tableHeader">
                  <tr>
                    <th>Page Title</th>
                  </tr>
                </thead>
                <tbody>
                  {!_.isEmpty(websiteContentPages)
                    ? _.map(websiteContentPages, (content, id) => {
                        return (
                          <tr
                            key={id}
                            className={classNames("clickShape", {
                              active:
                                selectedContentPageId == id ||
                                pointerHoverId == id,
                            })}
                            onClick={() => setSelectedContentPageId(id)}
                            onMouseEnter={() => setPointerHoverId(id)}
                            onMouseLeave={() => setPointerHoverId(null)}
                          >
                            <td>{content.title}</td>
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </table>
            </div>
            <div className="columnSide threeQuarterColumn normalPadding">
              {contentPagesDetails[selectedContentPageId] &&
              !_.isEmpty(contentPagesDetails[selectedContentPageId]) ? (
                <Fragment>
                  <div className="organizer">
                    <SelectBox
                      label="Languages"
                      value={selectedLang}
                      options={languageOptions}
                      styles={dropDownStyles}
                      onChange={(lang) => setSelectedLang(lang)}
                    />
                  </div>
                  <div className="organizer topNormalMargin">
                    <div className="inputBox">
                      <label>Content</label>
                      {!_.isEmpty(contents) &&
                      selectedContentPageId &&
                      !_.isEmpty(selectedLang) &&
                      contents[selectedContentPageId] ? (
                        <>
                          <RichTextEditor
                            reset={reset}
                            setReset={setReset}
                            setContentChange={setLanguageChange}
                            contentChange={languageChange}
                            onChangeEditor={(evt) => {
                              setContents({
                                ...contents,
                                [selectedContentPageId]: {
                                  ...contents[selectedContentPageId],
                                  [selectedLang.value]: evt,
                                },
                              });
                            }}
                            valueObj={
                              contents[selectedContentPageId][
                                selectedLang.value
                              ]
                                ? contents[selectedContentPageId][
                                    selectedLang.value
                                  ].content
                                : ""
                            }
                          />
                          {/* 
                          <EmailEditorProvider
                            data={initialValues}
                            height={"calc(100vh - 72px)"}
                            autoComplete
                            dashed={false}
                          >
                            {({ values }) => {
                              return (
                                <StandardLayout
                                  compact={!smallScene}
                                  showSourceCode={true}
                                >
                                  <EmailEditor />
                                </StandardLayout>
                              );
                            }}
                          </EmailEditorProvider> 
                          */}
                        </>
                      ) : null}
                    </div>
                  </div>
                  {/* <div className="organizer topNormalMargin">
                    <div className="inputBox">
                      <label>Content</label>
                      <EmailEditorProvider
                        data={initialValues}
                        height={"calc(100vh - 72px)"}
                        autoComplete
                        dashed={false}
                      >
                        {({ values }) => {
                          return (
                            <StandardLayout
                              compact={!smallScene}
                              showSourceCode={true}
                            >
                              <EmailEditor />
                            </StandardLayout>
                          );
                        }}
                      </EmailEditorProvider>
                    </div>
                  </div> */}
                  <div className="organizer topUnitMargin my-5">
                    <div
                      className="normalButton inlineView bg-mainblue whiteColor clickShape"
                      onClick={() => setShowModal(true)}
                    >
                      Email Editor
                    </div>
                  </div>

                  <div className="organizer topUnitMargin">
                    <div
                      className="normalButton inlineView blueBackground whiteColor clickShape"
                      onClick={saveContentPage}
                    >
                      Save Changes
                    </div>
                  </div>
                  {resultMessage ? (
                    <div
                      className={classNames("organizer textInCenter", {
                        redColor: !resultMessage.success,
                        greenColor: resultMessage.success,
                      })}
                    >
                      {resultMessage.message}
                    </div>
                  ) : null}
                </Fragment>
              ) : (
                <SimpleLoading />
              )}
            </div>
          </div>
        )}
      </div>

      {showModal ? (
        <EasyEmailEditor
          closeModal={() => setShowModal(false)}
          updateContentPage = {props.updateContentPage}
          id = {contentPagesDetails[selectedContentPageId]?.id}
          title = {contentPagesDetails[selectedContentPageId]?.title}
          contents = {contents[selectedContentPageId]}
        />
      ) : null}
    </Fragment>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    websiteContentPages: state.websiteContentPages,
    contentPagesDetails: state.contentPagesDetails,
    languages: state.languages,
    updateContentPageResult: state.updateContentPageResult,
  };
};

export default connect(mapStateToProps, {
  getAllContentPages,
  updateContentPage,
  getContentPage,
  getLanguages,
})(MainPage);
