import React , {useState , useEffect} from 'react' ;
import {connect} from 'react-redux' ;
import LeftNav from '../components/left-menu' ;
import TableComponent from '../components/TableComponent';
import {GetApprovableAsyncVendor , ApprovedAsyncVendors , DisApprovedAsyncVendors,setPriority} from "./actions";
import _, { indexOf } from 'lodash'
import { Fragment } from 'react';
import cssClasses from './ApprovedVendorListStyles.module.css'
import classNames from 'classnames';
import { Link, withRouter } from 'react-router-dom';
import SimpleLoading from '../components/simpleLoading';
const MainPage = props => {
    const {vendorList , ApprovedActionResult} = props
    const [selectedVendors , setSelectedVendors] = useState([])
    const [rows , setRows] = useState(null)
    const [total , setTotal] = useState(0)
    const [dtParameter , setDtParameter] = useState({current:1 , pageSize:10 , filter:null})
    const isAssociation = props.location.pathname.includes('Associations')
    const [loading , setLoading] = useState(false)
    const [columns , setColumns] = useState(null)


    useEffect(()=>{
        setLoading(true)
        props.GetApprovableAsyncVendor({dtParameter:dtParameter,isAssociation:isAssociation}) ;
    },[isAssociation])
    

    useEffect(() => {
        setLoading(true)
        props.GetApprovableAsyncVendor({dtParameter:dtParameter,isAssociation:isAssociation}) ;
    } , [dtParameter]) ;


    useEffect(()=>{
        if(!ApprovedActionResult)
            return
        setSelectedVendors([])
        if(ApprovedActionResult.succeeded){
            props.GetApprovableAsyncVendor({dtParameter:{...dtParameter, dir:"desc",orderBy:"lastChangeStatusDate"},isAssociation:isAssociation})
            setDtParameter({...dtParameter , current:1 ,dir:"desc",orderBy:"lastChangeStatusDate"})
        }else{
            console.log('Error happend try again later')
        }
    },[ApprovedActionResult])


    useEffect(()=>{
        if(!vendorList||_.isEmpty(vendorList))
            return
        var cols  = vendorList.columns.map(col=>{
            if(col.field && col.field=='priority'){
                return ({
                    ...col ,  editable:true
                })
            }
            if(!col.hasRenderCell)
                return col
            if(col.field&&col.field.includes('Logo')||col.field=='banner'){
                return ({...col , renderCell:(param)=>(<img src={param.value}/>)})
            }
            if(col.field&&col.field.toLowerCase().includes('keyword')){
                return ({...col , flex:2 , renderCell:(params)=>(
                    _.map(
                          params.value,
                          (key , index) => (
                                  <span
                                  key={index}
                                  className={cssClasses.keyItem}
                                  >
                                    {key}
                                  </span>
                                ))
                  )})
            }})
        cols.push({
            field:'id', flex:1 , minWidth:200 , headerName:'Action' , renderCell:(params)=>(
                <Link to={{pathname:`${process.env.REACT_APP_DATAPROVIDER_API}/vendor/${params.value}`}}  target='_blank' style={{textDecoration : 'none' , color:'black'}}><span className="giveAccess blueBackground clickShape">
                    <span className="icon icon-right-sign-arrow whiteColor"></span>
                    </span>
                </Link>
            )
        })
        setColumns(cols)
        setLoading(false)
        setRows(vendorList.data)
        setTotal(vendorList.total)
    },[vendorList])

    const approveVendors = ()=>{
        if(_.isEmpty(selectedVendors))
            return
        const approvalDto = {ids:selectedVendors , approvedStatus:2 , adminMessage:''}
        props.ApprovedAsyncVendors(approvalDto)
    }
    const NotApproveVendors = ()=>{
        if(_.isEmpty(selectedVendors))
            return
        const approvalDto = {ids:selectedVendors , approvedStatus:3 , adminMessage:''}
        props.DisApprovedAsyncVendors(approvalDto)
    }

    const onSortValueChange = (id, value) =>{
        props.setPriority({
            Ids:[id],
            Priority:value
        })
    }
    

    return (
        <>
            <div className="mainHeader organizer font-size-24 mediumWeight">
                <div className="titleItems">
                    <span className="mainTitle middleContext">{isAssociation?"Approved Vendor Associations":"Approved Vendors"}</span>
                </div>
                <div className="filterItems">
                    <div className="filters inlineView">
                        <div className="changeDate inlineView"></div>
                        <div className="accessLevel inlineView"></div>
                    </div>
                    {!_.isEmpty(selectedVendors)?
                    <Fragment>
                            <span style={{marginRight:'10px'}} onClick={approveVendors} className={classNames(cssClasses.cursorPointer , "createNewPlan font-size-18")}>
                                Approve
                            </span>
                            <span onClick={NotApproveVendors} className={classNames(cssClasses.cursorPointer,"font-size-18 createNewPlan")}>
                                DisApprove
                            </span>
                    </Fragment>
                      :null}
                  </div>
              </div>
            {rows&&columns?
              <div className="adminBoilerplate organizer">
                    <TableComponent
                        pageSizes={[5,10,15]} 
                        checkboxSelection={true}
                        totalCount={total} 
                        selectedList = {selectedVendors}
                        setSelectedList={setSelectedVendors} 
                        setDtParameter={setDtParameter} 
                        loading = {loading}
                        dtParameter = {dtParameter}
                        rows={rows} 
                        columns={columns}
                        getRowClassName={(params) => 
                            cssClasses[`row--${params.row.approvedStatus.split(' ').join('')}`]
                        }
                        api='api/vendor/GetApprovable'
                        hasSort={true}
                        changeSortColumnValue={onSortValueChange}
                        />
              </div>
            :<SimpleLoading/>}
        </>
  ) ;
};

const mapStateToProps = (state , ownProps) => {
    return {
        vendorList : state.ApprovedAsyncVendors,
        ApprovedActionResult : state.ApprovedVendorsResults
    } ;
};

export default connect(mapStateToProps , {GetApprovableAsyncVendor , ApprovedAsyncVendors , DisApprovedAsyncVendors , setPriority})(withRouter(MainPage)) ;

