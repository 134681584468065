import topicsReducer from './reducer.topics' ;
import topicsTotalCountReducer from './reducer.topicsTotalCount' ;
import singleTopicReducer from './reducer.singleTopic' ;
import singleTopicActionsResultReducer from './reducer.singleTopicActionsResult' ;

export default {
    topics : topicsReducer ,
    topicsTotalCount : topicsTotalCountReducer ,
    singleTopic : singleTopicReducer ,
    singleTopicActionsResult : singleTopicActionsResultReducer
};