import {GET , POST} from "../common/Action";
import Action from "../common/Action";



//actions
export const getAllResourcesAction = new Action('GET_ALL_RESOURCES_ACTION' , POST , 'api/Resources/GetAll') ;
export const getPagedResourcesByLanguagesAction = new Action('GET_PAGED_RESOURBCES_ACTION', POST,'api/Resources/GetPagedResourcesByLanguage');
export const getLanguagesAction = new Action('GET_LANGUAGES_ACTION' , GET , 'api/Languages/GetAll') ;
export const addNewResourceAction = new Action('ADD_RESOURCE_ACTION' , POST , 'api/Resources/add') ;
export const editResourceAction = new Action('EDIT_RESOURCE_ACTION' , POST , 'api/Resources/update') ;
export const deleteResourceAction = new Action('DELETE_RESOURCE_ACTION' , POST , (params)=>`api/resources/remove?id=${params.id}`);
export const getResourcesByLanguagesAction = new Action('GET_RESOURCE_ACTION' , GET , (param)=>`api/resources/GetAllByLanguage?lang=${param.lang}`)
export const importResourcesFromExcelAction = new Action('IMPORT_RESOURCES_FROM_EXCEL', GET , (param)=>`api/resources/ImportResourcesFromExcelFile?savedFileId=${param.savedFileId}`);
export const exportResourcesToExcelAction = new Action("EXPORT_RESOURCES_TO_EXCEL" , POST , 'api/resources/ExportResourcesToExcelFile')

export const getAllResources = () => {
    return getAllResourcesAction.callApi() 
};

export const getLanguages = () => {
  return getLanguagesAction.callApi() ;
};

export const addNewResource = (data)=>{
  return addNewResourceAction.callApi(data);
};
export const editResource= (data) => {
  return editResourceAction.callApi(data) ;
};

export const deleteResource=(id) => {
  return deleteResourceAction.callApi(null ,{id}) ;
};

export const getResourcesByLanguage = (lang) =>{
    return getResourcesByLanguagesAction.callApi(null , {lang});
}

export const getPagedResourcesByLanguage = (data)=>{
  return getPagedResourcesByLanguagesAction.callApi(data)
}

export const importResourcesFromExcel = (savedFileId) =>{
  return importResourcesFromExcelAction.callApi(null , {savedFileId})
}

export const ExportResourcesToExcel = () =>{
  return exportResourcesToExcelAction.callApi();
}