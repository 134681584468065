import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';


const ActionMenu = (props) =>{
    const id = props.id.id
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleEdit = () =>{
        setAnchorEl(null);
        props.handleEdit()
    }
    const handleDownload = () =>{
        setAnchorEl(null);
        props.handleDownload()
    }
    // const handleViewAccepted =()=>{
    //     setAnchorEl(null);
    //     props.handleViewAccepted()
    // }

    const handleHistory = () =>{
        setAnchorEl(null);
        props.handleViewHistory()
    }

    return (
        <div>
            <Button
                id="demo-positioned-button"
                aria-controls={open ? 'demo-positioned-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <span className="icon icon-menu text-black"></span>
            </Button>
            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
                }}
            >
                <MenuItem onClick={handleEdit}><span className='icon icon-edit pr-2 '></span>Edit</MenuItem>
                {props.handleDownload?
                    <MenuItem onClick={handleDownload}><span className='fa fa-download pr-2'></span>Download</MenuItem>
                :null}
                {props.handleViewHistory?
                    <MenuItem onClick={handleHistory}><span className='pr-2'>View version history</span></MenuItem>
                :null}
                {/* <MenuItem onClick={handleViewAccepted}><span className='pr-2'></span>View Accepted T&C</MenuItem> */}
            </Menu>
        </div>
    );
}
export default ActionMenu;

// const mapStateToProps = (state, ownProps) => {
//     return {
//         // download : state.reducer
//     };
//   };
//   export default connect(mapStateToProps, {
//     getTermsAndConditionFile
//   })(withRouter(withTranslation()(withAuth(ActionMenu))));
  