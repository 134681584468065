import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { withRouter, Link, useHistory, useParams } from "react-router-dom";
import { withTranslation } from "react-i18next";
import classNames from "classnames";
import TableComponent from '../components/TableComponent';
import {getAllPages , getPageMetaTags , updateMetaTag} from './actions';

import ActionMenu from "../templates/ActionMenu";
import SimpleLoading from "../components/simpleLoading";
import DetailMetaTag from './metaTags'
const MetaTags = (props) => {
    const {t  , allPages , getResults , allMetaTags} = props;
    const [loading ,  setLoading] = useState(false)
    const [dtParameter , setDtParameter] = useState({
        Current:1,
        pageSize:10
    })
    const [showModal , setShowModal] = useState(false)
    const [rows , setRows] = useState([])
    const [columns  , setColumns] = useState(null)
    const [errorMessage , setErrorMessage] = useState(null)
    const [selectedPage , setSelectedPage] = useState(null)
    const [submitLoading , setSubmitLoading] = useState(false)
    const [metaTags , setMetaTags] = useState({})
    
    useEffect(()=>{
        props.getAllPages({
            dtParameter:dtParameter
        })
    },[])

    
    useEffect(()=>{
        if(!dtParameter)
            return
        props.getAllPages({
            dtParameter:dtParameter
        })
    },[dtParameter])

    useEffect(()=>{
        if(!selectedPage || metaTags[selectedPage])
            return 
        props.getPageMetaTags({page:selectedPage.id})
    },[selectedPage])

    useEffect(()=>{
        if(!allPages||_.isEmpty(allPages))
            return
            setRows(
                allPages.data
                )
        if(!columns){
            setColumns(
                [...allPages.columns,
                {field:'Id', headerName:'Action' , flex:0.5, renderCell: (params) =>(
                    <ActionMenu id={params.row} handleEdit = {()=>showDetailModal(params.row)} 
                    />
                )}
            ]
            )
        }
    },[allPages])


    useEffect(()=>{
        if(!allMetaTags)
            return 
        if(allMetaTags && allMetaTags.page){
            setMetaTags({...metaTags , [allMetaTags.page]:allMetaTags.data})
        }
    },[allMetaTags])



    const showDetailModal = (page) =>{
        setSelectedPage(page)
        setShowModal(true)
    }

    const updateMetaTags = (values)=>{
        const data = metaTags[selectedPage.id].map(metaTag=>{
            return (
                {
                    ...metaTag , 
                    value : values[metaTag.keyId]
                }
            )
        })
        setSubmitLoading(true)
        props.updateMetaTag({
            page:selectedPage.id,
            metaTags:data
        })
    }

    useEffect(()=>{
        if(!getResults)
            return
        if(getResults && getResults.type =='UPDATE_META_TAG'){
            setSubmitLoading(false)
            if(getResults.succeeded){
                setShowModal(false)
                setSelectedPage(null)
            }else{
                setErrorMessage("Please try again later!")
            }
        }
    },[getResults])

    useEffect(()=>{
        if(!showModal)
            setSelectedPage(null)
            setErrorMessage(null)
    },[showModal])

    return(
        !columns?
        <SimpleLoading/>
        :
        <Fragment>
            <div className="mainHeader organizer font-size-24 mediumWeight">
                <div className="titleItems ">
                    {t("Meta Tag Pages")}    
                </div>
            </div>
            {errorMessage?
                <div>{errorMessage}</div>
            :null}
            {!_.isEmpty(allPages) && columns?
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="adminBoilerplate organizer">
                        <TableComponent 
                            removeToolbar = {false}
                            pageSizes={[5,10,15]} 
                            totalCount={allPages?allPages.total:0} 
                            selectedList={[]} 
                            rows={rows} 
                            columns={columns}
                            setSelectedList={(e)=>{}} 
                            setDtParameter={setDtParameter} 
                            loading = {loading}
                            dtParameter = {dtParameter}
                            clientSortMode = {true}
                            clientPaginationMode = {true}
                            clientFilterMode = {true}
                        />
                    </div>
                </div>
            </div>
            :null}
            {showModal && selectedPage?
                metaTags[selectedPage.id]?
                    <DetailMetaTag 
                        initialValues={
                            _.range(metaTags[selectedPage.id].length +1).map(m => 
                                !_.isEmpty(metaTags[selectedPage.id].filter(meta => meta.keyId == m))?  metaTags[selectedPage.id].filter(meta => meta.keyId == m)[0].value:null)
                        }
                        errorMessage={errorMessage} loading={submitLoading} 
                        metaTags={metaTags[selectedPage.id]} onSubmit={updateMetaTags} 
                        page={selectedPage} closeModal={()=>setShowModal(false)}/>
                :
                    <SimpleLoading/>
            :null}
            <br/>
            <br/>
            <br/>
        </Fragment>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        allPages: state.MetaTagsGetAllPages,
        getResults: state.MetaTagsGetResults,
        allMetaTags: state.MetaTagsGetMetaTagsByPage
    };
  };
  export default (connect(mapStateToProps, {
    getAllPages , getPageMetaTags , updateMetaTag
  })(withTranslation()(withRouter(MetaTags))));