import {GET , POST , PUT , POST_FORM} from "../common/Action";
import Action from "../common/Action";
//actions
export const getAllAcessLevelAction = new Action('GET_ALL_PLANS_ACTION' , GET , 'api/accessLevel/GetAllAccessLevels');
export const addAccessLevelAction = new Action('ADD_NEW_ACCESS_LEVEL_ACTION' , POST , 'api/accessLevel/CreateAccessLevel');
export const editAccessLevelAction = new Action('EDIT_ACCESS_LEVEL_ACTION' , POST , 'api/accessLevel/EditAccessLevel');
export const deleteAccessLevelAction = new Action('DELETE_ACCESS_LEVEL_ACTION' , POST , 'api/accessLevel/DeleteAccessLevel');

//action creators
export const getAllAccessLevels = () => {
  return getAllAcessLevelAction.callApi() ;
};

export const addAccessLevel = (data)=>{
  return addAccessLevelAction.callApi(data);
}

export const editAccessLevel = (data)=>{
  return editAccessLevelAction.callApi(data);
}

export const deleteAccessLevel = (data)=>{
  return deleteAccessLevelAction.callApi(data);
}
