import React , {useState , useEffect} from 'react' ;
import {connect} from 'react-redux' ;
import LeftNav from '../components/left-menu' ;
import TableComponent from '../components/TableComponent';
import {GetApprovableAsyncContract , ApprovedAsyncContracts , DisApprovedAsyncContracts} from "./actions";
import _ from 'lodash'
import { Fragment } from 'react';
import cssClasses from '../ApprovedVendors/ApprovedVendorListStyles.module.css'
import classNames from 'classnames';
import SimpleLoading from '../components/simpleLoading';
const MainPage = props => {
    const {contractList , ApprovedActionResult} = props
    const [selectedContracts , setSelectedContracts] = useState([])
    const [rows , setRows] = useState(null)
    const [total , setTotal] = useState(0)
    const [dtParameter , setDtParameter] = useState({current:1 , pageSize:10 , filter:null})
    const [loading , setLoading] = useState(false)
    const [columns , setColumns] = useState(null
        // {field:'email' , headerName:'email' , flex:1}
    //   {field:'uniqueUrl' , headerName:t('Unique URL'),flex:1},
    //   {field:'description' , headerName:t('Description'),flex:1},
    //   {field:'id',headerName:t('َActions') , flex:0.5 , renderCell: (params) => (
    //     <Fragment>
    //     <div className="cardListDetailToolsItem blueBackground clickShape whiteColor" 
    //       onClick={()=>history.push(`/contractRegistration/${params.value}`)}>
                  
    //                   <span className="icon icon-edit whiteColor"></span>
    //             </div>  
    //             <div
    //             onClick={() =>
    //               deleteDataEntry(params.value)
    //             }
    //             className="cardListDetailToolsItem redBackground clickShape whiteColor"
    //           >
                
    //             <span className="icon icon-close whiteColor"></span>
    //         </div> 
    //         </Fragment>
    //   )}
    )

    

    useEffect(() => {
        setLoading(true)
        props.GetApprovableAsyncContract({dtParameter:dtParameter}) ;
    } , [dtParameter]) ;


    useEffect(()=>{
        if(!ApprovedActionResult)
            return
        setSelectedContracts([])
        if(ApprovedActionResult.succeeded){
            props.GetApprovableAsyncContract({dtParameter:{...dtParameter,current:1 , dir:"desc",orderBy:"lastChangeStatusDate"}})
            setDtParameter({...dtParameter ,current:1 , dir:"desc",orderBy:"lastChangeStatusDate" })
        }else{
            console.log('Error happend try again later')
        }
    },[ApprovedActionResult])


    useEffect(()=>{
        if(!contractList||_.isEmpty(contractList))
            return
        setColumns(contractList.columns.map(col=>{
            if(!col.hasRenderCell)
                return col
            if(col.field&&col.field.includes('description')){
                return ({...col , renderCell:(param)=>(<div dangerouslySetInnerHTML={{__html: param.value}}/>)})
            }
        }))
        setLoading(false)
        setRows(contractList.data)
        setTotal(contractList.total)
    },[contractList])


    const approveContracts = ()=>{
        if(_.isEmpty(selectedContracts))
            return
        const approvalDto = {ids:selectedContracts , approvedStatus:2 , adminMessage:''}
        props.ApprovedAsyncContracts(approvalDto)
    }
    const NotApproveContracts = ()=>{
        if(_.isEmpty(selectedContracts))
            return
        const approvalDto = {ids:selectedContracts , approvedStatus:3 , adminMessage:''}
        props.DisApprovedAsyncContracts(approvalDto)
    }

    

    

    return (
        <>
            <div className="mainHeader organizer font-size-24 mediumWeight">
                <div className="titleItems">
                    <span className="mainTitle middleContext">Approved Contracts</span>
                </div>
                <div className="filterItems">
                    <div className="filters inlineView">
                        <div className="changeDate inlineView"></div>
                        <div className="accessLevel inlineView"></div>
                    </div>
                    {!_.isEmpty(selectedContracts)?
                    <Fragment>
                        <span style={{marginRight:'10px'}} onClick={approveContracts} className={classNames(cssClasses.cursorPointer , "createNewPlan font-size-18")}>
                            Approve
                        </span>
                        <span onClick={NotApproveContracts} className={classNames(cssClasses.cursorPointer,"font-size-18 createNewPlan")}>
                            DisApprove
                        </span>
                    </Fragment>
                    :null}
                </div>
            </div>
        {rows&&columns?
            <div className="adminBoilerplate organizer">
                <TableComponent
                    pageSizes={[5,10,15]} 
                    totalCount={total} 
                    selectedList = {selectedContracts}
                    setSelectedList={setSelectedContracts} 
                    setDtParameter={setDtParameter} 
                    checkboxSelection={true}
                    loading = {loading}
                    dtParameter = {dtParameter}
                    rows={rows} 
                    columns={columns}
                    getRowClassName={(params) => 
                        cssClasses[`row--${params.row.approvedStatus.split(' ').join('')}`]
                    }
                    api="api/contract/GetApprovable"
                    />
            </div>
        :<SimpleLoading/>}
        </>
  ) ;
};

const mapStateToProps = (state , ownProps) => {
    return {
        contractList : state.ApprovedAsyncContracts,
        ApprovedActionResult : state.ApprovedContractsResults
    } ;
};

export default connect(mapStateToProps , {GetApprovableAsyncContract , ApprovedAsyncContracts , DisApprovedAsyncContracts})(MainPage) ;


