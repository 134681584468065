import React, { Component } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
//@ts-ignore
import { Editor } from 'react-draft-wysiwyg';
import authService from '../api-authorization/AuthorizeService';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import apiRoutes from '../api-routes';
import axios from 'axios';
import UseToken from '../api-authorization/UseToken';

class EditorConvertToHTML extends Component {
  constructor(props) {
    super(props);
    const html = this.props.valueObj;
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      this.state = {
        editorState,
      };
    }
  }

    componentDidUpdate(prevProps) {
      if(!this.props.contentChange && !this.props.reset)
        return 
      if(this.props.contentChange){
          this.props.setContentChange(false)
          const contentBlock = htmlToDraft(this.props.valueObj);
          if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            this.setState({
              editorState,
            });
          }
      }else{
        this.props.setReset(false)
        const editorState = EditorState.createEmpty();
        this.setState({
          editorState,
        });
      }
    }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
    this.props.onChangeEditor(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  render() {
    const uploadImageCallback = async (file) => {
      const formData = new FormData();
      formData.append('upload' , file , file.name);

      const token = UseToken()
      
      const options = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        },
      }
      return await axios
        .post(`${apiRoutes.api}api/file/rootImageUpload?sendId=true`, formData, options)
        .then((res) => {
          return { data: { link: apiRoutes.api +  res.data.message } };
        });
    };

    var editorState = null ;
    if(this.state){
      editorState = this.state.editorState
    }
    return (
      <div style={{display:'inline-block',margin:'1rem 0 1rem 0',border: "1px solid #f1f1f1", padding:'0.5rem'}}>
        <Editor
          readOnly={this.props.disable}
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={this.onEditorStateChange}
          toolbar={{
            image: {
              className: undefined,
              component: undefined,
              popupClassName: undefined,
              urlEnabled: true,
              uploadEnabled: true,
              alignmentEnabled: true,
              uploadCallback: uploadImageCallback,
              previewImage: false,
              inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
              alt: { present: false, mandatory: false },
              defaultSize: {
                height: 'auto',
                width: 'auto',
              },
            },
          }}
        />
        {/* <textarea disabled value={draftToHtml(convertToRaw(editorState.getCurrentContent()))} /> */}
      </div>
    );
  }
}

export default EditorConvertToHTML;
