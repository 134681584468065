import {getFaqAction} from "./actions";
import _ from 'lodash';

export default (state = {} , action) => {
    switch (action.type) {
        case getFaqAction.resultAction :
            return {...state , [action.payload.data.id]: action.payload.data};
        default :
            return state ;

    }
}