import {GET , POST , PUT , POST_FORM} from "../common/Action";
import Action from "../common/Action";

//actions
export const GetApprovableAsyncPersonAction = new Action('GET_APPROVABLE_PERSONS' , POST , 'api/person/GetApprovable') ;
export const ApprovedAsyncPersonsAction = new Action('APPROVED_ASYNC_PERSON_ACTION' , POST , 'api/person/Approve');
export const DisApprovedAsyncPersonsAction = new Action('DISAPPROVED_ASYNC_PERSON_ACTION' , POST , 'api/person/DisApprove');
export const setProrityAction = new Action('APPROVED_PERSON_SET_PRORITY_ACTION' , POST , 'api/person/setPriority');


//action creators
export const GetApprovableAsyncPerson = (data) => {
  return GetApprovableAsyncPersonAction.callApi(data) ;
};

export const ApprovedAsyncPersons = (data) =>{
  return ApprovedAsyncPersonsAction.callApi(data);
}

export const DisApprovedAsyncPersons = (data) =>{
  return DisApprovedAsyncPersonsAction.callApi(data);
}

export const setPriority = (data) =>{
  return setProrityAction.callApi(data);
}