import classNames from "classnames";
import React, { Fragment } from "react";
import humanFileSize from "../common/humanFileSize";

export const showFileIcon = (fileName) => {
  var fileEx = /(?:\.([^.]+))?$/.exec(fileName)[1];
  switch (fileEx) {
    case "xlsx":
      return <img style={{width:'35px'}} src="/img/excel.svg" />;
    case "doc" , "docx":
      return <img style={{width:'35px'}} src="/img/word.svg" />;
    case "pdf":
      return <img style={{width:'35px'}} src="/img/pdf.svg" />;
    case "sav":
      return <img style={{width:'35px'}} src="/img/spss.svg" />;
    default:
      return <img style={{width:'35px'}} src="/img/other.svg" />;
  }
};



const FileComponent = (props) => {
  const {
    fileFormat,
    fileSize,
    fileName,
    deleteFunc,
    isUploading,
    uploadPecent,
    hasDeleteIcon = true,
    onActiveFile = false,
    onclick=null
  } = props;

  
  return (
    <div onClick={onclick?onclick:null} className={classNames("uploadedCart",{'activeBorder':onActiveFile})}>
      <span className="uploadedIcon">{showFileIcon(fileName)}</span>
      <div className="uploadedcartContent">
        <div className="uploadedcartContentRow1">
          <div className="fileName">
            <span>File Name: </span>
            <span>{fileName}</span>
          </div>

          <div>
            <span>File Size: </span>
            <span>{humanFileSize(fileSize)}</span>
          </div>
        </div>
        {isUploading ? (
          <>
            <div className="uploadedcartContentRow2">
              <div className="progress">
                <div
                  className="progress-bar progress-bar-striped progress-bar-animated"
                  role="progressbar"
                  aria-valuenow="100"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{ width: `${uploadPecent}%` }}
                ></div>
              </div>
            </div>
            <div className="uploadedcartContentRow3">
              <span>%{uploadPecent} Uploaded</span>
            </div>
          </>
        ) : null}
      </div>
      <div>
      {hasDeleteIcon?
        <span
          onClick={deleteFunc}
          className="icon-rubbish-bin-delete-button"
        ></span>
        :null}
      </div>
    </div>
  );
};

export default FileComponent;
