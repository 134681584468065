import React , {useState , useEffect, Fragment} from 'react' ;
import {connect} from 'react-redux' ;
import {getDashboardContent} from "./actions";
import LeftNav from '../components/left-menu' ;
import {Link , withRouter} from 'react-router-dom' ;
import DashboardItem from './DashboardItem';
import LineChart from '../components/LineChart' ;
import _ from 'lodash'

const MainPage = props => {

  const {dashboard} = props ;
  const counts = dashboard && !_.isEmpty(dashboard.dashboardCount)?dashboard.dashboardCount[0]:null;
  const questions = dashboard.mostVisitedQuestions;
  const dashboardItemList=[
    {
      color:'blue',
      name:'Topics',
      cls:'topic',
      count:counts?counts.topicsCount:0,
      hasLink:true
    },
    {
        color:'orange',
      name:'Sub Topics',
      cls:'subTopic',
      path:'subTopic',
      count:counts?counts.subTopicsCount:0,
      hasLink:false
    },
    {
        color:'green',
      name:'Users Registered',
      cls:'user',
      path:'users',
      count:counts?counts.usersCount:0,
      hasLink:true
    },
    {
        color:'red',
      name:'Surveys',
      cls:'surveys',
      count:counts?counts.surveysCount:0,
      hasLink:false
    },
    {
        color:'lightBlue',
        name:'Questions',
        cls:'question',
        count:counts?counts.questionsCount:0,
        hasLink:false
    },
    {
        color:'darkBlue',
      name:'Files Uploaded',
      path:'files',
      cls:'cloud',
      count:counts?counts.FilesCount:0,
      hasLink:false
    },
    {
        color:'pink',
      name:'Partners',
      cls:'partners',
      count:counts?counts.partnersCount:0,
      hasLink:false
    },
    {
        color:'gold',
      name:'Commissions',
      cls:'commission',
      count:counts?counts.commissionsCount:0,
      hasLink:false
    },
    {
        color:'purple',
      name:'Messages Received',
      cls:'message',
      path:'messages',
      count:counts?counts.messagesCount:0,
      hasLink:true
    }
]

  useEffect(()=> {
      const data= {languageId:'en-US'}
      props.getDashboardContent(data) ;
  } , []) ;

  return (
    <Fragment>
        <div className="mainHeader organizer font-size-24 mediumWeight">
            <div className="titleItems">
                Dashboard
            </div>
        </div>
        <div className="adminBoilerplate organizer">
            <div className="dashboardItemBoxes">
                {
                dashboardItemList.map((item,index)=>{
                    return <DashboardItem key={index} history={props.history} name={item.name} cls={item.cls} count={item.count} color={item.color} path={item.path} hasLink={item.hasLink} />
                })}
                
            </div>
            {questions? 
            <div className="mostItems blueBackground whiteColor">
                <div className="mostSelectBox font-size-24 mediumWeight">Most Visited Questions</div>
                {questions.map(question=>(
                    
                    <div key={question.questionId} className="mostVisited_items double_top_margin double_right_margin ">
                        <div className="mostVisited_items_text">
                            {question.questionTitle}
                        </div>
                        <div className="view_explore flexDisplaySpaceBetween">
                            <div className="mostVisited_view">
                                <div>{question.count+" "} View</div>
                            </div>
                            {question.questionId?
                            <Link to={{ pathname: `http://157.56.30.157:8094/question/${question.questionId}`}} target='_blank'>
                                <div className="explore_btn_outline double_top_margin">
                                    <div>Explore</div>
                                    <span className="icon-right-direction"></span>
                                </div>
                            </Link>
                            :null}
                        </div>
                    </div>
                ))}
            </div>
            :null}
            <div className="bigChart organizer topNormalMargin tempDisable"></div>
            <div className="trendingItems organizer topNormalMargin">
                <div className="trendTable bestPartnersTable leftFloat">
                    <div className="trendRow headerRow">
                        <div className="trendColumn mainColumn">Best Partners</div>
                        <div className="trendColumn">Questions</div>
                        <div className="trendColumn">Surveys</div>
                        <div className="trendColumn">Income</div>
                    </div>
                    <div className="trendRow font-size-14">
                        <div className="trendColumn">
                            <div className="columnInner">
                        <span className="partnerLogo">
                            <img src={require("../../img/partner_logo_1.png")}/>
                        </span>
                                <span className="icon icon-right-sign-arrow"></span>
                            </div>
                        </div>
                        <div className="trendColumn">12</div>
                        <div className="trendColumn">5</div>
                        <div className="trendColumn smallChart">
                            <div className="columnInner mediumWeight">
                                $512
                                <span className="icon icon-top-small-arrow font-size-12 greenColor"></span>
                                <div className="smallChart inlineView"></div>
                            </div>
                        </div>
                    </div>
                    <div className="trendRow font-size-14">
                        <div className="trendColumn">
                            <div className="columnInner">
                        <span className="partnerLogo">
                            <img src={require("../../img/partner_logo_2.png")}/>
                        </span>
                                <span className="icon icon-right-sign-arrow"></span>
                            </div>
                        </div>
                        <div className="trendColumn">12</div>
                        <div className="trendColumn">5</div>
                        <div className="trendColumn smallChart">
                            <div className="columnInner mediumWeight">
                                $512
                                <span className="icon icon-top-small-arrow font-size-12 greenColor"></span>
                                <div className="smallChart inlineView"></div>
                            </div>
                        </div>
                    </div>
                    <div className="trendRow font-size-14">
                        <div className="trendColumn">
                            <div className="columnInner">
                        <span className="partnerLogo">
                            <img src={require("../../img/partner_logo_3.png")}/>
                        </span>
                                <span className="icon icon-right-sign-arrow"></span>
                            </div>
                        </div>
                        <div className="trendColumn">12</div>
                        <div className="trendColumn">5</div>
                        <div className="trendColumn smallChart">
                            <div className="columnInner mediumWeight">
                                $512
                                <span className="icon icon-top-small-arrow font-size-12 greenColor"></span>
                                <div className="smallChart inlineView"></div>
                            </div>
                        </div>
                    </div>
                    <div className="trendRow font-size-14">
                        <div className="trendColumn">
                            <div className="columnInner">
                        <span className="partnerLogo">
                            <img src={require("../../img/partner_logo_4.png")}/>
                        </span>
                                <span className="icon icon-right-sign-arrow"></span>
                            </div>
                        </div>
                        <div className="trendColumn">12</div>
                        <div className="trendColumn">5</div>
                        <div className="trendColumn smallChart">
                            <div className="columnInner mediumWeight">
                                $512
                                <span className="icon icon-top-small-arrow font-size-12 greenColor"></span>
                                <div className="smallChart inlineView"></div>
                            </div>
                        </div>
                    </div>
                    <span className="tableStructure">
                        <span className="verticalLine"></span>
                        <span className="verticalLine"></span>
                        <span className="verticalLine"></span>
                    </span>
                </div>
                {dashboard && !_.isEmpty(dashboard.trendTopics)?
                    <div className="trendTable trendingTopicsTable rightFloat">
                        <div className="trendRow headerRow">
                            <div className="trendColumn mainColumn">Trending Topics</div>
                            <div className="trendColumn">Frequency</div>
                            <div className="trendColumn">Users</div>
                        </div>
                        {dashboard.trendTopics.map(topic=>(
                            <div key={topic.topicId} className="trendRow font-size-14">
                                <div className="trendColumn">
                                    #{topic.topicName}
                                    <span className="icon icon-right-sign-arrow"></span>
                                </div>
                                <div className="trendColumn smallChart">
                                    <div className="columnInner mediumWeight">
                                        {topic.count}
                                        <span className="icon icon-top-small-arrow font-size-12 greenColor"></span>
                                        <div className="smallChart inlineView"></div>
                                    </div>
                                </div>
                                <div className="trendColumn">783</div>
                            </div>
                        ))}
                        <span className="tableStructure">
                            <span className="verticalLine"></span>
                            <span className="verticalLine"></span>
                        </span>
                    </div>
                :null}
            </div>
        </div>
    </Fragment>
  ) ;
};

const mapStateToProps = (state , ownProps) => {
    return {
        dashboard : state.dashboard
    } ;
};

export default connect(mapStateToProps , {getDashboardContent})(withRouter(MainPage)) ;


/*
<div className="mainContent">
              <div className="mainHeader organizer font-size-24 mediumWeight">
                  <div className="titleItems">
                      Dashboard
                  </div>
              </div>
              <div className="adminBoilerplate organizer">
                  <div className="dashboardItemBoxes">
                      {
                      dashboardItemList.map((item)=>{
                          return <DashboardItem name={item.name} cls={item.cls} />
                      })}
                      {
                      const dashboardItemList=[
                          {
                            name:'Topics',
                            cls:'topic',
                            count:dashboard.topicsCount
                          },
                          {
                            name:'Topics',
                            cls:'topic'
                          },

                      ]}
                      <div className="dashboardItem topics clickShape" onClick={() => props.history.push('/topics')}>
                          <span className="icon icon-topic font-size-40 blueColor"></span>
                          <div className="itemTitle">Topics</div>
                          <span className="itemLine"></span>
                          <div className="itemCount">
                              <span className="itemNumber font-size-24 mediumWeight">{dashboard.topicsCount ? dashboard.topicsCount : null}</span>
                              <span className="icon icon-right-sign-arrow"></span>
                          </div>
                      </div>
                      <div className="dashboardItem subTopic clickShape" onClick={() => props.history.push('/subTopics')}>
                          <span className="icon icon-subTopic font-size-40 orangeColor"></span>
                          <div className="itemTitle">Sub Topics</div>
                          <span className="itemLine"></span>
                          <div className="itemCount">
                              <span className="itemNumber font-size-24 mediumWeight">{dashboard.subTopicsCount ? dashboard.subTopicsCount : null}</span>
                              <span className="icon icon-right-sign-arrow"></span>
                          </div>
                      </div>
                      <div className="dashboardItem users clickShape" onClick={() => props.history.push('/users')}>
                          <span className="icon icon-user font-size-40 greenColor"></span>
                          <div className="itemTitle">Users Registered</div>
                          <span className="itemLine"></span>
                          <div className="itemCount">
                              <span className="itemNumber font-size-24 mediumWeight">{dashboard.usersCount ? dashboard.uesrsCount : null}</span>
                              <span className="icon icon-right-sign-arrow"></span>
                          </div>
                      </div>
                      <div className="dashboardItem surveys clickShape" onClick={() => props.history.push('/surveys')}>
                          <span className="icon icon-surveys font-size-40 redColor"></span>
                          <div className="itemTitle">Surveys</div>
                          <span className="itemLine"></span>
                          <div className="itemCount">
                              <span className="itemNumber font-size-24 mediumWeight">{dashboard.surveysCount ? dashboard.surveysCount : null}</span>
                              <span className="icon icon-right-sign-arrow"></span>
                          </div>
                      </div>
                      <div className="dashboardItem questions clickShape" onClick={() => props.history.push('/questions')}>
                          <span className="icon icon-question font-size-40 lightBlueColor"></span>
                          <div className="itemTitle">Questions</div>
                          <span className="itemLine"></span>
                          <div className="itemCount">
                              <span className="itemNumber font-size-24 mediumWeight">{dashboard.questionsCount ? dashboard.questionsCount : null}</span>
                              <span className="icon icon-right-sign-arrow"></span>
                          </div>
                      </div>
                      <div className="dashboardItem files clickShape" onClick={() => props.history.push('/files')}>
                          <span className="icon icon-cloud font-size-40 darkBlueColor"></span>
                          <div className="itemTitle">Files Uploaded</div>
                          <span className="itemLine"></span>
                          <div className="itemCount">
                              <span className="itemNumber font-size-24 mediumWeight">{dashboard.uploadedFilesCount ? dashboard.uploadedFilesCount : null}</span>
                              <span className="icon icon-right-sign-arrow"></span>
                          </div>
                      </div>
                      <div className="dashboardItem partners clickShape" onClick={() => props.history.push('/partners')}>
                          <span className="icon icon-partner font-size-40 pinkColor"></span>
                          <div className="itemTitle">Partners</div>
                          <span className="itemLine"></span>
                          <div className="itemCount">
                              <span className="itemNumber font-size-24 mediumWeight">{dashboard.partnersCount ? dashboard.partnersCount: null}</span>
                              <span className="icon icon-right-sign-arrow"></span>
                          </div>
                      </div>
                      <div className="dashboardItem commission clickShape" onClick={() => props.history.push('/commissions')}>
                          <span className="icon icon-commission font-size-40 goldColor"></span>
                          <div className="itemTitle">Commissions</div>
                          <span className="itemLine"></span>
                          <div className="itemCount">
                              <span className="itemNumber font-size-24 mediumWeight">{dashboard.commissionsCount ? dashboard.commissionsCount : null}</span>
                              <span className="icon icon-right-sign-arrow"></span>
                          </div>
                      </div>
                      <div className="dashboardItem messages clickShape" onClick={() => props.history.push('/messages')}>
                          <span className="icon icon-message font-size-40 purpleColor"></span>
                          <div className="itemTitle">Messages Received</div>
                          <span className="itemLine"></span>
                          <div className="itemCount">
                              <span className="itemNumber font-size-24 mediumWeight">{dashboard.messagesCount ? dashboard.messagesCount : null}</span>
                              <span className="icon icon-right-sign-arrow"></span>
                          </div>
                      </div>
                  </div>
                  <div className="mostItems blueBackground whiteColor">
                      <div className="mostSelectBox font-size-24 mediumWeight">Most Visited Questions <span
                          className="icon icon-bottom-direction font-size-18"></span></div>
                      <div className="mostQuestions">
                          <div className="mostQuestionItem">
                              <div className="mostQuestionContext">
                                  X01- How has the financial situation of your household changed over the last
                                  12 months? It has…
                              </div>
                              <div className="mostQuestionCounter font-size-14 lightWeight">
                            <span className="mostNumber font-size-24">
                                156
                            </span>
                                  View
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="trendTable chartBox bestPartnersTable organizer topNormalMargin">
                      <div className="organizer bottomNormalMargin inlineFlex">
                          <div className="leftNormalMargin font-size-20 boldWeight">Income</div>
                          <div className="leftNormalMargin inlineFlex">
                              <div className="chartItem active leftNormalMargin inlineFlex"><div className="itemCircle" style={{width: '20px' ,
                                  height: '20px',
                                  backgroundColor: '#d10dcb' ,
                                  border: '1px solid #d10dcb' ,
                                  borderRadius: '15px'}}></div><div className="chartItemText" style={{marginLeft : '5px' , color : "#d10dcb"}}>Overall</div></div>
                              <div className="chartItem leftNormalMargin inlineFlex"><div className="itemCircle" style={{width: '20px' ,
                                  height: '20px',
                                  backgroundColor: '#d10d26' ,
                                  border: '1px solid #d10d26' ,
                                  borderRadius: '15px'}}></div><div className="chartItemText" style={{marginLeft : '5px' , color : "#d10d26"}}>Upgrade</div></div>
                              <div className="chartItem active leftNormalMargin inlineFlex"><div className="itemCircle" style={{width: '20px' ,
                                  height: '20px',
                                  backgroundColor: '#d1ae12' ,
                                  border: '1px solid #d1ae12' ,
                                  borderRadius: '15px'}}></div><div className="chartItemText" style={{marginLeft : '5px' , color : "#d1ae12"}}>Questions</div></div>
                              <div className="chartItem leftNormalMargin inlineFlex"><div className="itemCircle" style={{width: '20px' ,
                                  height: '20px',
                                  backgroundColor: '#0026d1' ,
                                  border: '1px solid #0026d1' ,
                                  borderRadius: '15px'}}></div><div className="chartItemText" style={{marginLeft : '5px' , color : "#0026d1"}}>Surveys</div></div>
                              <div className="chartItem leftNormalMargin inlineFlex"><div className="itemCircle" style={{width: '20px' ,
                                  height: '20px',
                                  backgroundColor: '#d1aeb3' ,
                                  border: '1px solid #d1aeb3' ,
                                  borderRadius: '15px'}}></div><div className="chartItemText" style={{marginLeft : '5px' , color : "#d1aeb3"}}>Files</div></div>
                          </div>
                      </div>
                      <LineChart/>
                  </div>
                  <div className="trendingItems organizer topNormalMargin">
                      <div className="trendTable bestPartnersTable leftFloat">
                          <div className="trendRow headerRow">
                              <div className="trendColumn mainColumn">Best Partners</div>
                              <div className="trendColumn">Questions</div>
                              <div className="trendColumn">Surveys</div>
                              <div className="trendColumn">Income</div>
                          </div>
                          <div className="trendRow font-size-14">
                              <div className="trendColumn">
                                  <div className="columnInner">
                                <span className="partnerLogo">
                                    <img src={require("../../img/partner_logo_1.png")}/>
                                </span>
                                      <span className="icon icon-right-sign-arrow"></span>
                                  </div>
                              </div>
                              <div className="trendColumn">12</div>
                              <div className="trendColumn">5</div>
                              <div className="trendColumn smallChart">
                                  <div className="columnInner mediumWeight">
                                      $512
                                      <span className="icon icon-top-small-arrow font-size-12 greenColor"></span>
                                      <div className="smallChart inlineView"></div>
                                  </div>
                              </div>
                          </div>
                          <div className="trendRow font-size-14">
                              <div className="trendColumn">
                                  <div className="columnInner">
                                <span className="partnerLogo">
                                    <img src={require("../../img/partner_logo_2.png")}/>
                                </span>
                                      <span className="icon icon-right-sign-arrow"></span>
                                  </div>
                              </div>
                              <div className="trendColumn">12</div>
                              <div className="trendColumn">5</div>
                              <div className="trendColumn smallChart">
                                  <div className="columnInner mediumWeight">
                                      $512
                                      <span className="icon icon-top-small-arrow font-size-12 greenColor"></span>
                                      <div className="smallChart inlineView"></div>
                                  </div>
                              </div>
                          </div>
                          <div className="trendRow font-size-14">
                              <div className="trendColumn">
                                  <div className="columnInner">
                                <span className="partnerLogo">
                                    <img src={require("../../img/partner_logo_3.png")}/>
                                </span>
                                      <span className="icon icon-right-sign-arrow"></span>
                                  </div>
                              </div>
                              <div className="trendColumn">12</div>
                              <div className="trendColumn">5</div>
                              <div className="trendColumn smallChart">
                                  <div className="columnInner mediumWeight">
                                      $512
                                      <span className="icon icon-top-small-arrow font-size-12 greenColor"></span>
                                      <div className="smallChart inlineView"></div>
                                  </div>
                              </div>
                          </div>
                          <div className="trendRow font-size-14">
                              <div className="trendColumn">
                                  <div className="columnInner">
                                <span className="partnerLogo">
                                    <img src={require("../../img/partner_logo_4.png")}/>
                                </span>
                                      <span className="icon icon-right-sign-arrow"></span>
                                  </div>
                              </div>
                              <div className="trendColumn">12</div>
                              <div className="trendColumn">5</div>
                              <div className="trendColumn smallChart">
                                  <div className="columnInner mediumWeight">
                                      $512
                                      <span className="icon icon-top-small-arrow font-size-12 greenColor"></span>
                                      <div className="smallChart inlineView"></div>
                                  </div>
                              </div>
                          </div>
                          <span className="tableStructure">
                        <span className="verticalLine"></span>
                        <span className="verticalLine"></span>
                        <span className="verticalLine"></span>
                    </span>
                      </div>
                      <div className="trendTable trendingTopicsTable rightFloat">
                          <div className="trendRow headerRow">
                              <div className="trendColumn mainColumn">Trending Topics</div>
                              <div className="trendColumn">Frequency</div>
                              <div className="trendColumn">Users</div>
                          </div>
                          <div className="trendRow font-size-14">
                              <div className="trendColumn">
                                  #Foreign_Politics
                                  <span className="icon icon-right-sign-arrow"></span>
                              </div>
                              <div className="trendColumn smallChart">
                                  <div className="columnInner mediumWeight">
                                      1.56k
                                      <span className="icon icon-top-small-arrow font-size-12 greenColor"></span>
                                      <div className="smallChart inlineView"></div>
                                  </div>
                              </div>
                              <div className="trendColumn">783</div>
                          </div>
                          <div className="trendRow font-size-14">
                              <div className="trendColumn">
                                  #usa_election
                                  <span className="icon icon-right-sign-arrow"></span>
                              </div>
                              <div className="trendColumn smallChart">
                                  <div className="columnInner mediumWeight">
                                      1.56k
                                      <span className="icon icon-top-small-arrow font-size-12 greenColor"></span>
                                      <div className="smallChart inlineView"></div>
                                  </div>
                              </div>
                              <div className="trendColumn">783</div>
                          </div>
                          <div className="trendRow font-size-14">
                              <div className="trendColumn">
                                  #election
                                  <span className="icon icon-right-sign-arrow"></span>
                              </div>
                              <div className="trendColumn smallChart">
                                  <div className="columnInner mediumWeight">
                                      1.56k
                                      <span className="icon icon-top-small-arrow font-size-12 greenColor"></span>
                                      <div className="smallChart inlineView"></div>
                                  </div>
                              </div>
                              <div className="trendColumn">783</div>
                          </div>
                          <div className="trendRow font-size-14">
                              <div className="trendColumn">
                                  #Products
                                  <span className="icon icon-right-sign-arrow"></span>
                              </div>
                              <div className="trendColumn smallChart">
                                  <div className="columnInner mediumWeight">
                                      1.56k
                                      <span className="icon icon-top-small-arrow font-size-12 greenColor"></span>
                                      <div className="smallChart inlineView"></div>
                                  </div>
                              </div>
                              <div className="trendColumn">783</div>
                          </div>
                          <div className="trendRow font-size-14">
                              <div className="trendColumn">
                                  #war
                                  <span className="icon icon-right-sign-arrow"></span>
                              </div>
                              <div className="trendColumn smallChart">
                                  <div className="columnInner mediumWeight">
                                      1.56k
                                      <span className="icon icon-top-small-arrow font-size-12 greenColor"></span>
                                      <div className="smallChart inlineView"></div>
                                  </div>
                              </div>
                              <div className="trendColumn">783</div>
                          </div>
                          <span className="tableStructure">
                        <span className="verticalLine"></span>
                        <span className="verticalLine"></span>
                    </span>
                      </div>
                  </div>
              </div>
          </div>
      </div>
*/