import React , {useState , useEffect} from 'react'
import { change, Field, reduxForm } from 'redux-form'
import {connect} from "react-redux";
import {renderSimpleInputField , renderTextAreaField , renderDropdown} from "../components/formFields";
import _ from 'lodash' ;
import classNames from 'classnames' ;
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import ColorPicker from '../addPlan/ColorPicker';

const validate = (values) => {
    let errors = {};
    if (!values.firstName) {
        errors.firstName = 'First Name is Required' ;
    }
    if (!values.lastName) {
        errors.lastName = 'Last Name is Required' ;
    }
    return errors ;
};

let PlanForm = props => {
    const { handleSubmit , change , edit , closeModal , formResultMessage} = props;
    const accessLevel = props.initialValues
    

    return (
        <div className="popup profiles">
            <div className="popupClose"></div>
            <div className="popUpBox columnContent">
                <div className="popupHeader spaceBetween organizer">
                    <div className="popUpTitle font-size-24 mediumWeight clickShape">
                        {edit ? "Edit AccessLevel" : "Add New AccessLevel"}
                    </div>
                    <div className="popupCross clickShape" onClick={closeModal}>
                        <span className="icon icon-cross font-size-18 lightGrayColor"></span>
                    </div>
                </div>
                <div className="popUpContent organizer" style={{display:'grid'}}>

                        <Field name='tireName' widthClass={2} cssClasses="leftNormalMargin" component={renderSimpleInputField} label='Name'/>
                        <Field name='tireDesc' cssClasses="leftNormalMargin" component={renderTextAreaField} label='Description'/>
                        <ColorPicker color={accessLevel.color} setColor={(color)=> accessLevel.color = color} text={'access level color'} />
                        <Field name='price' widthClass={2} cssClasses="leftNormalMargin" component={renderSimpleInputField} type="number" label='Price'/>

                        <div className="organizer aggregateAction inlineView clickShape leftNormalMargin topUnitMargin" onClick={handleSubmit}>
                            <div className="normalButton inlineView blueBackground whiteColor">
                                {edit ? "Update AccessLevel" : "Add AccessLevel"}
                            </div>
                        </div>
                        {formResultMessage ? <div className={classNames("topNormalMargin" , {'redColor' : !formResultMessage.success , 'greenColor' : formResultMessage.success})}>{formResultMessage.message}</div> : null}

                </div>
            </div>
        </div>
    ) ;
};

const mapStateToProps = state => {
    return {
        
    };
};

const form = connect(mapStateToProps , {change})(PlanForm);

const ReduxPlanForm = reduxForm({
    // a unique name for the form
    form: 'palnForm',
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    enableReinitialize : true ,
    validate
})(form);

export default withRouter(withTranslation()(ReduxPlanForm));