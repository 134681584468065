import {GET , POST , PUT , POST_FORM} from "../common/Action";
import Action from "../common/Action";
//actions
export const getAllUsersMessagesAction = new Action('GET_ALL_USERS_MESSAGES' , GET , 'api/messages/GetUsersMessages') ;
export const sendMessageAction = new Action('SEND_MESSAGE' , POST , 'api/messages/Send') ;
export const getUserMessagesAction = new Action('GET_USER_MESSAGES' , GET , (params) => `api/messages/GetUserMessages?userId=${params.userId}`) ;
export const readAllAction = new Action('READ_ALL' , GET , 'api/messages/ReadAll') ;
export const deleteMessageAction = new Action('DELETE_MESSAGE' , GET , 'api/messages/Delete') ;
export const clearUsersAction = new Action('CLEAR_USERS') ;
//action creators

export const getAllUsersMessages = () => {
  return getAllUsersMessagesAction.callApi() ;
};
export const sendMessage = (message) => {
  return sendMessageAction.callApi(message) ;
};
export const getUserMessages = (userId) => {
  return getUserMessagesAction.callApi(null , {userId}) ;
};
export const readAll = (userId) => {
  return readAllAction.callApi(null , {userId})
};
export const deleteMessage = (messageId) => {
  return deleteMessageAction.callApi(null , {messageId}) ;
};
export const clearUsers = () => {
  return clearUsersAction.setResult({}) ;
};