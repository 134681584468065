import { updateLegalDocAction , AddLegalDocAction } from "./actions";

export default (state={} , action) => {
    switch (action.type) {
        case updateLegalDocAction.resultAction:
            return {type:'UPDATE_LEGAL_DOC',succeeded:action.payload.data.succeeded , result:action.payload.data}
        case AddLegalDocAction.resultAction:
            return {type:'ADD_LEGAL_DOC',succeeded:action.payload.data.succeeded , result:action.payload.data}
        default:
        return state;
    }
}