import React, { useState } from 'react' ;
import Dropzone from 'react-dropzone';
import _ from 'lodash' ;


const UploadFile =(props)=>{
    const [fileAccept , setFileAccept] = useState(false);
    const [file , setFile] = useState({});
    const maxSize = 1048576;

    const onDrop = (file)=>{
        if(!_.isEmpty(file)){
            setFileAccept(true);
            setFile(file);
        }else{
            setFileAccept(false);
        }
    }
    
    return(
        <form id="file-upload-form">
            <label htmlFor="file-upload">
                <Dropzone 
                    onDrop={onDrop} 
                    //accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    accept={props.acceptType}
                    minSize={0}
                    maxSize={maxSize}>
                    {({getRootProps, getInputProps, isDragActive, isDragReject, fileRejections  ,acceptedFiles}) => {
                    
                    const message = fileRejections.length > 0?fileRejections[0].errors[0].message:null;
                    
                    return (
                        props.isButton?
                        
                            <div className="createNewPlan" {...getRootProps()} style={{cursor:'pointer' , margin:'5px'}}>
                                <input {...getInputProps()} /> 
                                {props.insideBtn?
                                    props.insideBtn
                                :
                                    <span className="icon icon-add">Upload File</span>
                                }
                            </div>
                        
                        :
                        <div style={{border:'3px' }}>
                            <div style={{ borderRadius:'0' , borderTop:'none'}} className="uploader uploadDiv inputBox">
                                <div id="start" {...getRootProps()}>
                                    <i className="fa fa-download" aria-hidden="true"></i>
                                    <p className='blueColor font-size-20'>
                                        {!isDragActive && 'Click here or drop a file to upload!'}
                                        {isDragActive && !isDragReject && "Drop it"}</p>
                                        {acceptedFiles.length > 0?
                                    <p className='greenColor font-size-20 topNormalMargin'>
                                        {acceptedFiles.length > 0 &&`file ${acceptedFiles[0].name} uploaded!`}</p>:
                                    <p className='redColor font-size-20 topNormalMargin'>
                                        {message}
                                        {isDragReject && "File type not accepted, sorry!"}</p>}
                                    <input {...getInputProps()} />   
                                </div>
                            </div>
                            <button disabled={!fileAccept} className="normalButton inlineView blueBackground grayOutline whiteColor topNormalMargin" onClick={()=>props.onSubmit(file)}>
                                Submit
                            </button>
                        </div>
                    )}}
                </Dropzone>
                {/* <DragDrop
                    width="100%"
                    height="100%"
                    note="Images up to 200×200px"
                    // assuming `this.uppy` contains an Uppy instance:
                    uppy={uppyState}
                    locale={{
                    strings: {
                    // Text to show on the droppable area.
                    // `%{browse}` is replaced with a link that opens the system file selection dialog.
                        dropHereOr: 'Drop here or %{browse}',
                        // Used as the label for the link that opens the system file selection dialog.
                        browse: 'browse',
                    },
                    }}
                /> */}
            </label>
        </form>
    )
}


  
  export default UploadFile;
