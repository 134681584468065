import React, { useEffect } from 'react'
import { CkInput, RadioInputComponent, renderTextEditor, scrollToFirstError, SelectInput , SimpleInput } from '../components/formFields' ;
import {Fragment} from 'react'
import {Field, formValueSelector , change, reduxForm} from 'redux-form'
import FileUploader from "../components/FileUploaderNew" 
import { useTranslation, withTranslation } from 'react-i18next';
import { useState } from 'react';
import classNames from 'classnames';
import {GetTermsAndConditionsTemplates} from './actions'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import FileComponent from '../components/FileComponent'
import _ from 'lodash';
import RichTextEditor from '../components/RichTextEditor'
// {
//     closeModal, submitForm, loading, setFileId,
// }

const validation = (values) => {
    const errors = {};
    if (!values.type){
        errors.type = 'Choose type!'
    }else if (values.type == 'Text'){
        if(!values.text){
            errors.text = "Terms And conditions text is required!"
        }
    }
    if(!values.versionName){
        errors.versionName = "Version is required!"
    }
    if(!values.title){
        errors.title ="Title is required!"
    }
    return errors;
  };


const NewTermsAndConditionForm = (props)=>{
    const {t} = useTranslation();
    const {templates ,change , templateSelector} = props;
    const [fieldList , setFieldList] = useState([])
    const [file , setFile] = useState(null)
    const [loading , setLoading] = useState(false)
    const [selectedTemplate , setSelectedTemplate] = useState(null)
    const [text , setText] = useState("")

    const onFileUploaded = (newFiles) => {
        setFile(newFiles[0])
        props.setFileId(newFiles[0].savedFileId)
        change('NewTermsAndConditionForm', 'type', 'File')
    };

    useEffect(()=>{
        props.GetTermsAndConditionsTemplates()
    },[])


    useEffect(()=>{
        if(!templates || _.isEmpty(templates))
            return 
    },[templates])


    useEffect(()=>{
        if(templateSelector && templates && !_.isEmpty(templates)){
            // console.log('text' , templates.filter(tmp => tmp.id == templateSelector.value)[0].text)
            setText(templates.filter(tmp => tmp.id == templateSelector.value)[0].text)
            setSelectedTemplate(templates.filter(tmp => tmp.id == templateSelector.value)[0])
            change('NewTermsAndConditionForm' , 'type' , 'Text')   
            // change('NewTermsAndConsitionForm', 'text' , templates.filter(tmp => tmp.id == templateSelector.value)[0].text)
        }
    },[templateSelector])

    useEffect(()=>{
        props.setTermsAndConditionsText(text)
    },[text])

    const deleteFile = () =>{
        setFile(null)
        props.setFileId(null)
    }

    return (
        <form>
            <div className='flex flex-row'>
                {!props.fromEdit?
                <Field name='title' label="Terms And Conditions Title" component={SimpleInput} cssClasses='w-4/5'/>
                :null}
                <Field name='versionName' label="Version name" component={SimpleInput} cssClasses='w-4/5'/>
            </div>
            <Field name='type' cssClasses="text-l font-extrabold pt-3" component={RadioInputComponent} type='radio' value='Text' label={t("Terms & conditions Text")}/>
            <div className='flex px-4'>
                <div className='w-4/5'></div>
                <div className='w-2/5 pb-2'>
                    <Field name='template' options={templates && !_.isEmpty(templates)? templates.map(tmp =>({value:tmp.id , id:tmp.id , label:tmp.title})):[]} component={SelectInput} label="Choose From VoxDash Template"/>
                </div>
            </div>
            <RichTextEditor
                setContentChange = {setSelectedTemplate}
                contentChange={selectedTemplate}
                onChangeEditor={(evt) => 
                    setText(evt)
                }
                valueObj={text}
            />
            {/* <Field cssClasses="pl-3" component={CkInput} setLoading={setLoading} initialValue={selectedTemplate? selectedTemplate.text:""} name='text' label={t('Terms and conditions related to this dataset viewers. (Leave empty if you don not want to impose any terms on this dataset viewers)')} /> */}
            <Field name='type' cssClasses="text-l font-extrabold  pt-3" component={RadioInputComponent} type='radio' value='File' label={t("Upload Your Terms & conditions File")}/>
            <div className='pl-3 pt-3'>
                <FileUploader
                    storageType={0}
                    name="file"
                    title={t("Darg File(s) Here Or Browse")}
                    onFileUploaded={(list) => onFileUploaded(list)}
                    uploadedFiles={[]}
                /> 
                {file?
                    <div className='topUnitMargin col-lg-12' style={{paddingLeft: 0}}>
                        <FileComponent fileName={file.name} fileFormat={file.type} fileSize={file.size} deleteFunc={()=>deleteFile(file)}/>
                    </div>
                :null}
            </div>
            <div className="aggregateAction inlineView rightFloat">
                <div className="normalButton inlineView grayBackground whiteColor clickShape topNormalMargin" onClick={props.closeModal}>
                    Cancel
                </div>
                <div className={classNames("normalButton inlineView whiteColor topNormalMargin leftNormalMargin" , {"blueBackground clickShape" : !props.loading , "grayBackground" : props.loading})} onClick={props.handleSubmit}>
                    Create
                </div>
            </div>           
        </form>
    )
}




const selector=formValueSelector('NewTermsAndConditionForm'); // <-- same as form name
const mapStateToProps = (state , ownProps) => {
    const templateSelector = selector(state ,'template');
    return {
        templates: state.TermsAndConditionsAllTemplates,
        templateSelector,
    }
};

const form = connect(mapStateToProps , {change,GetTermsAndConditionsTemplates})(withRouter(withTranslation()(NewTermsAndConditionForm)));

const ReduxNewTermsAndConditionsForm = reduxForm({
    // a unique name for the form
    form: 'NewTermsAndConditionForm', 
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    enableReinitialize : true ,
    onSubmitFail:  (errors) => scrollToFirstError(errors),
    validate:validation
})(form);

export default withRouter(withTranslation()(ReduxNewTermsAndConditionsForm)) ;