import React, {useState , useEffect , Fragment} from 'react' ;
import {connect} from 'react-redux' ;
import classNames from 'classnames' ;
import {renderTextAreaField , renderDropdown, renderSimpleInputField} from "../components/formFields";
import { Field, reduxForm} from 'redux-form';
import _ from 'lodash' ;
import FileUploader from '../components/ButtonFileUploader';

const validate = (values) => {
    let errors = {};
    
    if (!values.name) {
        errors.name = 'Name is Required' ;
    }
    
    return errors ;
};


const FormModal =(props)=>{
    const {handleSubmit ,title , message , setMessage , closeModal , onFileUploaded , token} = props;
    const [display , setDisplay] = useState(false)
    
    return(
        <div className="popup profiles">
            <div className="popupClose" onClick={()=>closeModal()}></div>
            <div className="popUpBox">
                <div className="popupHeader spaceBetween organizer">
                    <div className="popUpTitle font-size-24 mediumWeight">
                        {title}
                    </div>
                    <div className="popupCross clickShape" onClick={()=>closeModal()}>
                        <span className="icon icon-cross font-size-18 lightGrayColor"></span>
                    </div>
                </div>
                <div className="popUpContent organizer">
                    <Fragment>
                        <div className="inputBox">
                            <Field name='name'cssClasses="inputBox" component={renderSimpleInputField} widthClass={2} type="text"  label='Name' />
                        </div>
                        <div className="inputBox">
                            <Field name='description'cssClasses="inputBox" component={renderTextAreaField} label='Description' placeholder="Enter description for selected topic ..."/>
                        </div>
                        <div onClick={()=> setDisplay(true)} className="clickShape">
                            Change logo
                        </div>
                        <FileUploader storageType={0} display={display} setDisplay={setDisplay} setMessage ={setMessage}/> 
                        <div className="aggregateAction inlineView rightFloat">
                            <div className="normalButton inlineView redBackground whiteColor clickShape topNormalMargin" onClick={closeModal}>
                                Cancel
                            </div>
                            <div className={"normalButton inlineView whiteColor topNormalMargin leftNormalMargin greenBackground clickShape " } onClick={handleSubmit}>
                                Confirm
                            </div>
                        </div>
                    </Fragment>
                </div>
            </div>
        </div>
    ) ;
}
const mapStateToProps = (state , ownProps) => {
    return {
        token : state.token
    };
  };
  const form = connect(mapStateToProps , {})(FormModal);

  const GeoReduxForm = reduxForm({
      // a unique name for the form
      form: 'FormModal', 
      destroyOnUnmount: false, // <------ preserve form data
      forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
      enableReinitialize : true ,
      validate,
  })(form);
  
  export default GeoReduxForm;