import { useEffect, useState } from 'react';
import authService from './AuthorizeService';

function UseToken(){
    const [token, setToken] = useState(null)

    useEffect(() => {
        async function getToken(){
            var accessToken = await authService.getAccessToken();
            setToken(accessToken)
        }

        getToken();
    }, [])

    return token;
}

export default UseToken;