import React, { Fragment, useEffect } from 'react'
import { Field, reduxForm } from 'redux-form'
import {connect} from "react-redux";
import {
    renderSimpleInputField,
    renderTextAreaField,
    renderDropdown
} from "../components/formFields";
import {languagesOptions} from "../customPages/mainPage";
import _, { result } from 'lodash' ;
import classNames from "classnames";

const validate = (values) => {
    let errors = {};
    if (!values.language || _.isEmpty(values.language)) {
        errors.language = 'Language is Required' ;
    }
    // if (!values.coverId || values.coverId === '') {
    //     errors.coverId = 'Topic Cover is Required' ;
    // }
    if (!values.label || values.label === '') {
        errors.label = 'Label is Required' ;
    }
    // if (!values.description || values.description === '') {
    //     errors.description = 'Description is Required' ;
    // }
    // if (!values.metaTitle || values.metaTitle === '') {
    //     errors.metaTitle = 'Meta Title is Required' ;
    // }
    // if (!values.metaDescription || values.metaDescription === '') {
    //     errors.metaDescription = 'Meta Description is Required' ;
    // }
    //

  return errors ;
};

let TopicsForm = props => {
    const { handleSubmit , edit , deleteTopic , resultMessage } = props;

    return (
        <Fragment>
            <div className="addTopicContainer organizer">

                <form className="mainInfo organizer">
                    <Field name="language" component={renderDropdown} options={languagesOptions(props.languages)} widthClass={2} type="text" label="Language" />
                    <Field name="label" component={renderSimpleInputField} widthClass={2} type="text" label="Label"/>
                    <Field name="description" component={renderTextAreaField} label="Description" placeholder="Enter description for selected topic ..."/>
                </form>
                
                {/* <form className="SeoFields organizer topNormalMargin">
                    <div className="formTopic font-size-24 mediumWeight">SEO Fields</div>
                    <div className="organizer topUnitMargin">
                        <Field name="metaTitle" component={renderSimpleInputField} type="text" label="metaTitle"/>
                    </div>
                    <Field name="metaDescription" component={renderTextAreaField} label="Meta Description" placeholder=""/>
                </form> */}
                <div className="organizer topUnitMargin">
                    <div className="normalButton inlineView blueBackground whiteColor clickShape" onClick={handleSubmit}>
                        {edit ? 'Save Changes' : 'Add'}
                    </div>
                    {edit ? 
                        <div className="normalButton inlineView grayOutline redColor leftNormalMargin clickShape" onClick={deleteTopic}>
                            Delete Topic
                        </div> 
                    : null}
                    {resultMessage ?
                        <div className={classNames("topNormalMargin" , {'redColor' : !resultMessage.success , 'greenColor' : resultMessage.success})}>{resultMessage.message}</div>
                        : null}
                </div>
            </div>
        </Fragment>
    ) ;
};

const mapStateToProps = state => ({
    languages : state.languages
});

const form = connect(mapStateToProps)(TopicsForm);

const TopicInfoForm = reduxForm({
    // a unique name for the form
    form: 'topicForm',
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    enableReinitialize : true ,
    validate
})(form);

export default TopicInfoForm;