import React, { Fragment, useEffect, useState } from "react";
import Uppy from "@uppy/core";
import Tus from "@uppy/tus";
import { DashboardModal, useUppy } from "@uppy/react";
import ImageEditor from '@uppy/image-editor'
import { useTranslation } from "react-i18next";
import API from '../api-routes'
import UseToken from "../api-authorization/UseToken";

const LogoUploader = (props) => {

  
  const [open, setOpen] = useState(false)
  const {t} = useTranslation()
  const token = UseToken()
  
  let uppy = new Uppy({
      allowMultipleUploadBatches: false,
      restrictions: {
        allowedFileTypes: ['image/*'],
        maxNumberOfFiles: 1
      },
      meta:{
        storageType:1,
        session:randomTextGenerator(10)
      }
    })
      .use(Tus, {
        endpoint: API.tus,
        overridePatchMethod: true,
        chunkSize: 5000000,
        // withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        
      })
      .use(ImageEditor, {
        quality: 0.8,
        cropperOptions: {
          viewMode: 1,
          background: false,
          autoCropArea: 1,
          responsive: true,
          croppedCanvasOptions: {},
        },
        actions: {
          revert: true,
          rotate: true,
          granularRotate: true,
          flip: true,
          zoomIn: true,
          zoomOut: true,
          cropSquare: true,
          cropWidescreen: true,
          cropWidescreenVertical: true,
        },
      })
      
      .on("complete", (file) => {
        if(file.successful && file.successful.length > 0){
          const url = file.successful[0].uploadURL;
          const idStr = url.slice(url.lastIndexOf('/')+1);
          props.setUploadedFile(file.successful[0].preview)
          props.onChange(idStr)
        }
      });

  return (
      <Fragment>
        <div className="createNewPlan" onClick={e => setOpen(!open)}>
          <span className="icon icon-add">Upload New Banner</span>
        </div>
        <DashboardModal
          uppy={uppy}
          open={open}
          closeAfterFinish={true}
          closeModalOnClickOutside={true}
          plugins={["ImageEditor"]}
          proudlyDisplayPoweredByUppy={false}
          onRequestClose={() => setOpen(false)}
        />
        {/* {uploadedFile ? (
            <div className="uploaderWrapper">
              <img
                style={{ border: "none" }}
                src={uploadedFile}
                alt=""
              />
            </div>
          ) : null} */}
    </Fragment>
  );
};


export default LogoUploader;

