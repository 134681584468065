import React , {useState , useEffect , Fragment} from 'react' ;
import {connect} from 'react-redux' ;
import LeftNav from '../components/left-menu' ;
import _, { result } from 'lodash' ;
import {getAllAccessLevels , editAccessLevel , deleteAccessLevel , addAccessLevel} from "./actions";
import { SketchPicker } from 'react-color';
import SimpleLoading from '../components/simpleLoading';
import DeleteModal from '../components/cmp.modal'
import ReduxPlanForm from './planForm'
import UploadFile from '../uploadableExtension/UploadFile';

const MainPage = props => {

    const {allAccessLevels , results} = props ;
    const [showColorPickerId , setShowColorPickerId] = useState(false) ;
    const [onDelteModal , setOnDeleteModel] = useState(false)
    const [onEditModal , setOnEditModel] = useState(false)
    const [isEdit , setIsEdit] = useState(false)
    const [showModal , setShowModal] = useState(false)
    const [selectedAccessLevel , setSelectedAccessLevel] = useState(null)
    const [formResultMessage , setFormResultMessage] = useState("")
    const [loading , setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        props.getAllAccessLevels() ;
    } ,[]) ;

    useEffect(()=>{
        if(!allAccessLevels || _.isEmpty(allAccessLevels))
            return
        setLoading(false)
    },[allAccessLevels])

    useEffect(()=>{
        if(!results)
            return
        setLoading(false)
        if(results.action =='DELETE'){
            if(results.succeeded){
                setLoading(true)
                props.getAllAccessLevels()
            }else{
            setFormResultMessage('Something Unexpectedly Happened ! Please Try Again Later .')
            }
        }else if(results.action=='EDIT'){
            if(results.succeeded){
                setLoading(true)
                props.getAllAccessLevels()
            }else{
                setFormResultMessage('Something Unexpectedly Happened ! Please Try Again Later .')
            }
        }else if(results.action == 'NEW'){
            if(results.succeeded){
                setLoading(true)
                props.getAllAccessLevels()
            }else{
                setFormResultMessage('Something Unexpectedly Happened ! Please Try Again Later .')
            }
        }
    },[results])

    const handleChangeComplete = (color , event) => {
        // setColor(color.hex) ;
    };

    const onEditTier = (selectedAccessLevel) =>{
        setSelectedAccessLevel(selectedAccessLevel)
        setIsEdit(true)
        setShowModal(true)
    }

    const onDeleteTier = (selectedAccessLevel) =>{
        setSelectedAccessLevel(selectedAccessLevel)
        setOnDeleteModel(true)
    }
    const onNewTire = ()=>{
        setSelectedAccessLevel(null)
        setIsEdit(false)
        setShowModal(true)
    }

    const onDeleteConfirm = () =>{
        setLoading(true)
        props.deleteAccessLevel({AccessLevelId:selectedAccessLevel.id})
        setOnDeleteModel(false)
    }

    const formSubmit = (values)=>{
        var data = {...values , price: values.price? values.price:0}
        setShowModal(false)
        setLoading(true)
        if(isEdit){
            props.editAccessLevel(data)
        }else{
            props.addAccessLevel(data)
        }
    }

    const importResources= (saveFileId) =>{
        props.importResources(saveFileId)
    }

    const exportResources = () =>{
        props.exportResources()
    }
    return (
        <Fragment>
            <div className="mainHeader organizer font-size-24 mediumWeight">
                <div className="titleItems">
                    <span className="mainTitle middleContext">Access Levels</span>
                </div>
                <div className="filterItems">
                    <div onClick={onNewTire} className="createNewPlan clickShape">
                        <span className="icon icon-add "></span> New Access Level
                    </div>
                </div>
            </div>
            {loading?
                <SimpleLoading/>
            :
            <div className="adminBoilerplate organizer">
                <div className="planCarts organizer">
                    {!_.isEmpty(allAccessLevels) ? _.map(allAccessLevels , (plan , index) => {
                        return (
                            <div key={plan.id} className="planCart">
                                <div className="normalPadding organizer" style={{height:'180px'}}>
                                    <div className="planTitle font-size-20 boldWeight organizer">{plan.tireName}</div>
                                    <p className="planDescription lightWeight font-size-12 lightGrayColor organizer">
                                        {plan.tireDesc}
                                    </p>
                                    <div className="planAccColor organizer inlineFlex">
                                        <div className="accColorTitle mediumWeight">
                                            {/* <span className="icon icon-upgrade"></span> */}
                                            Color:
                                        </div>
                                        <div className="accColorTitle inlineFlex">
                                            <div className="colorPicker clickShape" style={{backgroundColor : plan.color?plan.color:'#f5f4f4' , borderColor : plan.color?plan.color:'#f5f4f4'}}></div>
                                            <div className="colorString" style={{color : plan.color?plan.color:'#f5f4f4'}}>{plan.color?plan.color:'#f5f4f4'}</div>
                                        </div>
                                    </div>
                                    {plan.price?
                                    <div className="planAccColor organizer inlineFlex">
                                        <div className="accColorTitle mediumWeight">
                                            Price:
                                        </div>
                                        <div className="accColorTitle mediumWeight inlineFlex">
                                            <div className="colorString">{plan.price}</div>
                                        </div>
                                    </div>
                                    :null}
                                    {/*{showColorPickerId === plan.id ? <SketchPicker color={color} onChangeComplete={handleChangeComplete}/> : null}*/}
                                    <div className="planFeatures organizer tempDisable">
                                        <div className="organizer">
                                            <div className="tagItem">
                                                <div className="planFeatureTitle mediumWeight organizer">Features</div>
                                                <ul className="topUnitMargin organizer">
                                                    <li>
                                                        Surveys Below 2010
                                                    </li>
                                                    <li>
                                                        My Folder
                                                    </li>
                                                    <li>
                                                        Questions Without Files
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="usersAssigned spaceBetween organizer" style={{backgroundColor: plan.color?plan.color:'#f5f4f4' , opacity : '0.7'}}>
                                    <div className="userCounter">
                                        <span className="icon icon-user font-size-40 leftFloat" style={{color : 'black'}}></span>
                                        <span className="userNumber mediumWeight leftFloat">
                                            {plan.userCount ? plan.userCount : 'No'}
                                            <p className="font-size-12 lightWeight">Users</p>
                                        </span>
                                    </div>
                                    <span className="icon icon-right-sign-arrow clickShape" style={{color : 'black'}}></span>
                                </div>
                                <div className="planActions spaceBetween organizer" style={{height:'70px'}}>
                                    <div className="editTier purpleColor clickShape" onClick={()=>onEditTier(plan)}>
                                        <span className="icon icon-edit font-size-14"></span> Edit
                                    </div>
                                    <div onClick={()=>onDeleteTier(plan)} className="editTier purpleColor clickShape">
                                        <span className="deleteRow clickShape">Delete</span>
                                    </div>
                                </div>
                            </div>
                        );
                    } ) : <SimpleLoading />}
                </div>
            </div>
            }
            {onDelteModal && selectedAccessLevel? 
            <DeleteModal
                closeModal={() => setOnDeleteModel(false)}
                acceptAction={onDeleteConfirm}
                title="Delete AccessLevel" modalMessage={`Are you sure you want to delete ${selectedAccessLevel.tireName} `}
            /> : null}
            {showModal? 
                <ReduxPlanForm formResultMessage={formResultMessage} initialValues={isEdit ? selectedAccessLevel : {}} onSubmit={formSubmit} edit={isEdit} closeModal={() => setShowModal(false)}/> 
            :null}
        </Fragment>
  ) ;
};

const mapStateToProps = (state , ownProps) => {
    return {
        allAccessLevels : state.allAccessLevels,
        results: state.accessLevelAllResults
    } ;
};

export default connect(mapStateToProps , {getAllAccessLevels , deleteAccessLevel , addAccessLevel , editAccessLevel})(MainPage) ;