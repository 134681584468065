import React , {useState , useEffect , Fragment} from 'react' ;
import {connect} from 'react-redux' ;
import LeftNav from '../components/left-menu' ;

const MainPage = props => {
  return (
      <Fragment>
              <div className="mainHeader organizer font-size-24 mediumWeight">
                  <div className="titleItems">
                <span className="mainTitle middleContext">
                    Search History
                    <span className="icon icon-right-direction font-size-14"></span>
                    <span className="font-size-18 mediumWeight">Mohammad Pourskandar</span>
                </span>
                      <div className="visitUser blueColor font-size-14 inlineView"><span
                          className="icon icon-visit"></span> Visit
                          User
                      </div>
                  </div>
                  <div className="filterItems">
                      <div className="filters inlineView">
                          <div className="changeDate inlineView"></div>
                          <div className="accessLevel inlineView"></div>
                      </div>
                      <span className="icon icon-zoom font-size-18"></span>
                  </div>
              </div>
              <div className="adminBoilerplate organizer">
                  <div className="userInnerBox organizer">
                      <div className="innerTopShelf organizer">
                          <div className="innerUserId leftFloat">
                              <div className="innerTitle font-size-18 mediumWeight">User ID</div>
                              <div className="innerContext font-size-14 topUnitMargin">009823</div>
                          </div>
                          <div className="innerEmailWider leftFloat">
                              <div className="innerTitle font-size-18 mediumWeight">Email</div>
                              <div className="innerContext font-size-14 topUnitMargin">mohammadpourskandar@gmail.com
                              </div>
                          </div>
                          <div className="innerIp leftFloat">
                              <div className="innerTitle font-size-18 mediumWeight">IP Address</div>
                              <div className="innerContext font-size-14 topUnitMargin">134.19.177.3</div>
                          </div>
                          <div className="innerAccessLevel leftFloat">
                              <div className="innerTitle font-size-18 mediumWeight">Access Level</div>
                              <div className="innerContext font-size-14 topUnitMargin mediumWeight"
                                   style={{color: "#FAA652"}}>
                                  Unlimited +
                              </div>
                          </div>
                          <div className="innerSessionSingle">
                              <div className="innerTitle font-size-18 mediumWeight">

                                  <div className="startSession">
                                      Session Date
                                  </div>
                              </div>
                              <div className="innerContext font-size-14 topUnitMargin">
                                  <div className="startSession mediumWeight">
                                      07 Sep, 2019
                                      <p className="font-size-12 lightWeight">09:12:43</p>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="innerMiddleShelf organizer">
                          <div className="innerInclude leftFloat">
                              <div className="innerTitle font-size-18 mediumWeight">Include</div>
                              <div className="innerContext font-size-14 topUnitMargin">racism in USA</div>
                          </div>
                          <div className="innerExclude leftFloat">
                              <div className="innerTitle font-size-18 mediumWeight">Exclude</div>
                              <div className="innerContext font-size-14 topUnitMargin">Politics Japan World</div>
                          </div>
                          <div className="innerCoverage leftFloat">
                              <div className="innerTitle font-size-18 mediumWeight">Coverage</div>
                              <div className="innerContext font-size-14 topUnitMargin">National</div>
                          </div>
                          <div className="innerSubTopics leftFloat">
                              <div className="innerTitle font-size-18 mediumWeight">Sub Topics</div>
                              <div className="innerContext font-size-14 topUnitMargin">Countries, Human Rights and
                                  Democracy
                              </div>
                          </div>
                          <div className="innerDates leftFloat">
                              <div className="innerTitle font-size-18 mediumWeight">Dates</div>
                              <div className="innerContext font-size-14 topUnitMargin">2012, 2015, 2019</div>
                          </div>
                      </div>
                      <div className="innerBottomShelf organizer">
                          <div className="innerQuestionAccessedId innerQuestionId">

                              <div className="innerTitle font-size-18 mediumWeight">1st Question Accessed ID</div>
                              <div className="innerContext font-size-14 topUnitMargin">009823</div>
                          </div>
                          <div className="innerQuestionAccessedTitle innerQuestionTitle">
                              <div className="innerTitle font-size-18 mediumWeight">1st Question Accessed Title</div>
                              <div className="innerContext font-size-14 topUnitMargin">In view of the general economic
                                  situation,
                                  do you think that now it is the right moment for people to make major purchases such
                                  as
                                  furniture, electrical/electronic devices, etc.?
                              </div>
                              <div className="visitQuestion blueColor font-size-14 topUnitMargin inlineView">
                                  <span className="icon icon-visit"></span> Visit Question
                              </div>
                          </div>
                          <div className="innerSurveyAccessedId innerSurveyId">
                              <div className="innerTitle font-size-18 mediumWeight">1st Survey Accessed Id</div>
                              <div className="innerContext font-size-14 topUnitMargin">009823</div>
                          </div>
                          <div className="innerSurveyAccessedTitle innerSurveyTitle">
                              <div className="innerTitle font-size-18 mediumWeight">1st Survey Accessed Title</div>
                              <div className="innerContext font-size-14 topUnitMargin">IranPoll ICCI SPSS wave2</div>
                              <div className="visitQuestion blueColor font-size-14 topUnitMargin inlineView">
                                  <span className="icon icon-visit"></span> Visit Survey
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="visitInfo organizer topNormalMargin">
                      <div className="halfColumn">

                          <div className="innerTitle font-size-18 mediumWeight">Last Visited URL</div>
                          <div className="innerContext font-size-14 topUnitMargin">
                              http://voxdash.northcentralus.cloudapp.azure.com/surveys
                          </div>

                          <div className="visitQuestion blueColor font-size-14 topUnitMargin inlineView">
                              <span className="icon icon-visit"></span> Visit URL
                          </div>
                      </div>
                      <div className="halfColumn">

                          <div className="innerTitle font-size-18 mediumWeight">Search Query</div>
                          <div className="innerContext font-size-14 topUnitMargin">
                              http://voxdash.northcentralus.cloudapp.azure.com/results?IncludedKeyword
                              =Nuclear&ExcludeKeywords=First%20Second&Subtopics=101014_105011_103006_1
                              03007&Topics=103_105_101&Coverages=National&Dates=2005_2012
                          </div>

                          <div className="visitQuestion blueColor font-size-14 topUnitMargin inlineView">
                              <span className="icon icon-visit"></span> Visit Query
                          </div>
                      </div>
                  </div>
              </div>
          </Fragment>
  ) ;
};

const mapStateToProps = (state , ownProps) => {
    return {

    } ;
};

export default connect(mapStateToProps , {})(MainPage) ;