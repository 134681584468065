import {GET , POST , PUT , POST_FORM} from "../common/Action";
import Action from "../common/Action";


//actions
export const getAllPollsAction = new Action('GET_ALL_COMMISSION_POLLS' , POST , 'api/commission/GetAllPolls') ;
export const getPollAction = new Action('GET_POLL' , GET , 'api/commission/GetPoll') ;
export const updatePollAction = new Action('UPDATE_POLL' , POST , 'api/commission/UpdatePoll') ;
export const deletePollAction = new Action('DELETE_POLL' , GET , 'api/commission/DeletePoll') ;

//action creators
export const getAllPolls = (data) => {
  return getAllPollsAction.callApi(data) ;
};
export const getPoll = (id) => {
  return getPollAction.callApi(null , {id}) ;
};
export const updatePoll = (commissionPoll) => {
  return updatePollAction.callApi(commissionPoll) ;
};
export const deletePoll = (pollId) =>{
  return deletePollAction.callApi(null , {pollId}) ;
};