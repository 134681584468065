
import reducerGetAllPages from './reducer.getAll';
import reducerGetResults from './reducer.getResults';
import reducerMetaTagsByPage from './reducer.getMetaTagsByPage'
import reducerGetMetaTagsByCustomPageId from './reducer.getMetaTagsByCustomPageId';



export default {
   MetaTagsGetAllPages:reducerGetAllPages,
   MetaTagsGetResults:reducerGetResults,
   MetaTagsGetMetaTagsByPage: reducerMetaTagsByPage,
   MetaTagsGetMetaTagsByCustomPageId: reducerGetMetaTagsByCustomPageId
};