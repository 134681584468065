import {newExtensionAction , editExtensionAction , deleteExtensionAction , newIconAction , importRestrictedExtensionAction} from "./actions";

export default (state={} , action) => {
    switch (action.type) {
        case newExtensionAction.resultAction :
            return {...action.payload.data , action : 'NEW'} ;
        case newIconAction.resultAction :
            return {id:action.payload.data.key , action : 'NEW_ICON'} ;
        case editExtensionAction.resultAction :
            return {...action.payload.data , action : 'UPDATE'} ;
        case deleteExtensionAction.resultAction :
            return {...action.payload.data , action : 'DELETE'} ;
        case importRestrictedExtensionAction.resultAction :
            return {...action.payload.data , action: "IMPORT"}
        default :
            return state ;
    }
};