import {ApprovedAsyncTopicsAction , DisApprovedAsyncTopicsAction} from "./actions";

export default (state=[] , action) => {
  switch (action.type) {
      case ApprovedAsyncTopicsAction.resultAction :
            return {...action.payload.data , action : 'Approved'} ;
      case DisApprovedAsyncTopicsAction.resultAction :
            return {...action.payload.data , action : 'DisApproved'} ;
      default :
            return state ;
  }
};