import React , {useState , Fragment , useEffect } from 'react' ;
import classNames from 'classnames'

const Modal = props => {

    const {closeModal , loading ,  acceptAction , data , title , resultMessage , modalMessage} = props ;

    return (
        <div className="popup profiles">
            <div className="popupClose" onClick={closeModal}></div>
            <div className="popUpBox">
                <div className="popupHeader spaceBetween organizer">
                    <div className="popUpTitle font-size-24 mediumWeight">
                        {title}
                    </div>
                    <div className="popupCross clickShape" onClick={closeModal}>
                        <span className="icon icon-cross font-size-18 lightGrayColor"></span>
                    </div>
                </div>
                <div className="popUpContent organizer">
                    {!resultMessage || !resultMessage.success ?
                        <Fragment><div className="organizer">{modalMessage}</div>
                            <div className="aggregateAction inlineView rightFloat">
                                <div className="normalButton inlineView redBackground whiteColor clickShape topNormalMargin" onClick={closeModal}>
                                    Cancel
                                </div>
                                <div className={classNames("normalButton inlineView whiteColor topNormalMargin leftNormalMargin" , {"blueBackground clickShape" : !loading , "grayBackground" : loading})} onClick={() => acceptAction(data)}>
                                    Confirm
                                </div>
                            </div></Fragment> : null}
                    {resultMessage ?
                        <div className={classNames("" , {'redColor' : !resultMessage.success , 'greenColor' : resultMessage.success})}>{resultMessage.message}</div>
                     : null}
                </div>
            </div>
        </div>
    ) ;
};

export default Modal ;