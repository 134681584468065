import {GET , POST, POST_FILE} from "../common/Action";
import Action from "../common/Action";



//actions
export const getExtensionsAction = new Action('GET_EXTENSION_ACTION' , POST , 'api/RestrictedExtension/GetAllRestrictedExtensions') ;
export const newExtensionAction = new Action('ADD_EXTENSION_ACTION' , POST , 'api/RestrictedExtension/CreateRestrictedExtension') ;
export const editExtensionAction = new Action('EDIT_EXTENSION_ACTION' , POST , 'api/RestrictedExtension/UpdateRestrictedExtension') ;
export const deleteExtensionAction = new Action('DELETE_EXTENSION_ACTION' , POST , 'api/RestrictedExtension/DeleteRestrictedExtension');
export const newIconAction = new Action ('NEW_ICON_ACTION' , POST_FILE , 'api/photo/upload')
export const importRestrictedExtensionAction = new Action ("IMPORT_RESTRICTED_EXTENSION_ACTION" , POST , 'api/RestrictedExtension/ImportRestrictedExtension')
//action creators
export const getExtensions = (data) => {
  return getExtensionsAction.callApi(data) ;
};


export const newExtension = (uploadableExtension)=>{
  return newExtensionAction.callApi(uploadableExtension);
};

export const newIcon = (file)=>{
    return newIconAction.callApi(file);
};


export const editExtension= (uploadableExtension) => {
  return editExtensionAction.callApi(uploadableExtension) ;
};

export const deleteExtension= (data) => {
  return deleteExtensionAction.callApi(data) ;
};

export const importRestrictedExtension = (data) =>{
  return importRestrictedExtensionAction.callApi(data)
}