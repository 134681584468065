import Action, {GET, POST_FORM,POST} from "../common/Action";
import { setCookie, removeCookie } from "../util/cookie";

export const saveTokenAction = new Action('SAVE_TOKEN');
export const signOutAction = new Action('SIGN_OUT');
// export const signInAction = new Action('SIGN_IN', POST_FORM, 'Users/TokenInv', 'SIGN_IN_FAILURE');
export const signInAction = new Action('LOGIN_TOKEN_ACTION' , POST_FORM ,'api/users/token' , 'LOGIN_TOKEN_FAILURE');

export const saveToken = (tokenInfo, isNew) => saveTokenAction.setResult({data: {...tokenInfo, isNew}});

export const setSignOut = (isSignOut) => signOutAction.setResult({data: isSignOut});

export const signIn = (user) => {
    let params = new URLSearchParams();
    params.append('grant_type', 'password');
    params.append('username', user.userName);
    params.append('password', user.password);
    params.append('client_id', 'webapp');

    return signInAction.callApi(params) ;
    // return post('/token', params, (data) =>
    // {
    //     setCookie('access_token', data.access_token)
    //     setCookie('refresh_token', data.refresh_token)
    //     return [action(LOGIN, { success: true, data }), action(CREDENTIALS, data)]
    // }, (error) =>
    //     action(LOGIN, { success: false, error }))
};
