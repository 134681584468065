import {newGeographicRegionAction , editGeographicRegionAction , deleteGeographicRegionAction} from "./actions";

export default (state={} , action) => {
    switch (action.type) {
        case newGeographicRegionAction.resultAction :
            return {id:action.payload.data ,succeeded:action.payload.data.succeeded , action : 'NEW'} ;
        case editGeographicRegionAction.resultAction :
            return {...action.payload.data , succeeded:action.payload.data.succeeded , action : 'EDIT'} ;
        case deleteGeographicRegionAction.resultAction :
            return {...action.payload.data , succeeded:action.payload.data.succeeded , action : 'DELETE'} ;
        default :
            return state ;
    }
};