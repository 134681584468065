import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux'
import createStore from './features/common/store'
import {BrowserRouter as Router} from "react-router-dom";
import config from './features/i18n'
import { I18nextProvider } from 'react-i18next';
import './index.css'
const store = createStore();

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <I18nextProvider i18n={config}><App /></I18nextProvider>
        </Router>
    </Provider>, document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.unregister();