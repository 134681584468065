import React , {useState , useEffect} from 'react' ;
import userDebounce from './use-debounce' ;

const SimpleLoading = props => {

    const [dots , setDots] = useState('Loading .') ;

    const loadingCount = userDebounce(dots , 200) ;
    useEffect(() => {
        if (dots === 'Loading .') {
            setDots('Loading ..') ;
        }
        else if (dots === 'Loading ..') {
            setDots('Loading ...') ;
        }
        else {
            setDots('Loading .') ;
        }
    } , [loadingCount]) ;

    return (
        <div className="organizer textInCenter">{loadingCount}</div>
    ) ;
};


export default SimpleLoading ;