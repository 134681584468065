import React, { useEffect } from 'react'
import ReduxNewTermsAndConditionsForm from './newTermsForm'

// close modal

const NewTermsAndConditionForm = (props)=>{
    
    return (
        <form>
            <div className="popup profiles">
                <div className="popupClose" onClick={props.closeModal}></div>
                <div className="popUpBox" style={{width:'90%'}}>
                    <div className="popupHeader spaceBetween organizer">
                        <div className="popUpTitle font-size-24 mediumWeight">
                            Add New Terms & conditions
                        </div>
                        <div className="popupCross clickShape" onClick={props.closeModal}>
                            <span className="icon icon-cross font-size-18 lightGrayColor"></span>
                        </div>
                    </div>
                    <div className="popUpContent organizer" style={{maxHeight:'550px' , overflowY:'scroll'}}>
                        <ReduxNewTermsAndConditionsForm
                            fromEdit={false}
                            closeModal={props.closeModal} 
                            onSubmit={props.onSubmit}
                            loading = {props.loading}
                            setFileId = {props.setFileId}
                            setTermsAndConditionsText={props.setTermsAndConditionsText}
                        />
                    </div>
                    <div className="btnContainer">
                        <button
                        // style={{marginRight : "10px"}}
                            type="button"
                            className="custom-button "
                            // onClick={() => setOpen(false)}
                        >
                            Deactivate
                        </button>
                        <button
                            type="button"
                            className="Cancel-button "
                            // onClick={() => setOpen(false)}
                            // ref={cancelButtonRef}
                        >
                            Cancel
                        </button>
                        </div>
                </div>
            </div>
        </form>
    )
}

export default NewTermsAndConditionForm;