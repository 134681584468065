import React , {useState , useEffect} from 'react'
import { Field, reduxForm } from 'redux-form'
import {connect} from "react-redux";
import { renderSimpleInputField , renderTextAreaField , renderDropdown , renderTextEditor } from "../components/formFields";
import _ from 'lodash' ;
import classNames from 'classnames' ;
import {languagesOptions} from "./mainPage";
import { getCustomPageMetaTags } from '../MetaTags/actions';
import DetailMetaTag from '../MetaTags/metaTags'

const validate = (values) => {
    let errors = {};

    if (!values.language || _.isEmpty(values.language)) {
        errors.language = 'Select a Language.' ;
    }
    if (!values.pageTitle) {
        errors.pageTitle = "Enter the Page Title." ;
    }
    if (!values.pageDescription) {
        errors.pageDescription = 'Enter the Page Description.'
    }
    if (!values.uniqueName) {
        errors.uniqueName = 'Enter the Page unique name.'
    }
    if (!values.content) {
        errors.content = 'Enter the Page Content.'
    }
    return errors ;
};

let CustomPage = props => {
    const { handleSubmit , edit , deleteCustomPage , resultMessage , languages , loading , allMetaTags} = props;
    const [metaTags , setMetaTags] = useState({})
    const [selectedId , setSelectedId] = useState(edit? props.initialValues.id:0)
    const [showMetaTagModal , setShowMetaTagModal] = useState(false)
    const [submitLoading , setSubmitLoading] = useState(false)
    const [errorMessage , setErrorMessage] = useState("")
    const [reset , setReset] =useState(!edit)

    const updateMetaTags = (values) =>{
        var data = []
        values.forEach((value, index) => {
            if(value != null && value !=''){
                data.push(
                    {
                        ...metaTags[selectedId].filter(m => m.keyId == index)[0], 
                        value : value
                    }
                )
            } 
        });
        props.updateMetaTagsFunction(data)
        setShowMetaTagModal(false)
    }

    useEffect(()=>{
        props.getCustomPageMetaTags({pageId:edit?props.initialValues.id:0})
        setSelectedId(edit?props.initialValues.id:0)
        setReset(!edit)
    },[edit])


    useEffect(()=>{
        if(!allMetaTags)
            return 
        if(allMetaTags && allMetaTags.pageId!= null){
            setMetaTags({...metaTags , [allMetaTags.pageId]:allMetaTags.data})
        }
    },[allMetaTags])


    return (
        <div className="columnSide threeQuarterColumn normalPadding">

            <div className="organizer">
                <Field name="language" widthClass={1} component={renderDropdown} label="Language" options={languagesOptions(languages)}/>
            </div>

            <div className="mainInfo organizer topNormalMargin">
                <div className="formTopic font-size-24 mediumWeight">Content</div>
                <div className="organizer topUnitMargin">
                    <Field name="pageTitle" widthClass={2} component={renderSimpleInputField} label="Page Title"/>
                </div>
                <div className="organizer topUnitMargin">
                    <Field name="uniqueName" widthClass={2} component={renderSimpleInputField} label="Page Unique name"/>
                </div>
                <div className="organizer topUnitMargin">
                    <Field name="pageDescription" component={renderTextAreaField} label="Description"/>
                </div>
                <div className="organizer">
                    <Field name="content" component={renderTextEditor} label="Content" reset={reset} setReset={setReset}/>
                </div>
            </div>


            <div className="SeoFields organizer topNormalMargin">
                <div className="normalButton inlineView grayOutline greenColor clickShape" onClick={()=> setShowMetaTagModal(true)}>
                    Setup meta tags
                </div>
            </div>
            {showMetaTagModal && metaTags[selectedId]?
                <DetailMetaTag 
                    initialValues={
                        _.range(metaTags[selectedId].length +1).map(m => 
                            !_.isEmpty(metaTags[selectedId].filter(meta => meta.keyId == m))?  metaTags[selectedId].filter(meta => meta.keyId == m)[0].value:null)
                    }
                    errorMessage={errorMessage} loading={submitLoading} 
                    metaTags={metaTags[selectedId]} onSubmit={updateMetaTags} 
                    closeModal={()=>setShowMetaTagModal(false)}
                />
            :null}

            <div className="organizer topUnitMargin">
                <div className={classNames("normalButton inlineView whiteColor" , {"blueBackground clickShape" : !loading , 'grayBackground' : loading})} onClick={handleSubmit}>
                    {edit ? `Save Changes` : `Add Page`}
                </div>
                {edit ? <div className="normalButton inlineView grayOutline redColor leftNormalMargin clickShape" onClick={deleteCustomPage}>
                    Delete Page
                </div> : null}
                {resultMessage ? <div className={classNames("rightFloat topUnitMargin" , {"greenColor" : resultMessage.success , "redColor" : !resultMessage.success})}>{resultMessage.message}</div> : null}
            </div>
        </div>
    ) ;
};

const mapStateToProps = state => {
    return {
        languages : state.languages,
        allMetaTags: state.MetaTagsGetMetaTagsByCustomPageId
    };
};

const form = connect(mapStateToProps , {getCustomPageMetaTags})(CustomPage);

const CustomPageForm = reduxForm({
    // a unique name for the form
    form: 'customPageForm',
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    enableReinitialize : true ,
    validate
})(form);

export default CustomPageForm;