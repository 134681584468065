import Action, { GET ,POST} from "../common/Action"


export const GetAllDefaultBannerAction = new Action('[UPLOAD_DEFAULT_BANNER] GET_ALL_DEFAULT_BANNER_ACTION' , GET , 'api/defaultBanner/GetAllDefaultBanners')
export const CreateDefaultBannerAction = new Action('[UPLOAD_DEFAULT_BANNER] CRAETE_DEFAULT_BANNER_ACTION' , POST , 'api/defaultBanner/CreateDefaultBanner')
export const DeleteDefaultBannerAction = new Action('[UPLOAD_DEFAULT_BANNER] DELETE_DEFAULT_BANNER_ACTION' , POST , params=>`api/defaultBanner/DeleteDefaultBanner?id=${params.id}`)


export const GetAllDefaultBanner = () =>{
  return GetAllDefaultBannerAction.callApi()
}

export const CreateDefaultBanner = (data) =>{
  return CreateDefaultBannerAction.callApi(data)
}

export const DeleteDefaultBanner = (id) =>{
  return DeleteDefaultBannerAction.callApi(null , {id})
}