import React , {useState , useEffect, Fragment, useDebugValue} from 'react' ;
import {connect} from 'react-redux' ;
import Pagination from "../components/pagination";
import {getMaps , addMap , downloadMap ,  getMap , updateMap } from "./actions";
import _ from 'lodash' ;
import DeleteModal from '../components/cmp.modal' ;
import classNames from 'classnames' ;
import ReducMapForm from './mapForm.js';
import {Link } from 'react-router-dom' ;
import SimpleLoading from '../components/simpleLoading';

const MainPage = props => {

  const {maps , selectedMaps , resultMessage} = props ;
  
  const [selectedId , setSelectedId] = useState(null);
  const [showModal,setShowModal] = useState(false);
  const [isNew , setIsNew] = useState(false);
  const [isDelete , setIsDelete] = useState(false);
  const [message , setMessage] = useState(null);
  const [mapContent , setMapContent] = useState(null);
   
    useEffect(() =>{
            props.getMaps();
        } , []) ;

    useEffect (()=>{
        if(_.isEmpty(maps)){
            return 
        }
        setSelectedId(_.map(maps , map => {
            return map ;
        })[0].id);
    },[maps]);

    useEffect (()=>{
        if(!selectedId){
            return 
        }
        setIsNew(false);
        if(!selectedMaps[selectedId]){
            props.getMap(selectedId)
        }
        
    },[selectedId]);
    
    useEffect(() => {
        if (_.isEmpty(resultMessage)) return;
        if (resultMessage.action === 'EDIT') {
            setMessage({success :resultMessage.success , message :resultMessage.success ? 'Edited!' : 'Something Unexpectedly Happened ! Please Try Again Later.'}) ;
            if (resultMessage.success) {
                props.getMaps();
                props.getMap(selectedId);
            }
        }
        else if (resultMessage.action === 'DELETE') {
            setMessage({success :resultMessage.success , message :resultMessage.success ? 'Deleted!' : 'Something Unexpectedly Happened ! Please Try Again Later.'}) ;
            if (resultMessage.success) {
                props.getMaps();
            }
        }
        else if (resultMessage.action === 'NEW') {
            setMessage({success :resultMessage.success , message :resultMessage.success ? 'Added!' : 'Something Unexpectedly Happened ! Please Try Again Later.'}) ;
            if (resultMessage.success) {
                props.getMaps();
                props.getMap(selectedId);
            }  
        }
    } , [resultMessage]) ;

    const onDeleteConfirm = ()=>{
        setShowModal(false);
    }
    
    const saveMap = (values)=>{
        if(isNew){
            setIsNew(false);
            props.addMap(values);
        }else{
            props.updateMap(values);
        }
    }
    
  return (
      <Fragment>
          
        <div className="mainHeader organizer font-size-24 mediumWeight">
            <div className="titleItems">
                <span className="mainTitle middleContext">Maps</span>
            </div>
            <div className="filterItems">
                <div className="createNewPlan" onClick={()=>setIsNew(true)}>
                    <span className="icon icon-add clickShape"></span>
                        New Map
                </div>
            </div>
        </div>
        <div className="adminBoilerplate organizer">
            <div className="twoSidePanel organizer noHeader">
                <div className="columnSide threeForthColumn ">
                    <table className="tableStructure organizer">
                        <thead className="tableHeader haveActive">
                            <tr>
                                <th>Name</th>
                            </tr>
                        </thead>
                        <tbody>
                        {maps? _.map(maps, (map) => {
                            return (
                                <tr key={map.id} onClick={()=>{setSelectedId(map.id)}} className={classNames("clickShape",{"active" : selectedId?selectedId== map.id:null})}>
                                    <td style={{textAlign:'left'}}>{map.name}</td>
                                    <td style={{textAlign:'right'}}>
                                        <Link to={`/maps/${map.id}`} style={{textDecoration : "none" , color:'black'}}>
                                        <div className={"normalButton inlineView grayOutline leftNormalMargin"} onClick={()=>isNew?setIsNew(false):setShowModal(true)}>
                                            Profiles
                                        </div> 
                                        </Link>
                                    </td>
                                </tr>
                            );
                        }) : null}
                        </tbody>
                    </table>
                </div>
                {isNew||(selectedMaps&&selectedId&&selectedMaps[selectedId])?
                    <ReducMapForm
                        onSubmit={saveMap}
                        initialValues={isNew?{name:"" , geoJson:""}:!_.isEmpty(selectedMaps)&&selectedMaps[selectedId] ?selectedMaps[selectedId]:{}}
                        isNew={isNew}
                        setIsNew={(value)=>setIsNew(value)}
                        setShowModal= {(value)=>setShowModal(value)}
                        resultMessage = {message}
                    />
                :
                    <div className="columnSide oneForthColumn normalPadding">
                        <SimpleLoading/>
                    </div>
                }
            </div>
            {// <Pagination pageNumber={pageNumber} viewByPage={viewByPage} changeData={updateList} totalPages={Math.ceil(mapsTotalCount/viewByPage)}/>
            }
        </div>
        
        {showModal ? <DeleteModal
                closeModal={() => setShowModal(false)}
            acceptAction={onDeleteConfirm}
            title="Delete map" modalMessage={`Are you sure you want to delete ${selectedMaps[selectedId].name} `}
            /> : null}
    </Fragment>
  ) ;
};

const mapStateToProps = (state , ownProps) => {
    return {
        maps : state.maps ,
        selectedMaps : state.selectedMaps,
        resultMessage :state.MapResultMessage
    } ;
};

export default connect(mapStateToProps , {getMaps , updateMap ,  getMap  , downloadMap , addMap })(MainPage) ;