import {GET , POST , PUT , POST_FORM} from "../common/Action";
import Action from "../common/Action";

//actions
export const addPageAction = new Action('ADD_PAGE_ACTION' , POST , 'api/content/AddPage') ;
export const getPageAction = new Action('GET_PAGE_ACTION' , GET , (params) => `api/content/GetPage?id=${params.id}`) ;
export const deletePageAction = new Action('DELETE_PAGE_ACTION' , GET , (params) => `api/content/DeletePage?id=${params.id}`) ;
export const getAllPagesAction = new Action('GET_ALL_PAGES_ACTION' , GET , 'api/content/GetAllPages') ;
export const editPageAction = new Action('EDIT_PAGE_ACTION' , POST ,  'api/content/EditPage') ;

//action creators
export const addPage = (page) => {
    return addPageAction.callApi(page) ;
};
export const getPage = (id) => {
    return getPageAction.callApi(null , {id}) ;
};
export const deletePage = (id) => {
  return deletePageAction.callApi(null , {id}) ;
};
export const  getAllPages = () => {
  return getAllPagesAction.callApi() ;
};
export const editPage = (page) => {
  return editPageAction.callApi(page) ;
};