import React , {useEffect , useState , Fragment} from 'react' ;
import _ from 'lodash' ;
import classNames from 'classnames' ;


const Pagination = props => {

    const {changeData , totalPages} = props ;
    const {pageNumber , viewByPage} = props ;

    const onChangePageParameters = (selectedPageNumber , selectedViewByPage) => {
        if (selectedPageNumber !== pageNumber && selectedPageNumber !== 0 && selectedPageNumber !== totalPages + 1) {
            changeData(selectedPageNumber , selectedViewByPage) ;
        }
        if (selectedViewByPage !== viewByPage) {
            changeData(1 , selectedViewByPage) ;
        }
    };

    const showingPageNumbers = () => {
        let showingPages = [1] ;
        if (pageNumber-1 > 0 && _.indexOf(showingPages , pageNumber-1) === -1) {
            showingPages.push(pageNumber-1) ;
        }
        if (_.indexOf(showingPages , pageNumber) === -1) {
            showingPages.push(pageNumber) ;
        }
        if (pageNumber+1 <= totalPages && _.indexOf(showingPages , pageNumber+1) === -1) {
            showingPages.push(pageNumber+1) ;
        }
        if (_.indexOf(showingPages , totalPages) === -1) {
            showingPages.push(totalPages) ;
        }
        return showingPages ;
    };

    const pageRendering = () => {
        const showingPages = showingPageNumbers() ;
        return _.map(showingPages , (page , index) => {
            return (
                <Fragment key={index}>
                    <span className={classNames("counterItem clickShape" , {"active" : page === pageNumber , "activeSide" : page === pageNumber + 1 || page === pageNumber - 1 , "clickShape" : page !== pageNumber})} onClick={() => onChangePageParameters(page , viewByPage)}>{page}</span>
                    {page !== totalPages && showingPages[index+1] > page + 1 ? <span className="lightGrayColor">...</span> : null}
                </Fragment>
            );
        })
    };

    return (
        <div className="viewNavigations organizer topNormalMargin">
            <div className="viewPerPage mediumWeight">
                <span className="counterTitle">
                    Views / Page
                </span>
                <span className={classNames("counterItem clickShape" , {"active" : viewByPage === 8 })} onClick={() => onChangePageParameters(pageNumber , 8)}>8</span>
                <span className={classNames("counterItem clickShape" , {"active" : viewByPage === 12 })} onClick={() => onChangePageParameters(pageNumber , 12)}>12</span>
                <span className={classNames("counterItem clickShape" , {"active" : viewByPage === 16 })} onClick={() => onChangePageParameters(pageNumber , 16)}>16</span>
                <span className={classNames("counterItem clickShape" , {"active" : viewByPage === 32 })} onClick={() => onChangePageParameters(pageNumber , 32)}>32</span>
            </div>
            <div className="pagination mediumWeight">
                <span className={classNames("rightNormalMargin icon icon-left-sign-arrow font-size-14 lightGrayColor middleContext" , {"clickShape" : pageNumber !== 1})} onClick={() => onChangePageParameters(pageNumber-1 , viewByPage)}></span>
                {pageRendering()}
                <span className={classNames("leftNormalMargin icon icon-right-sign-arrow font-size-14 lightGrayColor middleContext" , {"clickShape" : pageNumber !== totalPages})} onClick={() => onChangePageParameters(pageNumber+1 , viewByPage)}></span>
            </div>
        </div>
    );
};

export default Pagination ;
