import React, { useState } from 'react'
import {connect} from 'react-redux' ;
import RichTextEditor from '../components/RichTextEditor'
import classNames from 'classnames' ;
import {renderTextAreaField , renderDropdown} from "../components/formFields";
import { Field, reduxForm} from 'redux-form';
import _ from 'lodash' ;


let answerError = false;
const validate = (values) => {
    let errors = {};
    if (!values.language) {
        errors.language = 'langauge is Required' ;
    }
    if (!values.question) {
        errors.question = 'Question is Required' ;
    }
    if (_.isEmpty(values.answer)) {
        answerError= true;
        errors.answer = 'Answer is Reduired!'
    }else{
        answerError= false;
    }
    return errors ;
};



const FaqForm=(props)=>{
    const {faqFormResult , isNew , languageOptions , dropDownStyles, handleSubmit , setShowModal} = props;
    const [languageChange , setLanguageChange] = useState(false)
    const [reset , setReset] = useState(false)
    const answerField = ({ input }) => {
        return (
           <RichTextEditor
                setContentChange = {setLanguageChange}
                contentChange={languageChange}     
                onChangeEditor={(evt) => {
                input.onChange(evt)}
                }
                valueObj={input.value}
            />    
         );
      };
    return (
        <div className="columnSide normalPadding" style={{width:'50%'}}>
            {faqFormResult ? 
                <div className={classNames("topNormalMargin" , {'redColor' : !faqFormResult.success , 'greenColor' : faqFormResult.success})}>
                    {faqFormResult.message}
                </div> 
            : null}
            <div className="organizer">
            <div className="inputBox " style={{width:'50%'}}>
                <Field name='language'style={dropDownStyles} component={renderDropdown} 
                    options={_.map(languageOptions , position => {
                        return {label : position.label , value : position.value};
                    })}  label='Language'/>                    
            </div>
            {/* <div className="inputBox topUnitMargin"  style={{padding:'10px',width:'50%'}}>
                <Field name='Priority' style={dropDownStyles} component={renderDropdown} options={_.map(languageOptions.position , position => {
                    return {label : position.label , value : position.value};
                })} label='Category not in api'/>   
            </div> */}
            <div className="organizer topUnitMargin">
                <div className="inputBox">
                    <Field name='question'cssClasses="inputBox topUnitMargin" component={renderTextAreaField} label='Question' />
                </div>
            </div>
            <div className="organizer topUnitMargin">
            <div className="inputBox">
                <label >Answer</label>
                <Field name='answer'component={answerField} label='Answer' />
                {answerError?<div className="redColor font-size-12">Answer is Required</div>:null}
            </div>
            </div>
        </div>
        <div className="organizer topNormalMargin">
            <div className="normalButton inlineView blueBackground whiteColor clickShape" onClick={handleSubmit}>
                {isNew?'Add':'Save Changes'}
            </div>
            <div className="normalButton inlineView grayOutline redColor leftNormalMargin clickShape" onClick={()=>setShowModal(true)}>
                {isNew?'cancel':'Delete Question'}
            </div>
        </div>
    </div> 
    )
}

const mapStateToProps = state => {
    return {}
};
const form = connect(mapStateToProps , {})(FaqForm);

const FaqReduxForm = reduxForm({
    // a unique name for the form
    form: 'FaqForm', 
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    enableReinitialize : true ,
    validate,
})(form);

export default FaqReduxForm;