import {getPagedResourcesByLanguagesAction, getResourcesByLanguagesAction} from "./actions";
import _ from 'lodash' ;

export default (state = {} , action) => {
    switch (action.type) {
        case getResourcesByLanguagesAction.resultAction :
            return action.payload.data;
        case getPagedResourcesByLanguagesAction.resultAction :
            return {type:'PAGINATION' , ...action.payload.data};
        default :
            return state ;
    }
};