import {GET , POST , PUT , POST_FORM} from "../common/Action";
import Action from "../common/Action";

//actions
export const GetApprovableAsyncTopicAction = new Action('GET_APPROVABLE_TOPICS' , POST , 'api/topics/GetApprovable') ;
export const ApprovedAsyncTopicsAction = new Action('APPROVED_ASYNC_TOPIC_ACTION' , POST , 'api/topics/Approve');
export const DisApprovedAsyncTopicsAction = new Action('DISAPPROVED_ASYNC_TOPIC_ACTION' , POST , 'api/topics/DisApprove');


//action creators
export const GetApprovableAsyncTopic = (data) => {
  return GetApprovableAsyncTopicAction.callApi(data) ;
};

export const ApprovedAsyncTopics = (data) =>{
  return ApprovedAsyncTopicsAction.callApi(data);
}

export const DisApprovedAsyncTopics = (data) =>{
  return DisApprovedAsyncTopicsAction.callApi(data);
}