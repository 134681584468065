import classNames from 'classnames';
import React from 'react';
import Icon from './Icon';

const navigationItem=(props)=>{
    
    return (
        <div className={classNames("navigationItem")} >
            <h2 className="font-size-24 mediumWeight">{props.name}</h2>
            <ul className={props.name}>
                { props.iconList.map((icon ,index)=>{

                    if(icon.path===""){
                        return (<Icon
                            
                            key={index}
                            class={icon.class}
                            path=""
                            location={props.location} 
                            name={icon.name} 
                            onclick={props.onclick}
                            active={icon.icons.active} 
                            iconList={icon.icons.list}
                            active_id={icon.icons.active_id}/>);
                    }
                    else if(icon.name==="Messages"){
                        return (
                            <Icon alart={false} key={index} name={icon.name} class={icon.class} path={icon.path} location={props.location}/> )
                        }
                    else{

                    return (
                    <Icon key={index} name={icon.name} class={icon.class} path={icon.path} location={props.location}/> )}
                })}
                
            </ul>
        </div>
    );
}

export default navigationItem ;

/*return (
        <li className="navigationItem clickShape">
             
            <span  onClick={()=>props.onclick(props.id)} className="listText font-size-24 mediumWeight">{props.name}</span>
            <span className={classNames("pointArrow icon font-size-14 rightFloat" , {"icon-up-direction" : props.active , "icon-bottom-direction" : !props.active})}></span>
    
            {props.active ?
            <ul className="innerList">
                {props.iconList.map((icon)=>{
                    if(icon.path===""){
                        return (<Icon
                            class={icon.class}
                            path=""
                            location={props.location} 
                            name={icon.name} 
                            onclick={props.onclick}
                            active={icon.icons.active} 
                            iconList={icon.icons.list}
                            active_id={icon.icons.active_id}/>);
                    }else{
                    return (
                    <Icon name={icon.name} class={icon.class} path={icon.path} location={props.location}/> )}
                })}
            </ul> : null}
        </li>
    ); */







