import {getContentPageAction} from "./actions";

export default (state = {} , action) => {
    switch (action.type) {
        case getContentPageAction.resultAction :
            return {...state , [action.payload.data.id] :  action.payload.data} ;
        default :
            return state ;

    }
}