import {getAllFaqsAction} from "./actions";
import _ from 'lodash' ;

export default (state = {} , action) => {
    switch (action.type) {
        case getAllFaqsAction.resultAction :
            return _.mapKeys(action.payload.data , 'id') ;
        default :
            return state ;
    }
};