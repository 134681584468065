import {GetPageMetaTagsAction } from "./actions";
import _ from 'lodash'

export default (state={} , action) => {
    switch (action.type) {
        case GetPageMetaTagsAction.resultAction :
            return {data:action.payload.data, page:action.payload.postedData.page};
        default:
            return state;
    }
}