import React , {useState , useEffect} from 'react' ;
import {connect} from 'react-redux' ;
import TableComponent from '../components/TableComponent';
import {GetApprovableAsyncDataRole , ApprovedAsyncDataRoles , DisApprovedAsyncDataRoles} from "./actions";
import _ from 'lodash'
import { Fragment } from 'react';
import cssClasses from '../ApprovedVendors/ApprovedVendorListStyles.module.css'
import classNames from 'classnames';
import { Link, withRouter } from 'react-router-dom';
import SimpleLoading from '../components/simpleLoading';
import ReduxNewDataRoleForm from './NewDataRoleModal'
import { AddNewDataRole , UpdateDataRole , DeleteDataRole } from './actions';
import ActionMenu from './ActionMenu';
import DeleteModal from '../components/cmp.modal'

const MainPage = props => {
    const {dataroleList , ApprovedActionResult} = props
    const [selectedDataRoles , setSelectedDataRoles] = useState([])
    const [rows , setRows] = useState(null)
    const [total , setTotal] = useState(0)
    const [dtParameter , setDtParameter] = useState({current:1 , pageSize:10 , filter:null})
    const [loading , setLoading] = useState(false)
    const [columns , setColumns] = useState(null)
    const [openNewModal , setOpenNewModal] = useState(false)
    const [deleteRow, setDeleteRow] = useState(null)
    const [showDeleteModal, setShowDeleteModal] = useState(false)


    useEffect(() => {
        setLoading(true)
        props.GetApprovableAsyncDataRole({dtParameter:dtParameter}) ;
    } , [dtParameter]) ;

    useEffect(()=>{
        setShowDeleteModal(true)
    },[deleteRow])


    useEffect(()=>{
        if(!ApprovedActionResult)
            return
        setSelectedDataRoles([])
        if(ApprovedActionResult.succeeded){
            props.GetApprovableAsyncDataRole({dtParameter:{...dtParameter,current:1 , dir:"desc",orderBy:"lastChangeStatusDate"}})
            setDtParameter({...dtParameter ,current:1 , dir:"desc",orderBy:"lastChangeStatusDate" })
        }if(ApprovedActionResult.type=="Priority" ){
            console.log('the value is change' , ApprovedActionResult.id)
        }   
        else{
            console.log('Error happend try again later')
        }
    },[ApprovedActionResult])

    // React.useCallback(
    //     (newRow, oldRow) =>
    //       new Promise((resolve, reject) => {
    //         console.log('newRow', newRow, 'oldRow', oldRow)
    //         if (true) {
    //             console.log('ture')
    //           // Save the arguments to resolve or reject the promise later
    //         //   setPromiseArguments({ resolve, reject, newRow, oldRow });
    //         } else {
    //           resolve(oldRow); // Nothing was changed
    //         }
    //       }),
    //     [],
    // );

    

    useEffect(()=>{
        if(!dataroleList||_.isEmpty(dataroleList))
            return
        const cols = dataroleList.columns.map(col=>{
            if(col.field && col.field=='title'){
                return ({
                    ...col ,  editable:true
                })
            }
            if(!col.hasRenderCell)
                return col
            if(col.field&&col.field.includes('logo')||col.field=='banner'){
                return ({...col , renderCell:(param)=>(<img src={param.value}/>)})
            }
            if(col.field&&col.type=='list'){
                return ({...col , type:null , renderCell:(params)=>(
                    _.map(
                          params.value,
                          (key , index) => {
                                return (
                                  <span
                                  key={index}
                                  className={cssClasses.keyItem}
                                  >
                                    {key}
                                  </span>
                                );
                              }
                              )
                            
                    
                  )})
            }})
        cols.push({field:'Id', headerName:'Action' , flex:0.5, minWidth:'100', renderCell: (params) =>(
            <ActionMenu id={params.row} handleDelete = {()=>setDeleteRow(params.row)} 
            />
        )})
        setColumns(cols)
        setLoading(false)
        setRows(dataroleList.data.map(d=>({...d , sort:1})))
        setTotal(dataroleList.total)
    },[dataroleList])


    const approveDataRoles = ()=>{
        if(_.isEmpty(selectedDataRoles))
            return
        const approvalDto = {ids:selectedDataRoles , approvedStatus:2 , adminMessage:''}
        props.ApprovedAsyncDataRoles(approvalDto)
    }
    const NotApproveDataRoles = ()=>{
        if(_.isEmpty(selectedDataRoles))
            return
        const approvalDto = {ids:selectedDataRoles , approvedStatus:3 , adminMessage:''}
        props.DisApprovedAsyncDataRoles(approvalDto)
    }

    const onSortValueChange = (id, value) =>{
        props.UpdateDataRole({
            id:id,
            title:value
        })
    }
    const addNewDataRole = (values) =>{
        setOpenNewModal(false)
        props.AddNewDataRole({
            ...values
        })
    }
    const onDeleteConfirm = (id) =>{
        props.DeleteDataRole(id)
        setShowDeleteModal(false)
    }

    return (
        <>
            <div className="mainHeader organizer font-size-24 mediumWeight">
                <div className="titleItemsWithoutWidth">
                    <span className="mainTitle middleContext">Approved Data roles</span>
                </div>
                <div className="filterItems">
                    <div className="filters inlineView">
                        <div className="changeDate inlineView"></div>
                        <div className="accessLevel inlineView"></div>
                    </div>
                    {!_.isEmpty(selectedDataRoles)?
                    <Fragment>
                        <span onClick={approveDataRoles} className={classNames(cssClasses.cursorPointer , "mr-3 createNewPlan font-size-18 clickShape")}>
                            Approve
                        </span>
                        <span onClick={NotApproveDataRoles} className={classNames(cssClasses.cursorPointer,"font-size-18 createNewPlan clickShape")}>
                            DisApprove
                        </span>
                    </Fragment>
                    :null}
                    <span onClick={()=>setOpenNewModal(true)} className="createNewPlan ml-3 clickShape">
                        <span className="icon icon-add"></span> Add New Data Role
                    </span>
                    
                </div>
            </div>
            {rows&&columns?
             <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="adminBoilerplate organizer">
                        <TableComponent
                            checkboxSelection={true}
                            pageSizes={[5,10,15]} 
                            totalCount={total} 
                            selectedList = {selectedDataRoles}
                            setSelectedList={setSelectedDataRoles} 
                            setDtParameter={setDtParameter} 
                            loading = {loading}
                            dtParameter = {dtParameter}
                            rows={rows} 
                            columns={columns}
                            hasSort={true}
                            changeSortColumnValue={onSortValueChange}
                            getRowClassName={(params) => 
                                cssClasses[`row--${params.row.approvedStatus.split(' ').join('')}`]
                            }
                            api = 'api/dataRole/GetApprovable'
                        />
                    </div>
                </div>
            </div>
        :<SimpleLoading/>}
        {openNewModal?
            <ReduxNewDataRoleForm closeModal={()=> setOpenNewModal(false)} onSubmit={addNewDataRole}/> 
        :null}
        {showDeleteModal && deleteRow?
            <DeleteModal closeModal={() => setShowDeleteModal(false)} 
            acceptAction={()=> onDeleteConfirm(deleteRow.id)} data={null} 
            title="Delete Data Role"
            modalMessage={`Are you sure you want to delete ${deleteRow.title}`} 
            // resultMessage={resultMessage}
        /> 
        :null}
        </>
  ) ;
};

const mapStateToProps = (state , ownProps) => {
    return {
        dataroleList : state.ApprovedAsyncDataRoles,
        ApprovedActionResult : state.ApprovedDataRolesResults
    } ;
};

export default connect(mapStateToProps , {GetApprovableAsyncDataRole , ApprovedAsyncDataRoles , DisApprovedAsyncDataRoles , AddNewDataRole , UpdateDataRole, DeleteDataRole})(withRouter(MainPage)) ;


