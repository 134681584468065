import React, { Fragment, useState, useReducer, useEffect} from 'react';

import Uppy from "@uppy/core";
import Tus from "@uppy/tus";
import { Dashboard } from "@uppy/react";
import Url from "@uppy/url";
import API from  '../api-routes';
import _ from 'lodash';
import UseToken from '../api-authorization/UseToken'
import { randomTextGenerator } from './randomStringGenerator';



function reducer(state={}, action) {
    switch (action.type) {
      case 'newfiles':
        return [...state, ...action.payload];
      case 'progress':
        return {...state, [action.payload.fileName]: action.payload.progress};
      default:
        throw new Error();
    }
}


const FileUploader = ({maxSize ,onFileUploaded  , type , setMessage ,storageType , width , height}) => {
    const [uploaded, dispatch] = useReducer(reducer, []);
    const token = UseToken();


  useEffect(() => {
    console.log('uploaded in fileuploader', uploaded)
    if(!_.isEmpty(uploaded)){
      onFileUploaded(uploaded)
    }
  }, [uploaded])
  
  
  const uppy = new Uppy({
      restrictions: {
        allowedFileTypes: ['.xlsx' , 'image/*','.sav' , '.txt'],
        maxNumberOfFiles: 1
      },
      meta:{
        session:randomTextGenerator(10),
        storageType:storageType
      }
    })
    .use(Tus, {
      endpoint: API.file,
      overridePatchMethod: true,
      chunkSize: 5000000,
      // withCredentials: true,
      headers: {
        'Authorization' : `Bearer ${token}` 
      }
      })
      .use(Url, {
        // target: Dashboard,
        companionUrl: "http://companion.voxdash.com"
        
      })
      // .on("upload-progress" , (progressEvent) => {
      //   var filename = file.name;
      //   const percentage = Math.round(
      //       (progressEvent.loaded * 100) / progressEvent.total
      //       );
      //   dispatch({type: 'progress', payload: {
      //           fileName: filename,
      //           progress: percentage,
      //       }})
    // })
      .on("complete", (file) => {
        if(file.successful && file.successful.length > 0){
          const newFiles = file.successful.map((uploaded) => {
            const url = uploaded.uploadURL;
            const idStr = url.slice(url.lastIndexOf('/')+1);

            return {savedFileId: Number(idStr), name: uploaded.name, size: uploaded.size, isNew: true}
          })
          //onFileUploaded(newFiles)
          //setUploaded((prevValue) => [...prevValue, newFiles])
          dispatch({
            type: 'newfiles',
            payload: newFiles
          })
        }
      })
      .on('files-added', (files) => {
        console.log('Added files', files)
      });
    

    return (

          <Dashboard
            uppy={uppy}
            proudlyDisplayPoweredByUppy={true}
            plugins={["Url"]}
            height={height?height:250}
            width={width?width:300}
          />
  );
}


export default FileUploader;
