import React, { useEffect, useState } from 'react'
import { Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {GetAllDefaultBanner} from './actions'
import LogoUploader from './UploadBanner'
import DeleteModal from '../components/cmp.modal'
import { CreateDefaultBanner , DeleteDefaultBanner } from './actions'

const MainPage = (props) => {
    const {allDefaultBanner , defaultBannerResults } = props;  
    const [defaultBanners, setDefaultBanners] = useState([])
    const [activeId , setActiveId] = useState(null)
    const [uploadId , setUploadId] = useState(null)
    const [uploadedFile , setUploadedFile] = useState(null)
    const [showDeleteModal , setShowDeleteModal] = useState(false)
    const [resultLoading , setResultLoading] = useState(false)
    const [resultMessage , setResultMessage] = useState(null)

    useEffect(()=>{
        props.GetAllDefaultBanner()

    },[])

    useEffect(()=>{
      setResultMessage(null)
    },[activeId])

    useEffect(()=>{
        setDefaultBanners(allDefaultBanner.result)
        setUploadedFile(null)
    },[allDefaultBanner])

    useEffect(()=>{
      if(!defaultBannerResults)
        return
      if(defaultBannerResults.action == "DELETE"){
        setResultLoading(false)
        if(defaultBannerResults.succeeded){
          setActiveId(null)
          props.GetAllDefaultBanner()
        }else{
          setResultMessage({message:defaultBannerResults.result.errors.toString(), success:false})
        }
      }
      if(defaultBannerResults.action == "NEW"){
        if(defaultBannerResults.succeeded){
          props.GetAllDefaultBanner()
        }
      }
    },[defaultBannerResults])

    const uploadDefaultBanner = (id) =>{
      props.CreateDefaultBanner({fileId:id})
    }

    const onDeleteConfirm = (id) =>{
      props.DeleteDefaultBanner(id)
      setResultLoading(true)
    }



    return (
        <Fragment>
            <div className="mainHeader organizer font-size-24 mediumWeight">
                <div className="titleItems">
                    <span className="mainTitle middleContext">Default banner</span>
                </div>
                <div className="filterItems">
                    <LogoUploader onChange={uploadDefaultBanner} setUploadedFile = {setUploadedFile}/>
                </div>
            </div> 
            <div className="adminBoilerplate organizer">
                <div className='flex justify-center items-center flex-col'>
                    <div>
                        <div className='grid  grid-cols-7 gap-8 mt-4 sm:grid-cols-3 sm:gap-4 pr-1'>
                            {/* {defaultBanners?.map(banner=>(
                                <div className="relative block bg-gray-900 group" href="#">
                                  <img src={banner.url} className='absolute inset-0 object-cover w-full h-full group-hover:opacity-50 uploadBannerImg' />  
                                  <div className="py-5 flex items-center justify-center transition-all transform translate-y-8 opacity-0 group-hover:opacity-100 group-hover:translate-y-0">
                                    <span 
                                    onClick={()=>setActiveId(banner.id)} 
                                    className="icon icon-delete whiteColor text-lg cursor-pointer"></span>
                                  </div>
                                </div>
                            ))} */}
                            <img src='/img/banner1.jpg' className='uploadBannerImg' />
                            <img src='/img/banner2.jpg' className='uploadBannerImg' />
                            <img src='/img/banner3.jpg' className='uploadBannerImg' />
                            <img src='/img/banner4.jpg' className='uploadBannerImg' />
                            <img src='/img/banner5.jpg' className='uploadBannerImg' />
                            <img src='/img/banner6.jpg' className='uploadBannerImg' />
                            <img src='/img/banner7.jpg' className='uploadBannerImg' />
                            <div className="relative block bg-gray-900 group" href="#">
                              <img src='/img/banner8.jpg' className='absolute inset-0 object-cover w-full h-full group-hover:opacity-50 uploadBannerImg' />  
                              <div className="py-5 flex items-center justify-center transition-all transform translate-y-8 opacity-0 group-hover:opacity-100 group-hover:translate-y-0">
                                <span 
                                onClick={()=>setActiveId(6)} 
                                className="icon icon-delete whiteColor text-lg clickShape"></span>
                              </div>
                            </div>
                            {uploadedFile?
                              <img src={uploadedFile} className='uploadBannerImg'/>
                            :null}
                        </div>
                    </div>
                </div>
                {/* <div className="flex items-center justify-center h-screen">
                  <a className="relative block w-1/4 bg-gray-900 group" href="#">
                    <img className="absolute inset-0 object-cover w-full h-full group-hover:opacity-50"
                      src="https://cdn.pixabay.com/photo/2021/10/30/08/07/desert-6753729__340.jpg" alt="" />
                    <div className="relative p-2">
                      <div className="mt-40">
                        <div
                          className="transition-all transform translate-y-8 opacity-0 group-hover:opacity-100 group-hover:translate-y-0">
                          <div className="p-2">
                            <p className="text-sm text-white">
                              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Omnis
                              perferendis hic asperiores quibusdam quidem voluptates doloremque
                              reiciendis nostrum harum. Repudiandae?
                            </p>
                            <button className="px-4 py-2 text-sm text-white bg-indigo-600">Read More</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div> */}
            </div>
            {activeId?
                <DeleteModal closeModal={() => setActiveId(null)} 
                    acceptAction={()=> onDeleteConfirm(activeId)} 
                    data={null} 
                    title="Delete Banner"
                    modalMessage={`Are you sure you want to delete this banner`} 
                    loading = {resultLoading}
                    resultMessage={resultMessage}
                /> 
            :null}
        </Fragment>
    )
}



const mapStateToProps = (state , ownProps) => {
    return {
       allDefaultBanner:state.uploadBannerAllDefaultBanner,
       defaultBannerResults:state.uploadBannerGetResults
    } ;
};


export default  connect(mapStateToProps, {
    GetAllDefaultBanner,
    CreateDefaultBanner,
    DeleteDefaultBanner
  })(withRouter(withTranslation()(MainPage))) ; 