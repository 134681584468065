import {addAccessLevelAction , editAccessLevelAction , deleteAccessLevelAction} from './actions'
export default (state={} , action) => {
    switch (action.type) {
        case addAccessLevelAction.resultAction :
            return {...action.payload.data , action : 'NEW'} ;
        case editAccessLevelAction.resultAction :
            return {...action.payload.data , action : 'EDIT'} ;
        case deleteAccessLevelAction.resultAction :
            return {...action.payload.data , action : 'DELETE'} ;
        default :
            return state ;
    }
};