import {GET , POST , PUT , POST_FORM} from "../common/Action";
import Action from '../common/Action' ;

//actions
export const getSearchHistoryAction = new Action('GET_SEARCH_HISTORY' , POST , 'api/history/GetSearchHistory') ;



//action creators
export const getSearchHistory = (data) => {
    return getSearchHistoryAction.callApi(data) ;
};