import {getLanguagesAction} from "./actions";
import _ from 'lodash' ;

export default (state={} , action) => {
    switch (action.type) {
        case getLanguagesAction.resultAction :
            return _.mapKeys(action.payload.data , 'id') ;
        default :
            return state ;
    }
};

