import {getUserDropDownsDataAction} from "./actions";
import { getAllAccessLevelAction } from "./actions";

export default (state={positions : [] , industries : [] , countries : [] , permission : []} , action) => {
    switch (action.type) {
        case getUserDropDownsDataAction.resultAction :
            return {...action.payload.data , type:'USER_DROPDOWN_DATA'}
        case getAllAccessLevelAction.resultAction :
            return {data:action.payload.data , type:"GET_ALL_ACCESS_LEVEL"}
        default :
            return state ;
    }
};