import Action, { POST ,GET } from "../common/Action"


export const AddTermsAndConditionsAction = new Action ('[data entry] ADD_TERMS_AND_CONDITION_ACTION' , POST , 'api/TermsAndConditions/AddTermsAndConditions')
export const getDatasetUsersTermsAndConditionsDetailedAction = new Action ('[terms and conditions] GET_DATASET_USERS_TERMS_AND_CONDITIONS_DETAILED_ACTION',POST ,'api/termsAndConditions/GetDatasetTermsAndConditionsUser')
export const getDatasetHasTermsAndConditionApprovalListAction = new Action('[terms and conditions] GET_DATASET_HAS_TERMS_AND_CONDITION_APPROVAL_LIST_ACTION' , POST , 'api/termsAndConditions/GetTermsAndConditionsOfUser')
export const getTermsAndConditionFileAction = new Action('[terms and conditions] GET_TERMS_AND_CONDITIONS_FILE_ACTION' , GET , params => `api/file/DownloadTermsAndConditions?id=${params.id}`)
export const getAllAcceptanceAction = new Action('[terms and conditions] GET_ALL_ACCEPTANCE_ACTION' , POST , 'api/termsAndConditions/GetAcceptedTermsAndConditions')
export const getAllTermsVersionsAction = new Action('[terms and conditions] GET_ALL_TERMS_VERSIONS_ACTION', POST , 'api/termsAndConditions/GetTermsAndConditionsVersions')
export const getAllDatasetAcceptanceAction = new Action('[terms and conditions] GET_ALL_DATASET_ACCEPTANCE_ACTION', POST  ,'api/termsAndConditions/GetDatasetTermsAndConditionsUser')
export const getTermsAndConditionsAction = new Action('[terms and conditions] GET_TERMS_AND_CONDITIONS_ACTION' , POST , 'api/termsAndConditions/GetTermsAndConditions')
export const updateTermsAndConditionsAction = new Action('[terms and conditions] UPDATE_TERMS_AND_CONDITIONS_ACTION', POST , 'api/termsAndConditions/updateTermsAndConditions')
export const GetTermsAndConditionsTemplatesAction = new Action('[data entry] GET_TERMS_AND_CONDITIONS_TEMPLATES_ACTION' , POST , 'api/termsAndConditions/GetTermsAndConditionsTemplates')



export const GetTermsAndConditionsTemplates = () =>{
  return GetTermsAndConditionsTemplatesAction.callApi()
}

export const updateTermsAndConditions = (data) =>{
    return updateTermsAndConditionsAction.callApi(data)
}

export const getTermsAndConditions = (data) =>{
    return getTermsAndConditionsAction.callApi(data)
}

export const getTermsAndConditionFile = (id) =>{
    return getTermsAndConditionFileAction.callApi(null , {id})
}

export const getAllTermsVersions = (data)=>{
    return getAllTermsVersionsAction.callApi(data)
}

export const getAllAcceptance = (data) =>{
    return getAllAcceptanceAction.callApi(data)
}

export const getDatasetAllAcceptance = (data) =>{
    return getAllDatasetAcceptanceAction.callApi(data)
}

export const getDatasetUsersTermsAndConditionsDetailed  = (data) => {
    
    return getDatasetUsersTermsAndConditionsDetailedAction.callApi(data) ;
};

export const getTermsAndConditionApprovalList = (data)=>{
    return getDatasetHasTermsAndConditionApprovalListAction.callApi(data)
}









export const AddTermsAndConditions = (data) =>{
    return AddTermsAndConditionsAction.callApi(data)
  }
  