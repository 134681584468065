import React, { Fragment, useState, useEffect } from "react";
import { createContainer } from "unstated-next";

// this container is used to manage states that are needed in all components
export function useContainerService() {
  let [notifications, setNotifications] = useState([]);

  const openNotification = (notifObj) => {
    const newNotif = {
      title: notifObj.title,
      description: notifObj.description,
      type: notifObj.type || "info",
    };
    const newList = [...(notifications), newNotif];
    setNotifications(newList);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setNotifications((notifications) => notifications.slice(1));
    }, 5000);
    return () => clearTimeout(timer);
  }, [notifications]);

  return {
    notifications,
    openNotification,
  };
}
export let ContainerService = createContainer(useContainerService);
