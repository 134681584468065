import React , {useState , useEffect , Fragment} from 'react' ;
import {connect} from 'react-redux' ;

import SimpleLoading from "../components/simpleLoading";
import {getSearchHistory} from "./actions";
import _ from 'lodash' ;
import {Link, withRouter} from 'react-router-dom' ;
import TableComponent from '../components/TableComponent';

const MainPage = props => {

    const {searchHistories } = props ;
    const [rows , setRows] = useState(null)
    const [total , setTotal] = useState(0)
    const [dtParameter , setDtParameter] = useState({current: 1, pageSize: 10, filter: null, dir: null, orderBy: null})
    const [loading , setLoading] = useState(false)
    const [columns , setColumns] = useState(null)
    
    useEffect(() => {
        if(!dtParameter)
            return
        setLoading(true)
        props.getSearchHistory({dtParameter:dtParameter}) ;
    } , [dtParameter]) ;

    useEffect(()=>{
        if(!searchHistories||_.isEmpty(searchHistories))
            return
        const cols = searchHistories.columns.map(col=>{
            if(!col.hasRenderCell){
                return col
            }else{
                return ({...col , renderCell:(param)=>(
                    <div>
                        {param.value}
                    </div>
                )})
            }
            
        })
        cols.push({
            field:'id', flex:1 , minWidth:200, headerName:'Actions' , renderCell:(params)=>(
                <Link to={`/usageHistoryInner/${params.value}`} style={{textDecoration : 'none' , color:'black'}}>
                    <span className="giveAccess blueBackground clickShape">
                    <span className="icon icon-right-sign-arrow whiteColor"></span>
                    </span>
                </Link>
            )
        })
        setColumns(cols)
        setLoading(false)
        setRows(searchHistories.data)
        setTotal(searchHistories.total)
    },[searchHistories])



  return (
    <Fragment>
        {!rows||!columns?
            <SimpleLoading/>
        :
        <Fragment>
            <div className="mainHeader organizer font-size-24 mediumWeight">
                <div className="titleItems">
                    <span className="mainTitle middleContext">Search History</span>
                </div>
            </div>
            <div className="adminBoilerplate organizer">
                    <TableComponent
                        selectedList = {[]}
                        checkboxSelection={false}
                        setSelectedList = {(e)=> console.log(e)}
                        pageSizes={[5,10,15]} 
                        totalCount={total} 
                        setDtParameter={setDtParameter} 
                        loading = {loading}
                        dtParameter = {dtParameter}
                        rows={rows} 
                        columns={columns}
                        api = "api/history/GetSearchHistory"
                    />
                </div>
            </Fragment>
            }
        </Fragment>
  ) ;
};

const mapStateToProps = (state , ownProps) => {
    return {
        searchHistories : state.searchHistories
    } ;
};

export default connect(mapStateToProps , {getSearchHistory})(withRouter(MainPage)) ;