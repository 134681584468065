import React , {Fragment, useState} from "react"
import { useTranslation } from "react-i18next";
import {TwitterPicker} from 'react-color'
import { useEffect } from "react";
import classNames from "classnames";


// type propsType={
//     color:string,
//     setColor:Function,
//     text:string
// }

const ColorPicker = (props)=>{
    const [showColorPicker , setShowColorPicker] = useState(false);
    const [color , setColor] = useState(props.color? props.color:'#f5f4f4')
    useEffect(()=>{
        
    },[color])
    const {t} = useTranslation();
    return (
        <div className="planAccColor organizer inlineFlex" onClick={()=>setShowColorPicker(!showColorPicker)}>
            <div style={{width:'max-content'}} className={classNames( "inputBox leftNormalMargin active topUnitMargin")}>
                <label style={{color:'blue'}}>Color</label>
                <div className="accColorSelectBox inlineFlex">
                    <div className="colorPicker clickShape" style={{backgroundColor : color , borderColor : color}}></div>
                    <div className="colorString" style={{color : color}}>{color}</div>
                </div>
                {showColorPicker?
                    <div style={{position: 'absolute',zIndex: 1, whiteSpace: 'normal' }}>
                        <TwitterPicker width='150px' color={color?color:'black'} onChangeComplete={(color)=>{setColor(color.hex);props.setColor(color.hex)}} />
                    </div>
                :null}
            </div>
        </div>
    )
}

export default ColorPicker;