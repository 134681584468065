import {GET , POST , PUT , POST_FORM} from "../common/Action";
import Action from "../common/Action";

//actions
export const getFieldValuesAction = new Action('GET_FIELD_VALUES_ACTION' , GET , 'api/content/GetFieldValues') ;
export const loadAllResourcesAction = new Action('LOAD_ALL_RESOURCES_ACTION' , POST , 'api/resources/GetAll') ;
export const changeResourceValueAction = new Action('CHANGE_RESOURCES_VALUE_ACTION' , POST , 'api/resources/Update') ;
export const addNewResourceAction = new Action('ADD_RESOURCE_ACTION' , POST , 'api/resources/Add')
export const deleteResourceValueAction = new Action('DELETE_RESOURCE_ACTION' , POST ,params => `api/resources/Remove?id=${params.id}`)
export const importResourcesFromExcelAction = new Action('IMPORT_RESOURCES_FROM_EXCEL', GET , (param)=>`api/Resource/ImportResourcesFromExcelFile?savedFileId=${param.savedFileId}`);
export const exportResourcesToExcelAction = new Action("EXPORT_RESOURCES_TO_EXCEL" , POST , 'api/resources/ExportResourcesToExcelFile')


//action creators
export const getFieldValues = () => {
  return getFieldValuesAction.callApi() ;
};
export const loadAllResources = (data) => {
  return loadAllResourcesAction.callApi(data) ;
};
export const changeResourceValue = (data) => {
  return changeResourceValueAction.callApi(data) ;
};
export const addNewResource = (data)=>{
  return addNewResourceAction.callApi(data);
};
export const deleteResourceValue = (id)=>{
  return deleteResourceValueAction.callApi(null,{id});
}

export const importResourcesFromExcel = (savedFileId) =>{
  return importResourcesFromExcelAction.callApi(null , {savedFileId})
}

export const ExportResourcesToExcel = () =>{
  return exportResourcesToExcelAction.callApi();
}