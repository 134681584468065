import classNames from "classnames"
import _ from "lodash"
import { Fragment } from "react"
import { useTransition } from "react"
import { useState } from "react"
import { useEffect } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Field, reduxForm } from "redux-form"
import { scrollToFirstError, SimpleInput } from "../components/formFields"
import SimpleLoading from "../components/simpleLoading"

const DetailMetaTag = (props) =>{
    const {metaTags , page , t} = props
    
    return(
        <form>
            <div className="popup profiles">
                <div className="popupClose" onClick={props.closeModal}></div>
                <div className="popUpBox" style={{width:'90%'}}>
                    <div className="popupHeader spaceBetween organizer">
                        <div className="popUpTitle font-size-24 mediumWeight">
                            {page? page.label : "Custom page"}
                        </div>
                        <div className="popupCross clickShape" onClick={props.closeModal}>
                            <span className="icon icon-cross font-size-18 lightGrayColor"></span>
                        </div>
                    </div>
                    <div className="popUpContent organizer" style={{maxHeight:'550px' , overflowY:'scroll'}}>
                    {props.errorMessage?
                        <div>{props.errorMessage}</div>
                    :null}
                    {!metaTags?
                        <SimpleLoading/>
                    :
                        <Fragment>
                            <div>
                                {metaTags && !_.isEmpty(metaTags)?
                                    metaTags.map(metaTag=>(
                                        <div key={metaTag.keyId}>
                                            <Field name={""+metaTag.keyId} label={metaTag.keyLabel} component={SimpleInput}/>                    
                                        </div>
                                    ))
                                :null}
                            </div>
                            <div className="aggregateAction inlineView rightFloat">
                                <div className="normalButton inlineView grayBackground whiteColor clickShape topNormalMargin" onClick={props.closeModal}>
                                    Cancel
                                </div>
                                <div className={classNames("normalButton inlineView whiteColor topNormalMargin leftNormalMargin" , {"blueBackground clickShape" : !props.loading , "grayBackground" : props.loading})} onClick={props.handleSubmit}>
                                    Save changes
                                </div>
                            </div>
                            
                        </Fragment>
                    }
                    </div>
                </div>
            </div>
        </form>
    )   
}

const mapStateToProps = (state, ownProps) => {
    return {
        
    };
  };

  
const form = connect(mapStateToProps , {
  })(withRouter(withTranslation()(DetailMetaTag)));

const MetaTagsForm = reduxForm({
    // a unique name for the form
    form: 'MetaTagForm', 
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    enableReinitialize : true ,
    onSubmitFail:  (errors) => scrollToFirstError(errors),
    // validate
})(form);


  export default withRouter(withTranslation()(MetaTagsForm)) ;