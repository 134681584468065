import identityReducer from '../common/identity-reducer';
import identityWithFailureReducer from '../common/identity-with-failure';
import {signOutAction, signInAction,saveTokenAction} from "./actions";

const signOutReducer = identityReducer.bind(signOutAction.getIdentityReducerThisObj(false));
const signInReducer = identityWithFailureReducer.bind(signInAction.getIdentityWithFailureReducerThisObj({}));
const tokenReducer = identityReducer.bind(saveTokenAction.getIdentityReducerThisObj(null));

export default ({
    signOut: signOutReducer,
    signInResult: signInReducer,
    token: tokenReducer
});