import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
//const HttpBackend = require('i18next-http-backend');
import HttpBackend from 'i18next-http-backend';
import apiRoutes from "./api-routes";


i18n.use(HttpBackend)
    .use(LanguageDetector)
    .init({
        lng: 'en-US',
        fallbackLng: 'en-US',
        preload: ['en-US'],
        ns: ['translation'],
        defaultNS: 'translation',
        keySeparator: ':',
        backend : {
            loadPath : `${apiRoutes.api}api/resources/GetResource?lang={{lng}}`
            //loadPath : "https://adminapi-staging.voxdash.com/api/resources/GetResource?lang={{lng}}"
        },
        react: {
            useSuspense: false
        }
    } , 
        s => console.log('done!')
    );
export default i18n;