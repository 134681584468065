import React , {useState , useEffect , Fragment} from 'react' ;
import {connect} from 'react-redux' ;
import LeftNav from '../components/left-menu' ;
import Pagination from "../components/pagination";
import {getAllUsers, editAccessId , clearFormResult , changeUserSurveyPurchases , getUser , addNewUser , editUser , deleteUser , getUserSurveyPurchases} from "./actions";
import _ from 'lodash' ;
import UserInfoForm from "./userForm";
import {getUserDropDownsData} from "./actions";
import {reset} from "redux-form";
import DeleteModal from '../components/cmp.modal' ;
import TextFilter from "../components/textFilter";
import AccessLevelFilter from '../components/multipleChoicesFilter' ;
// import DateRangePickerFilter from "../components/datePickerFilter";
import CheckBox from './checkBoxes' ;
import SimpleLoading from "../components/simpleLoading";
import AnimatedLoading from "../components/AnimatedLoading";
import TableComponent from '../components/TableComponent';
import { useHistory, useParams, withRouter } from 'react-router-dom';

export const accessLevelOptions = [
    {label : 'Unlimited +' , value : 6 , color : '#a72cef'} ,
    {label: 'Demo' , value: 1 , color : '#40efa4'} ,
    {label : 'Professional' , value : 4 , color : '#ef3e97'} ,
    {label : 'Basic' , value : 3 , color : '#8cefe9'}
];
const setColor = (id) => {
    for (let i = 0; i < _.size(accessLevelOptions); i++) {
        if (accessLevelOptions[i].value === id) {
            return accessLevelOptions[i].color ;
        }
    }
};
const MainPage = props => {

    const {users , usersTotalCount , singleUser , userFormResult , userDropDownsData} = props ;
    const [pageNumber , setPageNumber] = useState(1) ;
    const [viewByPage , setViewByPage] = useState(8) ;
    const [showModal , setShowModal] = useState(false) ;
    const [isEdit , setIsEdit] = useState(false) ;
    const [formResultMessage , setFormResultMessage] = useState(null) ;
    const [showDeleteModal , setShowDeleteModal] = useState(false) ;
    const [selectedUserIdToDelete , setSelectedUserIdToDelete] = useState(null) ;
    const [filters , setFilters] = useState({email : '' , accessLevel : [] , startDate : null , endDate : null}) ;
    const [showSurveyPurchasesModal , setShowSurveyPurchasesModal] = useState(false) ;
    const [surveyPurchasesUserId , setSurveyPurchasesUserId] = useState(null) ;
    const [countries , setCountries] = useState([])
    
    const [rows , setRows] = useState(null)
    const [total , setTotal] = useState(0)
    const [dtParameter , setDtParameter] = useState({current: 1, pageSize: 10, filter: null, dir: null, orderBy: null})
    const [loading , setLoading] = useState(false)
    const [columns , setColumns] = useState(null)
    const history = useHistory()


    const params = useParams()
    const userId = params.id 

    useEffect(() => {
        if(!dtParameter)
            return
        setLoading(true)
        props.getAllUsers({dtParameter:dtParameter});
    } , [dtParameter]) ;

    useEffect(()=>{
        if(showModal ==null)
            return 
        if(!showModal)
            history.push('/users')
    },[showModal])

    useEffect(()=>{
        if(!userId)
            return 
        onEditUserClick(userId)
    },[userId])

    useEffect(()=>{
        if(!users||_.isEmpty(users))
            return
        const cols = users.columns.map(col=>{
            if(!col.hasRenderCell){
                return col
            }else{
                return ({...col , renderCell:(param)=>(
                    <div>
                        {param.value}
                    </div>
                )})
            }
            
        })
        cols.push({
            field:'id' , flex:1 , minWidth:200 , headerName:'Actions' , renderCell:(params)=>(
                <>
                    <span className="editRow leftNormalMargin redBackground clickShape" onClick={()=>onDeleteButtonClick(params.value)}>
                        <span className="icon icon-delete whiteColor"></span>
                    </span>
                    <span className="editRow leftNormalMargin greenBackground clickShape">
                        <span className="icon icon-edit whiteColor"onClick={()=>history.push(`/users/${params.value}`)}></span>
                    </span>
                </>
            )
        })
        setColumns(cols)
        setLoading(false)
        setRows(users.data.map(u=>({...u , country:countries && countries.filter(c=> c.id == u.countryId)[0]?countries.filter(c=> c.id == u.countryId)[0].name:u.countryId})))
        setTotal(users.total)
    },[users , countries])

    useEffect(()=>{
        if(!userDropDownsData)
            return 
        setCountries(userDropDownsData.countries)
    },[userDropDownsData])
  useEffect(() => {
      props.getUserDropDownsData() ;
  } , []);


  useEffect(() => {
      if (!_.isEmpty(userFormResult)) {
          setShowModal(false)
          if (userFormResult.action === 'EDIT_USER') {
              setFormResultMessage({success : userFormResult.success , message : userFormResult.success ? 'Edited !' : 'Something Unexpectedly Happened ! Please Try Again Later .'}) ;
              setShowModal(false)
              props.getAllUsers({dtParameter:dtParameter}) ;
          }
          else if (userFormResult.action === 'NEW_USER') {
              setFormResultMessage({success : userFormResult.success , message : userFormResult.success ? 'Added !' : userFormResult.message}) ;
              if (userFormResult.success) {
                  dtParameter.current = 1
                  props.getAllUsers({dtParameter:{...dtParameter ,dir:"desc",orderBy:"signUpDate"}}) ;
                  setIsEdit(true) ;
                  setShowModal(false)
              }
          }
          else if (userFormResult.action === 'DELETE_USER') {
              setFormResultMessage({success : userFormResult.success , message : userFormResult.success ? 'Deleted !' : 'Something Unexpectedly Happened ! Please Try Again Later .'}) ;
              setShowDeleteModal(false)
              if (userFormResult.succeeded) {
                dtParameter.current = 1
                setLoading(true)
                props.getAllUsers({dtParameter:dtParameter}); 
            }
          }
          else if (userFormResult.action === 'CHANGE_USER_SURVEY_PURCHASES') {
            if (userFormResult.succeeded) {
                dtParameter.current = 1
                setIsEdit(true) ;
            }
          }
      }
  } , [userFormResult]) ;

  
  const onUserFormSubmit = (values) => {
      const user = {
          ...values,
          salutation : values.salutation.label ,
        //   firstName : values.firstName ,
        //   lastName : values.lastName ,
          countryId : values.country? values.country.value:null ,
        //   postalCode : values.postalCode ,
          accessLevelId : values.accessLevel?values.accessLevel.value:null ,
          industryId : values.typeOfIndustry?values.typeOfIndustry.value:null ,
          positionId : values.position? values.position.value:null,
        //   institution : values.institution,
          phoneCountryId : values.phoneCountryId ? values.phoneCountryId.value:null
      };
        setFormResultMessage(null) ;
        if (isEdit) {
            user.Id = singleUser.id;
            props.editUser(user) ;
        }
        else {
            props.addNewUser(user) ;
        }
  };

  const onEditUserClick = (id) => {
      props.getUser(id) ;
      setIsEdit(true) ;
      setShowModal(true) ;
      setFormResultMessage(null) ;
  };

  const onNewUserClick = () => {
        props.reset('userForm') ;
        setShowModal(true) ;
        setIsEdit(false) ;
        setFormResultMessage(null) ;
  };
  const onConfirmDeleteUserClick = () => {
      setFormResultMessage(null) ;
      props.deleteUser(selectedUserIdToDelete) ;
  } ;
  const onDeleteButtonClick = (Id) => {
    setFormResultMessage(null) ;
    setSelectedUserIdToDelete(Id) ;
    setShowDeleteModal(true) ;
  };
  const searchEmailQuery = (query) => {
    // console.log(query) ;
    setFilters({...filters , email : query}) ;
    setPageNumber(1) ;
  };
  const searchAccessLevelQuery = (query) => {
      // console.log(query) ;
      setFilters({...filters , accessLevels : query}) ;
      setPageNumber(1) ;
  };
  const searchDateRangeQuery = (query) => {
      setFilters({...filters , datePicker : query}) ;
      setPageNumber(1) ;
  };
  const onSurveyPurchasesClick = (UserId) => {
      setShowSurveyPurchasesModal(true) ;
      setSurveyPurchasesUserId(UserId) ;
  };
  const onAccessLevelChange = (userId , accessLevelId) => {

  };
  

  return (
    !rows||!columns?
        <SimpleLoading/>
    :
    <Fragment>
        <div className="mainHeader organizer font-size-24 mediumWeight">
            <div className="titleItems">
                <span className="mainTitle middleContext">Users</span>
            </div>
        </div>
        <div className="adminBoilerplate organizer">
            <TableComponent
                selectedList = {[]}
                setSelectedList = {(e)=> console.log(e)}
                pageSizes={[5,10,15]} 
                totalCount={total} 
                setDtParameter={setDtParameter} 
                loading = {loading}
                dtParameter = {dtParameter}
                rows={rows} 
                columns={columns}
                api = 'api/users/getAllUsers'
                checkboxSelection={false}
            />
        </div>    
        {showModal? <UserInfoForm formResultMessage={formResultMessage} initialValues={isEdit ? singleUser : {}} onSubmit={onUserFormSubmit} edit={isEdit} closeModal={() => setShowModal(false)}/> : null}
        {showDeleteModal && selectedUserIdToDelete !== null? <DeleteModal closeModal={() => setShowDeleteModal(false)} acceptAction={onConfirmDeleteUserClick} data={null} title="Delete User" modalMessage={`Are you sure you want to delete user \"${rows.filter(r=>r.id == selectedUserIdToDelete)[0].firstName} ${rows.filter(r=>r.id == selectedUserIdToDelete)[0].lastName}\" ?`} resultMessage={formResultMessage}/> : null}
        {showSurveyPurchasesModal? <CheckBox userId={surveyPurchasesUserId} closeModal={() => {setShowSurveyPurchasesModal(false) ; props.clearFormResult()}} /> : null}
    </Fragment>
  );
};

const mapStateToProps = (state , ownProps) => {
    return {
        users : state.users ,
        singleUser : state.singleUser ,
        userFormResult : state.userFormResult,
        userDropDownsData : state.userDropDownsData
    } ;
};

export default connect(mapStateToProps , {getAllUsers , editAccessId , clearFormResult , addNewUser , editUser , getUserDropDownsData , getUser , deleteUser , getUserSurveyPurchases , changeUserSurveyPurchases , reset})(withRouter(MainPage)) ;