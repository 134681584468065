import {getTopicAction , getSubTopicAction} from "./actions";

export default (state={} , action) => {
    switch (action.type) {
        case getTopicAction.resultAction :
        case getSubTopicAction.resultAction :
            return action.payload.data ;
        default :
            return {} ;
    }
};