import {getAllUsersAction} from "./actions";
import {clearUsersAction} from "../messages/actions";

export default (state={} , action) => {
    switch (action.type) {
        case clearUsersAction.resultAction :
            return {} ;
        case getAllUsersAction.resultAction :
            return action.payload.data;
        default :
            return state;
    }
};