import React, {useState , useEffect , Fragment} from 'react' ;
import {connect} from 'react-redux' ;
import classNames from "classnames";
import useDebounce from "../components/use-debounce";
import {getAllUsers} from "../users/actions";
import {clearUsers} from "./actions";
import _ from 'lodash' ;

export const IS_LOADING = 'IS_LOADING' ;
export const NO_RESULT = 'NO_RESULT' ;
export const GOT_ALL_USERS = 'GOT_ALL_USERS' ;

const SelectUserModal = props => {


    const {closeModal , users , onSelectUserAction } = props ;
    const [PageSize , setPageSize] = useState(10) ;
    const [PageNumber , setPageNumber] = useState(1) ;
    const [listState , setListState] = useState(IS_LOADING) ;
    const [inputText , setInputText] = useState('') ;
    const [onUserLiHoverId , setOnUserLiHoverId] = useState(null) ;
    const emailQuery = useDebounce(inputText , 500) ;

    useEffect(() => {
        props.clearUsers() ;
        setListState(IS_LOADING) ;
        setPageNumber(1) ;
    } , [emailQuery]) ;

    useEffect(() => {
        const data = {
            PageNumber : PageNumber,
            PageSize ,
            filter : emailQuery
        };
        props.getAllUsers(data) ;
    } ,[PageNumber , PageSize , emailQuery]) ;

    // useEffect(() => {
    //     if (!_.isEmpty(users) && !_.isEmpty(users.data)) {
    //         let total = 0 ;
    //         for (let i = 1; i <= PageNumber ; i++) {
    //             total = total + _.size(users.data[i]) ;
    //         }
    //         if (total < users.total) {
    //             setListState(IS_LOADING) ;
    //         }
    //         else {
    //             if (total === 0) {
    //               setListState(NO_RESULT) ;
    //             }
    //             else {
    //                 setListState(GOT_ALL_USERS) ;
    //             }
    //         }
    //     }
    // } ,[users]) ;

    const onScrollListener = (e) => {
        if (listState === IS_LOADING) {
            let element = e.target ;
            if (Math.round(element.clientHeight + element.scrollTop) === element.scrollHeight) {
                setPageNumber(PageNumber+ 1) ;
            }
        }
    };

    const renderList = () => {
        return _.map(users.data , user => {
            return (
                <li key={user.id} className={classNames("selectUserLi inlineFlex" , {'userLiOnHover' : user.id === onUserLiHoverId})} 
                        onClick={() => onSelectUserAction(user.id , user.email)} 
                        onMouseEnter={() => setOnUserLiHoverId(user.id)} 
                        onMouseLeave={() => setOnUserLiHoverId(null)}>
                    <span className="messageLine tempDisable"></span>
                    <span className="userIcon">{user.firstName && user.lastName ? `${user.firstName[0].toUpperCase()}${user.lastName[0].toUpperCase()}` : `US`}</span>
                    <div className="notifMesssageContent clickShape">
                            <span className="messageDate font-size-10 lightGrayColor fullWidth inlineView">
                                <span className="icon icon-time"></span> {_.split(user.signUpDate , 'T')[0]}
                            </span>
                        <span className="messengerName font-size-18 fullWidth inlineView">
                        {user.email}
                            </span>
                        <span className="fullWidth messageBrief font-size-12 lightWeight inlineView">{`${user.salutation}${user.firstName} ${user.lastName}`}</span>
                    </div>
                    <div className="moreOptions inlineView middleContext clickShape tempDisable">
                        <span className="icon icon-options"></span>
                    </div>
                </li>
            );
        });
    }
       

    return (
        <div className="popup profiles">
            <div className="popupClose" onClick={closeModal}></div>
            <div className="popUpBox selectUserPopUp columnSide" onScroll={onScrollListener}>
                <div className="popupHeader spaceBetween organizer selectUserPadding">
                    <div className="inputBox">
                        <input value={inputText} onChange={e => setInputText(e.target.value)} disabled={false} type="text" className="chatSearch" placeholder="Search User Email ... " />
                    </div>

                    <div className="popupCross clickShape leftNormalMargin" onClick={closeModal}>
                        <span className="icon icon-cross font-size-18 lightGrayColor"></span>
                    </div>
                </div>
                <div className="popUpContent organizer userListModalContent" style={{paddingTop : '0'}}>
                    <ul>
                        {renderList()}
                    </ul>
                    {/* <div className="organizer textInCenter" >{listState === IS_LOADING ? `Loading More ...` : listState === NO_RESULT ? `No Result !` : ``}</div> */}
                </div>
            </div>
        </div>
    ) ;
};


const mapStateToProps = (state , ownProps) => {
  return {
        users : state.users
  };
};

export default connect(mapStateToProps , {getAllUsers , clearUsers})(SelectUserModal) ;