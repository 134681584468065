import React , {useState , Fragment , useEffect} from 'react' ;
import {connect} from 'react-redux' ;
import LeftNav from '../components/left-menu' ;
import SelectBox from "../components/selectBox";
import {dropDownStyles} from "../components/formFields";
import {loadAllResources , changeResourceValue , addNewResource , deleteResourceValue} from "./actions";
import EditLabelForm from './editLabelForm' ;
import classNames from 'classnames' ;
import {getLanguages} from "../languages/actions";
import _, { isEmpty } from 'lodash' ;
import {languagesOptions} from "../customPages/mainPage";
import SimpleLoading from "../components/simpleLoading";
import DeleteModal from '../components/cmp.modal' ;
import { useTranslation } from 'react-i18next';
import UploadFile from '../uploadableExtension/UploadFile';

const MainPage = props => {

  const {allResources , languages , changeResourceValueResult} = props ;
  const {t} = useTranslation();
  const [language , setLanguage] = useState({}) ;
  const [selectedLabelIndex , setSelectedLabelIndex] = useState(0) ;
  const [pointerHoverIndex , setPointerHoverIndex] = useState(null) ;
  const [formLoading , setFormLoading] = useState(false) ;
  const [changeLabelResultMessage , setChangeLabelResultMessage] = useState(null) ;
  const [loading , setLoading] = useState(true) ;
  const [isNew , setIsNew] = useState(false);
  const [isDelete , setIsDelete] =useState(false);
  const [showModal , setShowModal] = useState(false);
  const onEditLabelClick = (values) => {
      setFormLoading(true) ;
      if(isNew){
            props.addNewResource(values);
      }else{
          props.changeResourceValue(values) ;
      }
  };

  const onDeleteConfirm = () => {
    setShowModal(false);
    setIsDelete(true);
    props.deleteResourceValue(selectedLabelIndex);
};

  useEffect (()=>{
      if(isNew){
          setFormLoading(false)
      }
  },[isNew]);

  useEffect(() => {
      props.getLanguages() ;
  } , []) ;

  useEffect(() => {
      if (!_.isEmpty(language)) {
          if(!allResources[language.value]){
              const data = {CultureName:language.cultureName}
              props.loadAllResources(data) ;
          }
      }
  } , [language]) ;

//   useEffect(() => {
//     if(!languages)
//         return
//     console.log('languages' , languages)
//     if (!_.isEmpty(languages)) {
//         setLanguage({label : languages[0].langCode , value : languages[0].id , isRtl : languages[0].isRtl , cultureName:languages[0].cultureName} )
//     }
//   } , [languages]) ;


  useEffect(() => {
    if(!languages){
        return;
    }
    let langs =_.map(languages , lang => {
        return {label : lang.cultureName , value : lang.id , isRtl : lang.isRtl}
    });

    // setLanguageOptions(langs) ;
    setLanguage(langs[0]) ;

} , [languages])
  

  useEffect(() => {
      setIsNew(false);
      window.scrollTo({top : 0 , behavior : "smooth"}) ;
  } , [selectedLabelIndex]) ;
  
  useEffect(() => {
      if (_.isEmpty(changeResourceValueResult))return;
      setFormLoading(false) ;
      if (changeResourceValueResult.succeeded) {
          setLoading(true) ;
          const data = {CultureName:languages[language.value].cultureName}
          props.loadAllResources(data) ;
          if(isNew){
            setChangeLabelResultMessage({success : true , message : 'Label Successfully Added !'}) ;
        }else if(isDelete){
            setChangeLabelResultMessage({success : true , message : 'Label Successfully Deleted !'}) ;
        }
        else{
            setChangeLabelResultMessage({success : true , message : 'Label Successfully Updated !'}) ;
        }
      }
      else {
          setChangeLabelResultMessage({success : false , message : 'Something Unexpectedly Happened ! Please Try Again Later .'}) ;
      }
  } , [changeResourceValueResult]) ;

  useEffect(() => {
      if (!allResources||_.isEmpty(allResources))
        return;
      setLoading(false) ;
  } , [allResources]) ;

  const onNewClick=()=>{
      setIsNew(true);
  }
  
  const onFileUploaded= (list) =>{
    const savedFileId = list[0];
    props.importResourcesFromExcel(savedFileId);

}

const onExportClick = () =>{
    props.ExportResourcesToExcel();
}

  return (
      <Fragment>
          
              <div className="mainHeader organizer mediumWeight">
                  <div className="titleItems">
                      <span className="mainTitle middleContext font-size-24">Labels</span>
                          <SelectBox
                            label={null}
                            value={language}
                            onChange={lang => setLanguage(lang)}
                            styles={dropDownStyles}
                            options={languagesOptions(languages)}
                          />
                    </div>
                    <div className="filterItems">
                        <div className="createNewPlan" onClick={onNewClick}>
                            <span className="icon icon-add">New Label</span>
                        </div>
                    </div>
                    <div style={{display:'flex'}}>
                            <div className="createNewPlan" style={{cursor:'pointer' , margin:'5px'}} onClick={onExportClick}>
                                <span className="icon icon-add">{t('Export Resources')}</span>
                            </div>
                        
                            <UploadFile  isButton={true} onFileUploaded={onFileUploaded}/>
                        
                            <div className="createNewPlan" style={{cursor:'pointer'}} onClick={onNewClick}>
                                <span className="icon icon-add">{t('New Resource')}</span>
                            </div>
                        </div>
                </div>
              <div className="adminBoilerplate organizer">
                  {
                      loading ? <SimpleLoading/> :
                          <div className="twoSidePanel organizer">
                              <div className="columnSide threeForthColumn noHeader websiteContent">
                                  <table className="tableStructure organizer">
                                      <Fragment>
                                          <thead className="tableHeader">
                                              <tr>
                                                <th>Key</th>
                                                <th>Label</th>
                                              </tr>
                                          </thead>
                                          <tbody>
                                        
                                          {!_.isEmpty(allResources)&&allResources[language.value]?
                                          _.map(allResources[language.value] , (resource , index) => {
                                              return (
                                                  <tr key={index} className={classNames("clickShape" , {'active' : pointerHoverIndex === index || selectedLabelIndex === index})} onClick={() => setSelectedLabelIndex(index)} onMouseEnter={() => setPointerHoverIndex(index)} onMouseLeave={() => setPointerHoverIndex(null)}>
                                                      <td>{resource.key}</td>
                                                      <td>{resource.value}</td>
                                                  </tr>
                                              );
                                          }):null}
                                          </tbody>
                                      </Fragment>
                                  </table>
                              </div>
                              <EditLabelForm
                                loading={formLoading}
                                onSubmit={onEditLabelClick}
                                initialValues={_.isEmpty(allResources)||!allResources[language.value] ?{} :isNew?{languageId:language.value}: allResources[language.value][selectedLabelIndex]}
                                isNew={isNew}
                                setShowModal= {(value)=>setShowModal(value)}
                                resultMessage = {changeLabelResultMessage}
                              />
                          </div>
                  }

              </div>
              {showModal ? <DeleteModal closeModal={() => setShowModal(false)} acceptAction={onDeleteConfirm} data={null} title="Delete label" modalMessage={`Are you sure you want to delete ?`}/> : null}
          </Fragment>
  ) ;
};

const mapStateToProps = (state , ownProps) => {
    return {
        allResources : state.allResources ,
        languages : state.languages ,
        changeResourceValueResult : state.changeResourceValueResult
    } ;
};

export default connect(mapStateToProps , {loadAllResources , getLanguages , changeResourceValue , addNewResource , deleteResourceValue})(MainPage) ;