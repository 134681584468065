import {addNewUserAction , editUserAction , changeUserSurveyPurchasesAction , clearFormResultAction, deleteUserAction} from "./actions";

export default (state={} , action) => {
    switch (action.type) {
        case addNewUserAction.resultAction :
            return {...action.payload.data , action : 'NEW_USER'} ;
        case editUserAction.resultAction :
            return {...action.payload.data , action : 'EDIT_USER'} ;
        case deleteUserAction.resultAction :
            return {...action.payload.data , action : 'DELETE_USER'} ;
        case changeUserSurveyPurchasesAction.resultAction :
            return {...action.payload.data , action : 'CHANGE_USER_SURVEY_PURCHASES'}  ;
        case clearFormResultAction.resultAction :
            return {} ;
        default :
            return state ;
    }
};