import {getAllAcessLevelAction} from "./actions";

export default (state=[] , action) => {
  switch (action.type) {
      case getAllAcessLevelAction.resultAction :
          return action.payload.data ;
      default :
          return state ;
  }

};