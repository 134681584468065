import {GET , POST} from "../common/Action";
import Action from "../common/Action";



//actions
export const getAllAutocompletesAction = new Action('GET_ALL_AUTOCOMPLETES_ACTION' , GET , 'api/FieldValue/GetAllParentFieldValue') ;
export const getAutocompletesAction = new Action('GET_AUTOCOMPLETES_ACTION' , POST ,'api/FieldValue/GetByType') ;
export const addNewAutocompleteAction = new Action('ADD_AUTOCOMPLETE_ACTION' , POST , 'api/FieldValue/add') ;
export const editAutocompleteAction = new Action('EDIT_AUTOCOMPLETE_ACTION' , POST , 'api/FieldValue/update') ;
export const deleteAutocompleteAction = new Action('DELETE_AUTOCOMPLETE_ACTION' , POST , (params)=>`api/FieldValue/remove?id=${params.id}`);

//action creators
export const getAllAutocompletes = (data) => {
  return getAllAutocompletesAction.callApi(data) ;
};



export const getAutocompletes = (id) => {
  return getAutocompletesAction.callApi({fieldValueType: id}) ;
};

export const addNewAutocomplete = (data)=>{
  return addNewAutocompleteAction.callApi(data);
};
export const editAutocomplete= (data) => {
  return editAutocompleteAction.callApi(data) ;
};

export const deleteAutocomplete=(id) => {
  return deleteAutocompleteAction.callApi(null ,{id}) ;
};