import React, { useEffect } from 'react'
import { checkBoxInput, CkInput, RadioInputComponent, renderTextEditor, scrollToFirstError, SelectInput , SimpleInput } from '../components/formFields' ;
import {Fragment} from 'react'
import {Field, formValueSelector , change, reduxForm} from 'redux-form'
import { useTranslation, withTranslation } from 'react-i18next';
import { useState } from 'react';
import classNames from 'classnames';
import {GetTermsAndConditionsTemplates} from './actions'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import FileComponent from '../components/FileComponent'
import _ from 'lodash';
import RichTextEditor from '../components/RichTextEditor'
// {
//     closeModal, submitForm, loading, setFileId,
// }

const validation = (values) => {
    const errors = {};
    if(!values.text){
        errors.text = "Legal document text is required!"
    }
    if(!values.versionName){
        errors.versionName = "Version is required!"
    }
    if(!values.name){
        errors.name ="Name is required!"
    }
    return errors;
  };


const NewLegalDocumentForm = (props)=>{
    const {t} = useTranslation();
    const {change} = props;
    const [text , setText] = useState("")

    

    useEffect(()=>{
        props.setLegalText(text)
    },[text])

    

    return (
        <form>
            <div className='flex flex-row'>
                {!props.fromEdit?
                <Field name='name' label="Legal Document Name" component={SimpleInput} cssClasses='w-4/5'/>
                :null}
                <Field name='versionName' label="Version" component={SimpleInput} cssClasses='w-4/5'/>
            </div>
            <RichTextEditor
                onChangeEditor={(evt) => 
                    setText(evt)
                }
                valueObj={text}
            />
            <Field cssClasses='text-blue-600' type='checkbox' component={checkBoxInput} name='activeInWebsite' label='Is activate in website'/>
            <div className="aggregateAction inlineView rightFloat">
                <div className="normalButton inlineView grayBackground whiteColor clickShape bottomNormalMargin topNormalMargin" onClick={props.closeModal}>
                    Cancel
                </div>
                <div className={classNames("normalButton inlineView whiteColor topNormalMargin bottomNormalMargin leftNormalMargin" , {"blueBackground clickShape" : !props.loading , "grayBackground" : props.loading})} onClick={props.handleSubmit}>
                    Create
                </div>
            </div>           
        </form>
    )
}




const selector=formValueSelector('NewTermsAndConditionForm'); // <-- same as form name
const mapStateToProps = (state , ownProps) => {
    const templateSelector = selector(state ,'template');
    return {
        templates: state.TermsAndConditionsAllTemplates,
        templateSelector,
    }
};

const form = connect(mapStateToProps , {change})(withRouter(withTranslation()(NewLegalDocumentForm)));

const ReduxNewLegalDocumentForm = reduxForm({
    // a unique name for the form
    form: 'NewLegalDocumentForm', 
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    enableReinitialize : true ,
    onSubmitFail:  (errors) => scrollToFirstError(errors),
    validate:validation
})(form);

export default withRouter(withTranslation()(ReduxNewLegalDocumentForm)) ;