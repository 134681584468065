import React, {useState , useEffect , Fragment} from 'react' ;
import {connect} from 'react-redux' ;
import {renderSimpleInputField , SelectInput} from "../components/formFields";
import { Field, reduxForm} from 'redux-form';
import { initial } from 'lodash';
import { useTranslation } from 'react-i18next';

const validate = (values) => {
    let errors = {};
    if (!values.value) {
        errors.value = 'name is Required' ;
    }
    return errors ;
};

const FormModal =(props)=>{
    const {t} = useTranslation();
    const [inputText , setInputText] = useState('') ;
    const {handleSubmit , allDataprovider , entityTypes} =props;
    
    return(
        <div className="popup profiles">
            <div className="popupClose" onClick={props.closeModal}></div>
            <div className="popUpBox">
                <div className="popupHeader spaceBetween organizer">
                    <div className="popUpTitle font-size-24 mediumWeight">
                        {props.title}
                    </div>
                    <div className="popupCross clickShape" onClick={props.closeModal}>
                        <span className="icon icon-cross font-size-18 lightGrayColor"></span>
                    </div>
                </div>
                <div className="popUpContent organizer">
                    <Fragment>
                        <div className="organizer">{props.message}</div>
                        <div className="inputBox" style={{padding:'10px'}}>
                            <Field name='dataProvider' options={allDataprovider?allDataprovider:[]} getOptionValue={e => e.id} getOptionLabel={e=> e.firstName+" "+e.lastName} component={SelectInput} label='Data provider'/>
                            <Field name='entityTypes' options={entityTypes?entityTypes:[]} component={SelectInput} isMulti={true} label='Entity type'/>
                        </div>
                        <div className="aggregateAction inlineView rightFloat">
                            <div className="normalButton inlineView redBackground whiteColor clickShape topNormalMargin" onClick={props.closeModal}>
                                {t('Cancel')}
                            </div>
                            <div className={"normalButton inlineView whiteColor topNormalMargin leftNormalMargin greenBackground clickShape " } onClick={handleSubmit}>
                                {t('Confirm')}
                            </div>
                        </div>
                    </Fragment>
                </div>
            </div>
        </div>
    ) ;
}
const mapStateToProps = (state , ownProps) => {
    return {
          
    };
  };
  const form = connect(mapStateToProps , {})(FormModal);

  const ReduxForm = reduxForm({
      // a unique name for the form
      form: 'AutocompleteForm', 
      destroyOnUnmount: false, // <------ preserve form data
      forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
      enableReinitialize : true ,
      validate,
  })(form);
  
  export default ReduxForm;