import React, { useEffect } from 'react'
import { checkBoxInput, CkInput, RadioInputComponent, renderTextEditor, scrollToFirstError, SelectInput , SimpleInput } from '../components/formFields';
import {Fragment} from 'react'
import {Field, formValueSelector , change, reduxForm} from 'redux-form'
import { useTranslation, withTranslation } from 'react-i18next';
import { useState } from 'react';
import classNames from 'classnames';
import {getTermsAndConditions} from './actions'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import FileComponent from '../components/FileComponent';
import _ from 'lodash';
import RichTextEditor from '../components/RichTextEditor';
import {GetTermsAndConditionsTemplates} from './actions'
import ReduxNewTermsAndConditionsForm from './newTermsForm'


const EditTermsAndConditionsModal = (props)=>{
    const {t} = useTranslation();
    const {change , id , termsAndConditions , titleSelector} = props;
    const [selectedVersion , setSelectedVersion] = useState(null)
    const [allVersions , setAllVersions] = useState(null)
    const [newTerms , setNewTerms] = useState(false)
    const [text , setText] = useState("")
    const [newText, setNewText] = useState("")
    const [changeText , setChangeText] = useState(false)

    

    
    useEffect(()=>{
        props.GetTermsAndConditionsTemplates()
    },[])

    useEffect(()=>{
        if(!id)
            return
        props.getTermsAndConditions({id})
    },[id])

    useEffect(()=>{
        if(!termsAndConditions || !termsAndConditions.versions)
            return 
        change('EditTermsAndConditionsModal','title' , termsAndConditions.title)
        setAllVersions([...termsAndConditions.versions.map(v => ({...v , label:v.versionName , value:v.id})),{value:-1 , label:'Add new version'}])
    },[termsAndConditions])

    useEffect(()=>{
        if(!selectedVersion)
            return
        if(selectedVersion.value==-1){
            setNewTerms(true)
            setText("")
            setChangeText(!changeText)
        }else{
            setNewTerms(false)
            change('EditTermsAndConditionsModal' , 'versionName' , selectedVersion.versionName)
            change('EditTermsAndConditionsModal' , 'activeVersion' ,selectedVersion.id == termsAndConditions.activeVersionId )
            setText(selectedVersion.text)
            setChangeText(!changeText)
        }
    },[selectedVersion])

    const newVersionFunc = (values) =>{
        props.onSubmit({...values, isNew:true,text:newText, title:titleSelector})
    }
    
    
    return (
        <form>
            <div className="popup profiles">
                <div className="popupClose" onClick={props.closeModal}></div>
                <div className="popUpBox" style={{width:'90%' }}>
                    <div className="popupHeader spaceBetween organizer">
                        <div className="popUpTitle font-size-24 mediumWeight">
                            Edit Terms & conditions
                        </div>
                        <div className="popupCross clickShape" onClick={props.closeModal}>
                            <span className="icon icon-cross font-size-18 lightGrayColor"></span>
                        </div>
                    </div>
                    <div className="popUpContent organizer" style={{maxHeight:'550px' , overflowY:'visible'}}>
                        {termsAndConditions?
                        <Fragment>
                            <div className='flex flex-row' style={{marginBottom:'1rem' }} >
                                <div className='w-2/5'>
                                    <Field name='title' label="Terms And Conditions Title" component={SimpleInput}/>                    
                                </div>
                                <div className='pl-4'>
                                    <Field  name='version' onChange={(v)=> setSelectedVersion(v)} options={allVersions} label="Version" component={SelectInput}/>
                                </div>
                            </div>
                            {selectedVersion?
                                <Fragment>
                                    {!selectedVersion.termsFileId && !newTerms?
                                        <RichTextEditor
                                            setContentChange = {setChangeText} 
                                            contentChange={changeText}
                                            onChangeEditor={(evt) => 
                                                setText(evt)
                                            }
                                            disable={true}
                                            valueObj={text}
                                        />
                                    :null}
                                    {newTerms?
                                        <ReduxNewTermsAndConditionsForm
                                            fromEdit ={true}
                                            closeModal={props.closeModal} 
                                            onSubmit={newVersionFunc}
                                            loading = {props.loading}
                                            setFileId = {props.setFileId}
                                            setTermsAndConditionsText={setNewText}
                                        />
                                    :
                                        <>
                                            <Field cssClasses='text-blue-600' type='checkbox' component={checkBoxInput} name='activeVersion' label='Is activate in website'/>
                                            <div className="aggregateAction inlineView rightFloat">
                                                <div className="normalButton inlineView grayBackground whiteColor clickShape topNormalMargin" onClick={props.closeModal}>
                                                    Cancel
                                                </div>
                                                <div className={classNames("normalButton inlineView whiteColor topNormalMargin leftNormalMargin" , {"blueBackground clickShape" : !props.loading , "grayBackground" : props.loading})} onClick={props.handleSubmit}>
                                                    Save changes
                                                </div>
                                            </div> 
                                        </>
                                    }
                                </Fragment>
                            :null}
                        </Fragment>
                        :null}
                    </div>
                </div>
            </div>
        </form>
    )
}




const selector=formValueSelector('EditTermsAndConditionsModal'); // <-- same as form name
const mapStateToProps = (state , ownProps) => {
    const titleSelector = selector(state , 'title')
    return {
        titleSelector,
        termsAndConditions:state.TermsAndConditionsGetTermsAndConditions
    }
};

const form = connect(mapStateToProps , {change, getTermsAndConditions,GetTermsAndConditionsTemplates})(withRouter(withTranslation()(EditTermsAndConditionsModal)));

const ReduxEditTermsAndConditionsForm = reduxForm({
    // a unique name for the form
    form: 'EditTermsAndConditionsModal', 
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    enableReinitialize : true ,
    onSubmitFail:  (errors) => scrollToFirstError(errors),
    // validate
})(form);

export default withRouter(withTranslation()(ReduxEditTermsAndConditionsForm)) ;