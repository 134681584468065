import {addNewResourceAction , deleteResourceAction, editResourceAction , importResourcesFromExcelAction , exportResourcesToExcelAction} from "./actions";

export default (state={} , action) => {
    switch (action.type) {
        case addNewResourceAction.resultAction :
            return {...action.payload.data , action : 'NEW_RESOURCE'} ;
        case editResourceAction.resultAction :
            return {...action.payload.data , action : 'EDIT_RESOURCE'} ;
        case deleteResourceAction.resultAction :
            return {...action.payload.data , action : 'DELETE_RESOURCE'} ;
        case importResourcesFromExcelAction.resultAction :
            return {...action.payload.data , action : 'IMPORT_RESOURCE'} ;
        case exportResourcesToExcelAction.resultAction :
            return {data:action.payload.data , action : 'EXPORT_RESOURCE'} ;
        default :
            return state ;
    }
};