import {GetCustomPageMetaTagsAction } from "./actions";
import _ from 'lodash'

export default (state={} , action) => {
    switch (action.type) {
        case GetCustomPageMetaTagsAction.resultAction :
            return {data:action.payload.data, pageId:action.payload.postedData.pageId};
        default:
            return state;
    }
}