import React , {useState , useEffect} from 'react'
import { Field, reduxForm } from 'redux-form'
import {connect} from "react-redux";
import { renderSimpleInputField , renderTextAreaField , renderDropdown , renderTextEditor } from "../components/formFields";
import _ from 'lodash' ;
import classNames from 'classnames' ;


const validate = (values) => {
    let errors = {};

    if (!values.value) {
        errors.value = 'Enter the Label' ;
    }
    if (!values.key) {
        errors.key = 'Enter the key' ;
    }

    return errors ;
};

let EditLabel = props => {
    const { handleSubmit , loading , resultMessage , isNew ,setShowModal} = props;
    return (
        <div className="columnSide oneForthColumn normalPadding">
            {resultMessage ? <div className={classNames("topNormalMargin" , {'redColor' : !resultMessage.success , 'greenColor' : resultMessage.success})}>{resultMessage.message}</div> : null}
            <div className="mainInfo organizer">
                <div className="organizer topUnitMargin">
                    <Field name="value" component={renderSimpleInputField} label="label"/>
                </div>
            </div>
            <div className="mainInfo organizer">
                <div className="organizer topUnitMargin">
                    <Field name="key" component={renderSimpleInputField} label="key"/>
                </div>
            </div>
            <div className="organizer topUnitMargin">
                <div className={"normalButton inlineView blueBackground whiteColor"} onClick={handleSubmit}>
                    {isNew?"Add":"Save Changes"}
                </div>
            {isNew?
                null:
                
                <div className={"normalButton inlineView grayOutline redColor leftNormalMargin"} onClick={()=>setShowModal(true)}>
                    Delete Label
                </div> 
           }
           </div>
        </div>
    ) ;
};

const mapStateToProps = state => {
    return {
        languages : state.languages
    };
};

const form = connect(mapStateToProps , {})(EditLabel);

const EditLabelForm = reduxForm({
    // a unique name for the form
    form: 'editLabelForm',
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    enableReinitialize : true ,
    validate
})(form);

export default EditLabelForm;