import React from 'react' ;
import {connect} from 'react-redux' ;
import {renderSimpleInputField} from "../components/formFields";
import { Field, reduxForm} from 'redux-form';
import Dropzone from 'react-dropzone';

const validate = (values) => {
    let errors = {};
    if (!values.name) {
        errors.name = 'Name is Required' ;
    }
    
    return errors ;
};


;
const ExtensionForm =(props)=>{
    const {isNew ,setIsNew ,setIsDelete, handleSubmit , isFileUploaded , selectedExtension } = props;
    const sty = isNew? {borderTop:'none'}:{};
    
    
    return(
        <div style={sty} className="organizer p-4">
            <div className="inputBox">
                <Field name='name' cssClasses="inputBox topUnitMargin" component={renderSimpleInputField} label='Extension' />
            </div>
            <div className="organizer topNormalMargin">
                <div className="normalButton inlineView blueBackground whiteColor clickShape" onClick={handleSubmit}>
                    {isNew?"Add":"Save Changes"}
                </div>
                <div className="normalButton inlineView grayOutline redColor leftNormalMargin clickShape" onClick={()=>isNew?setIsNew(false):selectedExtension? setIsDelete(true):null}>
                    {isNew?"Cancel":"Delete Extension"}   
                </div>
            </div>
        </div>
    );
}


const mapStateToProps = (state , ownProps) => {
    return {
          
    };
  };
  const form = connect(mapStateToProps , {})(ExtensionForm);

  const ReduxForm = reduxForm({
      // a unique name for the form
      form: 'ExtensionForm', 
      destroyOnUnmount: false, // <------ preserve form data
      forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
      enableReinitialize : true ,
      validate,
  })(form);
  
  export default ReduxForm;