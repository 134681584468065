import {GET , POST , PUT , POST_FORM} from "../common/Action";
import Action from '../common/Action' ;

//actions
export const getUsageHistoryAction = new Action('GET_USAGE_HISTORY' , POST , 'api/history/GetUsageHistory') ;



//action creators
export const getUsageHistory = (data) => {
    return getUsageHistoryAction.callApi(data) ;
};