import {GetAllDefaultBannerAction} from "./actions";
import _ from 'lodash'

export default (state={} , action) => {
    switch (action.type) {
        case GetAllDefaultBannerAction.resultAction :
            return {...state ,
                result:action.payload.data
            };
        default:
            return state;
    }
}