import { combineReducers } from "redux";
import { reducer as formReducer } from 'redux-form';
import authenticationReducers from '../authentication/reducers' ;
import usersReducers from '../users/reducer' ;
import folderHistoryReducers from '../folderHistory/reducer' ;
import searchHistoryReducers from '../searchHistory/reducer' ;
import dashboardReducers from '../dashboard/reducer' ;
import usageHistoryReducers from '../usageHistory/reducer' ;
import commissionReducers from '../commissions/reducer' ;
import topicReducers from '../topics/reducer' ;
import mapsReducers from '../maps/reducer' ;
import messagesReducers from '../messages/reducer' ;
import plansReducers from '../plans/reducer' ;
import websiteContentReducers from '../websiteContent/reducer' ;
import CustomPagesReducers from '../customPages/reducer' ;
import LabelsReducers from '../labels/reducer' ;
import AutocompleteReducers from '../autocomplete/reducer';
import FaqReducers from '../faq/reducer';
import ProfileReducer from '../Profile/reducer';
import GeographicReducer from '../geographicRegion/reducer';
import ExtensionReducer from '../uploadableExtension/reducers'
import approvedVendorReducers from '../ApprovedVendors/reducer'
import approvedPersonReducers from '../ApprovedPersons/reducer'
import approvedDatasetReducers from '../ApprovedDatasets/reducer'
import approvedTopicReducers from '../ApprovedTopics/reducer'
import approvedFieldValueReducers from '../ApprovedFieldValues/reducer'
import approvedContractReducer from '../ApprovedContracts/reducer'
import resourceReducers from '../resources/reducer'
import contractingReducers from "../contracting/reducer";
import languagesReducers from '../languages/reducer'
import detailedUsageHistoryReducers from '../usageHistoryInner/reducer'
import templatesReducers from '../templates/reducer'
import legalDocsReducer from '../legalDocuments/reducers'
import approvedDataRolesReducer from '../ApprovedDataRoles/reducers';
import uploadDefaultBannerReducer from '../defaultBanner/reducer';
import approvalRulesReducer from '../ApprovalRules/reducer';
import metaTagReducer from '../MetaTags/reducers'

export const initialState = {};

export default combineReducers({
    form : formReducer ,
    ...authenticationReducers ,
    ...usersReducers ,
    ...folderHistoryReducers ,
    ...searchHistoryReducers ,
    ...usageHistoryReducers ,
    ...dashboardReducers ,
    ...commissionReducers ,
    ...topicReducers ,
    ...mapsReducers ,
    ...messagesReducers ,
    ...plansReducers ,
    ...websiteContentReducers ,
    ...CustomPagesReducers ,
    ...LabelsReducers,
    ...AutocompleteReducers,
    ...FaqReducers,
    ...ProfileReducer,
    ...GeographicReducer,
    ...ExtensionReducer,
    ...approvedVendorReducers,
    ...approvedPersonReducers,
    ...approvedDatasetReducers,
    ...approvedTopicReducers,
    ...approvedFieldValueReducers,
    ...resourceReducers,
    ...approvedContractReducer,
    ...contractingReducers,
    ...languagesReducers,
    ...detailedUsageHistoryReducers,
    ...templatesReducers,
    ...legalDocsReducer,
    ...approvedDataRolesReducer,
    ...uploadDefaultBannerReducer,
    ...approvalRulesReducer,
    ...metaTagReducer
});