

import FaqsReducer from './reducer.getAllFaqs' ;
import selectedFaqReducer from './reducer.getSelectedFaq';
import faqResultReducer from './reducer.faqResult';

export default {
    faqs : FaqsReducer ,
    selectedFaq:selectedFaqReducer,
    faqResult : faqResultReducer
};