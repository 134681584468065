import {action} from "./action-helper";
import {FAILURE} from "../failure/action.failure";
import identityReducer from './identity-reducer';

export const API = 'API';

export const GET = 'get';
export const POST = 'post';
export const POST_FORM = 'post_form';
export const PUT = 'put' ;
export const POST_FILE = 'post_file'

export default class Action {

    constructor(resultAction, method, apiRoute, failureAction){
        this._resultAction = resultAction;
        this._method = method;
        this._apiRoute = apiRoute;
        this._failureAction = failureAction;
    }

    setResult(payload){
        return action(this._resultAction, payload)
    }

    failure(payload){
        return action(this._failureAction, payload)
    }

    _getApiRoute(params){
        return typeof this._apiRoute === "function" ? this._apiRoute(params) : this._apiRoute;
    }

    callApi(data, params){
        return action(API, {
            method: this._method,
            url: this._getApiRoute(params),
            data,
            success: (responseData) => this.setResult({data: responseData, postedData: data, params}),
            failed: (data) => action(this._failureAction || FAILURE, {data, url: this._getApiRoute(params)})
        })
    }

    get resultAction(){
        return this._resultAction;
    }

    get failureAction(){
        return this._failureAction;
    }

    get cleanUpAction(){
        return 'CLEAN_UP_' + this._resultAction;
    }

    cleanUp() {
        return action(this.cleanUpAction)
    }

    getIdentityReducerThisObj(initialValue){
        return {selectedAction: this._resultAction, initialValue: initialValue === undefined ? {} : initialValue}
    }

    getIdentityReducer(initialValue){
        return identityReducer.bind(this.getIdentityReducerThisObj(initialValue));
    }

    getIdentityWithFailureReducerThisObj(initialValue){
        return {resultAction: this._resultAction, failureAction: this._failureAction,
             initialValue: initialValue === undefined ? {} : initialValue}
    }

    getIdsReducerThisObj(idSelector){
        return {selectedAction: this._resultAction, initialValue: [] , idSelector: (idSelector || (e => e.id))}
    }

    getPagingReducerThisObj(idSelector){
        return {selectedAction: this._resultAction, initialValue: {} , idSelector: (idSelector || (e => e.id))}
    }

    getListReduceThisObj(key){
        return {selectedAction: this._resultAction, initialValue: {} , key: (key || 'id')}
    }
}