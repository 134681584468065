import mapsReducer from './reducer.maps' ;
import mapsTotalCountReducer from './reducer.mapsTotalCount' ;
import selectedMaps from './reducer.selectedMaps';
import resultMessage from './reducer.resultMessage';

export default {
    maps : mapsReducer ,
    mapsTotalCount : mapsTotalCountReducer,
    selectedMaps : selectedMaps ,
    MapResultMessage : resultMessage 
};