import {GET , POST , PUT , POST_FORM} from "../common/Action";
import Action from "../common/Action";

//actions
export const getAllFaqsAction = new Action('GET_ALL_FAQS_ACTION' , POST , 'api/content/GetAllFaqs') ;
export const getFaqAction = new Action('GET_FAQ_ACTION' , GET , params => `api/content/GetFaq?id=${params.id}`) ;
export const addFaqAction = new Action('ADD_FAQ_ACTION' , POST , 'api/content/AddFaq') ;
export const updateFaqAction = new Action('UPDATE_FAQ_ACTION' , POST , 'api/content/UpdateFaq') ;
export const deleteFaqAction = new Action('DELETE_FAQ_ACTION' , GET , params => `api/content/DeleteFaq?id=${params.id}`) ;

//action creators
export const getAllFaqs = (data) => {
  return getAllFaqsAction.callApi(data) ;
};
export const getFaq = (id) => {
  if(!id)
    return ;
  return getFaqAction.callApi(null , {id}) ;
};
export const addFaq = (data) => {
    if(!data) return;
    return addFaqAction.callApi(data) ;
};
export const updateFaq = (data) => {
    return updateFaqAction.callApi(data) ;
};
export const deleteFaq = (id) => {
    return deleteFaqAction.callApi(null , {id}) ;
};

