import {GET , POST , PUT , POST_FORM} from "../common/Action";
import Action from "../common/Action";

//actions
export const getVoxdashStaticContractsAction =  new Action('CONTRACTING_GET_STATICS' , GET , 'api/contract/GetStaticVoxdashContracts') ;
export const updateContractAction = new Action('CONTRACTING_UPDATE_CONTRACT_ACTION' , POST , 'api/contract/update' )

//action creators
export const GetStaticVoxdashContracts = () => {
    return getVoxdashStaticContractsAction.callApi() ;
};


export const UpdateContract = (data) =>{
    return updateContractAction.callApi(data);
};

