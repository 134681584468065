import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import _, { isNull } from "lodash";
import { withRouter, Link, useHistory, useParams } from "react-router-dom";
import { withTranslation } from "react-i18next";
import classNames from "classnames";
import TableComponent from '../components/TableComponent';
import {getTermsAndConditionsHistory} from './actions';
import SimpleLoading from "../components/simpleLoading";

const LegalDocHistory = (props) => {
    const {t  ,allTermsVersions} = props;
    const [loading ,  setLoading] = useState(false)
    const [dtParameter , setDtParameter] = useState({
        Current:1,
        pageSize:10
    })
    const [rows , setRows] = useState([])
    const [columns  , setColumns] = useState(null)
    const [version , setVersion] = useState(null)
    const [errorMessage , setErrorMessage] = useState(null)
    const params = useParams();
    const id = params.id



    useEffect(()=>{
        if(!id)
            return
        props.getTermsAndConditionsHistory({
            dtParameter:dtParameter,
            legalDocumentId:id
        })
    },[id])

   

    useEffect(()=>{
        if(!dtParameter)
            return
        props.getTermsAndConditionsHistory({
            dtParameter:dtParameter,
            legalDocumentId:id
        })
    },[dtParameter])

    useEffect(()=>{
        if(!allTermsVersions||_.isEmpty(allTermsVersions))
            return
            setRows(
                allTermsVersions.data
                )
        if(!columns){
            setColumns([...allTermsVersions.columns])
        }
    },[allTermsVersions])



    return(
        !columns?
        <SimpleLoading/>
        :
           <Fragment>
                <div className="mainHeader organizer font-size-24 mediumWeight">
                    <div className="titleItems ">
                        {t("Terms and conditions versions history")}    
                    </div>
                    {/* <SelectInput value={version} options={allVersions} onChange={(v)=>setVersion(v)} /> */}
                </div>
           
            {errorMessage?
                <div>{errorMessage}</div>
                :null}
            {!_.isEmpty(allTermsVersions) && columns?
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="adminBoilerplate organizer">
                        <TableComponent 
                            removeToolbar = {false}
                            pageSizes={[5,10,15]} 
                            totalCount={allTermsVersions?allTermsVersions.total:0} 
                            selectedList={[]} 
                            rows={rows} 
                            columns={columns}
                            setSelectedList={(e)=>{}} 
                            setDtParameter={setDtParameter} 
                            loading = {loading}
                            dtParameter = {dtParameter}
                        />
                    </div>
                </div>
            </div>
            :null}
        <br/>
        <br/>
        <br/>
        </Fragment>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        allTermsVersions:state.LegalDocsGetLegalDocHistory
    };
  };
  export default connect(mapStateToProps, {
    getTermsAndConditionsHistory
  })(withRouter(withTranslation()((LegalDocHistory))));