import {GET , POST , PUT , POST_FORM} from "../common/Action";
import Action from "../common/Action";

//actions
export const getTopicsAction = new Action('GET_TOPICS' , POST , 'api/topics/GetTopics') ;
export const getTopicAction = new Action('GET_TOPIC' , GET , params => `api/topics/GetTopic?id=${params.id}`) ;
export const addTopicAction = new Action('ADD_TOPIC' , POST , 'api/topics/AddTopic') ;
export const editTopicAction = new Action('EDIT_TOPIC' , POST , 'api/topics/EditTopic') ;
export const deleteTopicAction = new Action('DELETE_TOPIC' , GET , params => `api/topics/DeleteTopic?id=${params.id}`) ;

export const getSubTopicsAction = new Action('GET_SUB_TOPICS' , POST , 'api/subTopics/GetSubTopics') ;
export const getSubTopicAction = new Action('GET_SUB_TOPIC' , GET , params => `api/subTopics/GetSubTopic?id=${params.id}`) ;
export const addSubTopicAction = new Action('ADD_SUB_TOPIC' , POST , 'api/subTopics/AddSubTopic') ;
export const editSubTopicAction = new Action('EDIT_SUB_TOPIC' , POST , 'api/subTopics/EditSubTopic') ;
export const deleteSubTopicAction = new Action('DELETE_SUB_TOPIC' , GET , params => `api/subTopics/DeleteSubTopic?id=${params.id}`) ;


//action creators
export const getTopics = (data , ParentId) => {
  if (ParentId)return getSubTopicsAction.callApi({...data , ParentId});
  return getTopicsAction.callApi(data) ;
};
export const getTopic = (id , ParentId) => {
  if (ParentId)return getSubTopicAction.callApi(null , {id}) ;
  return getTopicAction.callApi(null , {id}) ;
};
export const addTopic = (topic , ParentId) => {
  if (ParentId)return addSubTopicAction.callApi({...topic , ParentId}) ;
  return addTopicAction.callApi(topic) ;
};
export const editTopic = (topic , ParentId) => {
  if (ParentId)return editSubTopicAction.callApi({...topic , ParentId}) ;
  return editTopicAction.callApi(topic) ;
};
export const deleteTopic = (id , ParentId) => {
  if (ParentId)return deleteSubTopicAction.callApi(null , {id}) ;
  return deleteTopicAction.callApi(null , {id}) ;
};

