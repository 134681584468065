import React, {useState, useEffect, Fragment} from 'react' ;
import {connect} from 'react-redux' ;
import classNames from 'classnames' ;
import ReduxForm from './ExtensionForm.js'
import DeleteModal from '../components/cmp.modal' ;
import UploadFile from '../components/FileUploaderNew';
import Pagination from "../components/pagination";
import SimpleLoading from '../components/simpleLoading';
import _ from 'lodash' ;
import {getExtensions , newExtension , editExtension , deleteExtension , newIcon , importRestrictedExtension} from './actions';

const UploadableExtension=(props)=>{
    let {resultMessage , allExtensions , token } =props;
    const [isNew , setIsNew] = useState(false);
    const [isDelete , setIsDelete] = useState(false);
    const [readExcel , setReadExcel] = useState(false);
    const [extensionFormResult, setFormResult]=useState({});
    const [isFileUploaded , setIsFileUploaded] = useState(false);
    const [uploadedFileId , setUploadedFileId] = useState(null);
    const [pageNumber , setPageNumber] = useState(1) ;
    const [viewByPage , setViewByPage] = useState(8) ;
    const [selectedExtension , setSelectedExtension] = useState(null);
    const [message , setMessage] = useState(null)
    
    const updateList = (selectedPageNumber  , selectedViewByPage) => {
        setPageNumber(selectedPageNumber) ;
        setViewByPage(selectedViewByPage) ;
    };
    
    const onConfirm = (values)=>{
        if(isNew){
            const data ={
                RestrictedExtensionDto:{
                    name:values.name
                }
            }
            props.newExtension(data)
        }else if(isDelete){

        }else{

            let data ={RestrictedExtensionDto:{
                ...values}}
            props.editExtension(data);
        }
    }
    
    const onFileUploaded = (file) =>{
        props.importRestrictedExtension({fileId:file[0].savedFileId})
    }

    const onDeleteConfirm = ()=>{
        setIsDelete(false);
        props.deleteExtension({ExtensionId:selectedExtension.id}) 
    }

    useEffect(()=>{
        const data = {
            PageNumber : pageNumber ,
            PageSize : viewByPage 
        };
        props.getExtensions(data);
    } , [])

    useEffect(()=>{
        if(selectedExtension)
            setIsNew(false)
    } , [selectedExtension])

    useEffect(()=>{
        if(isNew)
            setSelectedExtension(false)
    } , [isNew])


    useEffect(()=>{
        if(!allExtensions||!allExtensions.extensions){
            return ;
        }
        setSelectedExtension(
            _.map(allExtensions.extensions[pageNumber],extension=>{
                return extension
            })[0]
        )
    } , [allExtensions])

    
    useEffect(()=>{
        if(!resultMessage){
            return
        }
        if(resultMessage.action ==="NEW_ICON"){
            setUploadedFileId(resultMessage.id);
            setIsFileUploaded(true);
        }else if(resultMessage.action ==="NEW"){
            const data = {
                PageNumber : pageNumber ,
                PageSize : viewByPage 
            };
            props.getExtensions(data)
        }else if(resultMessage.action==="UPDATE"){
            const data = {
                PageNumber : pageNumber ,
                PageSize : viewByPage 
            };
            props.getExtensions(data)
        }else if(resultMessage.action==="DELETE"){
            const data = {
                PageNumber : pageNumber ,
                PageSize : viewByPage 
            };
            props.getExtensions(data)
        }else if(resultMessage.action ==="IMPORT"){
            const data = {
                PageNumber : pageNumber ,
                PageSize : viewByPage 
            };
            props.getExtensions(data)
        }
    },[resultMessage])

    useEffect(()=>{
        if(!allExtensions ||!allExtensions.extensions){
            return
        }
        if(!allExtensions.extensions[pageNumber]){
            const data = {
                PageNumber : pageNumber ,
                PageSize : viewByPage 
            };
            props.getExtensions(data)
        }
    },[pageNumber])

    useEffect(()=>{
        const data = {
            PageNumber : pageNumber ,
            PageSize : viewByPage 
        };
        allExtensions = {};
        props.getExtensions(data)
    },[viewByPage])

    return(
        <Fragment>
            <div className="mainHeader organizer font-size-24 mediumWeight">
                <div className="titleItems">
                    <span className="mainTitle middleContext">Restricted Extension</span>
                </div>
                <div className="createNewPlan inlineView clickShape" onClick={()=>setIsNew(true)}>
                    <span className="icon icon-add"></span> New Extension
                </div>
                <div className="filterItems tempDisable">
                    <div className="filters inlineView">
                        <div className="changeDate inlineView"></div>
                        <div className="accessLevel inlineView"></div>
                    </div>
                    <span className="icon icon-zoom font-size-18 clickShape tempDisable"></span>
                </div>
            </div>
           {allExtensions?
            <Fragment>
                <div className="adminBoilerplate organizer">
                    <div className="twoSidePanel organizer">
                        <div className="columnSide leftBorder websiteContent">
                            <table className="tableStructure organizer">
                                <thead className="tableHeader">
                                    <tr>
                                        <th>Extensions</th>
                                    </tr>
                                </thead>
                                <tbody className="">
                                    {allExtensions.extensions?
                                    _.map(allExtensions.extensions[pageNumber] , extension=>(
                                        <tr key={extension.id} onClick={()=>setSelectedExtension(extension)} className={classNames({'active':selectedExtension?selectedExtension.id === extension.id:false})}>
                                            <td>{extension.name}</td>
                                        </tr>
                                    )):null}
                                </tbody>
                            </table>
                            
                        </div>
                        <div className="columnSide normalPadding" style={{width:'50%'}}>
                            {extensionFormResult ? 
                                <div className={classNames("topNormalMargin" , {'redColor' : !extensionFormResult.success , 'greenColor' : extensionFormResult.success})}>{extensionFormResult.message}</div> 
                            : null}
                            <div className="grid grid-cols-1">
                            {isNew?
                                <Fragment>
                                    <div className="organizer">
                                        <span className={classNames("newButton inlineView clickShape",{"activeLeft":!readExcel})} onClick={()=>setReadExcel(false)}>
                                            Add extension
                                        </span>
                                        <span className={classNames("newButton inlineView clickShape ",{"activeRight":readExcel})} onClick={()=>setReadExcel(true)}>
                                            upload file
                                        </span>
                                    </div>
                                    <div className='border border-solid border-[#f3f3f3]'>
                                    {readExcel?
                                        <UploadFile
                                            onFileUploaded = {onFileUploaded}
                                            setMessage = {setMessage}
                                            width={'100%'}
                                            height={'100%'}
                                            storageType={0}
                                        />
                                    :
                                        <ReduxForm
                                            isNew={isNew}
                                            setIsNew={(value)=>setIsNew(value)}
                                            setIsDelete={(value)=>setIsDelete(value)}
                                            initialValues={{name:''}}
                                            onSubmit={(val)=>onConfirm(val)}
                                            isFileUploaded ={isFileUploaded}
                                            // fileSubmit = {(val)=>onConfirmFileUpload(val)}
                                            selectedExtension ={isNew?null:selectedExtension?selectedExtension:null}
                                        />
                                    }
                                    </div>
                                </Fragment> 
                            :selectedExtension?
                                <ReduxForm
                                    isNew={isNew}
                                    setIsNew={(value)=>setIsNew(value)}
                                    setIsDelete={(value)=>setIsDelete(value)}
                                    initialValues={selectedExtension?selectedExtension:{}}
                                    onSubmit={(val)=>onConfirm(val)}
                                    isFileUploaded ={isFileUploaded}
                                    // fileSubmit = {(val)=>onConfirmFileUpload(val)}
                                    selectedExtension ={isNew?null:selectedExtension?selectedExtension:null}
                                />
                            :null}
                            </div>
                        </div>
                    </div>
                        {allExtensions.extensions&&!_.isEmpty(allExtensions.extensions[pageNumber]) ?
                            <Pagination pageNumber={pageNumber} viewByPage={viewByPage} 
                                changeData={updateList} 
                                totalPages={Math.ceil(allExtensions.totalCount/viewByPage)}/>
                        :null}
                </div>
            </Fragment>
        :<SimpleLoading/>}
            
        {isDelete && selectedExtension ? 
            <DeleteModal closeModal={() => setIsDelete(false)} acceptAction={onDeleteConfirm} data={null} title="Delete Extension" modalMessage={`Are you sure you want to delete ${selectedExtension.name}?`}/> 
        : null}
        </Fragment>
    )
}



const mapStateToProps = (state , ownProps) => {
    return {
        resultMessage :state.allExtensionResultMessage,
        allExtensions:state.allExtensions
    } ;
};

export default connect(mapStateToProps ,{getExtensions , newExtension , editExtension , deleteExtension , newIcon , importRestrictedExtension})(UploadableExtension) ;