import React , {useState , useEffect , Fragment} from 'react' ;
import {connect} from 'react-redux' ;
import LeftNav from '../components/left-menu' ;
import {getAllUsersMessages , getUserMessages , deleteMessage , readAll , sendMessage} from "./actions";
import _ from 'lodash' ;
import classNames from 'classnames' ;
import ChatBox from './cmp.chatBox' ;
import useDebounce from "../components/use-debounce";
import SelectUserModal from './selectUserModal' ;

export const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul","Aug", "Sep", "Oct", "Nov", "Dec"];
const MainPage = props => {

  const [selectedChatId , setSelectedChatId] = useState(null) ;
  const {usersMessages , chatListData} = props ;
  const [searchedChatList , setSearchedChatList] = useState(chatListData) ;
  const [chatSearchText , setChatSearchText] = useState('') ;
  const [chatName , setChatName] = useState(null) ;
  const [showWriteNewMessageModal , setShowWriteNewMessageModal] = useState(false) ;
  const chatSearchQuery = useDebounce(chatSearchText , 500) ;

  useEffect(() => {
      if (chatSearchQuery !== null) {
          let newObj = {} ;
          for (let chat in chatListData) {
              if (chatListData.hasOwnProperty(chat)) {
                  if (chatListData[chat].email.toLowerCase().includes(chatSearchQuery.toLowerCase())) {
                      newObj = {...newObj , [chat] : chatListData[chat]} ;
                  }              }
          }
          setSearchedChatList(newObj) ;
      }

  } , [chatSearchQuery]) ;
  useEffect(() => {
      props.getAllUsersMessages() ;
  } , []) ;
  useEffect(() => {
      if (selectedChatId) {
          props.getUserMessages(selectedChatId) ;
      }
  } , [selectedChatId]) ;
  useEffect(() => {
      setSearchedChatList(chatListData) ;
  } ,[chatListData]) ;

  const onShowWriteNewMessageModalClick = () => {
      setShowWriteNewMessageModal(true) ;
  };
  const onSelectUserAction = (userId , email) => {
      setSelectedChatId(userId) ;
      setShowWriteNewMessageModal(false) ;
      setChatName(email) ;
  };

  return (
      <Fragment>
        <div className="mainHeader organizer font-size-24 mediumWeight">
            <div className="titleItems">
                <span className="mainTitle middleContext">Messages</span>
            </div>
            <div className="filterItems">
                <div className="createNewPlan inlineView clickShape" onClick={() => onShowWriteNewMessageModalClick()}>
                    <span className="icon icon-add"></span> Write New Message
                </div>
            </div>
        </div>
        <div className="adminBoilerplate organizer">
            <div className="twoSidePanel organizer noHeader">
                <div className="columnSide oneQuarterColumn maxHeight">
                    <div className="inputBox">
                        <input value={chatSearchText} onChange={e => setChatSearchText(e.target.value)} disabled={false} type="text" className="chatSearch" placeholder="Search Chat ... " />
                    </div>
                    <ul className="messagedUsers">
                        {_.map(searchedChatList , (item , userId) => {
                            return (
                                <li key={item.id} className={classNames("" , {'unread' : !item.isRead , 'selected' : selectedChatId === userId})} onClick={() => setSelectedChatId(userId)}>
                                    <span className="messageLine"></span>
                                    <div className="notifMesssageContent clickShape">
                                        <span className="messageDate font-size-10 lightGrayColor fullWidth inlineView">
                                            <span className="icon icon-time"></span> {item.dateTime}
                                        </span>
                                        <span className="messengerName font-size-18 fullWidth inlineView">
                                            {item.email}
                                        </span>
                                        <span className="fullWidth messageBrief font-size-12 lightWeight inlineView">{item.text}</span>
                                    </div>
                                    <div className="moreOptions inlineView middleContext clickShape">
                                        <span className="icon icon-options tempDisable"></span>
                                    </div>
                                </li>
                            );
                        }) }
                    </ul>
                </div>
                <div style={{overflow:'hidden'}} className="columnSide threeQuarterColumn">
                    {selectedChatId ? <ChatBox chatName={chatListData[selectedChatId] ? chatListData[selectedChatId].email : chatName} userId={selectedChatId}/>
                        :
                    <div className="noMessageSelected">
                        Please Select a Chat to Start Messaging ...
                    </div>
                    }
                </div>
            </div>
        </div>
        {showWriteNewMessageModal? 
            <SelectUserModal onSelectUserAction={onSelectUserAction} closeModal={() => setShowWriteNewMessageModal(false)} title="Select a User ..."/>
        :null}
    </Fragment>
  ) ;
};

const mapStateToProps = (state , ownProps) => {
    return {
        usersMessages : state.usersMessages ,
        chatListData : state.chatListData
    } ;
};

export default connect(mapStateToProps , {getAllUsersMessages , getUserMessages , sendMessage , deleteMessage , readAll})(MainPage) ;