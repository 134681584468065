import { applyMiddleware, createStore } from 'redux'

import rootReducer, { initialState } from './reducers'
import apiMiddleware from './api'

const bindMiddleware = middleware => {
    if (process.env.NODE_ENV !== 'production') {
        const { composeWithDevTools } = require('redux-devtools-extension')
        return composeWithDevTools(applyMiddleware(...middleware))
    }
    return applyMiddleware(...middleware)
};

function configureStore () {
    const store = createStore(
        rootReducer,
        initialState,
        bindMiddleware([apiMiddleware])
    );

    return store
}

export default configureStore;