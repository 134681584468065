import {setProrityAction} from "./actions";
import { setProrityAction as  personPriorityAction } from "../ApprovedPersons/actions";
import { setProrityAction as vendorPriorityAction  } from "../ApprovedVendors/actions";


export default (state=[] , action) => {
  switch (action.type) {
    case setProrityAction.resultAction:
        return {...action.payload.data , id:action.payload.postedData.ids[0]};
    case personPriorityAction .resultAction:
        return {...action.payload.data , id:action.payload.postedData.ids[0]};
    case vendorPriorityAction.resultAction:
        return {...action.payload.data , id:action.payload.postedData.ids[0]};
      default :
            return state ;
  }
};