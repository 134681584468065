import usersReducer from './reducer.users'
import userDropDownsDataReducer from './reducer.homeData' ;
import singleUserReducer from './reducer.singleUser' ;
import userFormResultReducer from './reducer.userFormResult' ;
import userSurveyPurchasesReducer from './reducer.surveyPurchases' ;
import homeData from './reducer.homeData';

export default {
    users : usersReducer ,
    userDropDownsData : userDropDownsDataReducer ,
    singleUser : singleUserReducer ,
    userFormResult : userFormResultReducer ,
    userSurveyPurchases : userSurveyPurchasesReducer,
    userHomeData:homeData
};