import {addFaqAction , updateFaqAction , deleteFaqAction} from "./actions";

export default (state={} , action) => {
    switch (action.type) {
        case addFaqAction.resultAction :
            return {id:action.payload.data , action : 'NEW_FAQ'} ;
        case updateFaqAction.resultAction :
            return {...action.payload.data , action : 'EDIT_FAQ'} ;
        case deleteFaqAction.resultAction :
            return {...action.payload.data , action : 'DELETE_FAQ'}  ;
        default :
            return state ;
    }
};