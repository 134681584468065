import React , {useState , useEffect , Fragment} from 'react' ;
import {connect} from 'react-redux' ;
import LeftNav from '../components/left-menu' ;
import Pagination from "../components/pagination";
import {getTopics , addTopic , deleteTopic , editTopic , getTopic} from "./actions";
import TopicForm from './topicForm' ;
import _ from 'lodash' ;
import classNames from 'classnames' ;
import SelectBox from "../components/selectBox";
import {dropDownStyles} from "../components/formFields";
import {languagesOptions} from "../customPages/mainPage";
import {getLanguages} from "../languages/actions";
import DeleteModal from '../components/cmp.modal' ;
import SimpleLoading from '../components/simpleLoading';

const MainPage = props => {

    const {topics , topicsTotalCount , singleTopic , singleTopicActionsResult , languages} = props ;
    const [pageNumber , setPageNumber] = useState(1) ;
    const [viewByPage , setViewByPage] = useState(12) ;
    const [subTopicsTabShow , setSubTopicsTabShow] = useState(false) ;
    const [lang , setLang] = useState({label : 'en-US' , value : 1}) ;
    const [formData , setFormData] = useState({}) ;
    const [selectedTopicId , setSelectedTopicId] = useState(null) ;
    const [selectedTopicTitle , setSelectedTopicTitle] = useState(null) ;
    const [buttonLoading , setButtonLoading] = useState(false) ;
    const [formResultMessage  , setFormResultMessage] = useState(null) ;
    const [recentlyTryToAddTopicId , setRecentlyTryToAddTopicId] = useState(null) ;
    const [showDeleteTopicModal , setShowDeleteTopicModal] = useState(null) ;
    const [loading , setLoading] = useState(false)
    
    const getTopicsRequest = (isChanged) => {
        const data = {
            PageNumber : pageNumber ,
            PageSize : viewByPage ,
            Lang : lang.label,
            IsChanged: isChanged
        };
        if(isChanged){
            data.pageNumber = 1
        }
        setFormResultMessage(null)
        setLoading(true)
        props.getTopics(data , subTopicsTabShow) ;
    };

    useEffect(() => {
        props.getLanguages() ;
    } , []) ;


    useEffect(() =>{
        getTopicsRequest(false);
    } , [pageNumber , viewByPage]) ;

    useEffect(() => {
        setFormResultMessage(null)
        if (pageNumber !== 1) 
            setPageNumber(1) ;
        else {
            getTopicsRequest(false);
        }
        setSelectedTopicId(null) ;
    } , [lang]) ;

    useEffect(() => {
        setFormResultMessage(null)
        if (!singleTopic || _.isEmpty(singleTopic))return;
        setLoading(false)
        singleTopicFormValues() ;
    } , [singleTopic]) ;

    const updateList = (selectedPageNumber  , selectedViewByPage) => {
        setFormResultMessage(null)
        setPageNumber(selectedPageNumber) ;
        setViewByPage(selectedViewByPage) ;
    };

    useEffect(()=>{
        if(!topics)
            return
        setLoading(false)
        setFormResultMessage(null)
    },[topics])

    const singleTopicFormValues = () => {
        if (!singleTopic||_.isEmpty(singleTopic))
            return {};
        const data = 
            !singleTopic.localizedTopics || _.isEmpty(singleTopic.localizedTopics[lang.label])
             ? {}
        :{
            topicCoverId : singleTopic.localizedTopics[lang.label].topicCoverId,
            id : singleTopic.id ,
            language : lang ,
            label : singleTopic.localizedTopics[lang.label].title ,
                
            description :  singleTopic.localizedTopics[lang.label].description ,
            // metaTitle : subTopicsTabShow ? 
            //     singleTopic.localizedSubTopics[lang.value].metaTitle
            //     : singleTopic.localizedTopics[lang.value].metaTitle,
            // metaDescription : subTopicsTabShow ? 
            //     singleTopic.localizedSubTopics[lang.value].metaDescription: 
            //     singleTopic.localizedTopics[lang.value].metaDescription
        };
        setFormData(data) ;
    };

    const onFormSubmit = (values) => {
        setFormResultMessage(null) ;
        let postData = {
            Id : values.id ,
        } ;
        postData = {...postData ,
            localizedTopics : {
                [values.language.label] : {
                    TopicId : values.id ,
                    languageId : values.language.label ,
                    Title : values.label ,
                    Description : values.description ,
                    // MetaTitle : values.metaTitle ,
                    // MetaDescription: values.metaDescription ,
                }
            }
        }

        if (selectedTopicId === -1) {
            props.addTopic(postData , subTopicsTabShow) ;
            setRecentlyTryToAddTopicId(values.id) ;
        }
        else {
            props.editTopic(postData , subTopicsTabShow) ;
        }
    };

    const onDeleteTopicClick = () => {
        setShowDeleteTopicModal(selectedTopicId) ;
        setFormResultMessage(null) ;
    };
    const onDeleteTopicConfirm = () => {
        props.deleteTopic(selectedTopicId , subTopicsTabShow) ;
    };

    useEffect(() => {
        if (!_.isEmpty(singleTopicActionsResult)) {
            if (singleTopicActionsResult.action === 'EDIT_TOPIC') {
                if (singleTopicActionsResult.succeeded  || singleTopicActionsResult.success) {
                    setFormResultMessage({success : true , message : 'Successfully Edited.'});
                    setLoading(true)
                    props.getTopic(selectedTopicId , subTopicsTabShow) ;
                    getTopicsRequest(false);
                }
                else {
                    setFormResultMessage({success : false , message : 'Something Unexpected Happened. Please Try Again Later.'});
                }
            }
            else if (singleTopicActionsResult.action === 'DELETE_TOPIC') {
                setShowDeleteTopicModal(null)
                if (singleTopicActionsResult.succeeded  || singleTopicActionsResult.success) {
                    setFormResultMessage({success : true , message : 'Successfully Deleted.'});
                    setSelectedTopicId(null);
                    if (pageNumber !== 1)setPageNumber(1);
                    else getTopicsRequest(false);
                }
                else {
                    setFormResultMessage({success : false , message : 'Something Unexpected Happened. Please Try Again Later.'});
                }
            }
            else {
                if (singleTopicActionsResult.succeeded || singleTopicActionsResult.success) {
                    setFormResultMessage({success : true , message : 'Successfully Added.'});
                    setSelectedTopicId(recentlyTryToAddTopicId) ;
                    getTopicsRequest(true);
                    
                }
                else {
                    setFormResultMessage({success : false , message : 'Something Unexpected Happened. Please Try Again Later.'});
                }
            }
        }
    } , [singleTopicActionsResult]) ;

    useEffect(() => {
        setFormResultMessage(null)
        //setFormResultMessage(null) ;
        if (selectedTopicId!=null && selectedTopicId !== -1) {
            setLoading(true)
            props.getTopic(selectedTopicId , subTopicsTabShow) ;
        }
    } , [selectedTopicId]);


    useEffect(() => {
        setSelectedTopicId(null) ;
        getTopicsRequest(false) ;
    } , [subTopicsTabShow]) ;

  return (
    <Fragment>
        <div className="mainHeader organizer mediumWeight">
            <div className={classNames("titleItems")} style={{width : subTopicsTabShow ? "45%" : "30%"}}>
                <span className="middleContext font-size-24">Topics
                    {subTopicsTabShow ? ` > ${selectedTopicTitle}` : ''}
                </span>
                <SelectBox
                    label={null}
                    value={lang}
                    onChange={lang => setLang(lang)}
                    styles={dropDownStyles}
                    options={languagesOptions(languages)}
                />
            </div>
        </div>
        <div className="adminBoilerplate organizer">
            <div className="twoSidePanel organizer">
                <div className="columnSide">
                {subTopicsTabShow ?
                    <Fragment>
                        <div className="subTopicTopBar fullWidth">
                            <div className="backToTopics clickShape" onClick={() => {setSelectedTopicTitle(null);setSubTopicsTabShow(null)}}>
                                <span className="icon-left-small-arrow">Back</span>
                            </div>
                            <div className="boldWeight leftNormalMargin">{`Topic: ${selectedTopicTitle}`}</div>
                        </div>
                        <hr style={{border: '1px solid #dedede'}}/>
                    </Fragment>
                : null}
                    <table className="tableStructure organizer">
                        <thead className="tableHeader">
                            <th>ID</th>
                            <th>Title</th>
                        </thead>
                        <tbody>

                        {topics[pageNumber] && !loading ? 
                        
                        _.map(topics[pageNumber] , topic => {
                            return (
                            <tr key={topic.id} onClick={() => setSelectedTopicId(topic.id)} className={classNames("topicRow" , {'selected' : selectedTopicId === topic.id})}>
                                <td>{topic.id}</td>
                                <td className="spaceBetween fullWidth">
                                    <span className="leftFloat clickShape" >
                                        {topic.title}
                                    </span>
                                </td>
                                <td>
                                    <span className="tableAction" style={{float: 'right',display: 'flex',width: 'max-content'}}>
                                        <span className="buttonInTable clickShape">
                                            <span className="pinkColor mediumWeight">{topic.followCount}{" "}</span>
                                            Follower
                                        </span>
                                        {subTopicsTabShow ? null :
                                            <span className="buttonInTable clickShape" onClick={() => {setSelectedTopicTitle(topic.title);setSubTopicsTabShow(topic.id)}}>
                                                <span className="blueColor mediumWeight">
                                                    {topic.subTopicCount} Sub Topics
                                                    {/* <span className="inlineView icon icon-edit blueColor font-size-12 leftNormalMargin"></span> */}
                                                </span>
                                            </span>
                                        }
                                    </span>
                                </td>
                            </tr>
                            );
                        }) : 
                        <SimpleLoading />}
                        </tbody>
                    </table>
                </div>
                <div className="columnSide">
                    <div className="columnHeader spaceBetween organizer">
                        <div className="addItem blueColor font-size-18 mediumWeight clickShape" onClick={() => {setSelectedTopicId(-1)}}>
                            <span className="icon icon-add"></span>
                            {subTopicsTabShow ? 'New SubTopic' : 'New Topic'}
                        </div>
                    </div>
                    <div className="columnContent organizer">
                        {/* {formResultMessage ? 
                            <div className={classNames("topNormalMargin" , 
                            {'redColor' : !formResultMessage.success , 'greenColor' : formResultMessage.success})}>
                                {formResultMessage.message}
                            </div>
                        : null} */}
                        {selectedTopicId!=null?
                            <TopicForm resultMessage={formResultMessage} onSubmit={onFormSubmit} deleteTopic={onDeleteTopicClick} initialValues={selectedTopicId !== -1 ? formData : {}} edit={selectedTopicId !== -1} loading={buttonLoading}/>
                            : 
                            <div className="emptyTopic textInCenter">
                                <span className="icon icon-topic font-size-100"></span>
                                <p className="font-size-24 lightWeight topUnitMargin lightGrayColor">
                                    {subTopicsTabShow ? "Please Select a SubTopic First" :"Please Select a Topic First" }
                                </p>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <Pagination pageNumber={pageNumber} viewByPage={viewByPage} changeData={updateList} totalPages={Math.ceil(topicsTotalCount/viewByPage)}/>
        </div>
        {showDeleteTopicModal !== null ? <DeleteModal modalMessage={`Are you sure that you want to delete this topic? `} loading={buttonLoading} resultMessage={formResultMessage} title="Delete Topic" closeModal={() => setShowDeleteTopicModal(null)} acceptAction={onDeleteTopicConfirm}/> : null}
    </Fragment>
  ) ;
};

const mapStateToProps = (state , ownProps) => {
    return {
        topics : state.topics ,
        topicsTotalCount : state.topicsTotalCount ,
        singleTopic : state.singleTopic ,
        singleTopicActionsResult : state.singleTopicActionsResult ,
        languages : state.languages ,
    } ;
};

export default connect(mapStateToProps , {getTopics, getTopic, addTopic, editTopic, getLanguages, deleteTopic})(MainPage) ;