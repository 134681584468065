import React from 'react';
import { lowerFirst } from 'lodash';
import classNames from 'classnames';

const dashboardItem=(props)=>{

    const getPath=(name)=>{
        const pat = lowerFirst(name);
        return pat;
    }
    const path=props.path?props.path:getPath(props.name);
    return (
        <div className={classNames("dashboardItem "+path , {"clickShape":props.hasLink})} onClick={() =>props.hasLink?props.history.push('/'+path):null}>
            <span className={props.color+"Color icon font-size-40  icon-"+props.cls}></span>
            <div className="itemTitle">{props.name}</div>
            <span className="itemLine"></span>
            <div className="itemCount">
                <span className="itemNumber font-size-24 mediumWeight">{props.count}</span>
                <span className="icon icon-right-sign-arrow"></span>
            </div>
        </div>
    );
}
export default dashboardItem;