import {GET , POST , PUT , POST_FORM} from "../common/Action";
import Action from "../common/Action";

//actions
export const GetApprovableAsyncFieldValueAction = new Action('GET_APPROVABLE_FIELD_VALUES' , POST , 'api/FieldValue/GetApprovable') ;
export const ApprovedAsyncFieldValuesAction = new Action('APPROVED_ASYNC_FIELD_VALUE_ACTION' , POST , 'api/FieldValue/Approve');
export const DisApprovedAsyncFieldValuesAction = new Action('DISAPPROVED_ASYNC_FIELD_VALUE_ACTION' , POST , 'api/FieldValue/DisApprove');


//action creators
export const GetApprovableAsyncFieldValue = (data) => {
  return GetApprovableAsyncFieldValueAction.callApi(data) ;
};

export const ApprovedAsyncFieldValues = (data) =>{
  return ApprovedAsyncFieldValuesAction.callApi(data);
}

export const DisApprovedAsyncFieldValues = (data) =>{
  return DisApprovedAsyncFieldValuesAction.callApi(data);
}