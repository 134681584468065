import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { withRouter, Link, useHistory, useParams } from "react-router-dom";
import { withTranslation } from "react-i18next";
import classNames from "classnames";
import TableComponent from '../components/TableComponent';
import {getLegalDocs , AddLegalDoc , updateLegalDoc} from './actions';
import NewTermsAndConditionForm from "./NewLegalDoc";
import ActionMenu from "../templates/ActionMenu";
import EditTermsAndConditionsModal from "./EditLegalDocModal";
import SimpleLoading from "../components/simpleLoading";

const LegalDocs = (props) => {
    const {t  , getAllLegalDocs , getResults} = props;
    const [loading ,  setLoading] = useState(false)
    const [dtParameter , setDtParameter] = useState({
        Current:1,
        pageSize:10
    })
    const [rows , setRows] = useState([])
    const [columns  , setColumns] = useState(null)
    const [showNewLegalDocForm,setShowNewLegalDocForm] = useState(false)
    const [showEditLegalDocFrom , setShowEditLegalDocFrom] = useState(false)
    const [LegalDocEditId  , setLegalDocEditId] = useState(0)
    const [errorMessage , setErrorMessage] = useState(null)
    const [legalText, setLegalText] = useState("")
    
    useEffect(()=>{
        props.getLegalDocs({
            dtParameter:dtParameter
        })
    },[])

    useEffect(()=>{
        if(!LegalDocEditId)
            return
        setShowEditLegalDocFrom(true)
    },[LegalDocEditId])

    
    useEffect(()=>{
        if(!dtParameter)
            return
        props.getLegalDocs({
            dtParameter:dtParameter
        })
    },[dtParameter])

    useEffect(()=>{
        if(!getAllLegalDocs||_.isEmpty(getAllLegalDocs))
            return
            setRows(
                getAllLegalDocs.data
                )
        if(!columns){
            setColumns(
                [...getAllLegalDocs.columns.filter(c => c.field != 'text'),
                    {field:'text' , headerName:'Text' ,flex:2 , 
                        renderCell:(param)=>(<div dangerouslySetInnerHTML={{__html: param.value}}/>)
                    },
                    {field:'Id', headerName:'Action' , flex:1, renderCell: (params) =>(
                        <ActionMenu id={params.row} handleDownload={() =>downloadFileInBrowser(params.row.versionId)} handleEdit = {()=>setLegalDocEditId(params.row.id)} 
                            handleViewHistory={()=>props.history.push("/legalDocHistory/"+params.row.id)} 
                        // handleViewAccepted={()=>props.history.push('/termsAndConditionsAcceptedUsers/'+params.row.id)}
                        />
                    )}
                ]
            )
        }
    },[getAllLegalDocs])

    


    useEffect(()=>{
        if(!getResults)
            return 
        if(getResults.type =='UPDATE_LEGAL_DOC'){
            setLoading(false)
            if(getResults.succeeded){
                setShowEditLegalDocFrom(false)
                props.getLegalDocs({
                    dtParameter:dtParameter
                })
            }
        }else if(getResults.type == 'ADD_LEGAL_DOC'){
            setLoading(false)
            if(getResults.succeeded){
                setShowNewLegalDocForm(false)
                props.getLegalDocs({
                    dtParameter:dtParameter
                })
            }
        }
    },[getResults])

    const submitLegalDoc = (values) => {
        setLoading(true)
        const data ={ 
            title : values.name,
            text : legalText,
            versionName:values.versionName,
            isVisible:values.activeInWebsite
        }
        props.AddLegalDoc(data)
    }

    const downloadFileInBrowser = async (id) => {
        
        // var header = authHeaders()
        // const config = {
        //     responseType: 'blob',
        //     headers:{
        //         ...header,
        //         'Access-Control-Expose-Headers':'Content-Disposition'
        //     }
        //   };
        // return await axios
        //   .get(`${apiRoutes.api}api/file/DownloadTermsAndConditions?id=${id}`, config)
        //   .then(response=>{
        //     console.log('file',response)
        //     const headerval = response.headers['content-disposition'];
        //     var fileName = headerval?.split(';')[1].split('=')[1].replace('"', '').replace('"', '');
        //     const url = window.URL.createObjectURL(new Blob([response.data]));
        //     const link = document.createElement('a');
        //     link.href = url;
        //     link.setAttribute('download', fileName); //or any other extension
        //     document.body.appendChild(link);
        //     link.click();       
        //   });
      };

    const editLegalDoc = (values) =>{
        if(values.isNew){
            setLoading(true)
            props.updateLegalDoc({
                Id: LegalDocEditId,
                text : values.text,
                versionName:values.versionName,
                title:values.title
            })
        }else if(values.activeVersion && values.version && values.version.value != -1){
            setLoading(true)
            props.updateLegalDoc({
                versionId:values.version.id,
                Id: LegalDocEditId,
                title:values.title
            })
        }
        
    }

    return(
        !columns?
        <SimpleLoading/>
        :
        <Fragment>
            <div className="mainHeader organizer font-size-24 mediumWeight">
                <div className="titleItems ">
                    {t("Legal document List")}    
                </div>
                <div className="filterItems">
                    <div onClick={()=>setShowNewLegalDocForm(true)} className="createNewPlan">
                        <span className="icon icon-add"></span>Add New Legal document
                    </div>
                </div>
            </div>
            {errorMessage?
                <div>{errorMessage}</div>
            :null}
            {!_.isEmpty(getAllLegalDocs) && columns?
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="adminBoilerplate organizer">
                        <TableComponent 
                            removeToolbar = {false}
                            pageSizes={[5,10,15]} 
                            totalCount={getAllLegalDocs?getAllLegalDocs.total:0} 
                            selectedList={[]} 
                            rows={rows} 
                            columns={columns}
                            setSelectedList={(e)=>{}} 
                            setDtParameter={setDtParameter} 
                            loading = {loading}
                            dtParameter = {dtParameter}
                            api = 'api/LegalDocument/GetAllLegalDocument'
                        />
                    </div>
                </div>
            </div>
            :null}

            {showNewLegalDocForm?    
                <NewTermsAndConditionForm
                    closeModal={()=>setShowNewLegalDocForm(false)} 
                    onSubmit={submitLegalDoc}
                    loading = {loading}
                    setLegalText = {setLegalText}
                />
            :null}
            {LegalDocEditId && showEditLegalDocFrom?
                <EditTermsAndConditionsModal 
                    id={LegalDocEditId}
                    closeModal={()=>setShowEditLegalDocFrom(false)} 
                    onSubmit={editLegalDoc}
                    loading = {loading}
                />
            :null}
            
        <br/>
        <br/>
        <br/>
        </Fragment>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        getAllLegalDocs: state.LegalDocsGetAllLegalDocs,
        getResults: state.LegalDocsGetResults
    };
  };
  export default (connect(mapStateToProps, {
    getLegalDocs,
    AddLegalDoc,
    updateLegalDoc
  })(withTranslation()(withRouter(LegalDocs))));