import React from 'react';
import classNames from 'classnames' ;
import logoIcon from '../../../img/logo-small.png'
const logo = (props)=>{
    return (
        <div className={classNames("navigationHeader", {'smallNav' : props.smallNav})} >
            <span style={{marginRight:'20px',marginLeft:'20px'}}onClick={props.onclick} className="icon icon-menu clickShape font-size-24 middleContext blueColor"></span>
            <img className={classNames("logoImage", {'smallNav' : props.smallNav})} src={logoIcon}/>
        </div>
    );
}

export default logo;