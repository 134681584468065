import {getUserMessagesAction} from "./actions";
import _ from 'lodash' ;

export default (state={} , action) => {

    switch (action.type) {
        case getUserMessagesAction.resultAction :
            return {...state , [action.payload.params.userId] : action.payload.data} ;
        default :
            return state ;
    }
};