import {ApprovedAsyncPersonsAction , DisApprovedAsyncPersonsAction} from "./actions";

export default (state=[] , action) => {
  switch (action.type) {
      case ApprovedAsyncPersonsAction.resultAction :
            return {...action.payload.data , action : 'Approved'} ;
      case DisApprovedAsyncPersonsAction.resultAction :
            return {...action.payload.data , action : 'DisApproved'} ;
      default :
            return state ;
  }
};