import {getTermsAndConditionHistoryAction } from "./actions";
import _ from 'lodash'

export default (state={} , action) => {
    switch (action.type) {
        case getTermsAndConditionHistoryAction.resultAction :
            return action.payload.data ;
        default:
            return state;
    }
}