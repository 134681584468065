import {GET , POST , PUT , POST_FORM} from "../common/Action";
import Action from "../common/Action";

//actions
export const GetAllApprovalRuleAction = new Action('GET_APPROVAL_RULES_ACTION' , POST , 'api/approvalRule/GetAll') ;
export const AddApprovalRuleAction = new Action('ADD_APPROVAL_RULE_ACTION' , POST , 'api/approvalRule/Add');
export const DeleteApprovalRuleAction = new Action('DELETE_APPROVAL_RULE_ACTION' , POST , 'api/approvalRule/Delete');
export const GetAllDataproviderAction = new Action("GET_ALL_DATAPROVIDER_ACTION" , GET , 'api/approvalRule/getAllDataprovider')
export const GetEntityTypesAction = new Action("GET_ENTITY_TYPES_ACTION" , GET , 'api/approvalRule/GetAllEntityType')
//action creators
export const GetAllApprovalRule = (data) => {
  return GetAllApprovalRuleAction.callApi(data) ;
};

export const AddApprovalRule = (data) =>{
  return AddApprovalRuleAction.callApi(data);
}

export const DeleteApprovalRule = (data) =>{
  return DeleteApprovalRuleAction.callApi(data)
}

export const getAllDataprovider = () =>{
  return GetAllDataproviderAction.callApi(null , {})
}

export const getAllEntityTypes = () =>{
  return GetEntityTypesAction.callApi(null , {})
}