import {getTopicsAction , getSubTopicsAction} from "./actions";

export default (state={} , action) => {
    switch (action.type) {
        case getTopicsAction.resultAction :
        case getSubTopicsAction.resultAction :
            return {...state , [action.payload.postedData.PageNumber] : action.payload.data.result} ;
        default :
            return state ;
    }
};