import React , {useState , useEffect , Fragment} from 'react' ;
import {connect} from 'react-redux' ;
import LeftNav from '../components/left-menu' ;
import Pagination from "../components/pagination";

const MainPage = props => {

    const {partnerRequests , partnerRequestsTotalCount} = props ;
    const [pageNumber , setPageNumber] = useState(1) ;
    const [viewByPage , setViewByPage] = useState(8) ;

    useEffect(() =>{
        const data = {
            PageNumber : pageNumber ,
            PageSize : viewByPage
        };
       // props.getSearchHistory(data) ;
    } , [pageNumber , viewByPage]) ;

    const updateList = (selectedPageNumber  , selectedViewByPage) => {
        setPageNumber(selectedPageNumber) ;
        setViewByPage(selectedViewByPage) ;
    };

  return (
      <Fragment>
              <div className="mainHeader organizer font-size-24 mediumWeight">
                  <div className="titleItems">
                      <span className="mainTitle middleContext">Partner Requests</span>
                      
                      
                  </div>
                  
              </div>
              <div className="adminBoilerplate organizer">
                  <table className="tableStructure organizer endBorderTable">
                      <thead className="tableHeader">
                      <th>Full Name</th>
                      <th>Email</th>
                      <th>Date</th>
                      <th>Type</th>
                      <th>Status</th>
                      <th></th>
                      </thead>
                      <tr>
                          <td>
                              Mr. Mohammad Pourskandar
                          </td>
                          <td>
                              mohammadpourskandar@gmail.com
                          </td>
                          <td>
                              07 Sep, 2019
                          </td>
                          <td>
                        <span className="tdInner">
                            <span className="lightGrayColor">
                            dear Voxiran, hi, I have a number of valuable spss files and …
                            </span>
                            <span className="giveAccess blueColor">
                                <span className="icon icon-more"></span>
                            </span>
                        </span>
                          </td>
                          <td>
                        <span className="tdInner">
                            <span className="greenColor icon icon-chrck">
                            </span>
                        </span>
                          </td>
                          <td>
                            <span className="giveAccess blueColor">
                                <span className="icon icon-attach"></span>
                            </span>
                          </td>
                      </tr>
                      <tr>
                          <td>
                              Mr. Mohammad Pourskandar
                          </td>
                          <td>
                              mohammadpourskandar@gmail.com
                          </td>
                          <td>
                              07 Sep, 2019
                          </td>
                          <td>
                        <span className="tdInner">
                            <span className="lightGrayColor">
                            dear Voxiran, hi, I have a number of valuable spss files and …
                            </span>
                            <span className="giveAccess blueColor">
                                <span className="icon icon-more"></span>
                            </span>
                        </span>
                          </td>
                          <td>
                        <span className="tdInner">
                            <span className="redColor icon icon-cross">
                            </span>
                        </span>
                          </td>
                          <td>
                            <span className="giveAccess blueColor">
                                <span className="icon icon-attach"></span>
                            </span>
                          </td>
                      </tr>
                      <tr>
                          <td>
                              Mr. Mohammad Pourskandar
                          </td>
                          <td>
                              mohammadpourskandar@gmail.com
                          </td>
                          <td>
                              07 Sep, 2019
                          </td>
                          <td>
                        <span className="tdInner">
                            <span className="lightGrayColor">
                            dear Voxiran, hi, I have a number of valuable spss files and …
                            </span>
                            <span className="giveAccess blueColor">
                                <span className="icon icon-more"></span>
                            </span>
                        </span>
                          </td>
                          <td>
                        <span className="tdInner">
                            <span className="yellowColor icon icon-time">
                            </span>
                        </span>
                          </td>
                          <td>
                            <span className="giveAccess blueColor">
                                <span className="icon icon-attach"></span>
                            </span>
                          </td>
                      </tr>
                  </table>
                  <Pagination changeData={updateList} totalPages={15}/>
              </div>
          </Fragment>
  ) ;
};

const mapStateToProps = (state , ownProps) => {
    return {

    } ;
};

export default connect(mapStateToProps , {})(MainPage) ;