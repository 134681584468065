import {addNewAutocompleteAction , deleteAutocompleteAction, editAutocompleteAction} from "./actions";

export default (state={} , action) => {
    switch (action.type) {
        case addNewAutocompleteAction.resultAction :
            return {...action.payload.data , action : 'NEW_AUTO'} ;
        case editAutocompleteAction.resultAction :
            return {...action.payload.data , action : 'EDIT_AUTO'} ;
        case deleteAutocompleteAction.resultAction :
            return {...action.payload.data , action : 'DELETE_AUTO'} ;
        case "FAILURE":
            return {...action.payload.data , action:'FAILURE'}
        default :
            return state ;
    }
};