import React , {useState , useEffect} from 'react'
import { Field, reduxForm } from 'redux-form'
import {connect} from "react-redux";
import { renderSimpleInputField , renderTextAreaField , renderDropdown , renderTextEditor } from "../components/formFields";
import _ from 'lodash' ;
import classNames from 'classnames' ;


const validate = (values) => {
    let errors = {};

    if (!values.name) {
        errors.name = 'Enter the Name' ;
    }
    if (!values.geoJson) {
        errors.geoJson = 'Enter the geo json' ;
    }

    return errors ;
};

let MapForm = props => {
    const { handleSubmit , resultMessage , isNew ,setShowModal , setIsNew} = props;
    return (
        <div className="columnSide oneForthColumn normalPadding">
            {resultMessage ? 
                <div className={classNames("topNormalMargin" , {'redColor' : !resultMessage.success , 'greenColor' : resultMessage.success})}>
                    {resultMessage.message}
                </div> 
            : null}
            <div className="mainInfo organizer">
                <div className="inputBox organizer">
                    <Field name="name" component={renderSimpleInputField} label="Name"/>
                </div>
                <div className="inputBox organizer">
                    <Field name="geoJson" component={renderTextAreaField} label="Geo json"/>
                </div>
            </div>
            <div className="organizer topUnitMargin">
                <div className={"normalButton inlineView blueBackground whiteColor"} onClick={()=>handleSubmit()}>
                    {isNew?"Add":"Save Changes"}
                </div>
                <div className={"normalButton inlineView grayOutline redColor leftNormalMargin"} onClick={()=>isNew?setIsNew(false):setShowModal(true)}>
                    {isNew?"Cancel":"Delete Map"}
                </div> 
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        
    };
};

const form = connect(mapStateToProps , {})(MapForm);

const ReduxMapForm = reduxForm({
    // a unique name for the form
    form: 'mapForm',
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    enableReinitialize : true ,
    validate
})(form);

export default ReduxMapForm;