import React, { Fragment, useState, useReducer} from 'react';
import Dropzone from 'react-dropzone';
import _ from 'lodash';
// import FileComponent from './FileComponent';
import axios from 'axios';
import UseToken from '../api-authorization/UseToken';

function reducer(state, action) {
    switch (action.type) {
      case 'progress':
        return {...state, [action.payload.fileName]: action.payload.progress};
      default:
        throw new Error();
    }
  }

const LogoUploader = ({ maxSize , insideBtn, onFileUploaded , type , setMessage }) => {
    

    const [uploading, setUploading] = useState({});
    const [state, dispatches] = useReducer(reducer, {});

    const token = UseToken()
    const onConfirm = (files) => {
        const newFiles = _.mapKeys(files, f => f.name);
        setUploading({...uploading, ...newFiles});
        files.forEach(upload);
        // if(!token){
        //     setMessage('Something Unexpectedly Happened ! Please Try Again Later.')
        // }else{
        //     files.forEach(upload);
        // }
    };

    
    const upload = (file) => {
        const formData = new FormData();
        formData.append(
            "upload",
            file,
            file.name
        );


        formData.append("type" , type+"")

        axios
            .post(process.env.REACT_APP_FILE_API+'/api/file/UploadLogo' , formData, {
                onUploadProgress: (progressEvent) => {
                    var filename = file.name;
                    const percentage = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                        );
                    dispatches({type: 'progress', payload: {
                            fileName: filename,
                            progress: percentage,
                        }})
                },
                headers: {
                    'Authorization' : `Bearer ${token}`,
                    'Content-Type':'multipart/form-data'
                }
            })
            .then((resp) => {
                if(resp.data.succeeded){
                    onFileUploaded(resp.data.key, file.name, file.size);
                    
                    const { [file.name]: _, ...newData } = uploading;
                    setUploading(newData);
                }
            })
            .catch((error) => console.error(error));
    }

    return (
        <>
            <Dropzone
            onDrop={onConfirm}
            //accept="image/png, image/gif"
            minSize={0}
            maxSize={maxSize}
            >
            {({
                getRootProps, 
                getInputProps, 
                isDragActive, 
                isDragReject, 
                fileRejections  ,
                acceptedFiles
            }) => {
                const message =
                fileRejections.length > 0 ? fileRejections[0].errors[0].message : null;
        
                return (
                        <div {...getRootProps()}>
                            <input {...getInputProps()} /> 
                            {insideBtn?
                                insideBtn
                                :
                                <div className="createNewPlan"  style={{cursor:'pointer' , margin:'5px'}}>
                                        <span className="icon icon-add">Upload File</span>
                                </div>
                            }
                        </div>
                    
                )}}
            </Dropzone>
            
        {_.map(uploading, f  =>
            (
                <div key={f.name} className='topUnitMargin col-lg-8 col-md-8 col-sm-12' style={{paddingLeft: 0}}>
                    {state[f.name]<100 ? (
                        <>
                            <div className="uploadedcartContentRow2">
                            <div className="progress">
                                <div
                                className="progress-bar progress-bar-striped progress-bar-animated"
                                role="progressbar"
                                aria-valuenow="100"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style={{ width: `${state[f.name]}%` }}
                                ></div>
                            </div>
                            </div>
                            <div className="uploadedcartContentRow3">
                            <span>%{state[f.name]} Uploaded</span>
                            </div>
                        </>
                        ) : null}
                    {/* <FileComponent fileName={f.name} fileSize={f.size} isUploading={state[f.name]<100} uploadPecent={state[f.name]} /> */}
                </div>
                
            )
        )}
        </>
  );
}


  export default LogoUploader;