import React , {useState , useEffect} from 'react' ;
import {connect} from 'react-redux' ;
import LeftNav from '../components/left-menu' ;
import TableComponent from '../components/TableComponent';
import {GetApprovableAsyncTopic , ApprovedAsyncTopics , DisApprovedAsyncTopics} from "./actions";
import _ from 'lodash'
import { Fragment } from 'react';
import cssClasses from '../ApprovedVendors/ApprovedVendorListStyles.module.css'
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import SimpleLoading from '../components/simpleLoading';
const MainPage = props => {
    const {topicList , ApprovedActionResult} = props
    const [selectedTopics , setSelectedTopics] = useState([])
    const [rows , setRows] = useState(null)
    const [total , setTotal] = useState(0)
    const [dtParameter , setDtParameter] = useState({current:1 , pageSize:10 , filter:null})
    const [loading , setLoading] = useState(false)
    const [columns , setColumns] = useState(null
        // {field:'email' , headerName:'email' , flex:1}
    //   {field:'uniqueUrl' , headerName:t('Unique URL'),flex:1},
    //   {field:'description' , headerName:t('Description'),flex:1},
    //   {field:'id',headerName:t('َActions') , flex:0.5 , renderCell: (params) => (
    //     <Fragment>
    //     <div className="cardListDetailToolsItem blueBackground clickShape whiteColor" 
    //       onClick={()=>history.push(`/datasetRegistration/${params.value}`)}>
                  
    //                   <span className="icon icon-edit whiteColor"></span>
    //             </div>  
    //             <div
    //             onClick={() =>
    //               deleteDataEntry(params.value)
    //             }
    //             className="cardListDetailToolsItem redBackground clickShape whiteColor"
    //           >
                
    //             <span className="icon icon-close whiteColor"></span>
    //         </div> 
    //         </Fragment>
    //   )}
    )

    

    useEffect(() => {
        setLoading(true)
        props.GetApprovableAsyncTopic({dtParameter:dtParameter}) ;
    } , [dtParameter]) ;


    useEffect(()=>{
        if(!ApprovedActionResult)
            return
        setSelectedTopics([])
        if(ApprovedActionResult.succeeded){
            props.GetApprovableAsyncTopic({dtParameter:{...dtParameter ,current:1, dir:"desc",orderBy:"lastChangeStatusDate"}})
            setDtParameter({...dtParameter , current:1 ,dir:"desc",orderBy:"lastChangeStatusDate" })
        
        }else{
            console.log('Error happend try again later')
        }
    },[ApprovedActionResult])


    useEffect(()=>{
        if(!topicList||_.isEmpty(topicList))
            return
        setColumns(topicList.columns.map(col=>{
            if(!col.hasRenderCell)
                return col
            if(col.field&&col.field.includes('image')||col.field=='banner'){
                return ({...col , renderCell:(param)=>(<img src={param.value}/>)})
            }
            if(col.field&&col.type=='list'){
                return ({...col , type:null , renderCell:(params)=>(
                    _.map(
                          params.value,
                          (key , index) => {
                                return (
                                  <span
                                  key={index}
                                  className={cssClasses.keyItem}
                                  >
                                    {key}
                                  </span>
                                );
                              }
                              )
                            
                    
                  )})
            }}))
        
        setLoading(false)
        setRows(topicList.data)
        setTotal(topicList.total)
    },[topicList])

    const approveTopics = ()=>{
        if(_.isEmpty(selectedTopics))
            return
        const approvalDto = {ids:selectedTopics , approvedStatus:2 , adminMessage:''}
        props.ApprovedAsyncTopics(approvalDto)
    }
    const NotApproveTopics = ()=>{
        if(_.isEmpty(selectedTopics))
            return
        const approvalDto = {ids:selectedTopics , approvedStatus:3 , adminMessage:''}
        props.DisApprovedAsyncTopics(approvalDto)
    }

    

    

    return (
        <>
            <div className="mainHeader organizer font-size-24 mediumWeight">
                <div className="titleItems">
                    <span className="mainTitle middleContext">Approved Topics</span>
                </div>
                <div className="filterItems">
                    <div className="filters inlineView">
                        <div className="changeDate inlineView"></div>
                        <div className="accessLevel inlineView"></div>
                    </div>
                    {!_.isEmpty(selectedTopics)?
                    <Fragment>
                    {!_.isEmpty(selectedTopics.filter(d=> d.approvedStatus!=2))?    
                        <span style={{marginRight:'10px'}} onClick={approveTopics} className={classNames(cssClasses.cursorPointer , "createNewPlan font-size-18")}>
                            Approve
                        </span>
                    :null}
                    {!_.isEmpty(selectedTopics.filter(d=> d.approvedStatus!=3))?            
                        <span onClick={NotApproveTopics} className={classNames(cssClasses.cursorPointer,"font-size-18 createNewPlan")}>
                            DisApprove
                        </span>
                    :null}
                    </Fragment>
                    :null}
                </div>
            </div>
            {rows&&columns?
              <div className="adminBoilerplate organizer">
                    <TableComponent
                        pageSizes={[5,10,15]} 
                        checkboxSelection={true}
                        totalCount={total} 
                        selectedList = {selectedTopics}
                        setSelectedList={setSelectedTopics} 
                        setDtParameter={setDtParameter} 
                        loading = {loading}
                        dtParameter = {dtParameter}
                        rows={rows} 
                        columns={columns}
                        getRowClassName={(params) => 
                            cssClasses[`row--${params.row.approvedStatus.split(' ').join('')}`]
                        }
                        api = 'api/topics/GetApprovable'
                        />
              </div>
            :<SimpleLoading/>}
        </>
  ) ;
};

const mapStateToProps = (state , ownProps) => {
    return {
        topicList : state.ApprovedAsyncTopics,
        ApprovedActionResult : state.ApprovedTopicsResults
    } ;
};

export default connect(mapStateToProps , {GetApprovableAsyncTopic , ApprovedAsyncTopics , DisApprovedAsyncTopics})(withRouter(MainPage)) ;


