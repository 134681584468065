import React ,{useEffect , useState , Fragment} from 'react' ;
import {connect} from 'react-redux' ;
import {Link  , withRouter} from 'react-router-dom' ;
import classNames from 'classnames' ;
import _ from 'lodash' ;
import Logo from './leftNav/Logo';
import NavigationItem from './leftNav/NavigationItem'
import { change } from 'redux-form';
const LeftMenu = props => {

    const [showHistoryMenu , setShowHistoryMenu] = useState(props.location.pathname.includes('History'));
   // const [smallNav , setSmallNav] = useState(false) ;
    const smallNav = props.smallNav;
    const setSmallNav = props.setSmallNav
    const [activeList,setActiveList]=useState([true,false,false,false,false,false]);

   const [newMassage,setNewMassage]=useState(true);

    const changeMessageAlart=()=>{
        setNewMassage(!newMassage);
    }
    

    
    let list=[
        {
            name:"Users & Information",
            iconList:[
                {
                    name:"Dashboard",
                    path:"dashboard",
                    class:"icon-dashboard",
                },
                {
                    name:"History",
                    path:"",
                    class:"icon-history",
                    icons:
                    {
                        active:activeList[5],
                        active_id:5,
                        list:
                        [
                            {
                                name:"Usage History",
                                path:"usageHistory",
                                class:"icon-memory"
                            },
                            {
                                name:"Search History",
                                path:"searchHistory",
                                class:"icon-zoom"
                            },
                            {
                                name:"Folder History",
                                path:"folderHistory",
                                class:"icon-folder"
                            },


                        ]
                    }
                },
                {
                    name:"Users",
                    path:"users",
                    class:"icon-user"
                },
                {
                    name:"Messages",
                    path:"message",
                    class:"icon-message"
                }
                // ,
                // {
                //     name:"Upgrade Requests",
                //     path:"upgradeRequests",
                //     class:"icon-upgrade"
                // },
                // {
                //     name:"Commissions",
                //     path:"commissions",
                //     class:"icon-commission"
                // }
            ]
        },
        {
            name:"Approval",
            iconList:[
                {
                    name:"Dataset",
                    path:"approvedDatasets",
                    class:"icon-dataset"
                }, 
                {
                    name:"Standard",
                    path:"approvedStandards",
                    class:"icon-dataset"
                },
                {
                    name:"Vendor",
                    path:"approvedVendors",
                    class:"icon-dataset"
                },
                {
                    name:"Vendor Associations",
                    path:"approvedAssociations",
                    class:"icon-dataset"
                },
                {
                    name:"Person",
                    path:"approvedPersons",
                    class:"icon-dataset"
                },
                {
                    name:"Field value",
                    path:"approvedFieldValues",
                    class:"icon-dataset"
                },
                {
                    name:"Contracts",
                    path:"approvedContracts",
                    class:"icon-dataset"
                },
                {
                    name:"Topic",
                    path:"approvedTopics",
                    class:"icon-topic"
                },
                {
                    name:"Data Roles",
                    path:"approvedDataRoles",
                    class:"icon-dataset"
                }
            ]
        },
        {
            name:"Bacis Information",
            iconList:[
                {
                    name:"Topics",
                    path:"topics",
                    class:"icon-topic"
                },
                {
                    name:"Maps",
                    path:"maps",
                    class:"icon-map"
                },
                // {
                //     name:"Partner Requests",
                //     path:"partnerRequest",
                //     class:"icon-partners"
                // },
                {
                    name:"Access Levels",
                    path:"accessLevels",
                    class:"icon-plan"
                }
            ]
        },
        {
            name:"Contents",
            iconList:[
                {
                    name:"Website Contents",
                    path:"websiteContent",
                    class:"icon-content"
                },
                {
                    name:"Custom Pages",
                    path:"customPages",
                    class:"icon-page"
                },{
                    name:"meta tags",
                    path:"metaTags",
                    class:"icon-page"
                },
                {
                    name:"Autocomplete",
                    path:"autocomplete",
                    class:"icon-autocomplete"
                },
                {
                    name:"Geographic Regions",
                    path:"geographicRegions",
                    class:"icon-map"
                },
                {
                    name:"Resources",
                    path:"resources",
                    class:"icon-label"
                },
                {
                    name:"Logos",
                    path:"logos",
                    class:"icon-logo"
                },
                {
                    name:"Banner",
                    path:"defaultBanner",
                    class:"icon-logo"
                },
                {
                    name:"FAQ Contents",
                    path:"faq",
                    class:"icon-question"
                },
                {
                    name:"Languages",
                    path:"languages",
                    class:"icon-language"
                },
                {
                    name:"Restricted Extension",
                    path:"restrictedExtension",
                    class:"icon-upload"
                },
                {
                    name:"Contracting",
                    path:"contracting",
                    class:"icon-upload"
                },{
                    name:'Terms and conditions Templates',
                    path:'templates',
                    class:'icon-page'
                },
                {
                    name:'Legal documents',
                    path:'legalDocs',
                    class:'icon-page'
                }
                ,
                {
                    name:'Approval Rules',
                    path:'ApprovalRules',
                    class:'icon-page'
                }
            ]
        }
        // ,
        // {
        //     name:"Other",
        //     iconList:[
        //         {
        //             name:"Website Settings",
        //             path:"websiteSettings",
        //             class:"icon-settings"
        //         },
        //         {
        //             name:"Email Server",
        //             path:"emailServer",
        //             class:"icon-email"
        //         }
        //     ]
        // }

    ];
    const changeSmallNav=()=>{
        setSmallNav(!smallNav)
    }
    const change=(id)=>{ 
        const arr = [...activeList];
        arr[id] = !activeList[id];
        setActiveList([...arr]);
    }

    return (
        <div className={classNames("navDiv" , {'smallNav' : smallNav})}>
            <Logo smallNav={smallNav} onclick={changeSmallNav}/>
                <nav className={classNames("mainNavigationSystem", {'smallNav' : smallNav})}>
            <div >
            {
            list.map((navigation,index)=>{
                
                return(
                    <NavigationItem key={index} id={index} onclick={change}active={activeList[index]} location={props.location} name={navigation.name} iconList={navigation.iconList}/>
                    )
                
                }

            )}  

             </div>
           
            
            
            
        </nav>
            
        
        </div>
    );
};

const mapStateToProps = (state , ownProps) => {
    return {} ;
};

export default withRouter(connect(mapStateToProps , {})(LeftMenu)) ;


/*<div className="navigationHeader">
                <span onClick={() => setSmallNav(!smallNav)} className="icon icon-menu clickShape font-size-24 middleContext blueColor"></span>
                <img className="logoImage middleContext" src={require("../../img/logo-small.png")}/>
            </div>
            <div className="navigationItem">
                <h2 className="font-size-24 mediumWeight">
                    Users & Information
                </h2>
                <ul>
                    <li className={classNames("" , {"active" : _.includes(props.location.pathname , '/dashboard')})}>
                        <Link to="/dashboard" style={{textDecoration : "none"}}>
                        <span className="icon icon-dashboard"></span>
                        <span className="listText">Dashboard</span>
                        </Link>
                    </li>
                    <li className="clickShape" onClick={() => setShowHistoryMenu(!showHistoryMenu)}>

                        <span className="icon icon-history"></span>
                        <span className="listText">History</span>
                        <span className={classNames("pointArrow icon font-size-14 rightFloat" , {"icon-up-direction" : showHistoryMenu , "icon-bottom-direction" : !showHistoryMenu})}></span>

                        {showHistoryMenu ? <ul className="innerList">
                            <li className={classNames("" , {"active" : _.includes(props.location.pathname , '/usageHistory')})}>
                                <Link to="/usageHistory" style={{textDecoration : "none"}}>
                                <span className="icon icon-memory"></span>
                                <span className="listText">Usage</span>
                                </Link>
                            </li>
                            <li className={classNames("" , {"active" : _.includes(props.location.pathname , '/searchHistory')})}>
                                <Link to="/searchHistory" style={{textDecoration : "none"}}>
                                <span className="icon icon-zoom"></span>
                                <span className="listText">Searches</span>
                                </Link>
                            </li>
                            <li className={classNames("" , {"active" : _.includes(props.location.pathname , '/folderHistory')})}>
                                <Link to="/folderHistory" style={{textDecoration : "none"}}>
                                <span className="icon icon-folder"></span>
                                <span className="listText">Folders</span>
                                </Link>
                            </li>
                        </ul> : null}
                    </li>
                    <li className={classNames("" , {"active" : _.includes(props.location.pathname , '/users')})}>
                        <Link to="/users" style={{textDecoration : "none"}}>
                        <span className="icon icon-user"></span>
                        <span className="listText">Users</span>
                        </Link>
                    </li>
                    <Link to="/messages" style={{textDecoration : "none"}}>
                    <li className={classNames("alert" , {"active" : _.includes(props.location.pathname , '/messages')})}>
                        <span className="icon icon-message"></span>
                        <span className="listText">Messages</span>
                        <span className="activeBullet">

                    </span>
                    </li>
                    </Link>
                    <li className={classNames("" , {"active" : _.includes(props.location.pathname , '/commissions')})}>
                        <Link to="/commissions" style={{textDecoration : "none"}}>
                        <span className="icon icon-commission"></span>
                        <span className="listText">Commissions</span>
                        </Link>
                    </li>
                </ul>
            </div>
            <div className="navigationItem">
                <h2 className="font-size-24 mediumWeight">Dataset</h2>
                <ul>
                    <li className={classNames("" , {"active" : _.includes(props.location.pathname , '/newDataset')})}>
                        <span className="icon icon-topic"></span>
                        <span className="listText">New Dataset</span>
                    </li>
                    <li className={classNames("" , {"active" : _.includes(props.location.pathname , '/dataset')})}>
                        <span className="icon icon-dataset"></span>
                        <span className="listText">Dataset</span>
                    </li>
                </ul>
            </div>
            <div className="navigationItem">
                <h2 className="font-size-24 mediumWeight">Basic Information </h2>
                <ul>
                    <li className={classNames("" , {"active" : _.includes(props.location.pathname , '/topics')})}>
                        <Link to="/topics" style={{textDecoration : "none"}}>
                        <span className="icon icon-topic"></span>
                        <span className="listText">Topics</span>
                        </Link>
                    </li>
                    <li className={classNames("" , {"active" : _.includes(props.location.pathname , '/maps')})}>
                        <Link to="/maps" style={{textDecoration : "none"}}>
                        <span className="icon icon-map"></span>
                        <span className="listText">Maps</span>
                        </Link>
                    </li>
                    <li className={classNames("" , {"active" : _.includes(props.location.pathname , '/owners')})}>
                        <span className="icon icon-owner"></span>
                        <span className="listText">Owners</span>
                    </li>
                    <li className={classNames("" , {"active" : _.includes(props.location.pathname , '/partnerRequest')})}>
                        <Link to="/partnerRequest" style={{textDecoration : "none"}}>
                        <span className="icon icon-partner"></span>
                        <span className="listText">Partner Requests</span>
                        </Link>
                    </li>
                    <li className={classNames("" , {"active" : _.includes(props.location.pathname , '/plans')})}>
                        <Link to="/plans" style={{textDecoration : "none"}}>
                        <span className="icon icon-plan"></span>
                        <span className="listText">Plans</span>
                        </Link>
                    </li>
                </ul>
            </div>
            <div className="navigationItem">
                <h2 className="font-size-24 mediumWeight">Contents</h2>
                <ul>
                    <li className={classNames("" , {"active" : _.includes(props.location.pathname , '/websiteContent')})}>
                        <Link to="/websiteContent" style={{textDecoration : "none"}}>
                        <span className="icon icon-content"></span>
                        <span className="listText">Website Contents</span>
                        </Link>
                    </li>
                    <li className={classNames("" , {"active" : _.includes(props.location.pathname , '/customPages')})}>
                        <Link to="/customPages" style={{textDecoration : "none"}}>
                        <span className="icon icon-page"></span>
                        <span className="listText">Custom Pages</span>
                        </Link>
                    </li>
                    <li className={classNames("" , {"active" : _.includes(props.location.pathname , '/labels')})}>
                        <Link to="/labels" style={{textDecoration : "none"}}>
                        <span className="icon icon-label"></span>
                        <span className="listText">Labels</span>
                        </Link>
                    </li>
                    <li className={classNames("" , {"active" : _.includes(props.location.pathname , '/logos')})}>
                        <Link to="/logos" style={{textDecoration : "none"}}>
                        <span className="icon icon-logo"></span>
                        <span className="listText">Logos</span>
                        </Link>
                    </li>
                    <li className={classNames("" , {"active" : _.includes(props.location.pathname , '/faq')})}>
                        <Link to="/faq" style={{textDecoration : "none"}}>
                        <span className="icon icon-question"></span>
                        <span className="listText">FAQ Contents</span>
                        </Link>
                    </li>
                    <li className={classNames("" , {"active" : _.includes(props.location.pathname , '/languages')})}>
                        <Link to="/languages" style={{textDecoration : "none"}}>
                        <span className="icon icon-language"></span>
                        <span className="listText">Languages</span>
                        </Link>
                    </li>
                </ul>
            </div>
            <div className="navigationItem">
                <h2 className="font-size-24 mediumWeight">Other</h2>
                <ul>
                    <li  className={classNames("" , {"active" : _.includes(props.location.pathname , '/websiteSettings')})}>
                        <span className="icon icon-settings"></span>
                        <span className="listText">Website Settings</span>
                    </li>
                    <li className={classNames("" , {"active" : _.includes(props.location.pathname , '/emailServer')})}>
                        <span className="icon icon-email"></span>
                        <span className="listText">Email Server</span>
                    </li>
                </ul>
            </div>
        </nav> */